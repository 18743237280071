import { IEntityTemplate } from '@mitie/metadata-api-types';
import { action, makeObservable } from 'mobx';

import { Template } from './template';
import { stores } from 'store';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { Status } from 'DataTypes';
import * as TemplatesApi from '../api/templates';

export class EntityTemplate extends Template<IEntityTemplate> {
	public constructor(id: string) {
		super(id, 'entity');

		makeObservable(this, { discardChanges: action, save: action });
	}

	public get modified() {
		if (!this.data || !this.savedData) {
			return false;
		}

		if (!isEqual(this.data, this.savedData)) {
			return true;
		}

		if (this.data.template.points_templates) {
			for (const epTemplateId of this.data.template.points_templates) {
				const epTemplate = stores.entityPointTemplates.getTemplate(epTemplateId);

				if (epTemplate?.modified) {
					return true;
				}
			}
		}

		return false;
	}

	public discardChanges() {
		if (this.savedData) {
			const originalEntityPoints = this.savedData.template.points_templates;

			if (originalEntityPoints) {
				for (const originalEntityPoint of originalEntityPoints) {
					const ptTemplate = stores.entityPointTemplates.addAndGetTemplate(originalEntityPoint);
					ptTemplate.discardChanges();
				}
			}

			this.data = cloneDeep(this.savedData);
		} else {
			stores.entityTemplates.deleteTemplate(this);
		}
	}

	public async save() {
		if (this.unsaved) {
			this.saveRequest = Status.Loading;

			try {
				if (this.created && this.data) {
					const { templateData, updatedTime } = await TemplatesApi.createTemplate<IEntityTemplate>(
						this.id,
						this.templateType,
						this.data,
					);
					this.setSavedData(templateData, updatedTime, true);
				} else if (this.deleted) {
					await TemplatesApi.deleteTemplate(this.id, this.templateType);

					stores.entityTemplates.deleteTemplate(this);
					this.dataRequest = Status.Done;
				} else if (this.modified && this.data) {
					const { templateData, updatedTime } = await TemplatesApi.updateTemplate<IEntityTemplate>(
						this.id,
						this.templateType,
						this.data,
					);
					this.setSavedData(templateData, updatedTime, true);
				}

				const entityPoints = this.data?.template.points_templates;

				if (entityPoints) {
					for (const entityPoint of entityPoints) {
						const ptTemplate = stores.entityPointTemplates.addAndGetTemplate(entityPoint);
						ptTemplate.save();
					}
				}

				this.saveRequest = Status.Done;
			} catch (error: any) {
				this.saveRequest = Status.Error;
				throw error;
			}
		}
	}
}
