import { useContext, useEffect } from 'react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Route, Routes, Navigate } from 'react-router-dom';

import CustomTheme from './Theme';
import { routes } from './routing/routes';
import SecuredPage from 'pages/SecuredPage';
import { AlertsContext } from 'store/AlertsProvider';
import ErrorWithHttpCode from 'tools/ErrorWithHttpCode';

export default observer(function App() {
	const { addAlert } = useContext(AlertsContext);

	useEffect(() => {
		window.addEventListener('error', (ev) => {
			addAlert('error', ev.message);
		});

		window.addEventListener('unhandledrejection', (ev) => {
			const error: Error = ev.reason;

			if (error instanceof ErrorWithHttpCode) {
				addAlert('error', error.message, error.code);
			} else {
				addAlert('error', error.message);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<CustomTheme>
			<Box
				sx={{
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: (theme) => theme.palette.background.default,
					color: (theme) => theme.palette.text.primary,
				}}
			>
				<Routes>
					{routes.map((route) => (
						<Route
							key={route.path}
							path={route.path}
							element={
								route.secured ? (
									<SecuredPage pageName={route.label} component={route.component} />
								) : (
									<div style={{ width: '100%', height: '100%' }}>{route.component}</div>
								)
							}
						/>
					))}
					<Route path="/" element={<Navigate to={(process.env.REACT_APP_DIRECTORY_NAME || '') + '/entities'} />} />
				</Routes>
			</Box>
		</CustomTheme>
	);
});
