import { observer } from 'mobx-react-lite';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Chip, LinearProgress, Tooltip, Typography } from '@mui/material';
import { Info } from '@mui/icons-material';
import classNames from 'classnames';

import DeviceTwinTagsList from './DeviceTwinTagsList';
import DeviceTwinChannels from './DeviceTwinChannels';
import Entity from 'store/entity';
import DeviceReadOnlyProperty from './DeviceReadOnlyProperty';
import { Status } from 'DataTypes';
import DeviceTwinProperty from './DeviceTwinProperty';
import EntityProperty from './EntityProperty';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
		errorbadge: {
			background: theme.palette.error.main,
			color: theme.palette.secondary.contrastText,
		},
		okBadge: {
			background: 'green',
			color: theme.palette.secondary.contrastText,
		},
		statusChip: {
			marginRight: theme.spacing(1),
		},
		sectionTitle: {
			marginTop: '1rem',
			display: 'flex',
			alignItems: 'center',
			'&>p': {
				fontSize: '16px',
				fontWeight: 500,
				display: 'inline',
				marginRight: '1rem',
			},
		},
	}),
);

interface IDeviceTwinProps {
	entity: Entity;
	canEdit: boolean;
}

export default observer(function DeviceTwin({ entity, canEdit }: IDeviceTwinProps) {
	const classes = useStyles();
	const lastReported = entity.deviceReportedConfigData?.modifiedTime;

	return (
		<Box className={classes.root}>
			{(entity.deviceDataRequest === Status.Loading || entity.deviceReportedConfigRequest === Status.Loading) && (
				<LinearProgress />
			)}
			{entity.deviceTemplate?.data?.template.device_twin && (
				<>
					<Box className={classes.sectionTitle}>
						<Typography>Status</Typography>
						{entity.deviceOnline !== undefined && (
							<Tooltip title={entity.deviceStatusString} placement="right">
								<Chip
									label={entity.deviceOnline ? 'Online' : 'Offline'}
									size="small"
									className={classNames(classes.statusChip, {
										[classes.okBadge]: entity.deviceOnline,
										[classes.errorbadge]: !entity.deviceOnline,
									})}
								/>
							</Tooltip>
						)}
					</Box>
					<Box className={classes.sectionTitle}>
						<Typography>Device ID</Typography>
					</Box>
					<Box>
						<EntityProperty
							label="Device ID"
							type="string"
							value={entity.id}
							disabled={true}
							className={classes.field}
						/>
					</Box>
					<Box className={classes.sectionTitle}>
						<Typography>Twin tags</Typography>
					</Box>
					<Box>
						<DeviceTwinTagsList tags={entity?.deviceConfigData?.tags || {}} />
					</Box>
					<Box className={classes.sectionTitle}>
						<Typography>Twin properties</Typography>
						<Tooltip
							title={
								lastReported
									? `Last reported by device: ${lastReported.toLocaleString()}`
									: 'No configuration reported yet'
							}
							placement="right"
						>
							<Info sx={{ cursor: 'pointer' }} color="action" />
						</Tooltip>
					</Box>
					<Box>
						{entity.deviceTemplate.data.template.device_twin.properties.map((propertyDefinition) => {
							const property = entity!.getDeviceConfigProperty(propertyDefinition.name);
							const reportedProperty = entity!.getDeviceReportedConfigProperty(propertyDefinition.name);

							if (propertyDefinition.readOnly) {
								return (
									<DeviceReadOnlyProperty
										key={propertyDefinition.name}
										label={propertyDefinition.label}
										type={propertyDefinition.type}
										reported={reportedProperty}
										className={classes.field}
									/>
								);
							} else {
								return (
									<DeviceTwinProperty
										key={propertyDefinition.name}
										label={propertyDefinition.label}
										type={propertyDefinition.type}
										select={propertyDefinition.select}
										value={property}
										reported={reportedProperty}
										onChange={(v) => entity!.setDeviceConfigProperty(propertyDefinition.name, v)}
										className={classes.field}
										disabled={!canEdit}
									/>
								);
							}
						})}
					</Box>
					<Box className={classes.sectionTitle}>
						<Typography>Twin channels</Typography>
					</Box>
					<Box>
						{entity.deviceConfigData?.channels && (
							<DeviceTwinChannels
								desired={entity.deviceConfigData.channels}
								reported={entity.deviceReportedConfigData?.channels}
							/>
						)}
					</Box>
				</>
			)}
		</Box>
	);
});
