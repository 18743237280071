import React, { useState, useEffect } from 'react';
import {
	Box,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	InputAdornment,
	TextField,
	Typography,
} from '@mui/material';
import { TreeView, TreeItem, TreeItemProps, TreeItemContentProps, useTreeItem } from '@mui/lab';
import { ChevronRight, ExpandMore, FilterAlt } from '@mui/icons-material';
import classNames from 'classnames';
import { IApiEntity } from '@mitie/metadata-api-types';

import useDebounce from 'hooks/useDebounce';
import * as EntitiesApi from 'api/entities';
import { Status } from 'DataTypes';
import { createTreeFromArray, IFlatTreeNode, ITreeNode } from 'utils';

interface IEntityTreeMultiselectProps {
	entityType: string;
	selected?: string[];
	onSelect: (selected: string[]) => void;
}

type CustomTreeItemProps = {
	isLoading: boolean;
	isSelectable: boolean;
};

const CustomContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
	const { classes, className, label, nodeId, expansionIcon, isLoading, isSelectable } = props as TreeItemContentProps &
		CustomTreeItemProps;

	const { disabled, expanded, selected, handleExpansion, handleSelection, preventSelection } = useTreeItem(nodeId);

	const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		preventSelection(event);
	};

	const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		handleExpansion(event);
	};

	const handleSelectionClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleSelection(event);
	};

	const labelNode = (
		<Typography
			sx={{ display: 'inline', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', flex: 1 }}
			component="span"
		>
			{label}
		</Typography>
	);

	return (
		<Box
			className={classNames(className, classes.root, {
				[classes.expanded]: expanded,
				[classes.disabled]: disabled,
			})}
			onMouseDown={handleMouseDown}
			ref={ref as React.Ref<HTMLDivElement>}
			sx={{ padding: '0 !important' }} // Overwrite default padding that messes up with overflow
		>
			<div onClick={handleExpansionClick} className={classes.iconContainer}>
				{expansionIcon}
			</div>
			{isSelectable ? (
				<FormControlLabel
					control={<Checkbox checked={selected} onChange={handleSelectionClick} size="small" sx={{ padding: '3px' }} />}
					label={labelNode}
					key={nodeId}
					className={classes.label}
					sx={{ marginLeft: 0, '&>.MuiFormControlLabel-label': { flexGrow: 1 } }}
				/>
			) : (
				labelNode
			)}
			{isLoading && (
				<Box sx={{ width: '32px' }}>
					<CircularProgress size={16} />
				</Box>
			)}
		</Box>
	);
});

const CustomTreeItem = ({ isLoading, isSelectable, ...props }: TreeItemProps & CustomTreeItemProps) => (
	<TreeItem ContentComponent={CustomContent} ContentProps={{ isLoading, isSelectable } as any} {...props} />
);

export default function RiskRegisterTreeMultiselect({ entityType, selected, onSelect }: IEntityTreeMultiselectProps) {
	const [treeItems, setTreeItems] = useState<{ [id: string]: IFlatTreeNode<IApiEntity> }>({});
	const [tree, setTree] = useState<ITreeNode<IApiEntity>[]>([]);
	const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
	const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
	const [filter, setFilter] = useState<string>('');
	const debouncedFilter = useDebounce(filter, 250);

	// Load top level data for tree
	useEffect(() => {
		loadTopLevelEntities();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Update tree once flat list has been updated
	useEffect(() => {
		setTree(createTreeFromArray(treeItems, debouncedFilter));
	}, [treeItems, debouncedFilter]);

	const loadTopLevelEntities = async () => {
		const data = await EntitiesApi.fetchRootEntities('locations');
		const initialTreeData: { [id: string]: IFlatTreeNode<IApiEntity> } = Object.fromEntries(
			data.map((entity) => [
				entity.id,
				{
					id: entity.id,
					name: entity.name,
					data: entity,
					childrenLoadStatus: Status.None,
				},
			]),
		);

		setTreeItems(initialTreeData);
	};

	const loadChildren = async (parentNode: IFlatTreeNode<IApiEntity>) => {
		setTreeItems((current) => ({
			...current,
			[parentNode.id]: { ...parentNode, childrenLoadStatus: Status.Loading },
		}));

		try {
			const data = await EntitiesApi.fetchEntitiesChildren(parentNode.id, 'location', false);
			const childrenNodes: { [id: string]: IFlatTreeNode<IApiEntity> } = Object.fromEntries(
				data
					.filter((entity) => entity.tags.includes('location')) // Only include locations (ie not assets, devices, etc.)
					.map((childEntity) => [
						childEntity.id,
						{
							id: childEntity.id,
							parentId: parentNode.id,
							name: childEntity.name,
							data: childEntity,
							childrenLoadStatus: Status.None,
						} as IFlatTreeNode<IApiEntity>,
					]),
			);

			setTreeItems((current) => ({
				...current,
				[parentNode.id]: { ...parentNode, childrenLoadStatus: Status.Done },
				...childrenNodes,
			}));
		} catch (e) {
			setTreeItems((current) => ({
				...current,
				[parentNode.id]: { ...parentNode, childrenLoadStatus: Status.Error },
			}));
		}
	};

	const renderTree = ({ data, children, childrenLoadStatus }: ITreeNode<IApiEntity>) => {
		const hasNoChildren = childrenLoadStatus === Status.Done && children.length === 0;

		return (
			<CustomTreeItem
				key={data.id}
				nodeId={data.id}
				label={data.name}
				isLoading={childrenLoadStatus === Status.Loading}
				isSelectable={data.tags.includes(entityType)}
			>
				{hasNoChildren ? null : children.length ? children.map((n) => renderTree(n)) : <div key="stub" />}
			</CustomTreeItem>
		);
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}>
			<TextField
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<FilterAlt />
						</InputAdornment>
					),
				}}
				type="search"
				variant="standard"
				margin="none"
				placeholder="Filter tree"
				value={filter}
				onChange={(e) => setFilter(e.target.value)}
				sx={{ marginBottom: (theme) => theme.spacing(1) }}
			/>
			<Box sx={{ overflowY: 'auto' }}>
				<TreeView
					defaultCollapseIcon={<ExpandMore />}
					defaultExpandIcon={<ChevronRight />}
					expanded={expandedNodes}
					selected={selectedNodes}
					multiSelect={true}
					onNodeToggle={(event: React.SyntheticEvent, nodeIds: string[]) => {
						const [nodeId] = nodeIds.filter((id) => !expandedNodes.includes(id));
						const node = treeItems[nodeId];

						if (node && node.childrenLoadStatus === Status.None) {
							loadChildren(node);
						}

						setExpandedNodes(nodeIds);
					}}
					onNodeSelect={(event: React.SyntheticEvent, nodeIds: string[]) => {
						const nodeId = nodeIds[0];

						if (!nodeId) {
							return;
						}

						const newSelectedNodes = selectedNodes.includes(nodeId)
							? selectedNodes.filter((n) => n !== nodeId)
							: [...selectedNodes, nodeId];

						setSelectedNodes(newSelectedNodes);
						onSelect(newSelectedNodes);
					}}
				>
					{tree.map((node) => renderTree(node))}
				</TreeView>
			</Box>
		</Box>
	);
}
