import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, List, ListItem, ListItemText, Divider, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
	IDevicePointTemplate,
	IDeviceTemplate,
	IEntityTemplate,
	IExternalSystemTemplate,
	TemplateType,
} from '@mitie/metadata-api-types';

import TemplatesList from 'components/TemplatesList';
import { useNavigate, useParams } from 'routing/routing';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexGrow: 1,
		},
		listContainer: {
			display: 'flex',
			flexDirection: 'column',
			minWidth: '12rem',
		},
		list: {
			overflow: 'auto',
			padding: 0,
		},
		titleText: {
			lineHeight: '48px',
			margin: '0 0.5rem',
		},
		topBar: {
			display: 'flex',
		},
	}),
);

const templateTypes: Array<{ type: TemplateType; label: string }> = [
	{
		type: 'device',
		label: 'Devices',
	},
	{
		type: 'device_point',
		label: 'Device channels',
	},
	{
		type: 'entity',
		label: 'Entities',
	},
	{
		type: 'external_system',
		label: 'Integration',
	},
];

export default observer(function TemplatesPage() {
	const classes = useStyles();

	const { templatesType } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		// Set default entity type if not set
		if (!templatesType) {
			navigate(null, { templatesType: 'device' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [templatesType]);

	if (!templateTypes) {
		return null;
	}

	return (
		<Box className={classes.root}>
			<Box className={classes.listContainer}>
				<Box className={classes.topBar}>
					<Typography variant="h6" className={classes.titleText}>
						Template types
					</Typography>
				</Box>
				<Divider />
				<List className={classes.list}>
					{templateTypes.map(({ type, label }) => (
						<ListItem
							button={true}
							key={type}
							selected={templatesType === type}
							onClick={() => navigate(null, { templatesType: type, templateId: null })}
						>
							<ListItemText primary={label} />
						</ListItem>
					))}
				</List>
			</Box>
			<Divider orientation="vertical" />
			{templatesType === 'entity' && <TemplatesList<IEntityTemplate> templateType={templatesType} />}
			{templatesType === 'device' && <TemplatesList<IDeviceTemplate> templateType={templatesType} />}
			{templatesType === 'device_point' && <TemplatesList<IDevicePointTemplate> templateType={templatesType} />}
			{templatesType === 'external_system' && <TemplatesList<IExternalSystemTemplate> templateType={templatesType} />}
		</Box>
	);
});
