import { useState } from 'react';
import { Box, Dialog, LinearProgress, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { useStores } from 'store';
import Entity from 'store/entity';
import { Status } from 'DataTypes';
import DeviceTree from './DeviceTree';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {},
	}),
);

interface IDeviceSelectPopupProps {
	title: string;
	allowEmpty: boolean;
	device?: Entity;
	onClose: (entityId?: string | null) => void;
}

export default function DeviceSelectPopup({ title, allowEmpty, device, onClose }: IDeviceSelectPopupProps) {
	const classes = useStyles();
	const { entities } = useStores();
	const [selectedEntityId, setSelectedEntityId] = useState<string | null>(device?.id || null);

	return (
		<Dialog open={true} onClose={() => onClose()}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<Box className={classes.container}>
					{entities.rootLocationsFetchStatus === Status.Loading ? (
						<LinearProgress />
					) : (
						<DeviceTree selected={selectedEntityId} onSelect={(selected) => setSelectedEntityId(selected)} />
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} color="primary" variant="outlined">
					Cancel
				</Button>
				{allowEmpty && (
					<Button onClick={() => onClose(null)} color="primary" variant="outlined">
						No parent device
					</Button>
				)}
				<Button
					onClick={() => onClose(selectedEntityId)}
					color="primary"
					variant="outlined"
					disabled={!selectedEntityId}
				>
					Choose selected
				</Button>
			</DialogActions>
		</Dialog>
	);
}
