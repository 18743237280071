import { useState } from 'react';
import { TextField } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { TemplatePropertyType } from '@mitie/metadata-api-types';

import EntityProperty from './EntityProperty';

interface IEntityPropertyWithComparisonProps {
	value?: string | number | boolean;
	masterValue?: string | number | boolean;
	otherValues: { otherField: string; otherValue?: string | number | boolean | null }[];
	label: string;
	type: TemplatePropertyType;
	select?: Array<{ value: number | string; label: string }>;
	disabled?: boolean;
	onChange: (value: string | number | boolean | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
	}),
);

export default function EntityPropertyWithComparison({
	label,
	type,
	onChange,
	select,
	value,
	masterValue,
	otherValues,
}: IEntityPropertyWithComparisonProps) {
	const classes = useStyles();

	const parseValueFromOption = (value: string) => {
		if (type === 'boolean') {
			return value === 'true';
		} else if (type === 'number') {
			return Number(value);
		} else {
			return value;
		}
	};

	const [selectedOption, setSelectedOption] = useState<string>(String(value));
	const [inputDisabled, setInputDisabled] = useState(true);

	return (
		<Box className={classes.root}>
			<EntityProperty
				label={label}
				type={type}
				value={value}
				select={select}
				onChange={onChange}
				className={classes.field}
				disabled={inputDisabled}
			/>
			<TextField
				label="Choose value"
				className={classes.field}
				margin="normal"
				variant="outlined"
				select
				SelectProps={{
					native: true,
				}}
				InputLabelProps={{
					shrink: true,
				}}
				value={selectedOption}
				onChange={(e) => {
					const v = e.target.value;

					setSelectedOption(v);

					if (v !== 'none') {
						setInputDisabled(true);
						onChange(parseValueFromOption(v));
					} else {
						setInputDisabled(false);
					}
				}}
			>
				{masterValue !== undefined && (
					<option value={String(masterValue)}>{`Use current value in master data: ${String(masterValue)}`}</option>
				)}
				{otherValues.map(({ otherField, otherValue }) => (
					<option
						key={otherField}
						value={otherValue === undefined || otherValue === null ? '' : String(otherValue)}
					>{`Use value from '${otherField}' field: ${String(otherValue)}`}</option>
				))}
				<option value="none">Other value...</option>
			</TextField>
		</Box>
	);
}
