import { Box, Button, Popover } from '@mui/material';
import { useState } from 'react';
import { DateRangePicker, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import enGB from 'date-fns/locale/en-GB';

export interface DateRangePickerFieldProps {
	onChange: (value: Range) => void;
	value: Range;
}

export default function DateRangePickerField({ onChange, value }: DateRangePickerFieldProps) {
	const [datePickerAnchorEl, setDatePickerAnchorEl] = useState<HTMLButtonElement | null>(null);

	const displayDateRange =
		value.startDate && value.endDate
			? `${value.startDate.toLocaleDateString()} - ${value.endDate.toLocaleDateString()}`
			: 'Not set';

	return (
		<>
			<Button onClick={(e) => setDatePickerAnchorEl(e.currentTarget)} variant="outlined">
				{displayDateRange}
			</Button>
			<Popover
				open={Boolean(datePickerAnchorEl)}
				anchorEl={datePickerAnchorEl}
				onClose={() => setDatePickerAnchorEl(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<DateRangePicker
						onChange={(item) => onChange(item.selection)}
						showSelectionPreview={true}
						months={1}
						ranges={[value]}
						locale={enGB}
						maxDate={new Date()}
						moveRangeOnFirstSelection={false}
						retainEndDateOnFirstSelection={true}
						showDateDisplay={false}
						inputRanges={[]}
					/>
					<Button
						sx={{ margin: (theme) => theme.spacing(1) }}
						variant="text"
						onClick={() => setDatePickerAnchorEl(null)}
					>
						OK
					</Button>
				</Box>
			</Popover>
		</>
	);
}
