import { ExporterType, IExporter } from '@mitie/metadata-api-types';

import * as Api from './api';

export function fetchExportersList() {
	return Api.get<IExporter[]>('exporters');
}

export async function runExporter(type: ExporterType, params: any) {
	return Api.post<Blob>(`exporters/run/${type}`, params);
}
