import { useEffect, useState } from 'react';
import {
	Box,
	TextField,
	Button,
	LinearProgress,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tooltip,
	IconButton,
	Typography,
	Alert,
} from '@mui/material';
import { Delete, Info } from '@mui/icons-material';
import { IApiRole, IApiUserRoleAssignment, Role } from '@mitie/metadata-api-types';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';

import EntitySelectDialog from './EntitySelectDialog';
import Entity from 'store/entity';
import { useStores } from 'store';

interface IUserRoleAssignmentsProps {
	userId: string;
	roleAssignments: IApiUserRoleAssignment[] | null;
	addRoleAssignment: (data: IApiUserRoleAssignment) => void;
	removeRoleAssignment: (data: IApiUserRoleAssignment) => void;
	isLoading: boolean;
	isSaving: boolean;
	rolePermissions: Record<string, IApiRole> | undefined;
}

export default observer(function UserRoleAssignments({
	roleAssignments,
	addRoleAssignment,
	removeRoleAssignment,
	isLoading,
	isSaving,
	rolePermissions,
}: IUserRoleAssignmentsProps) {
	const [selectedClient, setSelectedClient] = useState<Entity>();
	const [selectedRole, setSelectedRole] = useState<Role>('Reader');
	const [clientSelectOpen, setClientSelectOpen] = useState(false);
	const { entities } = useStores();
	const [roleAssignmentsWithClientNames, setRoleAssignmentsWithClientNames] = useState<
		{ role_id: Role; client_id: string | null; clientName: string }[]
	>([]);

	// Fetch list of clients on load
	useEffect(() => {
		entities.fetchClients();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(
		() =>
			autorun(() => {
				if (!roleAssignments) {
					setRoleAssignmentsWithClientNames([]);
				} else {
					setRoleAssignmentsWithClientNames(
						roleAssignments.map(({ role_id, client_id }) => {
							let clientName = '';

							if (!client_id) {
								clientName = 'All clients';
							} else {
								const clientEntity = entities.getEntity(client_id);

								if (!clientEntity) {
									clientName = 'Unknown client';
								} else {
									clientName = clientEntity.displayName;
								}
							}

							return {
								role_id,
								client_id,
								clientName,
							};
						}),
					);
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[roleAssignments],
	);

	const generateTooltipForRole = (role: IApiRole) =>
		`${role.description}. Includes the following permissions:\n\n${role.permissions.map((p) => p.name).join('\n')}`;

	return (
		<Box>
			<Typography variant="h6">Role assignments</Typography>
			{isLoading && <LinearProgress />}
			{roleAssignmentsWithClientNames && rolePermissions && (
				<>
					<TableContainer component={Paper} sx={{ marginTop: (theme) => theme.spacing(2), maxHeight: '350px' }}>
						<Table size="small" stickyHeader>
							<TableHead>
								<TableRow sx={{ '>th': { backgroundColor: (theme) => theme.palette.background.paper } }}>
									<TableCell>Client</TableCell>
									<TableCell>Role</TableCell>
									<TableCell sx={{ width: '64px' }}></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{roleAssignmentsWithClientNames.length === 0 ? (
									<TableRow>
										<TableCell colSpan={3}>No role is assigned for this user</TableCell>
									</TableRow>
								) : (
									roleAssignmentsWithClientNames.map(({ role_id, client_id, clientName }, index) => {
										const tooltip = rolePermissions[role_id] ? generateTooltipForRole(rolePermissions[role_id]) : '';

										return (
											<TableRow key={index}>
												<TableCell>{clientName}</TableCell>
												<TableCell>
													{role_id}
													<Tooltip
														title={tooltip}
														arrow
														placement="right"
														PopperProps={{ style: { whiteSpace: 'pre-line' } }}
													>
														<IconButton color="default" size="small">
															<Info fontSize="inherit" />
														</IconButton>
													</Tooltip>
												</TableCell>
												<TableCell>
													<Tooltip title="Remove" arrow placement="right">
														<span>
															<IconButton
																edge="end"
																size="small"
																onClick={() => removeRoleAssignment({ role_id, client_id })}
															>
																<Delete />
															</IconButton>
														</span>
													</Tooltip>
												</TableCell>
											</TableRow>
										);
									})
								)}
							</TableBody>
						</Table>
					</TableContainer>

					<Paper
						sx={{
							marginTop: (theme) => theme.spacing(2),
							padding: (theme) => theme.spacing(2),
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'flex-end',
							}}
						>
							<TextField
								label="Client"
								variant="standard"
								disabled={true}
								value={selectedClient ? selectedClient.displayName : 'All clients'}
								// InputProps={{
								// 	endAdornment: (
								// 		<InputAdornment position="end">
								// 			<Button onClick={() => setClientSelectOpen(true)}>...</Button>
								// 		</InputAdornment>
								// 	),
								// 	disabled: isSaving,
								// }}
								sx={{ marginRight: (theme) => theme.spacing(2) }}
							/>
							<TextField
								select={true}
								label="Role"
								variant="standard"
								value={selectedRole}
								onChange={(event) => {
									setSelectedRole(event.target.value as Role);
								}}
								SelectProps={{
									native: true,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								disabled={isSaving}
								sx={{ marginRight: (theme) => theme.spacing(2) }}
							>
								{Object.values(rolePermissions).map(({ role_id }) => (
									<option key={role_id} value={role_id}>
										{role_id}
									</option>
								))}
							</TextField>
							<Button
								disabled={!selectedRole || isSaving}
								onClick={() => {
									if (!selectedRole) {
										return;
									}

									addRoleAssignment({
										role_id: selectedRole,
										client_id: selectedClient?.id || null,
									});
									setSelectedClient(undefined);
								}}
								variant="outlined"
							>
								Add role assignment
							</Button>
						</Box>
						{selectedRole && rolePermissions[selectedRole] && (
							<Alert severity="info" sx={{ marginTop: (theme) => theme.spacing(2), whiteSpace: 'pre-line' }}>
								{generateTooltipForRole(rolePermissions[selectedRole])}
							</Alert>
						)}
					</Paper>
				</>
			)}
			{clientSelectOpen && (
				<EntitySelectDialog
					entityType="client"
					value={selectedClient}
					optional={true}
					onChange={(clientId) => {
						setClientSelectOpen(false);

						if (clientId) {
							const client = entities.getEntity(clientId);

							if (client) {
								setSelectedClient(client);
							}
						} else {
							setSelectedClient(undefined);
						}
					}}
					onClose={() => setClientSelectOpen(false)}
				/>
			)}
		</Box>
	);
});
