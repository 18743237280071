import { useState } from 'react';
import { Chip, Fab, TextField, Select, FormControl, InputLabel } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Add } from '@mui/icons-material';
import { IDeviceTwinTags } from '@mitie/metadata-api-types';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(2),
			overflow: 'auto',
		},
		selectChip: {
			marginTop: theme.spacing(2),
			marginRight: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		fab: {
			marginLeft: theme.spacing(1),
		},
	}),
);

interface IDeviceTwinTagsListProps {
	tags: IDeviceTwinTags;
	setTags?: (tags: IDeviceTwinTags) => void;
}

export default function DeviceTwinTagsList({ tags, setTags }: IDeviceTwinTagsListProps) {
	const classes = useStyles();

	const [tagName, setTagName] = useState('');
	const [tagValue, setTagValue] = useState('' as string | number | boolean);
	const [datatype, setDatatype] = useState('string');

	return (
		<>
			{Object.entries(tags).map(
				([tag, value]) =>
					tag !== null && (
						<Chip
							className={classes.selectChip}
							key={tag}
							label={`${tag} = "${value}"`}
							onDelete={
								setTags
									? () => {
											if (tags[tag]) {
												const newTags = { ...tags };
												delete newTags[tag];
												setTags(newTags);
											}
									  }
									: undefined
							}
						/>
					),
			)}
			{setTags && (
				<>
					<Box sx={{ flexDirection: 'row', display: 'inline' }}>
						<TextField
							label="Tag name"
							sx={{ marginLeft: '8px', marginRight: '8px' }}
							value={tagName}
							variant="standard"
							onChange={(e) => setTagName(e.currentTarget.value)}
						/>
						<FormControl sx={{ marginRight: '8px' }}>
							<InputLabel htmlFor="twin-tag-datatype">Type</InputLabel>
							<Select
								native={true}
								inputProps={{
									name: 'Type',
									id: 'twin-tag-datatype',
								}}
								value={datatype}
								onChange={(e) => {
									if (e.target.value === 'boolean') {
										setTagValue(true);
									} else if (e.target.value === 'number') {
										setTagValue(0);
									} else {
										setTagValue('');
									}

									setDatatype(e.target.value as string);
								}}
							>
								<option value="string">string</option>
								<option value="number">number</option>
								<option value="boolean">boolean</option>
							</Select>
						</FormControl>
						{datatype === 'boolean' ? (
							<FormControl>
								<InputLabel htmlFor="twin-tag-value">Value</InputLabel>
								<Select
									native={true}
									inputProps={{
										name: 'Value',
										id: 'twin-tag-value',
									}}
									value={tagValue ? 'true' : 'false'}
									onChange={(e) => {
										const value = e.target.value === 'true';
										setTagValue(value);
									}}
								>
									<option value="true">true</option>
									<option value="false">false</option>
								</Select>
							</FormControl>
						) : (
							<TextField
								label="Value"
								value={tagValue}
								variant="standard"
								onChange={(e) => {
									const value = datatype === 'number' ? parseFloat(e.currentTarget.value) : e.currentTarget.value;
									setTagValue(value);
								}}
							/>
						)}
					</Box>
					<Fab
						size="small"
						disabled={!tagName || tagValue === '' || tags[tagName] !== undefined}
						color="secondary"
						className={classes.fab}
						onClick={() => {
							setTags({ ...tags, [tagName]: tagValue });

							setTagName('');

							if (datatype === 'boolean') {
								setTagValue(true);
							} else if (datatype === 'number') {
								setTagValue(0);
							} else {
								setTagValue('');
							}
						}}
					>
						<Add />
					</Fab>
				</>
			)}
		</>
	);
}
