import { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Divider, Tooltip, Button, Chip, CircularProgress } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Delete as DeleteIcon, Undo, Save, Info as InfoIcon } from '@mui/icons-material';
import {
	IEntityTemplate,
	IDeviceTemplate,
	IDevicePointTemplate,
	IExternalSystemTemplate,
} from '@mitie/metadata-api-types';

import DeviceTemplateDetails from './DeviceTemplateDetails';
import DevicePointTemplateDetails from './DevicePointTemplateDetails';
import EntityTemplateDetails from './EntityTemplateDetails';
import IntegrationTemplateDetails from './IntegrationTemplateDetails';
import { DeviceTemplate } from 'store/deviceTemplate';
import { DevicePointTemplate } from 'store/devicePointTemplate';
import { EntityTemplate } from 'store/entityTemplate';
import { ExternalSystemTemplate } from 'store/externalSystemTemplate';
import { useNavigate } from 'routing/routing';
import usePromptWithCallback from 'hooks/usePromptWithCallback';
import { Template } from 'store/template';
import { UserContext } from 'store/UserProvider';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
		},
		titleText: {
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		grow: {
			flexGrow: 1,
		},
		topBar: {
			display: 'flex',
			alignItems: 'center',
		},
		statusChip: {
			marginRight: theme.spacing(1),
		},
		iconsDivider: {
			width: '1px',
			backgroundColor: theme.palette.divider,
			height: '48px',
			margin: '0 12px',
		},
		button: {
			cursor: 'pointer',
		},
		actionButton: {
			marginRight: theme.spacing(2),
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
);

interface ITemplateDetailsProps<T> {
	template: Template<T>;
}

export default observer(function TemplateDetails<
	T extends IEntityTemplate | IDeviceTemplate | IDevicePointTemplate | IExternalSystemTemplate,
>({ template }: ITemplateDetailsProps<T>) {
	const classes = useStyles();
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	usePromptWithCallback(
		'Are you sure you want to navigate away? Unsaved changes to the template will be discarded.',
		template.unsaved,
		false,
		() => template.discardChanges(),
	);

	const canEdit = user?.roles.includes('Contributor') || false;

	return (
		<Box className={classes.root}>
			<Box className={classes.topBar}>
				<Typography variant="h6" className={classes.titleText}>
					Template details
				</Typography>
				{template.saving ? (
					<>
						<Chip label="Saving..." color="secondary" size="small" className={classes.statusChip} />
						<CircularProgress color="secondary" size={20} />
					</>
				) : template.unsaved ? (
					<Chip
						label={template.created ? 'New' : template.deleted ? 'Deleted' : 'Modified'}
						color="secondary"
						size="small"
						className={classes.statusChip}
					/>
				) : (
					<Tooltip
						title={template.updatedTime ? `Last saved: ${template.updatedTime.toLocaleString()}` : 'Not saved yet'}
					>
						<InfoIcon className={classes.button} color="action" />
					</Tooltip>
				)}
				<Box className={classes.grow} />
				{!template.created && canEdit && (
					<Button
						startIcon={<DeleteIcon />}
						variant="outlined"
						onClick={async () => {
							template.delete();
							await template.save();
							navigate(null, { templateId: null });
						}}
						className={classes.actionButton}
					>
						Delete template
					</Button>
				)}
				{template.unsaved && canEdit && (
					<>
						<Button
							startIcon={<Save />}
							color="secondary"
							variant="contained"
							onClick={() => template.save()}
							className={classes.actionButton}
						>
							Save template
						</Button>
						<Button
							startIcon={<Undo />}
							color="secondary"
							variant="outlined"
							onClick={() => template.discardChanges()}
							className={classes.actionButton}
						>
							Discard changes
						</Button>
					</>
				)}
			</Box>
			<Divider />
			{template.data && template.templateType === 'device' && (
				<DeviceTemplateDetails template={template as DeviceTemplate} canEdit={canEdit} />
			)}
			{template.data && template.templateType === 'device_point' && (
				<DevicePointTemplateDetails template={template as DevicePointTemplate} canEdit={canEdit} />
			)}
			{template.data && template.templateType === 'entity' && (
				<EntityTemplateDetails template={template as EntityTemplate} canEdit={canEdit} />
			)}
			{template.data && template.templateType === 'external_system' && (
				<IntegrationTemplateDetails template={template as ExternalSystemTemplate} canEdit={canEdit} />
			)}
		</Box>
	);
});
