import { makeAutoObservable, observable, reaction, toJS, runInAction } from 'mobx';
import { RelationType } from '@mitie/metadata-api-types';

import Entity, { IDeviceConfigData, IEntityData } from './entity';
import { stores } from 'store';
import { Status } from 'DataTypes';
import * as EntitiesApi from '../api/entities';
import * as DevicesApi from '../api/devices';

class Entities {
	public get entitiesUnsavedCount() {
		return Object.keys(this.unsavedList).length;
	}
	public unsavedList: { [id: string]: Entity } = {};
	public clients: Entity[] = [];
	public rootLocations: Entity[] = [];
	public rootDevices: Entity[] = [];
	public clientsFetchStatus: Status = Status.None;
	public rootLocationsFetchStatus: Status = Status.None;
	public rootDevicesFetchStatus: Status = Status.None;
	public entitiesToFetch: string[] = [];
	public devicesStatusToFetch: string[] = [];

	public childrenToFetch: { [relationType in RelationType]: string[] } = {
		device: [],
		entity: [],
		location: [],
		client: [],
		gateway: [],
		equip: [],
	};
	private list: { [id: string]: Entity } = {};

	constructor() {
		makeAutoObservable<Entities, 'list'>(this, { list: observable });

		reaction(
			() => toJS(this.entitiesToFetch),
			(entitiesToFetch) => {
				if (entitiesToFetch.length === 0) {
					return;
				}

				const chunkSize = 100;

				while (entitiesToFetch.length > 0) {
					const chunk = entitiesToFetch.splice(0, chunkSize);
					this.fetchEntitiesByIds(chunk);
				}

				this.entitiesToFetch = [];
			},
			{ delay: 300 },
		);

		reaction(
			() => toJS(this.devicesStatusToFetch),
			(devicesStatusToFetch) => {
				if (devicesStatusToFetch.length === 0) {
					return;
				}

				const chunkSize = 100;

				while (devicesStatusToFetch.length > 0) {
					const chunk = devicesStatusToFetch.splice(0, chunkSize);
					this.fetchDevicesStatusByIds(chunk);
				}

				this.devicesStatusToFetch = [];
			},
			{ delay: 300 },
		);

		reaction(
			() => toJS(this.childrenToFetch),
			(childrenToFetch) => {
				const chunkSize = 100;

				for (const relationType of Object.keys(childrenToFetch)) {
					const typedRelationType = relationType as RelationType;
					const ids = childrenToFetch[typedRelationType];

					if (ids.length > 0) {
						while (ids.length > 0) {
							const chunk = ids.splice(0, chunkSize);
							this.fetchMultipleEntitiesChildren(typedRelationType, chunk);
						}

						this.childrenToFetch[typedRelationType] = [];
					}
				}
			},
			{ delay: 300 },
		);
	}

	public getEntity(entityId: string): Entity | undefined {
		return this.list[entityId];
	}

	public addAndGetEntity(entityId: string): Entity {
		const entity = this.getEntity(entityId);

		if (entity) {
			return entity;
		}

		const newEntity = new Entity(entityId);
		this.list[entityId] = newEntity;

		return newEntity;
	}

	public discardAll() {
		for (const entityId of Object.keys(this.unsavedList)) {
			const entity = this.list[entityId];

			if (entity) {
				entity.discardChanges();
			}
		}
	}

	public saveAll() {
		const entitiesToCreate: { id: string; data: IEntityData }[] = [];
		const entitiesToUpdate: { id: string; data: IEntityData }[] = [];
		const entitiesToDelete: string[] = [];
		const devicesToCreate: { deviceId: string; data: IDeviceConfigData }[] = [];
		const devicesToUpdate: { deviceId: string; data: IDeviceConfigData }[] = [];
		const devicesToDelete: string[] = [];

		for (const entityId of Object.keys(this.unsavedList)) {
			const entity = this.list[entityId];

			if (!entity) {
				continue;
			}

			if (entity.deleted) {
				entitiesToDelete.push(entity.id);
				entity.saveRequest = Status.Loading;
			} else if (entity.created && entity.canSave) {
				entitiesToCreate.push({ id: entity.id, data: entity.data! });
				entity.saveRequest = Status.Loading;
			} else if (entity.modified && entity.canSave) {
				entitiesToUpdate.push({ id: entity.id, data: entity.data! });
				entity.saveRequest = Status.Loading;
			}

			if (entity.isDevice) {
				if (entity.deviceDeleted) {
					devicesToDelete.push(entity.id);
					entity.deviceSaveRequest = Status.Loading;
				} else if (entity.deviceCreated) {
					devicesToCreate.push({ deviceId: entity.id, data: entity.deviceConfigData! });
					entity.deviceSaveRequest = Status.Loading;
				} else if (entity.deviceModified) {
					devicesToUpdate.push({ deviceId: entity.id, data: entity.deviceConfigData! });
					entity.deviceSaveRequest = Status.Loading;
				}
			}
		}

		if (entitiesToCreate.length || entitiesToUpdate.length || entitiesToDelete.length) {
			EntitiesApi.batchSaveEntities(entitiesToCreate, entitiesToUpdate, entitiesToDelete);
		}

		if (devicesToCreate.length || devicesToUpdate.length || devicesToDelete.length) {
			DevicesApi.batchSaveDevices(devicesToCreate, devicesToUpdate, devicesToDelete);
		}

		const totalRequests =
			entitiesToUpdate.length +
			entitiesToCreate.length +
			entitiesToDelete.length +
			devicesToUpdate.length +
			devicesToCreate.length +
			devicesToDelete.length;
		stores.globals.savePendingCount += totalRequests;
		stores.globals.saveTotalCount += totalRequests;
	}

	public addEntitiesFromUi(
		entities: {
			id: string;
			entityData: IEntityData;
			deviceData?: IDeviceConfigData;
			savedDeviceData?: IDeviceConfigData;
		}[],
	) {
		for (const { id, entityData, deviceData, savedDeviceData } of entities) {
			const entity = this.addAndGetEntity(id);
			entity.setData(entityData, true);

			if (deviceData) {
				entity.deviceConfigData = deviceData;
			}

			if (savedDeviceData) {
				entity.savedDeviceConfigData = savedDeviceData;
			}
		}
	}

	public deleteEntity(entity: Entity | string) {
		const e = typeof entity === 'string' ? this.list[entity] : entity;

		if (!e) {
			return;
		}

		e.clearSavedData();
		e.clearData();

		delete this.list[e.id];
		delete this.unsavedList[e.id];
	}

	public async fetchClients() {
		if (this.clientsFetchStatus !== Status.None) {
			return;
		}

		this.clientsFetchStatus = Status.Loading;

		try {
			const resp = await EntitiesApi.fetchClients();

			runInAction(() => {
				resp.forEach(({ id, entityData, updatedTime }) => {
					const entity = this.addAndGetEntity(id);
					entity.setSavedData(entityData, updatedTime);

					return entity;
				});

				this.clientsFetchStatus = Status.Done;
			});
		} catch (error: any) {
			this.clientsFetchStatus = Status.Error;
			error.message = `Failed to load clients list (${error.message})`;
			throw error;
		}
	}

	public async fetchRootLocations() {
		if (this.rootLocationsFetchStatus !== Status.None) {
			return;
		}

		this.rootLocationsFetchStatus = Status.Loading;

		try {
			const resp = await EntitiesApi.fetchRootLocations();

			runInAction(() => {
				resp.forEach(({ id, entityData, updatedTime }) => {
					const entity = this.addAndGetEntity(id);
					entity.setSavedData(entityData, updatedTime);
				});

				this.rootLocationsFetchStatus = Status.Done;
			});
		} catch (error: any) {
			this.rootLocationsFetchStatus = Status.Error;
			error.message = `Failed to load list of top level locations (${error.message})`;
			throw error;
		}
	}

	public async fetchRootDevices() {
		if (this.rootDevicesFetchStatus !== Status.None) {
			return;
		}

		this.rootDevicesFetchStatus = Status.Loading;

		try {
			const resp = await EntitiesApi.fetchRootDevices();

			runInAction(() => {
				resp.forEach(({ id, entityData, updatedTime }) => {
					const entity = this.addAndGetEntity(id);
					entity.setSavedData(entityData, updatedTime);
				});

				this.rootDevicesFetchStatus = Status.Done;
			});
		} catch (error: any) {
			this.rootDevicesFetchStatus = Status.Error;
			error.message = `Failed to load list of top level devices (${error.message})`;
			throw error;
		}
	}

	public updateEntity(id: string, entityData: IEntityData, updatedTime: Date) {
		const entity = this.addAndGetEntity(id);
		entity.setSavedData(entityData, updatedTime, true);
		entity.saveRequest = Status.Done;
	}

	private async fetchEntitiesByIds(ids: string[]) {
		try {
			const resp = await EntitiesApi.fetchEntitiesByIds(ids);

			runInAction(() => {
				let idsMissing = [...ids];

				resp.forEach(({ id, entityData, updatedTime }) => {
					const entity = this.addAndGetEntity(id);
					entity.setSavedData(entityData, updatedTime);

					idsMissing = idsMissing.filter((idMissing) => idMissing !== id);
				});

				for (const id of idsMissing) {
					const entity = this.getEntity(id);

					if (entity) {
						entity.dataRequest = Status.Empty;
					}
				}
			});
		} catch (error: any) {
			for (const id of ids) {
				const entity = this.getEntity(id);

				if (entity) {
					entity.dataRequest = Status.Error;
				}
			}

			error.message = `Failed to load entities (${error.message})`;
			throw error;
		}
	}

	private async fetchDevicesStatusByIds(ids: string[]) {
		try {
			const resp = await DevicesApi.fetchDevicesStatusById(ids);

			runInAction(() => {
				let idsMissing = [...ids];

				resp.forEach(({ deviceId, deviceData }) => {
					const entity = this.addAndGetEntity(deviceId);
					entity.setDeviceStatus(deviceData);

					idsMissing = idsMissing.filter((idMissing) => idMissing !== deviceId);
				});

				for (const id of idsMissing) {
					const entity = this.getEntity(id);

					if (entity) {
						entity.deviceStatusRequest = Status.Empty;
					}
				}
			});
		} catch (error: any) {
			for (const id of ids) {
				const entity = this.getEntity(id);

				if (entity) {
					entity.deviceStatusRequest = Status.Error;
				}
			}

			error.message = `Failed to load device status (${error.message})`;
			throw error;
		}
	}

	public async fetchMultipleEntitiesChildren(relationType: RelationType, ids: string[]) {
		try {
			const resp = await EntitiesApi.fetchEntitiesChildrenByIds(relationType, ids);

			runInAction(() => {
				// Add entities to store
				resp.forEach(({ id, entityData, updatedTime }) => {
					const entity = this.addAndGetEntity(id);
					entity.setSavedData(entityData, updatedTime, false);
				});

				// Update request status
				for (const entityId of ids) {
					const entity = this.getEntity(entityId);

					if (entity) {
						entity.childrenRequest[relationType] = Status.Done;
					}
				}
			});
		} catch (error: any) {
			// Update request status
			for (const entityId of ids) {
				const entity = this.getEntity(entityId);

				if (entity) {
					entity.childrenRequest[relationType] = Status.Error;
				}
			}

			error.message = `Failed to load entities children (${error.message})`;
			throw error;
		}
	}

	public async fetchEntitiesDescendants(relationType: 'location' | 'gateway' | 'equip', id: string) {
		const parentEntity = this.addAndGetEntity(id);
		parentEntity.descendantsRequest[relationType] = Status.Loading;

		try {
			const resp = await EntitiesApi.fetchEntitiesDescendants(relationType, id);
			const descendants: Entity[] = [];
			parentEntity.descendantsRequest[relationType] = Status.Done;

			runInAction(() => {
				// Add entities to store
				resp.forEach(({ id, entityData, updatedTime }) => {
					const entity = this.addAndGetEntity(id);
					entity.setSavedData(entityData, updatedTime, false);
					descendants.push(entity);
				});

				parentEntity.descendants[relationType] = descendants;
			});
		} catch (error: any) {
			// Update request status
			parentEntity.descendantsRequest[relationType] = Status.Error;

			error.message = `Failed to load entities descendants (${error.message})`;
			throw error;
		}
	}

	public onEntityChanged(entity: Entity) {
		// Update location tree
		const isRootLocation = entity.isLocation && !entity.parentLocation;
		const wasRootLocation = this.rootLocations.find((e) => e.id === entity.id) !== undefined;

		if (isRootLocation && !wasRootLocation) {
			this.rootLocations.push(entity);
			this.rootLocations.sort((a, b) => {
				if (a.displayName < b.displayName) {
					return -1;
				}
				if (a.displayName > b.displayName) {
					return 1;
				}
				return 0;
			});
		} else if (!isRootLocation && wasRootLocation) {
			this.rootLocations = this.rootLocations.filter((e) => e.id !== entity.id);
		}

		// Update device tree
		const isRootDevice = entity.isDevice && !entity.parentDevice;
		const wasRootDevice = this.rootDevices.find((e) => e.id === entity.id) !== undefined;

		if (isRootDevice && !wasRootDevice) {
			this.rootDevices.push(entity);
			this.rootDevices.sort((a, b) => {
				if (a.displayName < b.displayName) {
					return -1;
				}
				if (a.displayName > b.displayName) {
					return 1;
				}
				return 0;
			});
		} else if (!isRootDevice && wasRootDevice) {
			this.rootDevices = this.rootDevices.filter((e) => e.id !== entity.id);
		}

		// Update clients list
		const isClient = entity.isClient;
		const wasClient = this.clients.find((e) => e.id === entity.id) !== undefined;

		if (isClient && !wasClient) {
			this.clients.push(entity);
			this.clients.sort((a, b) => {
				if (a.displayName < b.displayName) {
					return -1;
				}
				if (a.displayName > b.displayName) {
					return 1;
				}
				return 0;
			});
		} else if (!isClient && wasClient) {
			this.clients = this.clients.filter((e) => e.id !== entity.id);
		}
	}
}

export default Entities;
