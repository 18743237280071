import { Box, CircularProgress } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, TimeScale, Title, Tooltip } from 'chart.js';
import 'chartjs-adapter-date-fns';

import Entity from 'store/entity';
import { Status } from 'DataTypes';

ChartJS.register(LineElement, PointElement, LinearScale, TimeScale, Title, Tooltip);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		chartContainer: {
			position: 'relative',
			height: 'calc(100% - 64px)',

			'&>canvas': {
				width: '100% !important',
			},
		},
		loadingIndicator: {
			width: '100%',
			height: '100%',
			position: 'absolute',
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	}),
);

interface IChannelDataChartProps {
	entity: Entity;
	fetchStatus: Status;
	data: { x: Date; y: number }[];
}

export default function ChannelDataChart({ entity, fetchStatus, data }: IChannelDataChartProps) {
	const classes = useStyles();
	const units = entity.data?.properties.units || null;

	return (
		<Box className={classes.chartContainer}>
			{fetchStatus === Status.Loading && (
				<Box className={classes.loadingIndicator}>
					<CircularProgress />
				</Box>
			)}
			<Line
				data={{
					datasets: [
						{
							label: entity.id,
							data: data.sort((a, b) => a.x.getTime() - b.x.getTime()) as any,
							pointHitRadius: 5,
							pointRadius: 1,
							pointBorderColor: '#FF0000',
							borderColor: '#FF9999',
							fill: false,
							cubicInterpolationMode: 'monotone',
						},
					],
				}}
				options={{
					scales: {
						x: {
							type: 'time',
						},
						y: {
							ticks: {
								callback: (value: any) => (units ? `${value} ${units}` : value),
							},
						},
					},
					animation: false,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false,
						},
						tooltip: {
							callbacks: {
								label: ({ parsed: { y } }) => (units ? `${y} ${units}` : `${y}`),
							},
						},
					},
				}}
			/>
		</Box>
	);
}
