import { useEffect, useRef, useState } from 'react';
import { Autocomplete } from '@mui/material';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

import * as EntitiesApi from 'api/entities';
import Entity from 'store/entity';
import useDebounce from 'hooks/useDebounce';

interface IOptionType {
	label: string;
	value: string;
}

interface IEntitySelectDialogProps {
	entityType: string;
	value?: Entity;
	optional: boolean;
	onChange: (entityId: string | null) => void;
	onClose: () => void;
}

export default function EntitySelectDialog({
	entityType,
	value,
	optional,
	onChange,
	onClose,
}: IEntitySelectDialogProps) {
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<IOptionType[]>([]);
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useState<IOptionType | undefined>(
		value ? { label: value.displayName, value: value.id } : optional ? { label: 'None', value: '' } : undefined,
	);
	const [selectedEntityChanged, setSelectEntityChanged] = useState(false);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const debouncedSearchTerm = useDebounce(searchTerm, 500);
	const abortControllerRef = useRef<AbortController | null>();

	useEffect(() => {
		fetchOptions(debouncedSearchTerm);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchTerm]);

	const fetchOptions = async (inputValue: string) => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}
		abortControllerRef.current = new AbortController();

		setLoading(true);

		const params = inputValue ? { search: inputValue } : undefined;

		try {
			const entities = await EntitiesApi.searchEntities(entityType, params, abortControllerRef.current?.signal);

			if (optional) {
				entities.unshift({ label: 'None', value: '' });
			}

			if (selected && !entities.find(({ value }) => value === selected.value)) {
				entities.unshift(selected);
			}

			setOptions(entities);
			abortControllerRef.current = null;
		} catch (e) {}

		setLoading(false);
	};

	return (
		<Dialog open={true} onClose={() => onClose()}>
			<DialogTitle>{`Select entity`}</DialogTitle>
			<DialogContent>
				<Autocomplete<IOptionType, false, true>
					value={(selected || null) as any}
					onChange={(event, newValue) => {
						setSelected(newValue);
						setSelectEntityChanged(newValue.value !== (value?.id || ''));
					}}
					open={open}
					onOpen={() => {
						setOpen(true);
					}}
					onClose={() => {
						setOpen(false);
					}}
					fullWidth={true}
					isOptionEqualToValue={(option, value) => option.value === value.value}
					getOptionLabel={(option) => option.label}
					options={options}
					filterOptions={(x) => x}
					loading={loading}
					disableClearable={true}
					onInputChange={(event: object, value: string, reason: string) => {
						if (reason === 'input') {
							setSearchTerm(value);
						}
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label={`Search ${entityType}s`}
							variant="outlined"
							margin="dense"
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<>
										{loading ? <CircularProgress color="inherit" size={20} /> : null}
										{params.InputProps.endAdornment}
									</>
								),
							}}
							sx={{ minWidth: '200px' }}
						/>
					)}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} color="primary">
					Cancel
				</Button>
				<Button onClick={() => onChange(selected?.value || null)} color="primary" disabled={!selectedEntityChanged}>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
}
