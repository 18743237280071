import { action, computed, makeObservable, observable } from 'mobx';
import { IEntityPointTemplate } from '@mitie/metadata-api-types';
import { v4 } from 'uuid';

import Templates from './templates';
import { EntityPointTemplate } from './entityPointTemplate';
import { ITemplateData } from './template';

export default class EntityPointTemplates extends Templates<IEntityPointTemplate> {
	private list: { [id: string]: EntityPointTemplate } = {};

	constructor() {
		super('entity_point');

		makeObservable<EntityPointTemplates, 'list'>(this, {
			addAndGetTemplate: action,
			templates: computed,
			deleteTemplate: action,
			list: observable,
		});
	}

	public getTemplate(id: string): EntityPointTemplate | undefined {
		return this.list[id];
	}

	public addAndGetTemplate(templateId: string): EntityPointTemplate {
		const template = this.getTemplate(templateId);

		if (template) {
			return template;
		}

		const newTemplate = new EntityPointTemplate(templateId);
		this.list[templateId] = newTemplate;

		return newTemplate;
	}

	public get templates() {
		return Object.keys(this.list).map((id) => this.list[id]);
	}

	public createTemplate() {
		const id = v4();
		const newTemplate = this.addAndGetTemplate(id);

		const data: ITemplateData<IEntityPointTemplate> = {
			name: 'New point',
			template: {
				allow_multiple: false,
				tags: [],
			},
		};

		newTemplate.setData(data);

		return newTemplate;
	}

	public deleteTemplate(template: EntityPointTemplate) {
		template.savedData = undefined;
		template.data = undefined;
		template.updatedTime = undefined;

		delete this.list[template.id];
	}
}
