import { useContext, useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, Divider, Button, TextField, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import { v4 as uuid } from 'uuid';

import { useParams, useNavigate } from 'routing/routing';
import { useStores } from 'store';
import Entity, { IEntityData } from 'store/entity';
import { EntityTemplate } from 'store/entityTemplate';
import { AlertsContext } from 'store/AlertsProvider';

interface IAddLocationDialogProps {
	onClose: () => void;
}

export default observer(function AddLocationDialog({ onClose }: IAddLocationDialogProps) {
	const { entityId } = useParams();
	const { entities, entityTemplates } = useStores();

	const entity = entityId ? entities.addAndGetEntity(entityId) : undefined;
	let childrenTemplates: EntityTemplate[] = [];

	if (entity?.entityTemplate) {
		const childrenEntityTemplates = entity.entityTemplate.data?.template.children;

		if (childrenEntityTemplates) {
			for (let childTemplateIndex = 0; childTemplateIndex < childrenEntityTemplates.length; childTemplateIndex++) {
				const childTemplateDefinition = childrenEntityTemplates[childTemplateIndex];
				if (childTemplateDefinition.relation_type === 'location' && childTemplateDefinition.allow_multiple) {
					const childTemplate = entityTemplates.getTemplate(childTemplateDefinition.entity_template);

					if (childTemplate) {
						childrenTemplates.push(childTemplate);
					}
				}
			}
		}
	}

	const [childTemplateId, setChildTemplateId] = useState<string | null>(childrenTemplates[0]?.id || null);
	const [rootTemplateId, setRootTemplateId] = useState<string>();
	const navigate = useNavigate();
	const { addAlert } = useContext(AlertsContext);

	useEffect(
		() =>
			autorun(() => {
				const topLevelTemplate = entityTemplates.templates.find((template) => template.data?.name === 'Country');

				if (topLevelTemplate) {
					setRootTemplateId(topLevelTemplate.id);
				} else if (entityTemplates.templates.length) {
					// Templates have been loaded and the "Country" one is missing
					addAlert('error', 'Missing template definition for country location');
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const addLocation = (templateId: string, parentLocation?: Entity) => {
		const entityTemplate = entityTemplates.getTemplate(templateId);

		if (!entityTemplate || !entityTemplate.data) {
			return;
		}

		const entityData: IEntityData = {
			name: `New ${entityTemplate.displayName}`,
			properties: {},
			tags: ['location'],
			relations: {},
			externalMappings: {},
			templates: {},
			lifecycleStatus: 'Configuration',
		};

		if (parentLocation && parentLocation.data) {
			entityData.relations.location = parentLocation.id;
			entityData.relations.client = parentLocation.data.relations.client;
		}

		const id = uuid();

		entities.addEntitiesFromUi([{ id, entityData }]);

		const newEntity = entities.getEntity(id);

		if (newEntity && newEntity.data) {
			// Apply template to new entity
			newEntity.setEntityTemplate(templateId);
		}

		navigate(null, { entityId: id });
	};

	return (
		<Dialog open={true} onClose={() => onClose()} maxWidth="lg">
			<DialogContent>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<Typography variant="overline">Add top level location:</Typography>
						<Button
							onClick={() => {
								addLocation(rootTemplateId!);
								onClose();
							}}
							color="primary"
							variant="contained"
							startIcon={<Add />}
							disabled={!rootTemplateId}
							sx={{ margin: (theme) => `${theme.spacing(2)} 0` }}
						>
							Add top level location
						</Button>
					</Box>
					{childrenTemplates.length > 0 && (
						<>
							<Divider orientation="vertical" sx={{ margin: (theme) => `0 ${theme.spacing(4)}` }} flexItem={true} />
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<Typography variant="overline">Add child to selected location:</Typography>
								<TextField
									value={childTemplateId || ''}
									select={true}
									label="Device template"
									onChange={(event) => setChildTemplateId(event.target.value || null)}
									margin="normal"
									variant="standard"
									SelectProps={{
										native: true,
									}}
									InputLabelProps={{
										shrink: true,
									}}
								>
									{childrenTemplates.map(({ id, displayName }) => (
										<option value={id} key={id}>
											{displayName}
										</option>
									))}
								</TextField>
								<Button
									startIcon={<Add />}
									variant="contained"
									onClick={() => {
										addLocation(childTemplateId!, entity);
										onClose();
									}}
									color="primary"
									disabled={childTemplateId === null}
									sx={{ margin: (theme) => `${theme.spacing(2)} 0` }}
								>
									Add child location
								</Button>
							</Box>
						</>
					)}
				</Box>
			</DialogContent>
		</Dialog>
	);
});
