import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, LinearProgress } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { autorun, runInAction } from 'mobx';
import { FolderOutlined } from '@mui/icons-material';

import { Status } from 'DataTypes';
import { useStores } from 'store';
import EntityTreeItem from './EntityTreeItem';
import Entity from 'store/entity';
import { EntityPointTemplate } from 'store/entityPointTemplate';
import { bindEntityToPoint } from 'tools/tagging';
import TaggingOptionsDialog, { ITaggingOptions } from './TaggingOptionsDialog';
import { ReactComponent as PointFilledIcon } from 'icons/pointFilled.svg';
import { ReactComponent as PointUnfilledIcon } from 'icons/pointUnfilled.svg';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			overflow: 'auto',
			padding: theme.spacing(1),
		},
		tree: {
			listStyleType: 'none',
			margin: 0,
			padding: 0,
		},
	}),
);

interface ILocationTreeProps {
	selected: Entity | null;
	onSelect: (entity: Entity | null) => void;
	mappingModeEnabled?: boolean;
	hidePoints?: boolean;
}

export default observer(function LocationTree({
	selected,
	onSelect,
	mappingModeEnabled,
	hidePoints,
}: ILocationTreeProps) {
	const classes = useStyles();
	const { entities, entityPointTemplates } = useStores();
	const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
	const [showTaggingOptions, setShowTaggingOptions] = useState(false);
	const [taggingOptions, setTaggingOptions] = useState<ITaggingOptions>({
		predictiveTaggingEnabled: false,
		enableInterval: true,
		interval: 300,
		assetExternalMapping: {},
		pointExternalMapping: {},
	});

	const expandNodes = (nodeIds: string[]) => {
		for (const nodeId of nodeIds) {
			const entity = entities.getEntity(nodeId);

			if (entity && !entity.childrenRequest.location) {
				entity.fetchChildren('location');
			}
		}

		setExpandedNodes((current) => [...new Set([...current, ...nodeIds])]);
	};

	useEffect(() => {
		// Load top level items for the tree
		entities.fetchRootLocations();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(
		() =>
			autorun(() => {
				// Makes sure that the parents of the selected entity are always expanded
				if (!selected) {
					return;
				}

				if (selected.parentLocationIds.length > 0) {
					expandNodes(selected.parentLocationIds);
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selected],
	);

	useEffect(() => {
		// Show tagging options when tagging mode is enabled
		if (mappingModeEnabled) {
			setShowTaggingOptions(true);
		}
	}, [mappingModeEnabled]);

	const onNodeExpand = (nodeId: string) => {
		expandNodes([nodeId]);
	};

	const onNodeCollapse = (nodeId: string) => {
		const newExpandedNodes = expandedNodes.filter((n) => n !== nodeId);

		setExpandedNodes(newExpandedNodes);
	};

	const bindEntityWithPoint = (entity: Entity, pointId: string, entityPointTemplate: EntityPointTemplate) => {
		const point = entities.getEntity(pointId);

		if (!point?.data) {
			return;
		}

		if (point.data.relations.entity) {
			throw new Error('Unable to bind device channel to entity: This channel is already bound');
		}

		runInAction(async () => {
			if (!point.data || !entity.data) {
				return;
			}

			bindEntityToPoint(entity, point, entityPointTemplate, taggingOptions);
		});
	};

	const renderTree = (entity: Entity) => {
		return (
			<EntityTreeItem
				key={entity.id}
				nodeId={entity.id}
				label={entity.displayName}
				selectable={true}
				selected={selected?.id === entity.id}
				expanded={expandedNodes.includes(entity.id)}
				onExpand={() => onNodeExpand(entity.id)}
				onCollapse={() => onNodeCollapse(entity.id)}
				onSelect={() => onSelect(entity)}
				loading={entity.childrenRequest.location === Status.Loading}
				unsaved={entity.unsaved}
				deleted={entity.deleted}
				icon={<FolderOutlined />}
				badgeNumber={entity.childrenUnsavedCount}
				lifecycleStatus={entity.data?.lifecycleStatus}
			>
				<Box key="stub" />
				{entity.children.location &&
					entity.children.location.filter((entity) => entity.isLocation).map((n) => renderTree(n))}
				{!hidePoints &&
					entity.children.entity &&
					entity.children.entity.map((point) => (
						<EntityTreeItem
							nodeId={point.id}
							key={point.id}
							label={point.displayName}
							selectable={true}
							unsaved={point.unsaved}
							deleted={point.deleted}
							icon={<PointFilledIcon />}
							selected={selected?.id === point.id}
							expanded={expandedNodes.includes(point.id)}
							onExpand={() => onNodeExpand(point.id)}
							onCollapse={() => onNodeCollapse(point.id)}
							onSelect={() => onSelect(point)}
							isLeaf={true}
						/>
					))}
				{mappingModeEnabled &&
					entity.entityTemplate?.data?.template.points_templates &&
					entity.entityTemplate.data.template.points_templates.map((pointTemplateId) => {
						const pointTemplate = entityPointTemplates.getTemplate(pointTemplateId);

						if (!pointTemplate) {
							return null;
						}

						if (!pointTemplate.data?.template.allow_multiple) {
							// Check if this template point is already bound to a point under this entity
							// Skip this check if this point template has "allow_multiple" flag enabled
							const unbound = !entity.children.entity?.find((e) => e.data?.templates.entity_point === pointTemplateId);

							if (!unbound) {
								return null;
							}
						}

						const nodeId = `${entity.id}:${pointTemplate.id}`;
						return (
							<EntityTreeItem
								nodeId={nodeId}
								key={nodeId}
								label={pointTemplate.displayName}
								selectable={false}
								disabled={true}
								isLeaf={true}
								icon={<PointUnfilledIcon />}
								onDrop={(itemId) => bindEntityWithPoint(entity, itemId, pointTemplate)}
								datatype={pointTemplate.data?.template.datatype}
							/>
						);
					})}
			</EntityTreeItem>
		);
	};

	return (
		<Box className={classes.container}>
			{mappingModeEnabled && (
				<Button color="primary" variant="outlined" onClick={() => setShowTaggingOptions(true)}>
					Tagging options...
				</Button>
			)}
			{entities.rootLocationsFetchStatus === Status.Loading ? (
				<LinearProgress />
			) : (
				<ul className={classes.tree}>{entities.rootLocations.map(renderTree)}</ul>
			)}
			{showTaggingOptions && (
				<TaggingOptionsDialog
					value={taggingOptions}
					showPredictiveTaggingOption={false}
					entityType="location"
					onChange={(value) => setTaggingOptions(value)}
					onClose={() => setShowTaggingOptions(false)}
				/>
			)}
		</Box>
	);
});
