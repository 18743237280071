import { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Box,
	List,
	ListItem,
	ListItemText,
	LinearProgress,
	Divider,
	Typography,
	Tooltip,
	IconButton,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Add } from '@mui/icons-material';
import classNames from 'classnames';
import {
	IDevicePointTemplate,
	IDeviceTemplate,
	IEntityTemplate,
	IExternalSystemTemplate,
	TemplateType,
} from '@mitie/metadata-api-types';
import { autorun } from 'mobx';

import { useStores } from 'store';
import { Status } from 'DataTypes';
import TemplateDetails from 'components/TemplateDetails';
import { useParams, useNavigate } from 'routing/routing';
import NewTemplateDialog from './NewTemplateDialog';
import Templates from 'store/templates';
import { Template } from 'store/template';
import { UserContext } from 'store/UserProvider';

const templateTypeLabel = {
	device: 'device',
	entity: 'entity',
	device_point: 'device channel',
	external_system: 'integration',
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexGrow: 1,
		},
		listContainer: {
			display: 'flex',
			flexDirection: 'column',
		},
		list: {
			display: 'flex',
			minHeight: 0,
		},
		titleText: {
			lineHeight: '48px',
			margin: '0 0.5rem',
		},
		topBar: {
			display: 'flex',
		},
		grow: {
			flexGrow: 1,
		},
		unsaved: {
			color: theme.palette.secondary.dark,
		},
		deleted: {
			textDecoration: 'line-through',
		},
	}),
);

interface ITemplatesListProps {
	templateType: TemplateType;
}

export default observer(function TemplatesList<
	T extends IEntityTemplate | IDeviceTemplate | IDevicePointTemplate | IExternalSystemTemplate,
>({ templateType }: ITemplatesListProps) {
	const classes = useStyles();
	const { deviceTemplates, entityTemplates, devicePointTemplates, externalSystemTemplates } = useStores();
	const { templateId } = useParams();
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	const [selectedTemplate, setSelectedTemplate] = useState<Template<T> | null>(null);
	const [showNewTemplateDialog, setshowNewTemplateDialog] = useState(false);

	const [templates, setTemplates] = useState<Templates<T>>(
		(templateType === 'device'
			? deviceTemplates
			: templateType === 'entity'
			? entityTemplates
			: templateType === 'device_point'
			? devicePointTemplates
			: externalSystemTemplates) as Templates<T>,
	);

	useEffect(
		() =>
			autorun(() => {
				if (templates.fetchStatus === Status.None) {
					templates.fetchAll();
				} else if (templates.fetchStatus === Status.Done) {
					for (const template of templates.templates) {
						if (template.usageRequest === Status.None) {
							template.fetchUsage();
						}
					}
				}
			}),
		[templates],
	);

	useEffect(() => {
		setTemplates(
			(templateType === 'device'
				? deviceTemplates
				: templateType === 'entity'
				? entityTemplates
				: templateType === 'device_point'
				? devicePointTemplates
				: externalSystemTemplates) as Templates<T>,
		);
		setSelectedTemplate(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [templateType]);

	useEffect(
		() =>
			autorun(() => {
				// Select template based on templateId, or de-select template if it doesn't exists
				if (templateId && templates.fetchStatus === Status.Done) {
					const template = templates.getTemplate(templateId);

					if (template) {
						setSelectedTemplate(template);
					} else {
						navigate(null, { templateId: null });
					}
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[templateId],
	);

	const canEdit = user?.roles.includes('Contributor') || false;

	return (
		<Box className={classes.root}>
			<Box className={classes.listContainer}>
				<Box className={classes.topBar}>
					<Typography variant="h6" className={classes.titleText}>
						Templates
					</Typography>
					<Box className={classes.grow} />
					{canEdit && (
						<Tooltip title={`New ${templateTypeLabel[templateType]} template`} placement="bottom">
							<IconButton color="secondary" onClick={() => setshowNewTemplateDialog(true)}>
								<Add />
							</IconButton>
						</Tooltip>
					)}
				</Box>
				<Divider />
				<Box className={classes.list}>
					<Box sx={{ overflow: 'auto', minWidth: '12rem' }}>
						{templates.fetchStatus === Status.Loading && <LinearProgress />}
						<List sx={{ padding: 0 }}>
							{templates.templates.map((template) => (
								<ListItem
									button={true}
									key={template.id}
									selected={selectedTemplate?.id === template.id}
									autoFocus={selectedTemplate?.id === template.id}
									onClick={() => navigate(null, { templateId: template.id })}
								>
									<ListItemText
										sx={{ whiteSpace: 'nowrap' }}
										primary={
											<Typography
												variant="subtitle1"
												className={classNames({
													[classes.unsaved]: template.unsaved,
													[classes.deleted]: template.deleted,
												})}
											>
												{template.displayName}
											</Typography>
										}
										secondary={
											template.usage === undefined ? 'Fetching usage...' : `Used by ${template.usage} entities`
										}
									/>
								</ListItem>
							))}
						</List>
					</Box>
				</Box>
			</Box>
			<Divider orientation="vertical" />
			{selectedTemplate && <TemplateDetails<T> template={selectedTemplate} />}
			{showNewTemplateDialog && (
				<NewTemplateDialog
					templates={templates}
					onClose={(templateId) => {
						setshowNewTemplateDialog(false);
						navigate(null, { templateId });
					}}
				/>
			)}
		</Box>
	);
});
