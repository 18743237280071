import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Divider, Tabs, Tab, LinearProgress } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { Status } from 'DataTypes';
import EntityProperty from './EntityProperty';
import TagsInput from './TagsInput';
import CustomPropertiesDefinition from './CustomPropertiesDefinition';
import EntityPointsDefinitionTable from './EntityPointsDefinition';
import EntityTemplateChildren from './EntityTemplateChildren';
import { EntityTemplate } from 'store/entityTemplate';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			minHeight: 0,
		},
		tabs: {
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
		tab: {
			'&>span:first-child': {
				flexDirection: 'row',
			},
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
		properties: {
			display: 'flex',
			margin: theme.spacing(2),
		},
	}),
);

interface IEntityTemplateDetailsProps {
	template: EntityTemplate;
	canEdit: boolean;
}

enum TabName {
	Properties = 0,
	EntityPoints = 1,
	Children = 2,
}

export default observer(function EntityTemplateDetails({ template, canEdit }: IEntityTemplateDetailsProps) {
	const classes = useStyles();

	const [selectedTab, setSelectedTab] = useState(TabName.Properties as TabName);

	return (
		<Box className={classes.root}>
			{template.dataRequest === Status.Loading && <LinearProgress />}
			{template.data && (
				<>
					<Tabs
						value={selectedTab}
						onChange={(event, value: TabName) => setSelectedTab(value)}
						variant="scrollable"
						scrollButtons="auto"
						className={classes.tabs}
						indicatorColor="primary"
						textColor="primary"
					>
						<Tab value={TabName.Properties} label="Properties" className={classes.tab} />
						<Tab value={TabName.EntityPoints} label="Data points" className={classes.tab} />
						<Tab value={TabName.Children} label="Children templates" className={classes.tab} />
					</Tabs>
					{selectedTab === TabName.Properties && (
						<>
							<Box className={classes.properties}>
								<EntityProperty
									label="Template name"
									type="string"
									value={template.data?.name}
									onChange={(value) => template.data && (template.data.name = (value as string) || '')}
									className={classes.field}
									disabled={!canEdit}
								/>
								<TagsInput
									label="Tags"
									value={template.data.template.tags || []}
									onChange={(newTags) => template.data && (template.data.template.tags = newTags)}
									className={classes.field}
									disabled={!canEdit}
								/>
							</Box>
							<Divider />
							<CustomPropertiesDefinition properties={template.data.template.properties} canEdit={canEdit} />
						</>
					)}
					{selectedTab === TabName.EntityPoints && (
						<EntityPointsDefinitionTable template={template} canEdit={canEdit} />
					)}
					{selectedTab === TabName.Children && <EntityTemplateChildren template={template} canEdit={canEdit} />}
				</>
			)}
		</Box>
	);
});
