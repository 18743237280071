import { useContext, useEffect, useState } from 'react';
import { Box, Button, Divider } from '@mui/material';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Add } from '@mui/icons-material';

import EntityView from './EntityView';
import LocationTree from 'components/LocationTree';
import { useStores } from 'store';
import { useParams, useNavigate } from 'routing/routing';
import { UserContext } from 'store/UserProvider';
import AddLocationDialog from './AddLocationDialog';
import ResizablePanel from './ResizablePanel';

interface ILocationsProps {
	mappingModeEnabled?: boolean;
}

export default observer(function Locations({ mappingModeEnabled }: ILocationsProps) {
	const { entities } = useStores();
	const [addLocationDialogOpen, setAddLocationDialogOpen] = useState(false);
	const { entityId } = useParams();
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	useEffect(
		() =>
			autorun(() => {
				if (!entityId) {
					return;
				}

				const entity = entities.addAndGetEntity(entityId);

				if (entity && entity.isLocation === false && entity.isPoint === false) {
					navigate(null, { entityId: null });
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[entityId],
	);

	const entity = entityId ? entities.getEntity(entityId) : undefined;
	const canEdit = user?.roles.includes('Contributor') || false;

	return (
		<Box
			sx={{
				flex: 1,
				display: 'flex',
				height: '100%',
			}}
		>
			<ResizablePanel
				defaultWidth={350}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flex: 1,
						flexDirection: 'column',
						borderRight: '1px solid rgba(0, 0, 0, 0.12)',
						minHeight: 0,
					}}
				>
					{canEdit && (
						<>
							<Box
								sx={{
									display: 'flex',
									flexWrap: 'wrap',
								}}
							>
								<Button
									startIcon={<Add />}
									variant="contained"
									onClick={() => setAddLocationDialogOpen(true)}
									sx={{ margin: (theme) => theme.spacing(1) }}
								>
									Add location...
								</Button>
							</Box>
							<Divider />
						</>
					)}
					<LocationTree
						selected={entity || null}
						onSelect={(entity) => navigate(null, { entityId: entity?.id || null })}
						mappingModeEnabled={mappingModeEnabled}
					/>
				</Box>
			</ResizablePanel>
			<EntityView hierarchy="location" />
			{addLocationDialogOpen && <AddLocationDialog onClose={() => setAddLocationDialogOpen(false)} />}
		</Box>
	);
});
