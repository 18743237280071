import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Box,
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	TextField,
	Tooltip,
	IconButton,
	Divider,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { IDevicePointPropertyDefinition } from '@mitie/metadata-api-types';

import { DevicePointTemplate } from 'store/devicePointTemplate';
import DevicePointTemplatePropertyDefinition from './DevicePointTemplatePropertyDefinition';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexGrow: 1,
			minHeight: 0,
		},
		defaultPoints: {
			display: 'flex',
			marginTop: theme.spacing(2),
			flexGrow: 1,
		},
		propertiesList: {
			display: 'flex',
			flexDirection: 'column',
		},
		topBar: {
			display: 'flex',
		},
		list: {
			display: 'flex',
			minHeight: 0,
		},
		grow: {
			flexGrow: 1,
		},
		field: {
			minWidth: '25rem',
		},
		titleText: {
			lineHeight: '48px',
			marginRight: theme.spacing(2),
			marginLeft: theme.spacing(2),
		},
	}),
);

interface IDevicePointTemplatePropertiesDefinitionProps {
	template: DevicePointTemplate;
	canEdit: boolean;
}

export default observer(function DevicePointTemplatePropertiesDefinition({
	template,
	canEdit,
}: IDevicePointTemplatePropertiesDefinitionProps) {
	const classes = useStyles();

	const [selectedPropertyName, setSelectedPropertyName] = useState(null as string | null);
	const [newPropertyDialogOpen, setNewPropertyDialogOpen] = useState(false);
	const [newPropertyName, setNewPropertyName] = useState('');

	const updateProperty = (data: IDevicePointPropertyDefinition) => {
		if (!template.data) {
			return;
		}

		const index = template.data.template.properties.findIndex((p) => data.name === p.name);

		if (index >= 0) {
			template.data.template.properties[index] = data;
		}
	};

	const selectedProperty = (template.data?.template.properties || []).find((p) => p.name === selectedPropertyName);

	const createProperty = (name: string) => {
		if (!template.data) {
			return;
		}

		const newProperty: IDevicePointPropertyDefinition = {
			name,
			label: '',
			type: 'string',
			include_in_device_twin: false,
		};

		template.data.template.properties.push(newProperty);

		setSelectedPropertyName(newProperty.name);
	};

	const deleteProperty = (name: string) => {
		if (!template.data) {
			return;
		}

		template.data.template.properties = template.data.template.properties.filter((p) => p.name !== name);

		if (selectedPropertyName === name) {
			setSelectedPropertyName(null);
		}
	};

	return (
		<Box className={classes.root}>
			{template.data && (
				<>
					<Box className={classes.propertiesList}>
						<Box className={classes.topBar}>
							<Typography variant="h6" className={classes.titleText}>
								Custom properties
							</Typography>
							{canEdit && (
								<>
									<Box className={classes.grow} />
									<Tooltip title="New property" placement="bottom">
										<IconButton color="secondary" onClick={() => setNewPropertyDialogOpen(true)}>
											<Add />
										</IconButton>
									</Tooltip>
								</>
							)}
						</Box>
						<Divider />
						<Box className={classes.list}>
							<Box sx={{ overflow: 'auto', minWidth: '16rem' }}>
								<List sx={{ padding: 0 }}>
									{template.data.template.properties.map((propertyDefinition) => (
										<ListItem
											button={true}
											key={propertyDefinition.name}
											selected={selectedPropertyName === propertyDefinition.name}
											autoFocus={selectedPropertyName === propertyDefinition.name}
											onClick={() => setSelectedPropertyName(propertyDefinition.name)}
										>
											<ListItemText
												sx={{ whiteSpace: 'nowrap' }}
												primary={<Typography variant="subtitle1">{propertyDefinition.name}</Typography>}
											></ListItemText>
											{canEdit && (
												<ListItemSecondaryAction>
													<IconButton edge="end" onClick={() => deleteProperty(propertyDefinition.name)}>
														<Delete />
													</IconButton>
												</ListItemSecondaryAction>
											)}
										</ListItem>
									))}
								</List>
							</Box>
						</Box>
					</Box>
					<Divider orientation="vertical" />
					{selectedProperty && (
						<DevicePointTemplatePropertyDefinition data={selectedProperty} setData={updateProperty} canEdit={canEdit} />
					)}
					<Dialog open={newPropertyDialogOpen} onClose={() => setNewPropertyDialogOpen(false)}>
						<DialogTitle>New custom property</DialogTitle>
						<DialogContent>
							<TextField
								autoFocus
								label="Property name"
								fullWidth
								value={newPropertyName}
								onChange={({ target: { value } }) => setNewPropertyName(value)}
							/>
							<DialogActions>
								<Button onClick={() => setNewPropertyDialogOpen(false)}>Cancel</Button>
								<Button
									onClick={() => {
										createProperty(newPropertyName);
										setNewPropertyDialogOpen(false);
										setNewPropertyName('');
									}}
									color="primary"
								>
									Add
								</Button>
							</DialogActions>
						</DialogContent>
					</Dialog>
				</>
			)}
		</Box>
	);
});
