import { TemplateType } from '@mitie/metadata-api-types';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { Status } from 'DataTypes';
import * as TemplatesApi from '../api/templates';

export interface ITemplateData<T> {
	name: string;
	template: T;
}

export abstract class Template<T> {
	public id: string;
	/** Date and time when the template was last saved */
	public updatedTime?: Date;
	/**
	 * Template "working" data.
	 * Might be different than `savedData` if the template has not been saved.
	 * Might be `undefined` if data has not been loaded from server yet.
	 */
	public data?: ITemplateData<T>;
	/** Status of data REST request to server */
	public dataRequest: Status = Status.None;
	/** Status of save REST request to server */
	public saveRequest = Status.None;
	/**
	 * Template data as saved on server.
	 * Might be different than `savedData` if the template has not been saved.
	 * Might be `undefined` if data has not been loaded from server yet.
	 */
	public savedData?: ITemplateData<T>;
	/**
	 * Number of entities using this template.
	 * Might be `undefined` if usage has not been fetched from server
	 */
	public usage?: number;
	/** Status of usage REST request to server */
	public usageRequest = Status.None;
	/** Type of template as a string. Defined in constructor and used in API requests */
	public templateType: TemplateType;

	public constructor(id: string, templateType: TemplateType) {
		makeObservable(this, {
			updatedTime: observable,
			data: observable,
			dataRequest: observable,
			savedData: observable,
			usage: observable,
			usageRequest: observable,
			created: computed,
			deleted: computed,
			modified: computed,
			unsaved: computed,
			saving: computed,
			displayName: computed,
			setSavedData: action,
			setData: action,
			delete: action,
			fetchUsage: action,
		});

		this.id = id;
		this.templateType = templateType;
	}

	/**
	 * Returns whether the template has been added but not saved to the server
	 */
	public get created() {
		return Boolean(this.data && !this.savedData);
	}

	/**
	 * Returns whether the template has been deleted but not saved to the server
	 */
	public get deleted() {
		return Boolean(!this.data && this.savedData);
	}

	/**
	 * Returns whether the template has been modified but not saved to the server
	 */
	public get modified() {
		if (!this.data || !this.savedData) {
			return false;
		}

		if (!isEqual(this.data, this.savedData)) {
			return true;
		}

		return false;
	}

	/**
	 * Returns whether the template has been changed but not saved to the server (i.e. either deleted, created or modified)
	 */
	public get unsaved() {
		return this.created || this.deleted || this.modified;
	}

	public get saving() {
		return this.saveRequest === Status.Loading;
	}

	/**
	 * Returns the display name of the template.
	 * If the template is deleted but not saved, returns the saved name.
	 * If the template data has not been loaded yet, returns a placeholder.
	 */
	public get displayName() {
		if (!this.data) {
			if (this.savedData) {
				// If the template is deleted but not saved
				return this.savedData.name;
			} else {
				// If the template data has not been loaded yet from the server
				return 'Loading...';
			}
		}

		return this.data.name;
	}

	/**
	 * Update the saved data for a template based on data received from server.
	 * @param templateData Template data
	 * @param updatedTime Time the template was last updated
	 * @param override Set to `true` to override unsaved data already present. Defaults to `false`
	 */
	public setSavedData(templateData: ITemplateData<T>, updatedTime: Date, override: boolean = false) {
		this.savedData = templateData;
		this.updatedTime = updatedTime;

		if (override || !this.data) {
			this.setData(templateData);
		}

		this.dataRequest = Status.Done;
	}

	/**
	 * Update the working data for this template
	 * Called when a new template is created in UI
	 * Updated data is not sent to the server until the `save` method is called.
	 * @param templateData Template data
	 */
	public setData(templateData: ITemplateData<T>) {
		this.data = cloneDeep(templateData);
	}

	/**
	 * Revert any unsaved changes on this template
	 */
	public discardChanges() {
		// Overridden by inherited classes
	}

	/**
	 * Flag this template for deletion.
	 * It will only be deleted on the server when the `save` method is called.
	 */
	public delete() {
		if (!this.data) {
			return;
		}

		this.data = undefined;
	}

	/**
	 * Fetch usage count for this template from the API
	 */
	public async fetchUsage() {
		this.usageRequest = Status.Loading;

		try {
			this.usage = await TemplatesApi.fetchTemplateUsage(this.id, this.templateType);

			runInAction(() => (this.usageRequest = Status.Done));
		} catch (error: any) {
			runInAction(() => (this.usageRequest = Status.Error));
			throw error;
		}
	}

	/**
	 * Commit any unsaved change to the server (i.e. if the template was either modified, created or deleted)
	 */
	public async save() {
		// Overridden by inherited classes
	}
}
