import { useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { Range } from 'react-date-range';
import { addDays, endOfDay, startOfDay } from 'date-fns';

import * as TelemetryApi from 'api/telemetry';
import { AlertsContext } from 'store/AlertsProvider';
import DateRangePickerField from './DateRangePickerField';

export default function DeleteTelemetryDialog({
	pointId,
	onClose,
}: {
	pointId: string;
	onClose: (success: boolean) => void;
}) {
	const [dateRange, setDateRange] = useState<Range>({
		startDate: startOfDay(new Date()),
		endDate: endOfDay(new Date()),
		key: 'selection',
	});
	const [isDeleting, setIsDeleting] = useState(false);
	const { addAlert } = useContext(AlertsContext);

	const deleteData = async () => {
		if (!dateRange.startDate || !dateRange.endDate) {
			return;
		}

		setIsDeleting(true);

		try {
			await TelemetryApi.deleteTelemetry(pointId, dateRange.startDate, addDays(dateRange.endDate, 1));
			onClose(true);
			addAlert(
				'info',
				`Successfully deleted data in range ${dateRange.startDate.toLocaleDateString()} - ${dateRange.endDate.toLocaleDateString()}`,
			);
		} finally {
			setIsDeleting(false);
		}
	};

	return (
		<Dialog open={true} onClose={() => onClose(false)}>
			<DialogContent>
				<Typography sx={{ marginBottom: (theme) => theme.spacing(2) }}>
					Choose time period to delete data for this point. Use with caution as this action cannot be reverted.
				</Typography>
				<DateRangePickerField onChange={(value) => setDateRange(value)} value={dateRange} />
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose(false)}>Cancel</Button>
				<Button disabled={isDeleting} onClick={() => deleteData()} variant="outlined">
					Delete data
				</Button>
			</DialogActions>
		</Dialog>
	);
}
