import { useEffect, useState } from 'react';
import { IExporter } from '@mitie/metadata-api-types';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Button, TextField, Typography } from '@mui/material';
import { formatISO9075 } from 'date-fns';

import { fetchExportersList, runExporter } from 'api/exporters';
import EntitySelect from 'components/EntitySelect';
import EntitiesPicker from 'components/EntitiesPicker';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			// display: 'flex',
			margin: theme.spacing(2),
		},
		description: {
			margin: theme.spacing(2),
			whiteSpace: 'pre-line',
		},
		field: {},
	}),
);

function initialParameters(exporter: IExporter) {
	return exporter.parameters.reduce(
		(parameters, current) => {
			if (current.default) {
				// Use default value if defined
				parameters[current.name] = current.default;
			}

			return parameters;
		},
		{} as {
			[parameter: string]: string | number | boolean;
		},
	);
}

export default function Exports() {
	const classes = useStyles();
	const [exporters, setExporters] = useState<IExporter[]>([]);
	const [exporter, setExporter] = useState<IExporter | null>(null);
	const [parameters, setParameters] = useState<{
		[parameter: string]: string | number | boolean | string[];
	}>({});
	const [areParametersSet, setAreParametersSet] = useState(false);
	const [isExportRunning, setIsExportRunning] = useState(false);

	useEffect(() => {
		fetchExportersList().then(setExporters);
	}, []);

	useEffect(() => {
		if (!exporter) {
			return;
		}

		let allSet = true;

		for (const parameterDefinition of exporter.parameters.filter((p) => !p.optional)) {
			if (!parameters[parameterDefinition.name]) {
				allSet = false;
				break;
			}
		}

		setAreParametersSet(allSet);
	}, [parameters, exporter]);

	const runAndDownloadExport = async () => {
		if (!exporter) {
			return;
		}

		try {
			setIsExportRunning(true);
			const data = await runExporter(exporter.name, parameters);
			const filename = `${exporter.label} - ${formatISO9075(new Date())}.csv`;

			const url = window.URL.createObjectURL(data);
			const a = document.createElement('a');
			a.href = url;
			a.download = filename;
			a.click();
		} finally {
			setIsExportRunning(false);
		}
	};

	return (
		<Box className={classes.root}>
			<Box>
				<TextField
					select
					label="Exporter"
					value={exporter?.name || ''}
					onChange={(event) => {
						const exporter = exporters.find((e) => e.name === event.target.value);

						if (exporter) {
							setExporter(exporter);
							setParameters(initialParameters(exporter));
						}
					}}
					SelectProps={{
						native: true,
					}}
					InputLabelProps={{
						shrink: true,
					}}
				>
					<option value="">Select from list</option>
					{exporters.map(({ name, label }) => (
						<option key={name} value={name}>
							{label}
						</option>
					))}
				</TextField>
				{exporter && <Typography className={classes.description}>{exporter.description}</Typography>}
			</Box>
			{exporter && (
				<Box>
					<Box>
						{exporter.parameters.map((parameterDefinition) => {
							const value = parameters[parameterDefinition.name];

							if (parameterDefinition.type === 'entity' && parameterDefinition.entityPicker?.tagFilter) {
								return (
									<EntitySelect
										label={parameterDefinition.label}
										selectedEntityId={value as string | undefined}
										optional={parameterDefinition.optional}
										entityType={parameterDefinition.entityPicker.tagFilter}
										disabled={false}
										onChange={(selectedEntityId) => {
											if (selectedEntityId) {
												setParameters((current) => ({ ...current, [parameterDefinition.name]: selectedEntityId }));
											} else {
												setParameters((current) => {
													const newParameters = { ...current };
													delete newParameters[parameterDefinition.name];
													return newParameters;
												});
											}
										}}
										key={parameterDefinition.name}
										className={classes.field}
									/>
								);
							} else if (parameterDefinition.type === 'entities' && parameterDefinition.entityPicker?.tagFilter) {
								return (
									<EntitiesPicker
										label={parameterDefinition.label}
										value={(value || []) as string[]}
										optional={parameterDefinition.optional}
										entityType={parameterDefinition.entityPicker.tagFilter}
										disabled={false}
										onChange={(selectedEntityIds) => {
											if (!selectedEntityIds) {
												return;
											} else if (selectedEntityIds.length) {
												setParameters((current) => ({ ...current, [parameterDefinition.name]: selectedEntityIds }));
											} else {
												setParameters((current) => {
													const newParameters = { ...current };
													delete newParameters[parameterDefinition.name];
													return newParameters;
												});
											}
										}}
										key={parameterDefinition.name}
									/>
								);
							} else {
								return null;
							}
						})}
					</Box>
					<Button onClick={() => runAndDownloadExport()} disabled={isExportRunning || !areParametersSet}>
						Run exporter
					</Button>
				</Box>
			)}
		</Box>
	);
}
