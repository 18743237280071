import { action, runInAction } from 'mobx';
import { io, Socket } from 'socket.io-client';

import { stores } from 'store';
import ErrorWithHttpCode from 'tools/ErrorWithHttpCode';

export let socket: Socket;

export function get<T>(url: string, params: { [name: string]: any } = {}, signal?: AbortSignal) {
	const strParams = Object.keys(params).reduce((acc: string, name) => {
		const value = params[name];

		if (value !== null && value !== undefined) {
			if (acc !== '') {
				acc += '&';
			}

			acc += name + '=';

			if (value instanceof Date) {
				acc += value.toISOString();
			} else if (value instanceof Array) {
				acc += encodeURIComponent(JSON.stringify(value));
			} else {
				acc += encodeURIComponent(value);
			}
		}

		return acc;
	}, '');

	return runApiRequest<T>(params ? `${url}?${strParams}` : url, {
		method: 'GET',
		headers: {},
		signal,
	});
}

export function post<T>(url: string, postData: {}) {
	return runApiRequest<T>(url, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(postData),
	});
}

export function patch<T>(url: string, postData: {}) {
	return runApiRequest<T>(url, {
		method: 'PATCH',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(postData),
	});
}

export function put<T>(url: string, postData: {}) {
	return runApiRequest<T>(url, {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(postData),
	});
}

export function delete_<T>(url: string, params: { [name: string]: any } = {}) {
	const strParams = Object.keys(params).reduce((acc: string, name) => {
		const value = params[name];

		if (value !== null && value !== undefined) {
			if (acc !== '') {
				acc += '&';
			}

			acc += name + '=';

			if (value instanceof Date) {
				acc += value.toISOString();
			} else if (value instanceof Array) {
				acc += encodeURIComponent(JSON.stringify(value));
			} else {
				acc += encodeURIComponent(value);
			}
		}

		return acc;
	}, '');

	return runApiRequest<T>(params ? `${url}?${strParams}` : url, {
		method: 'DELETE',
		headers: {},
	});
}

async function runApiRequest<T>(url: string, options: RequestInit): Promise<T> {
	options.credentials = 'include';

	const response = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, options);

	return parseResponse(response);
}

async function parseResponse(response: Response) {
	if (response.status < 200 || response.status > 299) {
		return processHttpError(response);
	} else {
		const contentType = response.headers.get('content-type');

		if (!contentType) {
			return;
		} else if (contentType.indexOf('application/json') !== -1) {
			return response.json();
		} else if (contentType.indexOf('text/csv') !== -1) {
			return response.blob();
		} else {
			throw new Error(`Unable to parse API response: Invalid content-type: ${contentType}`);
		}
	}
}

async function processHttpError(response: Response) {
	const contentType = response.headers.get('content-type');

	if (contentType && contentType.indexOf('application/json') !== -1) {
		const json: any = await response.json();
		const message = json.message;
		const error = typeof message === 'string' ? message : response.statusText;

		throw new ErrorWithHttpCode(response.status, error);
	} else {
		throw new ErrorWithHttpCode(response.status, response.statusText);
	}
}

socket = io(`${process.env.REACT_APP_API_URL}`, { transports: ['websocket'] });

socket.on(
	'disconnect',
	action(() => {
		stores.globals.websocketConnected = false;
	}),
);

socket.on('connect', () => {
	runInAction(() => {
		stores.globals.websocketConnected = true;
	});
});

socket.on('unauthorized', () => socket.disconnect());
