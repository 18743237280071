import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import TagsInput from './TagsInput';
import { DevicePointTemplate } from 'store/devicePointTemplate';
import EntityProperty from './EntityProperty';
import { DeviceTemplate } from 'store/deviceTemplate';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginLeft: theme.spacing(2),
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
	}),
);

interface IDeviceTemplateChannelDetails {
	template: DevicePointTemplate;
	deviceTemplate: DeviceTemplate;
	metricName: string;
	canEdit: boolean;
}

export default observer(function DeviceTemplateChannelDetails({
	template,
	deviceTemplate,
	metricName,
	canEdit,
}: IDeviceTemplateChannelDetails) {
	const classes = useStyles();
	const channel = deviceTemplate.data?.template.default_device_points?.find(
		(p) => p.properties.metric_name === metricName,
	);

	if (!channel) {
		return null;
	}

	const updateTags = (tags: string[]) => {
		channel.tags = tags;
	};

	const updateProperty = (name: string) => (value: string | number | boolean | undefined) => {
		if (value === undefined) {
			delete channel.properties[name];
		} else {
			channel.properties[name] = value;
		}
	};

	const updateTwinProperty = (propertyName: string) => (value: string | number | boolean | undefined) => {
		const deviceTwin = deviceTemplate.data?.template.device_twin;

		if (!deviceTwin) {
			return;
		}

		if (!deviceTwin.channels) {
			deviceTwin.channels = [];
		}

		const twinChannel = deviceTwin.channels.find((c) => c.metric_name === metricName);

		if (twinChannel) {
			if (value === undefined) {
				delete twinChannel[propertyName];
			} else {
				twinChannel[propertyName] = value;
			}
		} else if (value !== undefined) {
			deviceTwin.channels.push({
				metric_name: metricName,
				[propertyName]: value,
			});
		}
	};

	const channelProperties = deviceTemplate.data?.template.device_twin?.channels
		? deviceTemplate.data.template.device_twin.channels.find((c) => c.metric_name === metricName)
		: undefined;

	return (
		<Box className={classes.root}>
			<Typography variant="h6">Channel properties</Typography>
			<Box>
				<EntityProperty
					label="Name"
					type="string"
					value={channel.name}
					onChange={(value) => {
						channel.name = value as string;
					}}
					className={classes.field}
					disabled={!canEdit}
				/>
				{template.data?.template.properties &&
					template.data.template.properties
						.filter((p) => !p.include_in_device_twin)
						.map((propertyDefinition) => (
							<EntityProperty
								key={propertyDefinition.name}
								label={propertyDefinition.label}
								type={propertyDefinition.type}
								value={channel.properties[propertyDefinition.name]}
								select={propertyDefinition.select}
								onChange={updateProperty(propertyDefinition.name)}
								className={classes.field}
								disabled={!canEdit}
							/>
						))}
			</Box>
			<Typography variant="h6">Channel device twin properties</Typography>
			<Box>
				{template.data?.template.properties &&
					template.data.template.properties
						.filter((p) => p.include_in_device_twin)
						.map((propertyDefinition) => {
							const twinProperty = channelProperties?.[propertyDefinition.name];

							return (
								<EntityProperty
									key={propertyDefinition.name}
									label={propertyDefinition.label}
									type={propertyDefinition.type}
									select={propertyDefinition.select}
									value={twinProperty as string | number | boolean | undefined}
									disabled={propertyDefinition.name === 'metric_name' || !canEdit}
									onChange={updateTwinProperty(propertyDefinition.name)}
									className={classes.field}
								/>
							);
						})}
			</Box>
			<Typography variant="h6">Channel default tags</Typography>
			<TagsInput
				label="Default tags"
				value={channel.tags}
				onChange={updateTags}
				className={classes.field}
				disabled={!canEdit}
			/>
		</Box>
	);
});
