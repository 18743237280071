import React, { useState } from 'react';
import { Chip, TextField, InputAdornment } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import camelCase from 'lodash/camelCase';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		tagsContainer: {
			flex: 1,
			height: 'auto',
			maxHeight: 'none',
			flexWrap: 'wrap',
			margin: '0.5rem 0',
		},
		input: {
			flexGrow: 1,
		},
		container: {
			marginRight: '1rem',
			minWidth: '25rem',
			'& input': {
				width: '10rem',
			},
		},
		chip: {
			margin: '0.25rem',
		},
	}),
);

interface ITagsInputProps {
	value: string[];
	label?: string;
	className?: string;
	disabled?: boolean;
	onChange: (tags: string[]) => void;
}

export default function TagsInput({ value, label, disabled, onChange }: ITagsInputProps) {
	const classes = useStyles();
	const delimiterKeys = ['Enter', 'Tab', ',', ' '];
	const [input, setInput] = useState('');
	const variant = label ? 'outlined' : 'standard';

	const formatTag = (str: string) => {
		return camelCase(str);
	};

	const addTag = (tag: string) => {
		if (!value.includes(tag)) {
			onChange([...value, tag]);
		}
	};

	const removeTag = (tag: string) => {
		if (value.includes(tag)) {
			onChange(value.filter((t) => t !== tag));
		}
	};

	const keyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (delimiterKeys.includes(event.key)) {
			const tag = formatTag(input);

			if (tag !== '') {
				addTag(tag);
				setInput('');
			}

			event.preventDefault(); // prevent triggering the onChange event
		}
	};

	return (
		<TextField
			value={input}
			label={label}
			onKeyDown={keyDown}
			onChange={(event) => setInput(event.currentTarget.value)}
			onBlur={() => setInput('')}
			placeholder={disabled ? '' : 'Add tag'}
			margin="normal"
			variant={variant}
			className={classes.container}
			disabled={disabled}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start" className={classes.tagsContainer}>
						{value.map((tag) => (
							<Chip
								key={tag}
								label={tag}
								onDelete={disabled ? undefined : () => removeTag(tag)}
								className={classes.chip}
							/>
						))}
					</InputAdornment>
				),
			}}
		/>
	);
}
