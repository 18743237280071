import { useState } from 'react';
import { Box, Button, Typography, Divider } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import DeviceTreeWithFilter from './DeviceTreeWithFilter';

interface IPointsMappingDrawerProps {
	onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			boxSizing: 'border-box',
			height: '100%',
		},
		header: {
			display: 'flex',
			padding: theme.spacing(1),
		},
		button: {
			marginLeft: theme.spacing(2),
		},
		grow: {
			flexGrow: 1,
		},
		title: {
			paddingLeft: theme.spacing(1),
		},
	}),
);

export default function PointsMappingDrawer({ onClose }: IPointsMappingDrawerProps) {
	const classes = useStyles();
	const [selectedEntityId, setSelectedEntityId] = useState<string | null>(null);

	return (
		<Box className={classes.root}>
			<Box className={classes.header}>
				<Typography variant="h6" className={classes.title}>
					Points mapping
				</Typography>
				<Box className={classes.grow} />
				<Button className={classes.button} startIcon={<CloseIcon />} onClick={() => onClose()}>
					Close
				</Button>
			</Box>
			<Divider />
			<DeviceTreeWithFilter
				selected={selectedEntityId}
				onSelect={(entityId) => setSelectedEntityId(entityId)}
				mappingModeEnabled={true}
			/>
		</Box>
	);
}
