import React, { useCallback, useState } from 'react';
import { IApiUserProfile, Role } from '@mitie/metadata-api-types';

import * as AuthApi from '../api/auth';

export type AuthenticationStatus = 'Unauthenticated' | 'Authenticating' | 'Failed' | 'Forbidden' | 'Authenticated';

interface IUserStore {
	user?: IUser;
	authenticationStatus: AuthenticationStatus;
	fetchUser: () => void;
	logout: () => void;
}

export interface IUser {
	profile: IApiUserProfile;
	photo: string | null;
	roles: Role[];
}

export const UserContext = React.createContext<IUserStore>({
	authenticationStatus: 'Unauthenticated',
	fetchUser: () => {},
	logout: () => {},
});

export default function UserProvider({ children }: { children: React.ReactNode[] | React.ReactNode }) {
	const [user, setUser] = useState<IUser>();
	const [authenticationStatus, setAuthenticationStatus] = useState<AuthenticationStatus>('Unauthenticated');

	const logout = () => {
		setUser(undefined);
		AuthApi.logout();
	};

	const fetchUser = async () => {
		if (authenticationStatus !== 'Unauthenticated') {
			return;
		}

		setAuthenticationStatus('Authenticating');

		const authenticationResult = await AuthApi.getProfile();

		if (authenticationResult.status === 'Unauthenticated') {
			AuthApi.login();
		}

		setAuthenticationStatus(authenticationResult.status);
		setUser(authenticationResult.profile);
	};

	const contextValue = {
		user,
		authenticationStatus,
		fetchUser: useCallback(fetchUser, [authenticationStatus]),
		logout: useCallback(logout, []),
	};

	return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}
