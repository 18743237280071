import { useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	LinearProgress,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Avatar,
} from '@mui/material';
import { Folder, Person } from '@mui/icons-material';
import { IApiUser, IApiUserWithProfile } from '@mitie/metadata-api-types';

import * as Api from '../api/users';
import useDebounce from 'hooks/useDebounce';

interface IAddUserDialogProps {
	onClose: (addedUserId?: string) => void;
}

export default function AddUserDialog({ onClose }: IAddUserDialogProps) {
	const [userId, setUserId] = useState<string>();
	const [input, setInput] = useState('');
	const [isSaving, setIsSaving] = useState(false);
	const [matchedUsers, setMatchedUsers] = useState<(IApiUser & IApiUserWithProfile)[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const debouncedInput = useDebounce(input, 500);

	useEffect(() => {
		if (debouncedInput.length > 2) {
			setIsLoading(true);
			Api.search(debouncedInput)
				.then((data) => {
					if (!data.find(({ id }) => id === userId)) {
						// Reset selected user if not in new list
						setUserId(undefined);
					}

					setMatchedUsers(data);
				})
				.finally(() => setIsLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedInput]);

	const createUser = async () => {
		if (!userId) {
			return;
		}

		setIsSaving(true);

		try {
			await Api.create(userId);
			onClose(input);
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Dialog open={true} onClose={() => onClose()}>
			<DialogTitle>Add user</DialogTitle>
			<DialogContent>
				<TextField
					label="Search user"
					value={input}
					onChange={(event) => setInput(event.currentTarget.value)}
					error={!input.length}
					autoFocus={true}
					fullWidth
					sx={{ marginTop: '8px' }}
				/>
				<List>
					{isLoading && <LinearProgress />}
					{matchedUsers.map((user, index) => {
						const isSelected = userId === user.id;

						return (
							<ListItem
								key={index}
								button
								onClick={() => setUserId(user.id)}
								sx={{
									backgroundColor: isSelected ? (theme) => theme.palette.secondary.light : 'inherit',
								}}
							>
								<ListItemAvatar>
									<Avatar>{user.type === 'User' ? <Person /> : <Folder />}</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={user.displayName}
									secondary={`${user.type}${
										user.profile ? `\n${user.profile.mail}\n${user.profile.job_title || ''}` : ''
									}`}
									sx={{ whiteSpace: 'pre-line' }}
								/>
							</ListItem>
						);
					})}
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} color="primary" variant="outlined">
					Cancel
				</Button>
				<Button onClick={() => createUser()} color="primary" variant="contained" disabled={!userId || isSaving}>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	);
}
