import React, { useState, useEffect } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
	Box,
	Typography,
	List,
	ListItem,
	ListItemText,
	TextField,
	Tooltip,
	IconButton,
	Divider,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	ListItemSecondaryAction,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { IDefaultDevicePoint, IDeviceTwinProperties } from '@mitie/metadata-api-types';

import { useStores } from 'store';
import { DeviceTemplate } from 'store/deviceTemplate';
import { DevicePointTemplate } from 'store/devicePointTemplate';
import DeviceTemplateChannelDetails from './DeviceTemplateChannelDetails';
import { Status } from 'DataTypes';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			minHeight: 0,
		},
		templateSelector: {
			margin: theme.spacing(2),
		},
		defaultPoints: {
			display: 'flex',
			flexGrow: 1,
			minHeight: 0,
		},
		pointsList: {
			display: 'flex',
			flexDirection: 'column',
			minHeight: 0,
		},
		topBar: {
			display: 'flex',
		},
		list: {
			display: 'flex',
			minHeight: 0,
		},
		grow: {
			flexGrow: 1,
		},
		field: {
			minWidth: '25rem',
		},
		titleText: {
			lineHeight: '48px',
			marginRight: theme.spacing(2),
			marginLeft: theme.spacing(2),
		},
	}),
);

interface IDevicePointsDefinitionTableProps {
	template: DeviceTemplate;
	canEdit: boolean;
}

export default observer(function DevicePointsDefinitionTable({ template, canEdit }: IDevicePointsDefinitionTableProps) {
	const classes = useStyles();

	const { devicePointTemplates } = useStores();
	const [dpTemplate, setDpTemplate] = useState<DevicePointTemplate>();
	const [selectedPointName, setSelectedPointName] = useState(null as string | null);
	const [newChannelDialogOpen, setNewChannelDialogOpen] = useState(false);
	const [newChannelName, setNewChannelName] = useState('');

	useEffect(() => {
		if (devicePointTemplates.fetchStatus === Status.None) {
			devicePointTemplates.fetchAll();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(
		() =>
			autorun(() => {
				if (!template.data?.template.device_points_template) {
					setDpTemplate(undefined);
				} else {
					const dpTemplateId = template.data.template.device_points_template;
					const dpTemplate = devicePointTemplates.getTemplate(dpTemplateId);

					if (dpTemplate) {
						setDpTemplate(dpTemplate);
					}
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[template],
	);

	const createDefaultPoint = (metricName: string) => {
		if (!template.data || !dpTemplate?.data) {
			return;
		}

		if (!template.data.template.default_device_points) {
			template.data.template.default_device_points = [];
		}

		const newPoint: IDefaultDevicePoint = {
			name: metricName,
			properties: {
				metric_name: metricName,
			},
			tags: [],
		};

		if (!template.data.template.device_twin) {
			template.data.template.device_twin = { tags: {}, properties: [] };
		}

		if (!template.data.template.device_twin?.channels) {
			template.data.template.device_twin.channels = [];
		}

		const dtChannel = template.data.template.device_twin.channels.find((c) => c.metric_name === metricName);

		if (!dtChannel) {
			// Add channel to device twin
			const dtProperties: IDeviceTwinProperties = { metric_name: metricName };

			for (const propertyDefinition of dpTemplate.data.template.properties) {
				if (propertyDefinition.default) {
					dtProperties[propertyDefinition.name] = propertyDefinition.default;
				}
			}

			template.data.template.device_twin.channels.push(dtProperties);
		}

		template.data.template.default_device_points.push(newPoint);

		setSelectedPointName(metricName);
	};

	const deleteDefaultPoint = (metricName: string) => {
		if (!template.data?.template.default_device_points || !template.data?.template.device_twin?.channels) {
			return;
		}

		template.data.template.default_device_points = template.data.template.default_device_points.filter(
			(p) => p.properties.metric_name !== metricName,
		);
		template.data.template.device_twin.channels = template.data.template.device_twin.channels.filter(
			(c) => c.metric_name === metricName,
		);

		if (selectedPointName === metricName) {
			setSelectedPointName(null);
		}
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.templateSelector}>
				<Typography variant="h6">Device channels template</Typography>
				{devicePointTemplates.fetchStatus === Status.Loading && <LinearProgress />}
				<TextField
					select
					value={dpTemplate?.id}
					onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
						if (template.data) {
							if (value !== '') {
								template.data.template.device_points_template = value;
							} else {
								delete template.data.template.device_points_template;
								delete template.data.template.default_device_points;
							}
						}
					}}
					className={classes.field}
					SelectProps={{
						native: true,
					}}
					disabled={!canEdit}
				>
					<option value="">No template</option>
					{devicePointTemplates.templates.map((template) => (
						<option key={template.id} value={template.id}>
							{template.data?.name}
						</option>
					))}
				</TextField>
			</Box>
			<Divider />
			{dpTemplate && (
				<Box className={classes.defaultPoints}>
					<Box className={classes.pointsList}>
						<Box className={classes.topBar}>
							<Typography variant="h6" className={classes.titleText}>
								Default channels
							</Typography>
							{canEdit && (
								<>
									<Box className={classes.grow} />
									<Tooltip title="New default channel" placement="bottom">
										<IconButton color="secondary" onClick={() => setNewChannelDialogOpen(true)}>
											<Add />
										</IconButton>
									</Tooltip>
								</>
							)}
						</Box>
						<Divider />
						<Box className={classes.list}>
							<Box sx={{ overflow: 'auto', minWidth: '16rem' }}>
								{template.data?.template.default_device_points && (
									<List sx={{ padding: 0 }}>
										{template.data.template.default_device_points.map((defaultDevicePoint) => (
											<ListItem
												button={true}
												key={defaultDevicePoint.properties.metric_name as string}
												selected={selectedPointName === defaultDevicePoint.properties.metric_name}
												onClick={() => setSelectedPointName(defaultDevicePoint.properties.metric_name as string)}
											>
												<ListItemText
													sx={{ whiteSpace: 'nowrap' }}
													primary={<Typography variant="subtitle1">{defaultDevicePoint.name}</Typography>}
												></ListItemText>
												{canEdit && (
													<ListItemSecondaryAction>
														<IconButton
															edge="end"
															onClick={() => deleteDefaultPoint(defaultDevicePoint.properties.metric_name as string)}
														>
															<Delete />
														</IconButton>
													</ListItemSecondaryAction>
												)}
											</ListItem>
										))}
									</List>
								)}
							</Box>
						</Box>
					</Box>
					<Divider orientation="vertical" />
					{selectedPointName && (
						<DeviceTemplateChannelDetails
							template={dpTemplate}
							deviceTemplate={template}
							metricName={selectedPointName}
							canEdit={canEdit}
						/>
					)}
				</Box>
			)}
			<Dialog open={newChannelDialogOpen} onClose={() => setNewChannelDialogOpen(false)}>
				<DialogTitle>New default channel</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						label="Metric name"
						fullWidth
						value={newChannelName}
						onChange={({ target: { value } }) => setNewChannelName(value)}
					/>
					<DialogActions>
						<Button onClick={() => setNewChannelDialogOpen(false)}>Cancel</Button>
						<Button
							onClick={() => {
								createDefaultPoint(newChannelName);
								setNewChannelDialogOpen(false);
								setNewChannelName('');
							}}
							color="primary"
						>
							Add
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</Box>
	);
});
