import { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Check, Close } from '@mui/icons-material';

import EntityTreeMultiselect from './EntityTreeMultiselect';

interface IEntitiesPickerDialogProps {
	label: string;
	value: string[];
	optional: boolean;
	entityType: string;
	onClose: (value?: string[]) => void;
}

export default function EntitiesPickerDialog({
	label,
	value,
	optional,
	entityType,
	onClose,
}: IEntitiesPickerDialogProps) {
	const [selected, setSelected] = useState(value);

	return (
		<Dialog open={true} onClose={() => onClose()} maxWidth="sm" fullWidth>
			<DialogTitle>Select entities</DialogTitle>
			<DialogContent sx={{ height: '400px' }}>
				<EntityTreeMultiselect entityType={entityType} selected={value} onSelect={setSelected} />
			</DialogContent>
			<DialogActions>
				<Button
					startIcon={<Close />}
					onClick={() => {
						onClose();
					}}
					variant="outlined"
				>
					Cancel
				</Button>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					startIcon={<Check />}
					onClick={() => {
						onClose(selected);
					}}
					variant="contained"
					disabled={!optional && selected.length === 0}
				>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
