import { action, computed, makeObservable, observable } from 'mobx';
import { IEntityTemplate } from '@mitie/metadata-api-types';
import { v4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';

import Templates from './templates';
import { EntityTemplate } from './entityTemplate';
import { stores } from './index';

export default class EntityTemplates extends Templates<IEntityTemplate> {
	private list: { [id: string]: EntityTemplate } = {};

	constructor() {
		super('entity');

		makeObservable<EntityTemplates, 'list'>(this, {
			addAndGetTemplate: action,
			deleteTemplate: action,
			templates: computed,
			list: observable,
		});
	}

	public getTemplate(id: string): EntityTemplate | undefined {
		return this.list[id];
	}

	public addAndGetTemplate(templateId: string): EntityTemplate {
		const template = this.getTemplate(templateId);

		if (template) {
			return template;
		}

		const newTemplate = new EntityTemplate(templateId);
		this.list[templateId] = newTemplate;

		return newTemplate;
	}

	public get templates() {
		return Object.keys(this.list)
			.map((id) => this.list[id])
			.sort((a, b) => a.displayName.localeCompare(b.displayName));
	}

	public createTemplate(baseTemplateId?: string) {
		const entityId = v4();
		const newTemplate = this.addAndGetTemplate(entityId);

		if (baseTemplateId) {
			const baseTemplate = this.getTemplate(baseTemplateId);

			if (!baseTemplate || !baseTemplate.data) {
				throw new Error('Unable to create template: Base template not found');
			}

			const newTemplateData = cloneDeep(baseTemplate.data);
			newTemplateData.template.points_templates = [];

			for (const basePointTemplateId of baseTemplate.data.template.points_templates) {
				const basePointTemplate = stores.entityPointTemplates.getTemplate(basePointTemplateId);

				if (!basePointTemplate || !basePointTemplate.data) {
					throw new Error('Unable to create template: Base point template not found');
				}

				const newPointTemplateId = v4();
				const newPointTemplate = stores.entityPointTemplates.addAndGetTemplate(newPointTemplateId);
				newPointTemplate.setData(basePointTemplate.data);

				newTemplateData.template.points_templates.push(newPointTemplateId);
			}

			newTemplate.setData(newTemplateData);
			newTemplate.data!.name = 'New entity template';
		} else {
			newTemplate.setData({
				name: 'New entity template',
				template: {
					properties: [],
					tags: [],
					points_templates: [],
					children: [],
				},
			});
		}

		return newTemplate;
	}

	public deleteTemplate(template: EntityTemplate) {
		template.savedData = undefined;
		template.data = undefined;
		template.updatedTime = undefined;

		delete this.list[template.id];
	}
}
