import { makeAutoObservable, autorun, runInAction, observable, toJS } from 'mobx';
import {
	RelationType,
	TemplateType,
	EntityLifecycleStatus,
	IDeviceTwinPropertyDefinition,
	IDeviceTwinTags,
} from '@mitie/metadata-api-types';
import { formatRelative } from 'date-fns';
import { v4 as uuid } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import * as EntitiesApi from '../api/entities';
import * as DevicesApi from '../api/devices';
import * as TelemetryApi from '../api/telemetry';
import { Status } from '../DataTypes';
import { stores } from 'store';

export interface IEntityData {
	name: string;
	properties: { [property: string]: string | number | boolean };
	tags: string[];
	relations: { [relation in RelationType]?: string };
	externalMappings: { [system: string]: { [property: string]: string | number | boolean } };
	templates: { [type in TemplateType]?: string };
	lifecycleStatus: EntityLifecycleStatus;
}

export interface IDeviceConfigData {
	tags: IDeviceTwinTags;
	properties: { [property: string]: string | number | boolean };
	channels: { [property: string]: string | number | boolean }[];
}

export interface IDeviceReportedConfigData {
	properties: { [property: string]: string | number | boolean };
	channels: { [property: string]: string | number | boolean }[];
	modifiedTime: Date;
}

export interface IDeviceStatus {
	isOnline: boolean;
	lastTelemetryTime?: Date;
	otherProperties: { [key: string]: string | number | boolean };
}

export default class Entity {
	/** Unique ID of the entity */
	public id: string;
	/** Date and time when the entity was last saved */
	public updatedTime?: Date;
	/**
	 * Entity "working" data.
	 * Might be different than `savedData` if the entity has not been saved.
	 * Might be `undefined` if data has not been loaded from server yet.
	 */
	public data?: IEntityData;
	/**
	 * Device "working" data.
	 * Might be different than `savedDeviceData` if the entity has not been saved.
	 * Might be `undefined` if data has not been loaded from server yet.
	 */
	public deviceConfigData?: IDeviceConfigData;
	public deviceReportedConfigData?: IDeviceReportedConfigData;
	/** Holds the device status. Might be `undefined` if data has not been loaded from the server yet */
	public deviceStatus?: IDeviceStatus;
	/** Children entities, indexed by relation type (i.e. hierarchy type: location, device, etc.) */
	public children: { [relation in RelationType]?: Entity[] } = {};
	/** Descendants entities. Only applies to location and devices hierarchy */
	public descendants: { [relation in 'location' | 'gateway' | 'equip']?: Entity[] } = {};
	/** Status of data REST request to server */
	public dataRequest = Status.None;
	/** Status of device config REST request to server */
	public deviceDataRequest = Status.None;
	/** Status of device reported config request to server */
	public deviceReportedConfigRequest = Status.None;
	/** Status of device status REST request to server */
	public deviceStatusRequest = Status.None;
	/** Status of children REST request to server */
	public childrenRequest: { [relation in RelationType]?: Status } = {};
	/** Status of descendants REST request to server. Only applies to location and devices hierarchy */
	public descendantsRequest: { [relation in 'location' | 'gateway' | 'equip']?: Status } = {};
	/** Status of save REST request to server */
	public saveRequest = Status.None;
	/** Status of device twin save REST request to server */
	public deviceSaveRequest = Status.None;
	/**
	 * Entity data as saved on server.
	 * Might be different than `savedData` if the entity has not been saved.
	 * Might be `undefined` if data has not been loaded from server yet.
	 */
	private savedData?: IEntityData;
	/**
	 * Device twin data as saved in IoT hub.
	 * Might be different than `savedData` if the entity has not been saved.
	 * Might be `undefined` if data has not been loaded from server yet.
	 */
	public savedDeviceConfigData?: IDeviceConfigData;
	/**
	 * Flag set when the device is deleted in the UI but not saved.
	 * It is necessary to keep `data` as it is used to do some cleanup when the entity is saved.
	 */
	public deleted?: boolean;

	/**
	 * Returns whether the entity has been added but not saved to the server
	 */

	public get created() {
		return Boolean(this.data && !this.savedData);
	}

	/**
	 * Returns whether the device has been added but not saved to the server
	 */

	public get deviceCreated() {
		return Boolean(this.deviceConfigData && !this.savedDeviceConfigData);
	}

	/**
	 * Returns whether the device has been deleted but not saved to the server
	 */

	public get deviceDeleted() {
		return Boolean(this.deleted && this.savedDeviceConfigData);
	}

	/**
	 * Returns whether the entity has been modified but not saved to the server
	 */

	public get modified() {
		if (!this.data || !this.savedData) {
			return false;
		}

		// Compare entity data
		if (!isEqual(toJS(this.data), toJS(this.savedData))) {
			return true;
		}

		return false;
	}

	/**
	 * Returns whether the entity can be saved (form validation)
	 */

	public get canSave() {
		return this.metadataUnsaved && this.data && this.data.name.length > 0;
	}

	/**
	 * Returns whether the device config has been modified but not saved to the server
	 */

	public get deviceModified() {
		// Compare device data
		if (!this.deviceConfigData || !this.savedDeviceConfigData) {
			return false;
		}

		// Compare entity data
		if (!isEqual(toJS(this.deviceConfigData), toJS(this.savedDeviceConfigData))) {
			return true;
		}

		return false;
	}

	/**
	 * Returns whether the device config data is different from the device reported config data
	 */

	public get deviceConfigMismatch() {
		if (!this.deviceConfigData || !this.deviceReportedConfigData || this.deviceUnsaved) {
			return false;
		}

		// Ignore the tags since they are not in reported config object
		const { tags, ...configDataWitoutTags } = toJS(this.deviceConfigData);
		const { modifiedTime, ...reportedDataWithoutDate } = toJS(this.deviceReportedConfigData);

		if (!isEqual(configDataWitoutTags, reportedDataWithoutDate)) {
			return true;
		}

		return false;
	}

	/**
	 * Returns whether the entity has been changed but not saved to the server (i.e. either deleted, created or modified)
	 */

	public get unsaved() {
		return this.metadataUnsaved || this.deviceUnsaved;
	}

	public get metadataUnsaved() {
		return this.created || this.deleted || this.modified;
	}

	public get deviceUnsaved() {
		return this.deviceCreated || this.deviceDeleted || this.deviceModified;
	}

	public get saving() {
		return this.saveRequest === Status.Loading || this.deviceSaveRequest === Status.Loading;
	}

	/**
	 * Returns the number of unsaved children based on location hierarchy (itself not included)
	 * The count is recursive.
	 */

	public get childrenUnsavedCount() {
		if (!this.children.location) {
			return 0;
		}

		return this.children.location.reduce((acc, e) => {
			acc += e.childrenUnsavedCount;

			if (e.unsaved) {
				acc++;
			}

			return acc;
		}, 0);
	}

	/**
	 * Returns the number of unsaved children based on devices/gateway hierarchy (itself not included)
	 * The count is recursive.
	 */

	public get childrenDevicesUnsavedCount() {
		if (!this.children.gateway) {
			return 0;
		}

		return this.children.gateway.reduce((acc, e) => {
			acc += e.childrenDevicesUnsavedCount;

			if (e.unsaved) {
				acc++;
			}

			return acc;
		}, 0);
	}

	/**
	 * Returns the parent entity following the devices/gateway hierarchy.
	 * Returns `undefined` if this entity has no parent.
	 */

	public get parentDevice(): Entity | undefined {
		if (!this.data) {
			return undefined;
		}

		const parentId = this.data.relations.gateway || this.data.relations.device;

		if (!parentId) {
			return undefined;
		}

		return stores.entities.addAndGetEntity(parentId);
	}

	/**
	 * Returns the parent entity IDs following the devices/gateway hierarchy.
	 * The first element of the returned array is the direct parent, the last element is the top level ancestor
	 * Returns an empty array if this entity has no parent.
	 */

	public get parentDeviceIds() {
		const parents: string[] = [];

		for (let parent: Entity | undefined = this.parentDevice; parent !== undefined; parent = parent.parentDevice) {
			parents.push(parent.id);
		}

		return parents;
	}

	/**
	 * Returns the parent entity following the locations hierarchy.
	 * Returns `undefined` if this entity has no parent.
	 */

	public get parentLocation(): Entity | undefined {
		if (!this.data) {
			return undefined;
		}

		const parentId = this.data.relations.location;

		if (!parentId) {
			return undefined;
		}

		return stores.entities.addAndGetEntity(parentId);
	}

	/**
	 * Returns the parent entity IDs following the locations hierarchy.
	 * The first element of the returned array is the direct parent, the last element is the top level ancestor
	 * Returns an empty array if this entity has no parent.
	 */

	public get parentLocationIds() {
		const parents: string[] = [];

		for (let parent: Entity | undefined = this.parentLocation; parent !== undefined; parent = parent.parentLocation) {
			parents.push(parent.id);
		}

		return parents;
	}

	/**
	 * Returns the full parent locations hierarchy.
	 * The first item in the returned array is the top level location, the last item is the direct parent
	 */

	public get locationParents() {
		if (!this.data) {
			return undefined;
		}

		const parents: Entity[] = [];

		for (let parent: Entity | undefined = this.parentLocation; parent !== undefined; parent = parent.parentLocation) {
			parents.unshift(parent);
		}

		return parents;
	}

	/**
	 * Returns the parent entity following the equip hierarchy.
	 * Returns `undefined` if this entity has no parent.
	 */

	public get parentEquip(): Entity | undefined {
		if (!this.data) {
			return undefined;
		}

		const parentId = this.data.relations.equip || this.data.relations.entity;

		if (!parentId) {
			return undefined;
		}

		return stores.entities.addAndGetEntity(parentId);
	}

	/**
	 * Returns the parent entity IDs following the equip hierarchy.
	 * The first element of the returned array is the direct parent, the last element is the top level ancestor
	 * Returns an empty array if this entity has no parent.
	 */

	public get parentEquipIds() {
		const parents: string[] = [];

		for (let parent: Entity | undefined = this.parentEquip; parent !== undefined; parent = parent.parentEquip) {
			parents.push(parent.id);
		}

		return parents;
	}

	/**
	 * Returns the full parent device hierarchy.
	 * The first item in the returned array is the top level device, the last item is the direct parent
	 */

	public get deviceParents() {
		if (!this.data) {
			return undefined;
		}

		const parents: Entity[] = [];

		for (let parent: Entity | undefined = this.parentDevice; parent !== undefined; parent = parent.parentDevice) {
			parents.unshift(parent);
		}

		return parents;
	}

	/**
	 * Returns the display name of the entity.
	 * If the entity is deleted but not saved, returns the saved name.
	 * If the entity data has not been loaded yet, returns a placeholder.
	 */

	public get displayName() {
		if (!this.data) {
			if (this.savedData) {
				// If the entity is deleted but not saved
				return this.savedData.name;
			} else {
				// If the entity data has not been loaded yet from the server
				return 'Loading...';
			}
		}

		return this.data.name;
	}

	/**
	 * Returns whether the device is online.
	 * Returns `undefined` if the entity is not a device or if the device status is not defined
	 * A device is considered online if `lastTelemetryTime` is within the past 3 hours
	 */

	public get deviceOnline() {
		if (!this.deviceStatus) {
			return undefined;
		}

		return this.deviceStatus.isOnline;
	}

	/**
	 * Returns the time of the last telemetry formatted as human friendly relative time
	 */

	public get deviceStatusString() {
		if (!this.deviceStatus?.lastTelemetryTime) {
			return 'Never sent any data';
		}

		return `Last telemetry time: ${formatRelative(this.deviceStatus.lastTelemetryTime, new Date())}`;
	}

	/**
	 * Returns whether this entity is tagged as a point
	 * If the entity is deleted and not saved, response is based on saved data
	 * Return `undefined` if unknown (i.e. if the data is still loading)
	 */

	public get isPoint() {
		if (!this.data && !this.savedData) {
			return undefined;
		} else if (this.data && this.data.tags.includes('point')) {
			return true;
		} else if (this.savedData && this.savedData.tags.includes('point')) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Returns whether this entity is tagged as a device
	 * If the entity is deleted and not saved, response is based on saved data
	 * Return `undefined` if unknown (i.e. if the data is still loading)
	 */

	public get isDevice() {
		if (!this.data && !this.savedData) {
			return undefined;
		} else if (this.data && this.data.tags.includes('device')) {
			return true;
		} else if (this.savedData && this.savedData.tags.includes('device')) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Returns whether this entity is tagged as a gateway
	 * If the entity is deleted and not saved, response is based on saved data
	 * Return `undefined` if unknown (i.e. if the data is still loading)
	 */

	public get isGateway() {
		if (!this.data && !this.savedData) {
			return undefined;
		} else if (this.data && this.data.tags.includes('gateway')) {
			return true;
		} else if (this.savedData && this.savedData.tags.includes('gateway')) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Returns whether this entity is tagged as a client
	 * If the entity is deleted and not saved, response is based on saved data
	 * Return `undefined` if unknown (i.e. if the data is still loading)
	 */

	public get isClient() {
		if (!this.data && !this.savedData) {
			return undefined;
		} else if (this.data && this.data.tags.includes('client')) {
			return true;
		} else if (this.savedData && this.savedData.tags.includes('client')) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Returns whether this entity is tagged as a location
	 * If the entity is deleted and not saved, response is based on saved data
	 * Return `undefined` if unknown (i.e. if the data is still loading)
	 */

	public get isLocation() {
		if (!this.data && !this.savedData) {
			return undefined;
		} else if (this.data && this.data.tags.includes('location')) {
			return true;
		} else if (this.savedData && this.savedData.tags.includes('location')) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Returns whether this entity is tagged as an equip
	 * If the entity is deleted and not saved, response is based on saved data
	 * Return `undefined` if unknown (i.e. if the data is still loading)
	 */

	public get isEquip() {
		if (!this.data && !this.savedData) {
			return undefined;
		} else if (this.data && this.data.tags.includes('equip')) {
			return true;
		} else if (this.savedData && this.savedData.tags.includes('equip')) {
			return true;
		} else {
			return false;
		}
	}

	public get entityTemplate() {
		if (!this.data?.templates.entity) {
			return undefined;
		}

		return stores.entityTemplates.getTemplate(this.data.templates.entity);
	}

	public get deviceTemplate() {
		let templateId: string | undefined;

		if (this.data) {
			templateId = this.data?.templates.device;
		} else if (this.deleted && this.savedData) {
			templateId = this.savedData.templates.device;
		}

		if (!templateId) {
			return undefined;
		}

		return stores.deviceTemplates.getTemplate(templateId);
	}

	public get integrationTemplate() {
		if (!this.data?.templates.external_system) {
			return undefined;
		}

		return stores.externalSystemTemplates.getTemplate(this.data.templates.external_system);
	}

	public get deviceChannelTemplate() {
		if (!this.data?.templates.device_point) {
			return undefined;
		}

		return stores.devicePointTemplates.getTemplate(this.data.templates.device_point);
	}

	public get entityChannelTemplate() {
		if (!this.data?.templates.entity_point) {
			return undefined;
		}

		return stores.entityPointTemplates.getTemplate(this.data.templates.entity_point);
	}

	constructor(id: string) {
		makeAutoObservable<Entity, 'savedData'>(this, { savedData: observable });
		this.id = id;

		autorun(() => {
			// Add or remove from list of unsaved entities, when the entity is modified, saved or reverted
			if (this.unsaved) {
				stores.entities.unsavedList[this.id] = this;
			} else {
				delete stores.entities.unsavedList[this.id];
			}
		});

		autorun(() => {
			// Fetch data for related entities
			if (!this.data) {
				return;
			}

			for (const relationName of Object.keys(this.data.relations)) {
				const parentId = this.data.relations[relationName as RelationType];

				if (parentId) {
					const entity = stores.entities.addAndGetEntity(parentId);

					if (!entity.created && entity.dataRequest === Status.None) {
						entity.fetchData();
					}
				}
			}
		});
	}

	/**
	 * Safe method to access a device config property
	 * Returns `undefined` if the property does not exist or if the entity is not a device
	 * @param propName Config property name
	 */
	public getDeviceConfigProperty(propName: string) {
		return this.deviceConfigData?.properties[propName];
	}

	/**
	 * Safe method to access a device reported config property
	 * Returns `undefined` if the property does not exist or if the entity is not a device
	 * @param propName Config property name
	 */
	public getDeviceReportedConfigProperty(propName: string) {
		return this.deviceReportedConfigData?.properties[propName];
	}

	/**
	 * Safe method to access a metadata property on this entity
	 * Returns `undefined` if the property does not exist
	 * @param propName Property name
	 */
	public getProperty(propName: string) {
		return this.data?.properties?.[propName];
	}

	/**
	 * Safe method to access a property for an external system mapping
	 * @param templateId Template ID for the external system
	 * @param propName Property name
	 */
	public getExternalMapping(templateId: string, propName: string) {
		return this.data?.externalMappings?.[templateId]?.[propName];
	}

	/**
	 * Updates a property for an external system mapping.
	 * Updated data is not sent to the server until the `save` method is called.
	 * @param templateId Template ID for the external system
	 * @param propName Property name
	 * @param value Property value
	 */

	public setExternalMapping(templateId: string, propName: string, value: string | number | boolean) {
		if (!this.data || !this.data.externalMappings[templateId]) {
			return;
		}

		this.data.externalMappings[templateId][propName] = value;
	}

	/**
	 * Update the saved data for an entity based on data received from server.
	 * Related entities are also updated.
	 * @param entityData Entity data
	 * @param updatedTime Time the entity was last updated
	 * @param override Set to `true` to override unsaved data already present. Defaults to `false`
	 */

	public setSavedData(entityData: IEntityData, updatedTime: Date, override: boolean = false) {
		this.savedData = entityData;
		this.updatedTime = updatedTime;

		if (override || !this.data) {
			this.setData(entityData, false);
		}

		this.dataRequest = Status.Done;
	}

	public clearSavedData() {
		this.savedData = undefined;
		this.updatedTime = undefined;
	}

	/**
	 * Update the working data for an entity
	 * Related entities are also updated and templates applied (setting any default property values)
	 * Called when a new entity is created in UI and when data is imported in bulk.
	 * Updated data is not sent to the server until the `save` method is called.
	 * @param entity Entity data
	 * @param applyTemplates Whether the templates should be applied (i.e. set default values and create default points as defined in templates)
	 */

	public setData(
		{ name, properties, tags, externalMappings, relations, templates, lifecycleStatus }: IEntityData,
		applyTemplates: boolean,
	) {
		const entitiesStore = stores.entities;
		const existingRelations = this.data ? this.data.relations : {};

		// Update everything except relations, and device properties
		if (!this.data) {
			this.data = {
				name,
				properties,
				tags,
				externalMappings,
				relations: {},
				templates: {},
				lifecycleStatus,
			};
		} else {
			this.data.name = name;
			this.data.properties = properties;
			this.data.tags = tags;
			this.data.externalMappings = externalMappings;
		}

		// Remove entity from existing related objects, if the relation has been removed
		for (const existingRelation of Object.keys(existingRelations)) {
			const typedExistingRelation = existingRelation as RelationType;

			if (!relations[typedExistingRelation]) {
				this.removeRelation(typedExistingRelation);
			}
		}

		// Add entity to related objects
		for (const relation of Object.keys(relations)) {
			const typedRelation = relation as RelationType;
			const relatedEntityId = relations[typedRelation] as string;
			this.setRelation(typedRelation, relatedEntityId);
		}

		if (applyTemplates) {
			// Apply templates. Needs to be after relations are updated as some templates require the parent entity
			if (templates.device) {
				this.setDeviceTemplate(templates.device);
			}

			if (templates.entity) {
				this.setEntityTemplate(templates.entity);
			}

			if (templates.device_point) {
				this.data.templates.device_point = templates.device_point;
			}

			if (templates.entity_point) {
				this.data.templates.entity_point = templates.entity_point;
			}

			if (templates.external_system) {
				this.data.templates.external_system = templates.external_system;
			}
		} else {
			// Set templates but don't apply them
			this.data.templates = templates;
		}

		entitiesStore.onEntityChanged(this);
	}

	public clearData() {
		const existingRelations = this.data ? this.data.relations : {};

		// Remove entity from existing related objects, if the relation has been removed
		for (const existingRelation of Object.keys(existingRelations)) {
			const typedExistingRelation = existingRelation as RelationType;

			this.removeRelation(typedExistingRelation);
		}

		this.data = undefined;
		stores.entities.onEntityChanged(this);
	}

	/**
	 * Update the saved device data for an entity based on data received from server.
	 * @param deviceData device twin data
	 * @param override Set to `true` to override unsaved data already present. Defaults to `false`
	 */

	public setSavedDeviceConfigData(deviceData: IDeviceConfigData, override: boolean = false) {
		this.savedDeviceConfigData = deviceData;

		if (override || !this.deviceConfigData) {
			this.deviceConfigData = deviceData;
		}
	}

	/**
	 * Update the device status for an entity based on data received from server.
	 * @param deviceData Device status data
	 */

	public setDeviceStatus(deviceData: IDeviceStatus) {
		this.deviceStatus = deviceData;

		this.deviceStatusRequest = Status.Done;
	}

	/**
	 * Queue a request to fetch entities children for the specified relation type
	 * @param relationType Name of the relation (location, gateway, etc.)
	 */

	public fetchChildren(relationType: RelationType) {
		this.childrenRequest[relationType] = Status.Loading;
		stores.entities.childrenToFetch[relationType].push(this.id);
	}

	/**
	 * Fetch entities children for the specified relation type
	 * @param relationType Name of the relation (location, gateway, etc.)
	 */

	public async fetchChildrenAsync(relationType: RelationType) {
		this.childrenRequest[relationType] = Status.Loading;
		await stores.entities.fetchMultipleEntitiesChildren(relationType, [this.id]);
	}

	/**
	 * Fetch descendants for the specified relation type
	 * @param relationType Name of the relation (e.g., "location", "gateway")
	 */

	public fetchDescendantsAsync(relationType: 'location' | 'gateway' | 'equip') {
		return stores.entities.fetchEntitiesDescendants(relationType, this.id);
	}

	/**
	 * Revert any unsaved changes on this entity
	 * Related entities are also updated.
	 */

	public discardChanges() {
		const updatedRelations = this.data ? this.data.relations : {};

		if (this.savedData) {
			const originalRelations = this.savedData.relations;

			// update children of new / old parent entities if necessary
			for (const relation of Object.keys(originalRelations)) {
				if (originalRelations[relation] !== updatedRelations[relation]) {
					const originalEntity = stores.entities.getEntity(originalRelations[relation] as string);

					if (originalEntity) {
						originalEntity.addChild(this, relation as RelationType);
					}

					if (updatedRelations[relation]) {
						const updatedEntity = stores.entities.getEntity(updatedRelations[relation] as string);

						if (updatedEntity) {
							updatedEntity.removeChild(this, relation as RelationType);
						}
					}
				}
			}

			for (const relation of Object.keys(updatedRelations)) {
				if (!originalRelations[relation]) {
					const typedRelation = relation as RelationType;
					const parentId = updatedRelations[typedRelation];

					if (parentId) {
						const updatedEntity = stores.entities.addAndGetEntity(parentId);

						if (updatedEntity) {
							updatedEntity.removeChild(this, relation as RelationType);
						}
					}
				}
			}

			// Revert entity
			this.data = cloneDeep(this.savedData);

			// Revert child points
			if (this.children.device) {
				this.children.device.forEach((point) => point.discardChanges());
			}

			if (this.children.entity) {
				this.children.entity.forEach((point) => point.discardChanges());
			}

			// Revert device twin
			if (this.savedDeviceConfigData) {
				this.deviceConfigData = cloneDeep(this.savedDeviceConfigData);
			}

			this.deleted = false;
			delete stores.entities.unsavedList[this.id];
		} else {
			// Remove this entity from its parents
			for (const relation of Object.keys(updatedRelations)) {
				const typedRelation = relation as RelationType;
				const parentId = updatedRelations[typedRelation];

				if (parentId) {
					const parent = stores.entities.addAndGetEntity(parentId);

					if (parent) {
						parent.removeChild(this, typedRelation);
					}
				}
			}

			stores.entities.deleteEntity(this);
		}
	}

	/**
	 * Flag this entity for deletion.
	 * It will only be deleted on the server when the `save` method is called.
	 */

	public async delete(includeChildren: boolean = false) {
		if (!this.data || !this.savedData) {
			return;
		}

		this.deleted = true;

		// Delete children points
		if (this.children.device) {
			this.children.device.forEach((point) => point.delete());
		}

		if (this.children.entity) {
			this.children.entity.forEach((point) => point.delete());
		}

		if (includeChildren) {
			const relationsList: RelationType[] = ['client', 'location', 'equip', 'gateway'];

			for (const relation of relationsList) {
				if (this.childrenRequest[relation] !== Status.Done) {
					await this.fetchChildrenAsync(relation);
				}

				const children = this.children[relation];

				if (children) {
					children.forEach((entity) => entity.delete(true));
				}
			}
		}
	}

	/**
	 * Commit any unsaved change to the server (i.e. if the entity was either modified, created or deleted)
	 */

	public async save() {
		this.saveMetadata();
		this.saveDevice();
	}

	private async saveMetadata() {
		if (this.metadataUnsaved) {
			this.saveRequest = Status.Loading;

			try {
				if (this.created && this.data && this.canSave) {
					const { entityData, updatedTime } = await EntitiesApi.createEntity(this.id, this.data);
					this.setSavedData(entityData, updatedTime, true);
				} else if (this.deleted) {
					await EntitiesApi.deleteEntity(this.id);

					// Remove this entity from its parents
					for (const relation of Object.keys(this.savedData!.relations)) {
						const typedRelation = relation as RelationType;
						const parentId = this.savedData!.relations[typedRelation];

						if (parentId) {
							const parent = stores.entities.addAndGetEntity(parentId);

							if (parent) {
								parent.removeChild(this, typedRelation);
							}
						}
					}

					stores.entities.deleteEntity(this);
					this.dataRequest = Status.Done;
				} else if (this.modified && this.data && this.canSave) {
					const { entityData, updatedTime } = await EntitiesApi.updateEntity(this.id, this.data);
					this.setSavedData(entityData, updatedTime, true);
				}

				this.saveRequest = Status.Done;
			} catch (error: any) {
				this.saveRequest = Status.Error;
				throw error;
			}
		}
	}

	public async saveDevice() {
		if (this.deviceUnsaved) {
			this.deviceSaveRequest = Status.Loading;

			try {
				if (this.deviceCreated && this.deviceConfigData) {
					const deviceData = await DevicesApi.createDevice(this.id, this.deviceConfigData);
					this.setSavedDeviceConfigData(deviceData, true);
				} else if (this.deviceDeleted) {
					await DevicesApi.deleteDevice(this.id);
					this.savedDeviceConfigData = undefined;
					this.deviceConfigData = undefined;
					this.deviceDataRequest = Status.Done;
				} else if (this.deviceModified && this.deviceConfigData) {
					const deviceData = await DevicesApi.updateDeviceConfig(this.id, this.deviceConfigData);
					this.setSavedDeviceConfigData(deviceData, true);
				}

				this.deviceSaveRequest = Status.Done;
			} catch (error: any) {
				this.deviceSaveRequest = Status.Error;
				throw error;
			}
		}
	}

	/**
	 * Queue a request to fetch data for this entity from the server
	 */

	public async fetchData() {
		this.dataRequest = Status.Loading;
		stores.entities.entitiesToFetch.push(this.id);
	}

	/**
	 * Request device config from server
	 */

	public async fetchDeviceConfigData(force: boolean = false) {
		if (this.created) {
			return;
		}

		if (force || this.deviceDataRequest === Status.None) {
			this.deviceDataRequest = Status.Loading;

			try {
				this.setSavedDeviceConfigData(await DevicesApi.fetchDeviceConfigById(this.id));

				this.deviceDataRequest = Status.Done;
			} catch (error: any) {
				this.deviceDataRequest = Status.Error;
				throw error;
			}
		}
	}

	/**
	 * Request device reported config from server
	 */

	public async fetchDeviceReportedConfigData() {
		if (this.created) {
			return;
		}

		if (this.deviceReportedConfigRequest === Status.None) {
			this.deviceReportedConfigRequest = Status.Loading;

			try {
				this.deviceReportedConfigData = await DevicesApi.fetchDeviceReportedConfigById(this.id);
				this.deviceReportedConfigRequest = Status.Done;
			} catch (error: any) {
				this.deviceReportedConfigRequest = Status.Error;
				throw error;
			}
		}
	}

	/**
	 * Queue a request to fetch device status from server
	 */

	public async fetchDeviceStatus(force: boolean = false) {
		if (this.created) {
			return;
		}

		if (force || this.deviceStatusRequest === Status.None) {
			this.deviceStatusRequest = Status.Loading;
			stores.entities.devicesStatusToFetch.push(this.id);
		}
	}

	/**
	 * Add a child entity. This method is called when a related entity is modified
	 * To update a parent entity, use `setRelation` method instead
	 * @param entity Entity to add as a child
	 * @param relationType Type of relation
	 */

	private addChild(entity: Entity, relationType: RelationType) {
		if (!this.children[relationType]) {
			this.children[relationType] = [];
		}

		const children = this.children[relationType]!;

		if (!children.find((e) => e.id === entity.id)) {
			children.push(entity);

			this.children[relationType] = children.slice().sort((a, b) => {
				if (!a.data) {
					return 1;
				}

				if (!b.data) {
					return -1;
				}

				if (a.data.name < b.data.name) {
					return -1;
				}
				if (a.data.name > b.data.name) {
					return 1;
				}

				return 0;
			});
		}

		if (relationType === 'location' || relationType === 'gateway') {
			const descendants = this.descendants[relationType];

			if (descendants) {
				if (!descendants.find((e) => e.id === entity.id)) {
					descendants.push(entity);
				}
			}
		}
	}

	/**
	 * Remove a child entity. This method is called when a related entity is modified
	 * To remove a parent entity, use `removeRelation` method instead
	 * @param entity Entity to remove as a child
	 * @param relationType Type of relation
	 */

	private removeChild(entity: Entity, relationType: RelationType) {
		if (!this.children[relationType]) {
			return;
		}

		if (this.children[relationType]!.find((e) => e.id === entity.id)) {
			this.children[relationType] = this.children[relationType]!.filter((e) => e.id !== entity.id);
		}
	}

	/**
	 * Helper method to add a related/parent entity
	 * This entity will also be updated as a child to the parent entity
	 * Updated data is not sent to the server until the `save` method is called.
	 * @param relation Type of relation
	 * @param relatedEntityId ID of the related entity. Set to `undefined` to remove the relation
	 */

	public async setRelation(relation: RelationType, relatedEntityId: string | undefined) {
		if (!this.data) {
			return;
		}

		const existingRelatedEntityId = this.data.relations[relation];

		if (existingRelatedEntityId === relatedEntityId) {
			// No change, do nothing
			return;
		}

		if (existingRelatedEntityId) {
			// Remove entity from existing parent
			const existingRelatedEntity = stores.entities.getEntity(existingRelatedEntityId);

			if (existingRelatedEntity) {
				existingRelatedEntity.removeChild(this, relation);
			}
		}

		if (relatedEntityId) {
			// Add entity to new parent
			const newRelatedEntity = stores.entities.addAndGetEntity(relatedEntityId);
			newRelatedEntity.addChild(this, relation);
			// Update relation on entity
			this.data.relations[relation] = relatedEntityId;
		} else {
			// Remove relation from entity
			delete this.data.relations[relation];
		}

		if (this.data.tags.includes('device') && relation === 'gateway') {
			// Set parentDeviceId on device twin
			if (!this.deviceConfigData) {
				await this.fetchDeviceConfigData();
			}

			if (relatedEntityId) {
				this.deviceConfigData!.tags.parentDeviceId = relatedEntityId;
			} else {
				// Remove relation from entity
				delete this.deviceConfigData!.tags.parentDeviceId;
			}
		}
	}

	/**
	 * Helper method to remove a related/parent entity if it exists
	 * This entity will also be removed as a child from the parent entity
	 * Updated data is not sent to the server until the `save` method is called.
	 * @param relation Type of relation
	 */

	public removeRelation(relation: RelationType) {
		this.setRelation(relation, undefined);
	}

	/**
	 * Helper method to update a device config property
	 * Updated data is not sent to the server until the `save` method is called.
	 * @param propertyName Device twin property name
	 * @param value Property value
	 */

	public setDeviceConfigProperty(propertyName: string, value: string | boolean | number | null) {
		if (!this.data) {
			return;
		}

		if (!this.deviceConfigData) {
			this.deviceConfigData = { tags: {}, properties: {}, channels: [] };
		}

		if (value !== null) {
			this.deviceConfigData.properties[propertyName] = value;
		} else if (this.deviceConfigData.properties[propertyName]) {
			delete this.deviceConfigData.properties[propertyName];
		}
	}

	/**
	 * Apply a device template to this entity.
	 * This does the following:
	 *  - Set any default value as defined in the template
	 *  - Create or update associated device points as defined in the template
	 * Updated data is not sent to the server until the `save` method is called.
	 * @param templateId ID of the device template
	 */

	public setDeviceTemplate(templateId: string) {
		if (!this.data) {
			return;
		}

		const template = (templateId !== '' && stores.deviceTemplates.getTemplate(templateId)) || undefined;

		if (!template) {
			this.deviceConfigData = undefined;
			this.data.templates.device = undefined;
		} else {
			this.data.templates.device = templateId;

			if (template.data?.template.properties) {
				for (const prop of template.data.template.properties) {
					if (!this.data.properties[prop.name]) {
						if (prop.default !== undefined) {
							this.data.properties[prop.name] = prop.default;
						} else if (prop.select && prop.select[0]) {
							this.data.properties[prop.name] = prop.select[0].value;
						} else if (prop.type === 'boolean') {
							this.data.properties[prop.name] = false;
						}
					}
				}
			}

			if (template.data?.template.tags) {
				for (const tag of template.data.template.tags) {
					if (!this.data.tags.includes(tag)) {
						this.data.tags.push(tag);
					}
				}
			}

			if (!template.data?.template.device_twin) {
				this.deviceConfigData = undefined;
			} else {
				if (!this.deviceConfigData) {
					this.deviceConfigData = { tags: {}, properties: {}, channels: [] };
				}

				const {
					tags: templateTags,
					properties: templateProperties,
					channels: templateChannels,
				} = template.data.template.device_twin;

				// Add template tags to existing tags
				this.deviceConfigData!.tags = {
					...this.deviceConfigData.tags,
					...templateTags,
					template: template.displayName,
				};

				// Merge template properties with existing properties. Use existing properties as default, otherwise use ones from template
				const mappedProperties = templateProperties.reduce(
					(
						acc: {
							[key: string]: string | number | boolean;
						},
						prop: IDeviceTwinPropertyDefinition,
					) => {
						let desired: string | number | boolean | undefined;

						if (prop.default !== undefined) {
							desired = prop.default;
						} else if (prop.select?.[0] !== undefined) {
							desired = prop.select[0].value;
						} else if (prop.type === 'boolean') {
							desired = false;
						}

						const parentDeviceId = this.data!.relations.gateway;

						if (parentDeviceId && prop.inheritedDefault) {
							const parent = stores.entities.getEntity(parentDeviceId);

							if (parent) {
								const [type, propName] = prop.inheritedDefault.split('.');

								if (type === 'deviceProperties') {
									const inheritedProp = parent.getDeviceConfigProperty(propName);

									if (inheritedProp !== undefined) {
										desired = inheritedProp;
									}
								} else if (type === 'properties') {
									const inheritedProp = parent.getProperty(propName);

									if (inheritedProp !== undefined) {
										desired = inheritedProp;
									}
								}
							}
						}

						if (desired !== undefined) {
							acc[prop.name] = desired;
						}

						return acc;
					},
					{},
				);

				this.deviceConfigData!.properties = { ...mappedProperties, ...this.deviceConfigData.properties };

				// Add default channels from template
				if (templateChannels) {
					const existingChannels = this.deviceConfigData!.channels;

					const channelsToAdd = templateChannels.filter((defaultChannel) => {
						return !existingChannels.find((c) => c.metric_name === defaultChannel.metric_name);
					}) as {
						[property: string]: string | number | boolean;
					}[];

					for (const channelToAdd of channelsToAdd) {
						this.deviceConfigData!.channels.push(channelToAdd);
					}
				}
			}
		}

		runInAction(() => {
			// Update device points for this device using default points defined in the device template
			const existingDevicePoints = this.children.device || [];
			const defaultDevicePoints = template?.data?.template.default_device_points || [];
			const devicePointTemplateId = template?.data?.template.device_points_template;

			// Points to add are point in the new device template with no matching point
			const devicePointsToAdd = defaultDevicePoints.filter((defaultPoint) => {
				return !existingDevicePoints.find((p) => Boolean(p.data && p.data.name === defaultPoint.name));
			});

			for (const devicePoint of devicePointsToAdd) {
				// Create device point
				const id = uuid();
				const { name, tags } = devicePoint;
				const properties = { ...devicePoint.properties };

				const channels = template?.data?.template.device_twin?.channels;

				if (channels) {
					const channel = channels.find((c) => c.metric_name === properties.metric_name);

					if (channel && typeof channel.datatype == 'string') {
						properties.datatype = channel.datatype;
					}
				}

				const entityData: IEntityData = {
					name,
					properties,
					tags: ['point', ...tags],
					relations: {
						device: this.id,
					},
					externalMappings: {},
					templates: {
						device_point: devicePointTemplateId,
					},
					lifecycleStatus: 'Configuration',
				};

				stores.entities.addEntitiesFromUi([{ id, entityData }]);
			}

			// Create binding to related entity if defined
			for (const devicePointTemplate of defaultDevicePoints) {
				if (devicePointTemplate.default_binding) {
					const defaultBinding = devicePointTemplate.default_binding;
					const relatedEntityId = this.data!.relations[defaultBinding.entity_relation];

					if (relatedEntityId) {
						const relatedEntity = stores.entities.getEntity(relatedEntityId);

						if (
							relatedEntity &&
							relatedEntity.data &&
							relatedEntity.data.templates.entity === defaultBinding.entity_template
						) {
							const childrenEntityPoints = relatedEntity.children.entity;

							if (
								!childrenEntityPoints ||
								!childrenEntityPoints.find(
									(c) => c.data!.templates.entity_point === defaultBinding.entity_point_template,
								)
							) {
								const entityPointTemplate = stores.entityPointTemplates.getTemplate(
									defaultBinding.entity_point_template,
								);

								if (entityPointTemplate?.data) {
									// Create entity point
									const entityData: IEntityData = {
										name: entityPointTemplate.data.name,
										properties: {},
										tags: [...entityPointTemplate.data.template.tags],
										relations: {
											entity: relatedEntityId,
										},
										externalMappings: {},
										templates: {
											entity_point: defaultBinding.entity_point_template,
										},
										lifecycleStatus: 'Configuration',
									};
									const id = uuid();

									stores.entities.addEntitiesFromUi([{ id, entityData }]);
								}
							}
						}
					}
				}
			}
		});
	}

	/**
	 * Apply a template to this entity.
	 * This does the following:
	 *  - Set any tag as defined in the template
	 *  - Set any default value as defined in the template
	 *  - Create or update associated entity points as defined in the template
	 * Updated data is not sent to the server until the `save` method is called.
	 * @param templateId ID of the entity template
	 */

	public setEntityTemplate(templateId: string) {
		if (!this.data) {
			return;
		}

		const template = (templateId !== '' && stores.entityTemplates.getTemplate(templateId)) || undefined;

		if (!template) {
			this.data.templates.entity = undefined;
		} else {
			this.data.templates.entity = templateId;

			if (template.data?.template.properties) {
				for (const prop of template.data.template.properties) {
					if (!this.data.properties[prop.name]) {
						if (prop.default !== undefined) {
							this.data.properties[prop.name] = prop.default;
						} else if (prop.select && prop.select[0]) {
							this.data.properties[prop.name] = prop.select[0].value;
						} else if (prop.type === 'boolean') {
							this.data.properties[prop.name] = false;
						}
					}
				}
			}

			if (template.data?.template.tags) {
				for (const tag of template.data.template.tags) {
					if (!this.data.tags.includes(tag)) {
						this.data.tags.push(tag);
					}
				}
			}

			const existingEntityPoints = this.children.entity || [];
			const newEntityPointsTemplateIds = (template && template.data?.template.points_templates) || [];

			// Unlink points with entity_point template that are not in new entity template
			const pointsToUnlink = existingEntityPoints.filter((p) => {
				if (!p.data) {
					return false;
				}

				return p.data.templates.entity_point && !newEntityPointsTemplateIds.includes(p.data.templates.entity_point);
			});

			for (const point of pointsToUnlink) {
				this.removeChild(point, 'entity');

				if (point.data) {
					delete point.data.relations.entity;
					delete point.data.templates.entity_point;
				}
			}

			if (template.data?.template.children) {
				// Create children entities as defined in template
				for (const childDefinition of template.data.template.children) {
					if (childDefinition.default) {
						const childId = uuid();
						const { name, tags, relation_type, entity_template } = childDefinition;
						const childEntityData: IEntityData = {
							name,
							properties: {},
							tags: [...tags],
							relations: {
								client: this.data.relations.client,
								location: this.data.relations.location,
								[relation_type]: this.id,
							},
							externalMappings: {},
							templates: {},
							lifecycleStatus: 'Configuration',
						};

						stores.entities.addEntitiesFromUi([{ id: childId, entityData: childEntityData }]);
						const childEntity = stores.entities.getEntity(childId);

						if (childEntity) {
							childEntity.setEntityTemplate(entity_template);
						}
					}
				}
			}
		}
	}

	/**
	 * Apply a device channel template to this entity.
	 * This does the following:
	 *  - Set any default property value as defined in the template
	 * Updated data is not sent to the server until the `save` method is called.
	 * @param templateId ID of the device point template
	 */

	public setDevicePointTemplate(templateId: string) {
		if (!this.data || !this.parentDevice || !this.parentDevice.deviceConfigData) {
			return;
		}

		const template = stores.devicePointTemplates.getTemplate(templateId);

		if (!template) {
			this.data.templates.device_point = undefined;
		} else {
			this.data.templates.device_point = templateId;

			const metricName = this.getProperty('metric_name') as string | undefined;

			if (template.data?.template.properties) {
				for (const prop of template.data.template.properties) {
					if (prop.include_in_device_twin) {
						if (metricName) {
							if (prop.default !== undefined) {
								this.parentDevice.setChannelDeviceConfigProperty(metricName, prop.name, prop.default);
							} else if (prop.select && prop.select[0]) {
								this.parentDevice.setChannelDeviceConfigProperty(metricName, prop.name, prop.select[0].value);
							} else if (prop.type === 'boolean') {
								this.parentDevice.setChannelDeviceConfigProperty(metricName, prop.name, false);
							}
						}
					} else {
						if (!this.data.properties[prop.name]) {
							if (prop.default !== undefined) {
								this.data.properties[prop.name] = prop.default;
							} else if (prop.select && prop.select[0]) {
								this.data.properties[prop.name] = prop.select[0].value;
							} else if (prop.type === 'boolean') {
								this.data.properties[prop.name] = false;
							}
						}
					}
				}
			}
		}
	}

	/**
	 * Apply a point template to this entity.
	 * This does the following:
	 *  - Set any tag as defined in the template
	 *  - Set the default units as defined in the template
	 * Updated data is not sent to the server until the `save` method is called.
	 * @param templateId ID of the entity point template
	 */

	public setEntityPointTemplate(templateId: string) {
		if (!this.data) {
			return;
		}

		const template = (templateId !== '' && stores.entityPointTemplates.getTemplate(templateId)) || undefined;

		if (!template) {
			this.data.templates.entity_point = undefined;
		} else {
			this.data.templates.entity_point = templateId;

			if (template.data?.template.datatype && !this.data.properties.datatype) {
				this.data.properties.datatype = template.data?.template.datatype;
			}

			if (template.data?.template.units_type && !this.data.properties.units_type) {
				this.data.properties.units_type = template.data?.template.units_type;
			}

			if (template.data?.template.default_units && !this.data.properties.units) {
				this.data.properties.units = template.data?.template.default_units;
			}

			if (template.data?.template.tags) {
				for (const tag of template.data.template.tags) {
					if (!this.data.tags.includes(tag)) {
						this.data.tags.push(tag);
					}
				}
			}
		}
	}

	public setChannelDeviceConfigProperty(
		metricName: string,
		propertyName: string,
		value: string | number | boolean | null,
	) {
		if (!this.deviceConfigData) {
			return;
		}

		const channelDesiredProperties = this.deviceConfigData.channels.find((c) => c.metric_name === metricName);

		if (channelDesiredProperties) {
			if (value === null) {
				delete channelDesiredProperties[propertyName];
			} else {
				channelDesiredProperties[propertyName] = value;
			}
		} else {
			if (value !== null) {
				this.deviceConfigData.channels.push({
					metric_name: metricName,
					[propertyName]: value,
				});
			}
		}
	}

	public removeChannelDeviceTwin(metricName: string) {
		if (!this.deviceConfigData) {
			return;
		}

		this.deviceConfigData.channels = this.deviceConfigData.channels.filter((c) => c.metric_name !== metricName);
	}

	/**
	 * Query telemetry data for points associated to this entity
	 * @param startDate Start of date range
	 * @param endDate End of date range
	 */
	public fetchTelemetry(startDate: Date, endDate: Date) {
		return TelemetryApi.fetchTelemetry(this.id, startDate, endDate);
	}

	public clone(newEntityName: string) {
		if (!this.data) {
			return;
		}

		const entityData: IEntityData = cloneDeep(this.data);
		entityData.name = newEntityName;
		const id = uuid();

		stores.entities.addEntitiesFromUi([{ id, entityData }]);

		return stores.entities.getEntity(id);
	}
}
