import { IUnitsDictionary } from '@mitie/metadata-api-types';

import { fetchUnits } from 'api/dictionaries';

class UnitsDictionary {
	private units?: IUnitsDictionary;
	private fetchingPromise?: Promise<IUnitsDictionary>;

	public async getList() {
		if (this.units) {
			return this.units;
		} else if (this.fetchingPromise) {
			return this.fetchingPromise;
		} else {
			this.fetchingPromise = fetchUnits();
			this.units = await this.fetchingPromise;

			return this.units!;
		}
	}
}

export default UnitsDictionary;
