import { useContext, useEffect, useState } from 'react';
import { Alert, Button, Link } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { AlertsContext, IAlert } from 'store/AlertsProvider';
import * as AuthApi from 'api/auth';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		'@global': {
			'@keyframes progress': {
				from: { width: '100%' },
				to: { width: '0' },
			},
		},
		messageContainer: {
			width: '100%',
			paddingBottom: 0,
		},
		message: {
			paddingBottom: '5px',
		},
		progressBar: {
			backgroundColor: theme.palette.grey[400],
			height: '3px',
		},
		alert: {
			cursor: 'pointer',
			opacity: '75%',
			'&>div:last-child': {
				visibility: 'hidden',
			},
			'&:hover': {
				opacity: '100%',
			},
			'&:hover>div:last-child': {
				visibility: 'visible',
			},
		},
	}),
);

export default function AlertsBanner() {
	const classes = useStyles();
	const { alerts, removeNextAlert } = useContext(AlertsContext);
	const [currentAlert, setCurrentAlert] = useState<IAlert>();
	const [restartAnimation, setRestartAnimation] = useState(false);
	const [timer, setTimer] = useState<NodeJS.Timeout>();

	useEffect(() => {
		const first = alerts[0];

		if (first) {
			if (!currentAlert || first.id !== currentAlert.id) {
				setCurrentAlert(first);

				setRestartAnimation(true);

				if (first.timeout) {
					setTimer(
						setTimeout(() => {
							removeNextAlert();
						}, first.timeout),
					);
				}
			}
		} else {
			setCurrentAlert(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alerts]);

	useEffect(() => {
		if (restartAnimation) {
			setRestartAnimation(false);
		}
	}, [restartAnimation]);

	if (!currentAlert) {
		return null;
	}

	return (
		<Alert
			severity={currentAlert.type}
			onClick={() => {
				if (timer) {
					clearTimeout(timer);
				}
				removeNextAlert();
			}}
			action={<Button size="small">Dismiss</Button>}
			classes={{ message: classes.messageContainer }}
			className={classes.alert}
		>
			{currentAlert.code === 401 ? (
				<div className={classes.message}>
					Your session has expired, click <Link onClick={() => AuthApi.logout()}>here</Link> to log back in
				</div>
			) : (
				<div className={classes.message}>{currentAlert.message}</div>
			)}
			{!restartAnimation && currentAlert.timeout && (
				<div className={classes.progressBar} style={{ animation: `progress ${currentAlert.timeout}ms linear` }}></div>
			)}
		</Alert>
	);
}
