import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Badge, Box, CircularProgress, Tooltip, Typography } from '@mui/material';
import { OfflineBoltOutlined, CheckCircleOutlined, ExpandMore, ChevronRight, Build } from '@mui/icons-material';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import { DragObject } from 'DataTypes';
import { EntityLifecycleStatus } from '@mitie/metadata-api-types';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'block',
		},
		node: {
			boxSizing: 'border-box',
			display: 'flex',
			padding: '4px',
		},
		children: {
			listStyleType: 'none',
			margin: 0,
			padding: 0,
			marginLeft: '24px',
		},
		expansionIcon: {
			marginRight: '4px',
			cursor: 'pointer',
			height: '20px',
			'&:hover': {
				borderRadius: '50%',
				backgroundColor: theme.palette.grey[300],
			},
			'&>svg': {
				fontSize: '20px',
			},
		},
		notExpansionIcon: {
			marginRight: '4px',
			width: '20px',
			height: '20px',
		},
		labelContainer: {
			display: 'flex',
			flex: '1',
			width: 'calc(100% - 24px)',
		},
		icon: {
			height: '20px',
			width: '20px',
			marginRight: theme.spacing(1),
			'&>svg': {
				fontSize: '20px',
			},
		},
		label: {
			display: 'inline',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			flex: 1,
			lineHeight: 1.25,
		},
		loadingIcon: {
			height: 'fit-content !important',
		},
		statusIcon: {
			marginLeft: theme.spacing(1),
		},
		okColor: {
			color: '#4caf50',
		},
		badge: {
			marginRight: '0.5rem',
			marginLeft: '0.25rem',
			'&>span': {
				top: '10px',
			},
		},
		unsavedBadge: {
			margin: `0 ${theme.spacing(1)}`,
			'&>span': {
				top: '10px',
			},
		},
		unsaved: {
			'&>div': {
				color: theme.palette.secondary.dark,
			},
		},
		deleted: {
			textDecoration: 'line-through',
		},
		selected: {
			backgroundColor: theme.palette.grey[200],
		},
		selectable: {
			cursor: 'pointer',
		},
		disabled: {
			'&>div': {
				color: `${theme.palette.grey[400]}`,
			},
		},
		draggable: {
			cursor: 'grab',
		},
		dragging: {
			opacity: 0.5,
		},
		draggingOver: {
			backgroundImage: `repeating-linear-gradient(-45deg, #fff, #fff 10px, #dfd 10px, #afa 15px, #dfd 20px)`,
			backgroundSize: '200% 100%',
			animation: '$dropbg 400ms linear infinite',
			borderRadius: '8px',
			padding: '2px',
			border: `2px dashed ${theme.palette.success.main}`,
			'&>div>div': {
				color: theme.palette.success.main,
				fontWeight: 'bold',
			},
		},
		draggingOverBanned: {
			backgroundImage: `linear-gradient(to top left,transparent 0%,transparent calc(50% - 4px),#fdd 50%,transparent calc(50% + 4px),transparent 100%),
				linear-gradient(to top right,transparent 0%,transparent calc(50% - 4px),#fdd 50%,transparent calc(50% + 4px),transparent 100%)`,
			backgroundSize: '29px 22px',
			borderRadius: '8px',
			padding: '2px',
			border: `2px dashed ${theme.palette.error.main}`,
			animation: '$dropbg 400ms linear infinite',
			'&>div>div': {
				color: theme.palette.error.main,
				fontWeight: 'bold',
			},
		},
		'@keyframes dropbg': {
			'100%': {
				backgroundPosition: '-29px 0',
			},
		},
	}),
);

interface ICustomTreeItemProps {
	nodeId: string;
	label: string;
	icon?: React.ReactNode;
	selectable: boolean;
	selected?: boolean;
	onSelect?: () => void;
	expanded?: boolean;
	onExpand?: () => void;
	onCollapse?: () => void;
	badgeNumber?: number;
	online?: boolean;
	loading?: boolean;
	unsaved?: boolean;
	deleted?: boolean;
	disabled?: boolean;
	children?: React.ReactNode[];
	lifecycleStatus?: EntityLifecycleStatus;
	isLeaf?: boolean;
	onDrop?: (itemId: string) => void;
	canDrag?: boolean;
	datatype?: 'number' | 'boolean';
}

export default function EntityTreeItem({
	nodeId,
	children,
	label,
	icon,
	selectable,
	selected,
	onSelect,
	expanded,
	onExpand,
	onCollapse,
	online,
	loading,
	unsaved,
	deleted,
	disabled,
	badgeNumber,
	isLeaf,
	onDrop,
	canDrag,
	datatype,
	lifecycleStatus,
}: ICustomTreeItemProps) {
	const classes = useStyles();
	const droppable = onDrop !== undefined;

	const [{ isDragging }, drag] = useDrag<DragObject, void, { isDragging: boolean }>({
		type: 'point',
		item: { id: nodeId, datatype },
		canDrag: !!canDrag,
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
	});

	const [{ isOver, canDrop, draggedItem }, drop] = useDrop<
		DragObject,
		void,
		{ isOver: boolean; canDrop: boolean; draggedItem: DragObject | null }
	>({
		accept: 'point',
		canDrop: (props: DragObject) => {
			return droppable && props.datatype === datatype;
		},
		drop: onDrop ? (item) => onDrop(item.id) : undefined,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
			draggedItem: monitor.isOver() ? monitor.getItem() : null,
		}),
	});

	let dropLabel: string | undefined;

	if (droppable && draggedItem) {
		if (canDrop) {
			dropLabel = `Bind with '${label}'`;
		} else {
			dropLabel = 'Cannot bind: Wrong datatype';
		}
	}

	const handleExpansionClick = () => {
		if (expanded && onCollapse) {
			onCollapse();
		} else if (!expanded && onExpand) {
			onExpand();
		}
	};

	function attachRef(el: HTMLDivElement) {
		drag(el);
		drop(el);
	}

	return (
		<li className={classes.root}>
			<Box
				className={classNames(classes.node, {
					[classes.selected]: selected,
					[classes.draggingOver]: droppable && canDrop && isOver,
					[classes.draggingOverBanned]: droppable && !canDrop && isOver,
				})}
			>
				{isLeaf ? (
					<Box className={classes.notExpansionIcon}></Box>
				) : (
					<Box onClick={handleExpansionClick} className={classes.expansionIcon}>
						{expanded ? <ExpandMore /> : <ChevronRight />}
					</Box>
				)}
				<Box
					className={classNames(classes.labelContainer, {
						[classes.selectable]: selectable,
						[classes.disabled]: disabled,
						[classes.unsaved]: unsaved,
						[classes.deleted]: deleted,
					})}
					onClick={selectable ? onSelect : undefined}
				>
					{icon && <Box className={classes.icon}>{icon}</Box>}
					{badgeNumber !== undefined && badgeNumber > 0 && (
						<Badge badgeContent={badgeNumber} color="secondary" className={classes.badge}>
							{' '}
						</Badge>
					)}
					<Typography
						variant="subtitle1"
						component="div"
						className={classNames(classes.label, {
							[classes.draggable]: canDrag,
							[classes.dragging]: isDragging,
						})}
						ref={attachRef}
					>
						{dropLabel || label}
					</Typography>
					{loading && <CircularProgress size={16} className={classNames([classes.statusIcon, classes.loadingIcon])} />}
					{unsaved && (
						<Badge color="secondary" variant="dot" className={classNames([classes.statusIcon, classes.unsavedBadge])}>
							{' '}
						</Badge>
					)}
					{lifecycleStatus && lifecycleStatus !== 'Live' && (
						<Tooltip title="Not live" placement="right">
							<Build fontSize="small" color="disabled" className={classNames([classes.statusIcon])} />
						</Tooltip>
					)}
					{online !== undefined &&
						(online ? (
							<Tooltip title="Sending data" placement="right">
								<CheckCircleOutlined fontSize="small" className={classNames([classes.statusIcon, classes.okColor])} />
							</Tooltip>
						) : (
							<Tooltip title="Not sending data" placement="right">
								<OfflineBoltOutlined fontSize="small" className={classNames([classes.statusIcon])} color="error" />
							</Tooltip>
						))}
				</Box>
			</Box>
			{children && expanded && <ul className={classes.children}>{children}</ul>}
		</li>
	);
}
