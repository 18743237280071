import { useContext, useState, useEffect } from 'react';
import {
	Box,
	LinearProgress,
	Button,
	List,
	ListItem,
	ListItemText,
	TextField,
	InputAdornment,
	ListItemAvatar,
	Avatar,
} from '@mui/material';
import { IApiUser } from '@mitie/metadata-api-types';
import { Add, FilterAlt, Folder, Person } from '@mui/icons-material';

import * as Api from '../api/users';
import { useNavigate, useParams } from '../routing/routing';
import UserDetails from '../components/UserDetails';
import { AlertsContext } from 'store/AlertsProvider';
import AddUserDialog from 'components/AddUserDialog';

export default function Users() {
	const [users, setUsers] = useState<IApiUser[]>();
	const [selectedUser, setSelectedUser] = useState<IApiUser>();
	const [isLoading, setIsLoading] = useState(false);
	const [filter, setFilter] = useState<string>('');
	const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
	const { userId } = useParams();
	const navigate = useNavigate();
	const { addAlert } = useContext(AlertsContext);

	useEffect(() => {
		loadUsersList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// Select user in url on page load
		if (users) {
			const match = users.find((r) => r.id === userId);
			setSelectedUser(match);
		}
	}, [userId, users]);

	const loadUsersList = async () => {
		setIsLoading(true);

		try {
			const data = await Api.fetchUsersList();
			setUsers(data.sort((a, b) => (a.displayName > b.displayName ? 1 : -1)));

			if (data.length > 0 && userId === undefined) {
				const userId = data[0].id;
				navigate(null, { userId });
			}
		} catch {
			addAlert('error', 'Failed to load the list of users');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box sx={{ display: 'flex', width: '100%' }}>
			<Box
				sx={{
					borderRight: (theme) => `1px solid ${theme.palette.divider}`,
					minWidth: '300px',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Box
					sx={{
						borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Button
						color="primary"
						variant="contained"
						sx={{ marginTop: (theme) => theme.spacing(1), marginBottom: (theme) => theme.spacing(1) }}
						startIcon={<Add />}
						onClick={() => setAddUserDialogOpen(true)}
					>
						Add user or group...
					</Button>
				</Box>
				{isLoading && <LinearProgress />}
				{users && (
					<Box sx={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}>
						<TextField
							InputProps={{
								startAdornment: (
									<InputAdornment position="start" sx={{ marginLeft: (theme) => theme.spacing(1) }}>
										<FilterAlt />
									</InputAdornment>
								),
							}}
							type="search"
							variant="standard"
							margin="none"
							placeholder="Filter users"
							value={filter}
							onChange={(e) => setFilter(e.target.value)}
						/>
						<List component="nav" sx={{ overflowY: 'auto', flexGrow: 1 }}>
							{users
								.filter((u) => !filter.length || u.displayName.toLowerCase().includes(filter.toLowerCase()))
								.map((user) => (
									<ListItem
										button
										sx={{
											backgroundColor:
												user.id === selectedUser?.id ? (theme) => theme.palette.secondary.light : 'inherit',
										}}
										onClick={() => navigate(null, { userId: user.id })}
										key={user.id}
									>
										<ListItemAvatar>
											<Avatar>{user.type === 'User' ? <Person /> : <Folder />}</Avatar>
										</ListItemAvatar>
										<ListItemText primary={user.displayName} secondary={user.type} />
									</ListItem>
								))}
						</List>
					</Box>
				)}
			</Box>
			<Box sx={{ display: 'flex' }}>
				{selectedUser && <UserDetails user={selectedUser} onChange={() => loadUsersList()} />}
			</Box>
			{addUserDialogOpen && (
				<AddUserDialog
					onClose={async (addedUserId) => {
						if (addedUserId) {
							await loadUsersList();
							navigate(null, { userId: addedUserId });
						}

						setAddUserDialogOpen(false);
					}}
				/>
			)}
		</Box>
	);
}
