import { useState } from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';
import { EntityLifecycleStatus } from '@mitie/metadata-api-types';

import LifecycleStatusPickerDialog from './LifecycleStatusPickerDialog';
import Entity from 'store/entity';

interface ILifecycleStatusPickerProps {
	entity: Entity;
	hierarchy?: 'location' | 'gateway' | 'equip';
	value: EntityLifecycleStatus;
	disabled: boolean;
}

export default function RiskTypePicker({ entity, hierarchy, value, disabled }: ILifecycleStatusPickerProps) {
	const [isPickerOpen, setIsPickerOpen] = useState(false);

	return (
		<TextField
			variant="outlined"
			margin="normal"
			sx={{
				marginRight: '1rem',
				minWidth: '25rem',
			}}
			label="Lifecycle status"
			value={value}
			InputProps={{
				endAdornment: (
					<InputAdornment
						position="end"
						sx={{
							height: 'auto',
							margin: '12px 8px',
						}}
					>
						<>
							<Button disabled={disabled} onClick={() => setIsPickerOpen(true)}>
								...
							</Button>
							{isPickerOpen && (
								<LifecycleStatusPickerDialog
									entity={entity}
									hierarchy={hierarchy}
									initialValue={value}
									onClose={() => {
										setIsPickerOpen(false);
									}}
								/>
							)}
						</>
					</InputAdornment>
				),
				disabled,
				inputProps: {
					disabled: true,
				},
			}}
			InputLabelProps={{
				shrink: true,
			}}
		/>
	);
}
