import { Box, Link, Paper, Typography } from '@mui/material';

import { Page } from 'routing/routes';
import { useNavigate } from 'routing/routing';
import logo from '../images/mitie-logo-stacked.png';

export default function Unauthenticated() {
	const navigate = useNavigate();

	return (
		<Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<Paper
				elevation={3}
				sx={{
					width: '50%',
					height: '50%',
					display: 'flex',
					alignItems: 'center',
					padding: (theme) => theme.spacing(2),
				}}
			>
				<Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
					<img style={{ maxWidth: '200px' }} src={logo} alt="Logo" />
				</Box>
				<Box
					sx={{
						flexGrow: 1,
						margin: (theme) => theme.spacing(2),
						'&>p': {
							marginBottom: (theme) => theme.spacing(2),
						},
					}}
				>
					<Typography>Failed to authenticate.</Typography>
					<Typography>If you require access to MDM, please contact an administrator.</Typography>

					<Link component="button" variant="body1" onClick={() => navigate(Page.Entities)}>
						Click here to go back to the login page
					</Link>
				</Box>
			</Paper>
		</Box>
	);
}
