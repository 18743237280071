import { useState } from 'react';
import { Box, Dialog, DialogContent, Divider, Button, TextField, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Add } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { v4 as uuid } from 'uuid';

import { useParams, useNavigate } from 'routing/routing';
import { DevicePointTemplate } from 'store/devicePointTemplate';
import { useStores } from 'store';
import Entity, { IEntityData } from 'store/entity';
import { DeviceTemplate } from 'store/deviceTemplate';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'center',
		},
		box: {
			display: 'flex',
			flexDirection: 'column',
		},
		divider: {
			margin: `0 ${theme.spacing(4)}`,
		},
		select: {},
		textField: {
			marginTop: theme.spacing(2),
		},
		button: {
			margin: `${theme.spacing(2)} 0`,
		},
	}),
);

interface INewDeviceDialogProps {
	onClose: () => void;
}

export default observer(function NewDeviceDialog({ onClose }: INewDeviceDialogProps) {
	const classes = useStyles();
	const { entityId } = useParams();
	const { entities, deviceTemplates, devicePointTemplates } = useStores();

	const entity = entityId ? entities.addAndGetEntity(entityId) : undefined;
	let childrenTemplates: DeviceTemplate[] = [];
	let channelsTemplate: DevicePointTemplate | undefined;

	if (entity?.deviceTemplate) {
		const childrenDeviceTemplatesIds = entity.deviceTemplate.data?.template.children_templates;

		if (childrenDeviceTemplatesIds) {
			for (let childTemplateIndex = 0; childTemplateIndex < childrenDeviceTemplatesIds.length; childTemplateIndex++) {
				const childTemplateId = childrenDeviceTemplatesIds[childTemplateIndex];
				const childTemplate = deviceTemplates.getTemplate(childTemplateId);

				if (childTemplate) {
					childrenTemplates.push(childTemplate);
				}
			}
		}
	}

	if (entity?.deviceTemplate) {
		const channelsTemplateId = entity?.deviceTemplate?.data?.template.device_points_template;

		if (channelsTemplateId) {
			channelsTemplate = devicePointTemplates.getTemplate(channelsTemplateId);
		}
	} else if (entity?.isPoint) {
		channelsTemplate = entity.deviceChannelTemplate;
	}

	const [templateId, setTemplateId] = useState<string | null>(deviceTemplates.templates[0]?.id || null);
	const [childTemplateId, setChildTemplateId] = useState<string | null>(childrenTemplates[0]?.id || null);
	const [newChannelMetricName, setNewChannelMetricName] = useState('');
	const navigate = useNavigate();

	const addDevice = (templateId: string, parentDevice?: Entity) => {
		const deviceTemplate = deviceTemplates.getTemplate(templateId);

		if (!deviceTemplate || !deviceTemplate.data) {
			return;
		}

		const entityData: IEntityData = {
			name: deviceTemplate.data?.name,
			properties: {},
			tags: ['device'],
			relations: {},
			externalMappings: {},
			templates: {},
			lifecycleStatus: 'Configuration',
		};

		if (parentDevice && parentDevice.data) {
			entityData.relations.gateway = parentDevice.id;
			entityData.relations.location = parentDevice.data.relations.location;
			entityData.relations.client = parentDevice.data.relations.client;
		}

		const id = uuid();

		entities.addEntitiesFromUi([{ id, entityData }]);

		const newEntity = entities.getEntity(id);

		if (newEntity && newEntity.data) {
			// Apply template to new device
			newEntity.setDeviceTemplate(templateId);
		}

		navigate(null, { entityId: id });
	};

	const addChannel = (metricName: string) => {
		if (!entityId) {
			return;
		}

		const entity = entities.getEntity(entityId);

		if (!entity) {
			return;
		}

		let device: Entity | undefined;
		let channelTemplateId: string | undefined;

		if (entity.isPoint) {
			device = entity.parentDevice;
			channelTemplateId = entity.deviceChannelTemplate?.id;
		} else if (entity.isDevice) {
			device = entity;
			channelTemplateId = entity.deviceTemplate?.data?.template.device_points_template;
		}

		if (!device || !channelTemplateId) {
			return;
		}

		const entityData: IEntityData = {
			name: metricName,
			properties: {
				metric_name: metricName,
			},
			tags: ['point'],
			relations: {
				device: device.id,
			},
			externalMappings: {},
			templates: {},
			lifecycleStatus: 'Configuration',
		};

		const id = uuid();

		runInAction(() => {
			entities.addEntitiesFromUi([{ id, entityData }]);

			const newEntity = entities.getEntity(id);

			if (newEntity && newEntity.data && device!.deviceConfigData) {
				// Apply template to new channel
				newEntity.setDevicePointTemplate(channelTemplateId!);
				device!.setChannelDeviceConfigProperty(metricName, 'metric_name', metricName);
				navigate(null, { entityId: id });
			}
		});
	};

	return (
		<Dialog open={true} onClose={() => onClose()} maxWidth="lg">
			<DialogContent>
				<Box className={classes.container}>
					<Box className={classes.box}>
						<Typography variant="overline">Add top level device:</Typography>
						<TextField
							value={templateId || ''}
							select={true}
							label="Device template"
							onChange={(event) => setTemplateId(event.target.value || null)}
							margin="normal"
							variant="standard"
							SelectProps={{
								native: true,
							}}
							InputLabelProps={{
								shrink: true,
							}}
							className={classes.select}
						>
							{deviceTemplates.templates.map(({ id, displayName }) => (
								<option value={id} key={id}>
									{displayName}
								</option>
							))}
						</TextField>
						<Button
							onClick={() => {
								addDevice(templateId!);
								onClose();
							}}
							color="primary"
							variant="contained"
							startIcon={<Add />}
							disabled={templateId === null}
							className={classes.button}
						>
							Add device
						</Button>
					</Box>
					{childrenTemplates.length > 0 && (
						<>
							<Divider orientation="vertical" className={classes.divider} flexItem={true} />
							<Box className={classes.box}>
								<Typography variant="overline">Add child to selected device:</Typography>
								<TextField
									value={childTemplateId || ''}
									select={true}
									label="Device template"
									onChange={(event) => setChildTemplateId(event.target.value || null)}
									margin="normal"
									variant="standard"
									SelectProps={{
										native: true,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									className={classes.select}
								>
									{childrenTemplates.map(({ id, displayName }) => (
										<option value={id} key={id}>
											{displayName}
										</option>
									))}
								</TextField>
								<Button
									startIcon={<Add />}
									variant="contained"
									onClick={() => {
										addDevice(childTemplateId!, entity);
										onClose();
									}}
									color="primary"
									disabled={childTemplateId === null}
									className={classes.button}
								>
									Add child device
								</Button>
							</Box>
						</>
					)}
					{channelsTemplate && (
						<>
							<Divider orientation="vertical" className={classes.divider} flexItem={true} />
							<Box className={classes.box}>
								<Typography variant="overline">{`Add ${channelsTemplate.displayName} to selected device:`}</Typography>
								<TextField
									autoFocus
									label="Metric name"
									fullWidth
									value={newChannelMetricName}
									onChange={({ target: { value } }) => setNewChannelMetricName(value)}
									className={classes.textField}
								/>
								<Button
									startIcon={<Add />}
									variant="contained"
									onClick={() => {
										addChannel(newChannelMetricName);
										onClose();
									}}
									color="primary"
									disabled={newChannelMetricName === ''}
									className={classes.button}
								>
									Add channel
								</Button>
							</Box>
						</>
					)}
				</Box>
			</DialogContent>
		</Dialog>
	);
});
