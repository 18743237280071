import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, LinearProgress } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { autorun } from 'mobx';
import { FolderOutlined, RouterOutlined } from '@mui/icons-material';

import { useStores } from 'store';
import { Status } from 'DataTypes';
import Entity from 'store/entity';
import EntityTreeItem from './EntityTreeItem';
import { DeviceTemplate } from 'store/deviceTemplate';
import { ReactComponent as PointFilledIcon } from 'icons/pointFilled.svg';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			overflow: 'auto',
			padding: theme.spacing(1),
		},
		tree: {
			listStyleType: 'none',
			margin: 0,
			padding: 0,
		},
	}),
);

interface IDeviceTreeProps {
	selected: string | null;
	onSelect: (entityId: string | null) => void;
	mappingModeEnabled?: boolean;
}

export default observer(function DeviceTree({ selected, onSelect, mappingModeEnabled }: IDeviceTreeProps) {
	const classes = useStyles();
	const { entities, deviceTemplates } = useStores();
	const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
	const [devicesToRefresh, setDevicesToRefresh] = useState<Entity[]>([]);

	const expandNodes = (nodeIds: string[]) => {
		for (const nodeId of nodeIds) {
			const entity = entities.getEntity(nodeId);

			if (entity && !entity.childrenRequest.gateway) {
				entity.fetchChildren('gateway');
			}
		}

		setExpandedNodes((current) => [...new Set([...current, ...nodeIds])]);
	};

	useEffect(() => {
		// Load top level items for the tree
		entities.fetchRootDevices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(
		() =>
			autorun(() => {
				// Makes sure that the parents of the selected entity are always expanded
				if (!selected) {
					return;
				}

				const entity = entities.addAndGetEntity(selected);
				const parentDeviceIds = [...entity.parentDeviceIds];
				const topLevelDevice =
					parentDeviceIds.length > 0 ? entities.addAndGetEntity(parentDeviceIds[parentDeviceIds.length - 1]) : entity;
				const topLevelDeviceTemplate = topLevelDevice.deviceTemplate;

				if (topLevelDeviceTemplate) {
					parentDeviceIds.push(topLevelDeviceTemplate.id);
				}

				if (parentDeviceIds.length > 0) {
					expandNodes(parentDeviceIds);
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selected],
	);

	useEffect(
		() =>
			autorun(() => {
				// Update list of devices displayed in the tree and store in "devicesToRefresh"
				const expandedEntities: Entity[] = expandedNodes
					.map((nodeId) => entities.getEntity(nodeId)!)
					.filter((entity) => entity !== undefined);
				const newDevicesToRefresh: Entity[] = [];

				for (const entity of expandedEntities) {
					if (entity.children.gateway) {
						newDevicesToRefresh.push(...entity.children.gateway);
					}
				}

				const expandedTemplates: DeviceTemplate[] = expandedNodes
					.map((nodeId) => deviceTemplates.getTemplate(nodeId)!)
					.filter((template) => template !== undefined);

				for (const template of expandedTemplates) {
					for (const entity of entities.rootDevices) {
						if (entity.deviceTemplate?.id === template.id) {
							newDevicesToRefresh.push(entity);
						}
					}
				}

				setDevicesToRefresh(newDevicesToRefresh);
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[expandedNodes],
	);

	useEffect(() => {
		// Poll statuses for devices displayed in the tree
		const refreshStatuses = () => {
			for (const device of devicesToRefresh) {
				device.fetchDeviceStatus(true);
			}
		};

		refreshStatuses();
		const timer = setInterval(refreshStatuses, 30000);

		return () => clearInterval(timer);
	}, [devicesToRefresh]);

	const rootDevicesByType = entities.rootDevices.reduce((acc, cur) => {
		const deviceTemplate = cur.deviceTemplate;
		const deviceTemplateId = deviceTemplate?.id || 'none';

		if (!acc[deviceTemplateId]) {
			acc[deviceTemplateId] = [];
		}

		acc[deviceTemplateId].push(cur);

		return acc;
	}, {} as { [templateId: string]: Entity[] });

	const onNodeExpand = (nodeId: string) => {
		expandNodes([nodeId]);
	};

	const onNodeCollapse = (nodeId: string) => {
		const newExpandedNodes = expandedNodes.filter((n) => n !== nodeId);

		setExpandedNodes(newExpandedNodes);
	};

	const renderTree = (entity: Entity) => {
		return (
			<EntityTreeItem
				nodeId={entity.id}
				key={entity.id}
				label={entity.displayName}
				online={entity.deviceOnline}
				selectable={true}
				loading={entity.childrenRequest.gateway === Status.Loading}
				unsaved={entity.unsaved}
				deleted={entity.deleted}
				icon={<RouterOutlined />}
				selected={selected === entity.id}
				expanded={expandedNodes.includes(entity.id)}
				lifecycleStatus={entity.data?.lifecycleStatus}
				onExpand={() => onNodeExpand(entity.id)}
				onCollapse={() => onNodeCollapse(entity.id)}
				onSelect={() => onSelect(entity.id)}
			>
				<Box key="stub" />
				{entity.children.gateway && entity.children.gateway.map((n) => renderTree(n))}
				{entity.children.device &&
					entity.children.device.map((point) => {
						// Show as disabled if point is not linked to a parent entity
						const disabled = mappingModeEnabled ? point.data?.relations.entity !== undefined : false;

						return (
							<EntityTreeItem
								nodeId={point.id}
								key={point.id}
								label={point.displayName}
								selectable={true}
								unsaved={point.unsaved}
								deleted={point.deleted}
								icon={<PointFilledIcon />}
								selected={selected === point.id}
								expanded={expandedNodes.includes(point.id)}
								onExpand={() => onNodeExpand(point.id)}
								onCollapse={() => onNodeCollapse(point.id)}
								onSelect={() => onSelect(point.id)}
								isLeaf={true}
								disabled={disabled}
								canDrag={mappingModeEnabled && !disabled}
								datatype={point.getProperty('datatype') as 'number' | 'boolean' | undefined}
							/>
						);
					})}
			</EntityTreeItem>
		);
	};

	return (
		<Box className={classes.container}>
			{entities.rootDevicesFetchStatus === Status.Loading ? (
				<LinearProgress />
			) : (
				<ul className={classes.tree}>
					{Object.keys(rootDevicesByType).map((templateId) => {
						const devices = rootDevicesByType[templateId];

						return (
							<EntityTreeItem
								nodeId={templateId}
								key={templateId}
								label={deviceTemplates.getTemplate(templateId)?.displayName || ''}
								selectable={false}
								icon={<FolderOutlined />}
								expanded={expandedNodes.includes(templateId)}
								onExpand={() => onNodeExpand(templateId)}
								onCollapse={() => onNodeCollapse(templateId)}
							>
								{devices.map((n) => renderTree(n))}
							</EntityTreeItem>
						);
					})}
				</ul>
			)}
		</Box>
	);
});
