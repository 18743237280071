import { useState } from 'react';
import { Box, Dialog, DialogContent, Divider, Button, TextField, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Add } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import { v4 as uuid } from 'uuid';
import { IEntityTemplateChild } from '@mitie/metadata-api-types';

import { useParams, useNavigate } from 'routing/routing';
import { useStores } from 'store';
import Entity, { IEntityData } from 'store/entity';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'center',
		},
		box: {
			display: 'flex',
			flexDirection: 'column',
		},
		divider: {
			margin: `0 ${theme.spacing(4)}`,
		},
		select: {},
		textField: {
			marginTop: theme.spacing(2),
		},
		button: {
			margin: `${theme.spacing(2)} 0`,
		},
	}),
);

interface INewAssetDialogProps {
	onClose: () => void;
}

export default observer(function NewAssetDialog({ onClose }: INewAssetDialogProps) {
	const classes = useStyles();
	const { entityId, locationId } = useParams();
	const { entities, entityTemplates } = useStores();

	const entity = entityId ? entities.addAndGetEntity(entityId) : undefined;

	if (!locationId) {
		throw new Error('Cannot add an asset as there is no location selected');
	}

	const location = entities.addAndGetEntity(locationId);
	const childrenFromTemplate = entity?.entityTemplate?.data?.template.children;
	const childrenAssetsFromTemplate = childrenFromTemplate
		? childrenFromTemplate.filter((c) => c.relation_type === 'equip')
		: [];

	const [templateId, setTemplateId] = useState<string | null>(entityTemplates.templates[0]?.id || null);
	const [childTemplateIndex, setChildTemplateIndex] = useState<number>(0);
	const navigate = useNavigate();

	const addChildAsset = (spec: IEntityTemplateChild, parentAsset: Entity) => {
		addAsset(spec.entity_template, spec.name, spec.tags, parentAsset);
	};

	const addAsset = (templateId: string, name: string = 'New asset', tags: string[] = [], parentAsset?: Entity) => {
		if (!location || !location.data) {
			return;
		}

		const entityData: IEntityData = {
			name,
			properties: {},
			tags: ['equip', 'asset', ...tags],
			relations: {},
			externalMappings: {},
			templates: {},
			lifecycleStatus: 'Configuration',
		};

		if (parentAsset && parentAsset.data) {
			entityData.relations.equip = parentAsset.id;
			entityData.relations.location = parentAsset.data.relations.location;
			entityData.relations.client = parentAsset.data.relations.client;
		} else {
			entityData.relations.location = location.id;
			entityData.relations.client = location.data.relations.client;
		}

		const id = uuid();

		entities.addEntitiesFromUi([{ id, entityData }]);

		const newEntity = entities.getEntity(id);

		if (newEntity && newEntity.data) {
			// Apply template to new device
			newEntity.setEntityTemplate(templateId);
		}

		navigate(null, { entityId: id });
	};

	return (
		<Dialog open={true} onClose={() => onClose()} maxWidth="lg">
			<DialogContent>
				<Box className={classes.container}>
					<Box className={classes.box}>
						<Typography variant="overline">{`Add asset to location '${location.displayName}'`}</Typography>
						<TextField
							value={templateId || ''}
							select={true}
							label="Entity template"
							onChange={(event) => setTemplateId(event.target.value || null)}
							margin="normal"
							variant="standard"
							SelectProps={{
								native: true,
							}}
							InputLabelProps={{
								shrink: true,
							}}
							className={classes.select}
						>
							{entityTemplates.templates.map(({ id, displayName }) => (
								<option value={id} key={id}>
									{displayName}
								</option>
							))}
						</TextField>
						<Button
							onClick={() => {
								addAsset(templateId!);
								onClose();
							}}
							color="primary"
							variant="contained"
							startIcon={<Add />}
							disabled={templateId === null}
							className={classes.button}
						>
							Add asset
						</Button>
					</Box>
					{entity && childrenAssetsFromTemplate.length > 0 && (
						<>
							<Divider orientation="vertical" className={classes.divider} flexItem={true} />
							<Box className={classes.box}>
								<Typography variant="overline">Add child to selected asset:</Typography>
								<TextField
									value={String(childTemplateIndex)}
									select={true}
									label="Child asset"
									onChange={(event) => setChildTemplateIndex(Number(event.target.value))}
									margin="normal"
									variant="standard"
									SelectProps={{
										native: true,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									className={classes.select}
								>
									{childrenAssetsFromTemplate.map(({ name }, index) => (
										<option value={String(index)} key={index}>
											{name}
										</option>
									))}
								</TextField>
								<Button
									startIcon={<Add />}
									variant="contained"
									onClick={() => {
										addChildAsset(childrenAssetsFromTemplate[childTemplateIndex], entity);
										onClose();
									}}
									color="primary"
									className={classes.button}
								>
									Add child asset
								</Button>
							</Box>
						</>
					)}
				</Box>
			</DialogContent>
		</Dialog>
	);
});
