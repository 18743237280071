import { useEffect, useState } from 'react';
import {
	Box,
	Dialog,
	LinearProgress,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	FormControlLabel,
	Checkbox,
	Typography,
	CircularProgress,
} from '@mui/material';

import { useStores } from 'store';
import Entity from 'store/entity';
import { Status } from 'DataTypes';
import LocationTree from './LocationTree';

interface ILocationSelectPopupProps {
	entity: Entity;
	initialLocation?: Entity;
	onClose: (entityId?: string | null) => void;
	hierarchy?: 'location' | 'gateway' | 'equip';
}

export default function LocationSelectPopup({
	entity,
	initialLocation,
	onClose,
	hierarchy,
}: ILocationSelectPopupProps) {
	const { entities } = useStores();
	const [selectedLocation, setSelectedLocation] = useState<Entity | null>(initialLocation || null);
	const [propagate, setPropagate] = useState(false);
	const [childrenCount, setChildrenCount] = useState<number | null>(null);
	const [childrenCountLoading, setChildrenCountLoading] = useState(false);

	useEffect(() => {
		// Load descendants if "Propagate" checkbox is ticked
		if (
			(hierarchy === 'gateway' || hierarchy === 'equip') &&
			propagate &&
			childrenCount === null &&
			!entity.descendantsRequest[hierarchy]
		) {
			loadDescendants();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entity, propagate, hierarchy]);

	useEffect(() => {
		if (hierarchy) {
			const allDescendants = entity.descendants[hierarchy];

			if (allDescendants) {
				setChildrenCount(allDescendants.filter((e) => !e.isPoint).length || 0);
			}
		}
	}, [entity, hierarchy]);

	const loadDescendants = async () => {
		if (hierarchy === 'gateway' || hierarchy === 'equip') {
			setChildrenCountLoading(true);
			await entity.fetchDescendantsAsync(hierarchy);

			const allDescendants = entity.descendants[hierarchy]!;
			setChildrenCount(allDescendants.filter((e) => !e.isPoint).length || 0);
			setChildrenCountLoading(false);
		}
	};

	const updateLocation = async (location: Entity | null) => {
		entity.setRelation('location', selectedLocation?.id);

		if (propagate && hierarchy && entity.descendants[hierarchy]) {
			for (const child of entity.descendants[hierarchy]!) {
				if (child.data && !child.isPoint) {
					child.setRelation('location', selectedLocation?.id);
				}
			}
		}

		onClose();
	};

	return (
		<Dialog open={true} onClose={() => onClose()} maxWidth="md" fullWidth>
			<DialogTitle>Select entity location</DialogTitle>
			<DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
				<Box sx={{ height: '500px', overflow: 'auto' }}>
					{entities.rootLocationsFetchStatus === Status.Loading ? (
						<LinearProgress />
					) : (
						<LocationTree
							selected={selectedLocation}
							onSelect={(selected) => setSelectedLocation(selected)}
							hidePoints
						/>
					)}
				</Box>
				{(hierarchy === 'gateway' || hierarchy === 'equip') && (
					<FormControlLabel
						control={<Checkbox checked={propagate} onChange={(event) => setPropagate(event.target.checked)} />}
						label={
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Typography sx={{ marginRight: (theme) => theme.spacing(1) }}>
									{`${
										hierarchy === 'gateway'
											? 'Override location of children devices'
											: 'Override location of children assets'
									} ${childrenCount !== null ? ` (${childrenCount} children)` : ''}`}
								</Typography>
								{childrenCountLoading && <CircularProgress size={16} />}
							</Box>
						}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} color="primary" variant="outlined">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button onClick={() => updateLocation(null)} color="primary" variant="outlined">
					Clear location
				</Button>
				<Button
					onClick={() => updateLocation(selectedLocation)}
					color="primary"
					variant="contained"
					disabled={(selectedLocation === initialLocation && !propagate) || childrenCountLoading}
				>
					Choose selected
				</Button>
			</DialogActions>
		</Dialog>
	);
}
