import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Box,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
	Typography,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { IEntityTemplateChild } from '@mitie/metadata-api-types';

import { EntityTemplate } from 'store/entityTemplate';
import EntityTemplateChild from './EntityTemplateChild';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexGrow: 1,
			minHeight: 0,
		},
		topBar: {
			display: 'flex',
		},
		list: {
			display: 'flex',
			flexDirection: 'column',
			minHeight: 0,
		},
		titleText: {
			lineHeight: '48px',
			marginRight: theme.spacing(2),
			marginLeft: theme.spacing(2),
		},
		grow: {
			flexGrow: 1,
		},
	}),
);

interface IEntityTemplateChildrenProps {
	template: EntityTemplate;
	canEdit: boolean;
}

export default observer(function EntityTemplateChildren({ template, canEdit }: IEntityTemplateChildrenProps) {
	const classes = useStyles();
	const [selectedChildTemplateIndex, setSelectedChildTemplateIndex] = useState<number | null>(null);

	const updateChildTemplate = (data: IEntityTemplateChild) => {
		if (!template.data?.template.children || selectedChildTemplateIndex === null) {
			return;
		}

		template.data.template.children[selectedChildTemplateIndex] = data;
	};

	const addChildTemplate = () => {
		if (!template.data) {
			return;
		}

		const data: IEntityTemplateChild = {
			entity_template: '',
			relation_type: 'equip',
			name: 'Child asset',
			default: true,
			allow_multiple: false,
			tags: [],
		};

		if (!template.data.template.children) {
			template.data.template.children = [data];
		} else {
			template.data.template.children.push(data);
		}

		setSelectedChildTemplateIndex(template.data.template.children.length);
	};

	const deleteChildTemplate = (index: number) => {
		if (!template.data?.template.children) {
			return;
		}

		template.data.template.children.splice(index, 1);

		if (index === selectedChildTemplateIndex) {
			setSelectedChildTemplateIndex(null);
		}
	};

	return (
		<Box className={classes.root}>
			{template.data && (
				<>
					<Box className={classes.list}>
						<Box className={classes.topBar}>
							<Typography variant="h6" className={classes.titleText}>
								Children
							</Typography>
							{canEdit && (
								<>
									<Box className={classes.grow} />
									<Tooltip title="New property" placement="bottom">
										<IconButton color="secondary" onClick={addChildTemplate}>
											<Add />
										</IconButton>
									</Tooltip>
								</>
							)}
						</Box>
						<Divider />
						<Box className={classes.list}>
							<Box sx={{ overflow: 'auto', minWidth: '20rem' }}>
								<List sx={{ padding: 0 }}>
									{template.data.template.children &&
										template.data.template.children.map((child, index) => (
											<ListItem
												button={true}
												key={index}
												selected={index === selectedChildTemplateIndex}
												autoFocus={index === selectedChildTemplateIndex}
												onClick={() => setSelectedChildTemplateIndex(index)}
											>
												<ListItemText
													sx={{ whiteSpace: 'nowrap' }}
													primary={<Typography variant="subtitle1">{child.name}</Typography>}
												></ListItemText>
												{canEdit && (
													<ListItemSecondaryAction>
														<IconButton edge="end" onClick={() => deleteChildTemplate(index)}>
															<Delete />
														</IconButton>
													</ListItemSecondaryAction>
												)}
											</ListItem>
										))}
								</List>
							</Box>
						</Box>
					</Box>
					<Divider orientation="vertical" />
					{selectedChildTemplateIndex !== null && template.data.template.children?.[selectedChildTemplateIndex] && (
						<EntityTemplateChild
							data={template.data.template.children[selectedChildTemplateIndex]}
							setData={updateChildTemplate}
							canEdit={canEdit}
						/>
					)}
				</>
			)}
		</Box>
	);
});
