import { IApiUserRoleAssignment } from '@mitie/metadata-api-types';

import * as Api from './api';

export function getAssignmentsForUser(principalId: string) {
	return Api.get<IApiUserRoleAssignment[]>(`users/${principalId}/role-assignments`);
}

export function create(principalId: string, data: IApiUserRoleAssignment) {
	return Api.post(`users/${principalId}/role-assignments`, data);
}

export function remove(principalId: string, data: IApiUserRoleAssignment) {
	return Api.delete_(`users/${principalId}/role-assignments`, data);
}
