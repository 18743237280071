import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Button } from '@mui/material';
import { FileUpload } from '@mui/icons-material';

import { IFileImportResult } from 'api/entities';

interface IUploaderProps {
	onStatusChange: (importStatus: IFileImportResult) => void;
}

export default function Uploader({ onStatusChange }: IUploaderProps) {
	const onDrop = useCallback(
		([acceptedFile]: File[]) => {
			if (!acceptedFile) {
				return;
			}

			const reader = new FileReader();
			reader.onload = async () => {
				const rawContent = reader.result;

				if (typeof rawContent === 'string') {
					onStatusChange({
						status: 'File loaded',
						statusText: `File '${acceptedFile.name}' selected (size: ${Math.round(acceptedFile.size / 1024)}KB)`,
						rawContent,
					});
				} else {
					onStatusChange({
						status: 'Loading error',
						statusText: 'Invalid file format. Must be a text file in CSV format',
					});
				}
			};

			reader.onerror = () => {
				onStatusChange({
					status: 'Loading error',
					statusText: `Unable to read file '${acceptedFile.name}'`,
				});
			};

			reader.onabort = () => {
				onStatusChange({
					status: 'Loading error',
					statusText: 'File reading was aborted',
				});
			};

			reader.readAsText(acceptedFile);
			onStatusChange({ fileName: acceptedFile.name, status: 'Loading in progress' });
		},
		[onStatusChange],
	);
	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop,
		noClick: true,
		noKeyboard: true,
		multiple: false,
		accept: { 'text/csv': [] },
	});

	return (
		<Box {...getRootProps()} sx={{ margin: (theme) => theme.spacing(1) }}>
			<input {...getInputProps()} />
			<Button variant="contained" color="primary" onClick={open} startIcon={<FileUpload />}>
				Pick file...
			</Button>
		</Box>
	);
}
