import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { IEntityTemplateChild, RelationType } from '@mitie/metadata-api-types';

import { useStores } from 'store';
import TagsInput from './TagsInput';
import EntityProperty from './EntityProperty';
import { Status } from 'DataTypes';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginLeft: theme.spacing(2),
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
	}),
);

interface IEntityTemplateChildProps {
	data: IEntityTemplateChild;
	setData: (data: IEntityTemplateChild) => void;
	canEdit: boolean;
}

export default observer(function EntityTemplateChild({ data, setData, canEdit }: IEntityTemplateChildProps) {
	const classes = useStyles();
	const [entityTemplateSelect, setEntityTemplatesSelect] = useState<{ value: string; label: string }[]>([]);
	const { entityTemplates } = useStores();

	// Fetch list of entity templates for drop down
	useEffect(() => {
		if (entityTemplates.fetchStatus === Status.None) {
			entityTemplates.fetchAll();
		}
	}, [entityTemplates]);

	// Build values for template drop down
	useEffect(
		() =>
			autorun(() => {
				const list: { value: string; label: string }[] = [{ value: '', label: 'Select template for child' }];

				for (let template of entityTemplates.templates) {
					list.push({ value: template.id, label: template.displayName });
				}
				setEntityTemplatesSelect(list);
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[entityTemplates],
	);

	return (
		<Box className={classes.root}>
			<EntityProperty
				label="Relation type"
				type="string"
				value={data.relation_type}
				select={[
					{ value: 'location', label: 'Child location' },
					{ value: 'equip', label: 'Child asset' },
				]}
				onChange={(value) => setData({ ...data, relation_type: value as RelationType })}
				className={classes.field}
				disabled={!canEdit}
			/>
			<EntityProperty
				label="Entity template"
				type="string"
				value={data.entity_template}
				select={entityTemplateSelect}
				onChange={(value) => setData({ ...data, entity_template: value as string })}
				className={classes.field}
				disabled={!canEdit}
			/>
			<EntityProperty
				label="Name"
				type="string"
				value={data.name}
				onChange={(value) => setData({ ...data, name: value as string })}
				className={classes.field}
				disabled={!canEdit}
			/>
			<EntityProperty
				label="Create by default"
				type="boolean"
				value={data.default}
				onChange={(value) => setData({ ...data, default: value as boolean })}
				className={classes.field}
				disabled={!canEdit}
			/>
			<EntityProperty
				label="Allow multiple instances"
				type="boolean"
				value={data.allow_multiple}
				onChange={(value) => setData({ ...data, allow_multiple: value as boolean })}
				className={classes.field}
				disabled={!canEdit}
			/>
			<TagsInput
				label="Additional tags"
				value={data.tags}
				onChange={(value) => setData({ ...data, tags: value })}
				className={classes.field}
				disabled={!canEdit}
			/>
		</Box>
	);
});
