import { useEffect, useState } from 'react';
import { TextField, InputAdornment, Button } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';

import { useStores } from 'store';
import EntitySelectDialog from './EntitySelectDialog';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		input: {
			disabled: 'disabled',
		},
	}),
);

interface IEntitySelectProps {
	selectedEntityId?: string;
	entityType: string;
	label: string;
	className: string;
	optional: boolean;
	disabled: boolean;
	onChange: (entityId: string | null) => void;
}

export default observer(function EntitySelect({
	selectedEntityId,
	entityType,
	label,
	className,
	optional,
	disabled,
	onChange,
}: IEntitySelectProps) {
	const classes = useStyles();
	const { entities } = useStores();
	const [popupOpen, setPopupOpen] = useState(false);

	useEffect(
		() =>
			autorun(() => {
				if (selectedEntityId) {
					const selectedEntity = entities.addAndGetEntity(selectedEntityId);

					if (!selectedEntity?.dataRequest) {
						selectedEntity.fetchData();
					}
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedEntityId],
	);

	const selectedEntity = selectedEntityId ? entities.getEntity(selectedEntityId) : undefined;

	return (
		<>
			<TextField
				label={label}
				margin="normal"
				variant="outlined"
				className={className}
				disabled={disabled}
				value={selectedEntity ? selectedEntity.displayName : 'None'}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Button onClick={() => setPopupOpen(true)}>...</Button>
						</InputAdornment>
					),
					className: classes.input,
					disabled: true,
				}}
			/>
			{popupOpen && (
				<EntitySelectDialog
					entityType={entityType}
					value={selectedEntity}
					optional={optional}
					onChange={(v) => {
						setPopupOpen(false);
						onChange(v);
					}}
					onClose={() => setPopupOpen(false)}
				/>
			)}
		</>
	);
});
