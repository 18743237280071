import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { IDeviceCommandDefinition } from '@mitie/metadata-api-types';

import EntityProperty from './EntityProperty';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginLeft: theme.spacing(2),
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
	}),
);

interface IDeviceCommandDefinitionProps {
	data: IDeviceCommandDefinition;
	setData: (data: IDeviceCommandDefinition) => void;
	canEdit: boolean;
}

export default function DeviceCommandDefinition({ data, setData, canEdit }: IDeviceCommandDefinitionProps) {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<EntityProperty
				label="Display name"
				type="string"
				value={data.label}
				className={classes.field}
				onChange={(value) => setData({ ...data, label: value as string })}
				disabled={!canEdit}
			/>
			<EntityProperty
				label="Name"
				type="string"
				value={data.name}
				className={classes.field}
				onChange={(value) => setData({ ...data, name: value as string })}
				disabled={!canEdit}
			/>
		</Box>
	);
}
