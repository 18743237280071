import React, { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { HttpCode } from 'tools/ErrorWithHttpCode';

export type AlertType = 'success' | 'error' | 'warning' | 'info';

export interface IAlert {
	id: string;
	message: string;
	code?: HttpCode;
	type: AlertType;
	timeout: number | null;
}

interface IAlertStore {
	alerts: IAlert[];
	addAlert: (type: AlertType, message: string, code?: HttpCode) => void;
	removeNextAlert: () => void;
}

export const AlertsContext = React.createContext<IAlertStore>({
	alerts: [],
	addAlert: () => {},
	removeNextAlert: () => {},
});

export default function AlertsProvider({ children }: { children: React.ReactNode[] | React.ReactNode }) {
	const [alerts, setAlerts] = useState<IAlert[]>([]);

	const addAlert = (type: AlertType, message: string, code?: HttpCode) => {
		const existingAlert = alerts.find((existingAlert) => existingAlert.message === message);

		if (!existingAlert) {
			const timeout = type === 'error' ? null : 5000;
			const id = uuid();
			const newAlert = {
				id,
				message,
				code,
				type,
				timeout,
			};

			setAlerts((current) => [...current, newAlert]);
		}
	};

	const removeNextAlert = () => {
		if (!alerts.length) {
			return;
		}

		setAlerts((current) => current.slice(1));
	};

	const contextValue = {
		alerts: alerts,
		addAlert: useCallback(addAlert, [alerts]),
		removeNextAlert: useCallback(removeNextAlert, [alerts]),
	};

	return <AlertsContext.Provider value={contextValue}>{children}</AlertsContext.Provider>;
}
