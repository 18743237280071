import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { IApiDeviceCommand, IApiDeviceCommandStatus } from '@mitie/metadata-api-types';
import { Send } from '@mui/icons-material';

import Entity from 'store/entity';
import { socket } from 'api/api';
import { subscribeToDeviceCommands, triggerDeviceCommand, unsubscribeFromDeviceCommands } from 'api/devices';
import { AlertsContext } from 'store/AlertsProvider';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(2),
		},
	}),
);

interface IDeviceCommandsProps {
	entity: Entity;
}

export default observer(function DeviceCommands({ entity }: IDeviceCommandsProps) {
	const classes = useStyles();
	const { addAlert } = useContext(AlertsContext);
	const [commandStatuses, setCommandStatuses] = useState<{ [command: string]: IApiDeviceCommandStatus }>({});

	useEffect(() => {
		// Entity has changed, subscribe to new data
		function handleCommandStatus(commandStatus: IApiDeviceCommandStatus) {
			if (commandStatus.deviceId === entity.id) {
				setCommandStatuses((current) => ({ ...current, [commandStatus.command]: commandStatus }));
			}
		}

		subscribeToDeviceCommands(entity.id);
		socket.on('command-status', handleCommandStatus);

		return function cleanup() {
			unsubscribeFromDeviceCommands(entity.id);
			socket.off('command-status', handleCommandStatus);
		};
	}, [entity]);

	const triggerCommand = async (command: string) => {
		try {
			let commandStatus: IApiDeviceCommandStatus = {
				deviceId: entity.id,
				command,
				status: 'pending',
				status_text: 'Sending command to server',
			};
			setCommandStatuses((current) => ({ ...current, [command]: commandStatus }));

			const commandData: IApiDeviceCommand = {
				deviceId: entity.id,
				command,
			};
			commandStatus = await triggerDeviceCommand(commandData);
			setCommandStatuses((current) => ({ ...current, [command]: commandStatus }));
		} catch (error: any) {
			addAlert('error', 'Failed to trigger command');
			const commandStatus: IApiDeviceCommandStatus = {
				deviceId: entity.id,
				command,
				status: 'failed',
				status_text: 'Failed to send command to server',
			};
			setCommandStatuses((current) => ({ ...current, [command]: commandStatus }));
		}
	};

	return (
		<Box className={classes.root}>
			{entity.deviceTemplate?.data?.template.commands?.length ? (
				<>
					{entity.deviceTemplate?.data?.template.commands.map(({ name, label }) => {
						const status = commandStatuses[name];
						const isLoading = status !== undefined && status.status !== 'success' && status.status !== 'failed';

						return (
							<Box sx={{ display: 'flex', alignItems: 'center' }} key={name}>
								<LoadingButton
									loading={isLoading}
									loadingPosition="start"
									startIcon={<Send />}
									onClick={() => triggerCommand(name)}
									variant="outlined"
									sx={{ marginRight: '16px' }}
								>
									{label}
								</LoadingButton>
								<Typography variant="body2">{status?.status_text || ''}</Typography>
							</Box>
						);
					})}
				</>
			) : (
				<Typography>This device doesn't support commands</Typography>
			)}
		</Box>
	);
});
