import { makeObservable, reaction, toJS, runInAction, observable, action } from 'mobx';
import { TemplateType } from '@mitie/metadata-api-types';

import { Status } from 'DataTypes';
import * as TemplatesApi from '../api/templates';
import { Template } from './template';

abstract class Templates<T> {
	public fetchStatus: Status = Status.None;
	public templateType: TemplateType;
	public templatesToFetch: string[] = [];

	constructor(templateType: TemplateType) {
		makeObservable(this, {
			fetchStatus: observable,
			templatesToFetch: observable,
			fetchAll: action,
		});
		this.templateType = templateType;

		reaction(
			() => toJS(this.templatesToFetch),
			(templatesToFetch) => {
				if (templatesToFetch.length === 0) {
					return;
				}

				const chunkSize = 100;

				while (templatesToFetch.length > 0) {
					const chunk = templatesToFetch.splice(0, chunkSize);
					this.fetchTemplatesByIds(chunk);
				}

				this.templatesToFetch = [];
			},
			{ delay: 300 },
		);
	}

	public abstract getTemplate(id: string): Template<T> | undefined;

	public abstract addAndGetTemplate(templateId: string): Template<T>;
	public abstract createTemplate(baseTemplateId?: string): Template<T>;

	public abstract get templates(): Array<Template<T>>;

	public async fetchAll() {
		if (this.fetchStatus !== Status.None) {
			return;
		}

		this.fetchStatus = Status.Loading;

		try {
			const data = await TemplatesApi.fetchTemplates<T>(this.templateType);

			runInAction(() => {
				data.forEach(({ id, templateData, updatedTime }) => {
					const template = this.addAndGetTemplate(id);
					template.setSavedData(templateData, updatedTime, false);
				});
				this.fetchStatus = Status.Done;
			});
		} catch (error: any) {
			runInAction(() => {
				this.fetchStatus = Status.Error;
				error.message = `Failed to load templates list (${error.message})`;
				throw error;
			});
		}
	}

	private async fetchTemplatesByIds(ids: string[]) {
		try {
			const data = await TemplatesApi.fetchTemplates<T>(this.templateType);

			runInAction(() => {
				let idsMissing = [...ids];

				data.forEach(({ id, templateData, updatedTime }) => {
					const template = this.addAndGetTemplate(id);
					template.setSavedData(templateData, updatedTime, false);

					idsMissing = idsMissing.filter((existingId) => existingId !== id);
				});

				for (const id of idsMissing) {
					const template = this.getTemplate(id);

					if (template) {
						template.dataRequest = Status.Empty;
					}
				}
			});
		} catch (error: any) {
			for (const id of ids) {
				const template = this.getTemplate(id);

				if (template) {
					runInAction(() => (template.dataRequest = Status.Error));
				}
			}

			error.message = `Failed to load templates details (${error.message})`;
			throw error;
		}
	}
}

export default Templates;
