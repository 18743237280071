import { useEffect, useState } from 'react';
import { EntityLifecycleStatus } from '@mitie/metadata-api-types';
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';

import Entity from 'store/entity';

interface ILifecycleStatusPickerDialogProps {
	entity: Entity;
	hierarchy?: 'location' | 'gateway' | 'equip';
	initialValue: EntityLifecycleStatus;
	onClose: () => void;
}

export default function LifecycleStatusPickerDialog({
	entity,
	hierarchy,
	initialValue,
	onClose,
}: ILifecycleStatusPickerDialogProps) {
	const [value, setValue] = useState<EntityLifecycleStatus>(initialValue);
	const [propagate, setPropagate] = useState(false);
	const [childrenCount, setChildrenCount] = useState<number | null>(null);
	const [childrenCountLoading, setChildrenCountLoading] = useState(false);

	useEffect(() => {
		if (hierarchy && propagate && childrenCount === null && !entity.descendantsRequest[hierarchy]) {
			loadDescendants();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [propagate, entity, hierarchy]);

	useEffect(() => {
		if (hierarchy) {
			const allDescendants = entity.descendants[hierarchy];

			if (allDescendants) {
				setChildrenCount(allDescendants.filter((e) => !e.isPoint).length || 0);
			}
		}
	}, [entity, hierarchy]);

	const loadDescendants = async () => {
		if (!hierarchy) {
			return;
		}

		setChildrenCountLoading(true);
		await entity.fetchDescendantsAsync(hierarchy);

		const allDescendants = entity.descendants[hierarchy]!;
		setChildrenCount(allDescendants.filter((e) => !e.isPoint).length || 0);
		setChildrenCountLoading(false);
	};

	const updateLifecycleStatus = async () => {
		if (value && entity.data) {
			entity.data.lifecycleStatus = value;

			if (propagate && hierarchy && entity.descendants[hierarchy]) {
				for (const child of entity.descendants[hierarchy]!) {
					if (child.data && !child.isPoint) {
						child.data.lifecycleStatus = value;
					}
				}
			}
		}

		onClose();
	};

	return (
		<Dialog open={true} onClose={() => onClose()} maxWidth="sm" fullWidth>
			<DialogTitle>Change entity lifecycle status</DialogTitle>
			<DialogContent>
				<RadioGroup value={value} onChange={(e) => setValue(e.currentTarget.value as EntityLifecycleStatus)}>
					<FormControlLabel value="Configuration" control={<Radio />} label="Configuration" />
					<FormControlLabel value="Live" control={<Radio />} label="Live" />
					<FormControlLabel value="Decommissioned" control={<Radio />} label="Decommissioned" />
				</RadioGroup>
				{hierarchy && (
					<FormControlLabel
						control={<Checkbox checked={propagate} onChange={(event) => setPropagate(event.target.checked)} />}
						label={
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Typography sx={{ marginRight: (theme) => theme.spacing(1) }}>
									{`${
										hierarchy === 'location'
											? 'Override lifecycle status of locations, devices and assets below this location'
											: hierarchy === 'gateway'
											? 'Override lifecycle status of children devices'
											: 'Override lifecycle status of children assets'
									} ${childrenCount !== null ? ` (${childrenCount} children)` : ''}`}
								</Typography>
								{childrenCountLoading && <CircularProgress size={16} />}
							</Box>
						}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={() => onClose()}>
					Cancel
				</Button>
				<Button
					variant="contained"
					onClick={() => updateLifecycleStatus()}
					disabled={(value === initialValue && !propagate) || childrenCountLoading}
				>
					Change
				</Button>
			</DialogActions>
		</Dialog>
	);
}
