import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { IPropertyDefinition, TemplatePropertyType } from '@mitie/metadata-api-types';

import EntityProperty from './EntityProperty';
import DropdownOptionsInput from './DropdownOptionsInput';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginLeft: theme.spacing(2),
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
	}),
);

interface ICustomPropertyDefinitionProps {
	data: IPropertyDefinition;
	setData: (data: IPropertyDefinition) => void;
	canEdit: boolean;
}

export default function CustomPropertyDefinition({ data, setData, canEdit }: ICustomPropertyDefinitionProps) {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<EntityProperty
				label="Name"
				type="string"
				value={data.name}
				className={classes.field}
				onChange={(value) => setData({ ...data, name: value as string })}
				disabled={!canEdit}
			/>
			<EntityProperty
				label="Display name"
				type="string"
				value={data.label}
				onChange={(value) => setData({ ...data, label: value as string })}
				className={classes.field}
				disabled={!canEdit}
			/>
			<EntityProperty
				label="Type"
				type="string"
				value={data.type}
				select={[
					{ value: 'string', label: 'String' },
					{ value: 'string-multiline', label: 'Multiline string' },
					{ value: 'number', label: 'Number' },
					{ value: 'boolean', label: 'Boolean' },
				]}
				onChange={(value) => setData({ ...data, type: value as TemplatePropertyType, default: undefined })}
				className={classes.field}
				disabled={!canEdit}
			/>
			<EntityProperty
				label="Default value"
				type={data.type}
				value={data.default}
				onChange={(value) => setData({ ...data, default: value })}
				className={classes.field}
				disabled={!canEdit}
			/>
			<DropdownOptionsInput
				value={data.select}
				type={data.type}
				onChange={(value) => setData({ ...data, select: value })}
				canEdit={canEdit}
			/>
		</Box>
	);
}
