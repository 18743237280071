import {
	IApiDeviceConfig,
	IApiDeviceConfigCreate,
	IApiDeviceConfigUpdate,
	IApiDeviceStatus,
	IDeviceTwinProperties,
	IApiDevicesSaveRequest,
	IApiDeviceCommandStatus,
	IApiDeviceCommand,
	IApiDeviceReportedConfig,
} from '@mitie/metadata-api-types';
import { differenceInMinutes } from 'date-fns';

import { IDeviceConfigData, IDeviceReportedConfigData, IDeviceStatus } from 'store/entity';
import { get, post, delete_, put, socket } from './api';
import { stores } from 'store';
import { Status } from 'DataTypes';

let devicesSubscribedToCommands: string[] = [];

export async function fetchDeviceConfigById(deviceId: string) {
	const device = await get<IApiDeviceConfig>(`devices/${deviceId}`);

	return parseDeviceConfigFromApi(device);
}

export async function createDevice(deviceId: string, data: IDeviceConfigData) {
	const resp = await post<IApiDeviceConfig>('devices', parseDeviceForApi(deviceId, data));

	return parseDeviceConfigFromApi(resp);
}

export function deleteDevice(deviceId: string) {
	return delete_<void>(`devices/${deviceId}`);
}

export async function updateDeviceConfig(deviceId: string, data: IDeviceConfigData) {
	const resp = await put<IApiDeviceConfig>(`devices/${deviceId}`, parseDeviceForApi(deviceId, data));

	return parseDeviceConfigFromApi(resp);
}

export function batchSaveDevices(
	toCreate: { deviceId: string; data: IDeviceConfigData }[],
	toUpdate: { deviceId: string; data: IDeviceConfigData }[],
	toDelete: string[],
) {
	const request: IApiDevicesSaveRequest = {
		create: toCreate.map(({ deviceId, data }) => parseDeviceForApi(deviceId, data)),
		update: toUpdate.map(({ deviceId, data }) => parseDeviceForApi(deviceId, data)),
		delete: toDelete,
	};

	return post<void>('devices/batchSave', request);
}

export function triggerDeviceCommand(command: IApiDeviceCommand) {
	return post<IApiDeviceCommandStatus>('devices/commands', command);
}

function parseDeviceConfigFromApi(device: IApiDeviceConfig): IDeviceConfigData {
	const properties: {
		[key: string]: string | number | boolean;
	} = {};

	for (const propName in device.desired_properties) {
		const twinProp = device.desired_properties[propName];

		if (typeof twinProp === 'string' || typeof twinProp === 'number' || typeof twinProp === 'boolean') {
			properties[propName] = twinProp;
		}
	}

	const channels = Array.isArray(device.desired_properties.channels)
		? (device.desired_properties.channels as {
				[key: string]: string | number | boolean;
		  }[])
		: [];

	return {
		tags: device.tags,
		properties,
		channels,
	};
}

function parseDeviceForApi(
	deviceId: string,
	{ tags, properties, channels }: IDeviceConfigData,
): IApiDeviceConfigCreate | IApiDeviceConfigUpdate {
	const desired: IDeviceTwinProperties = properties;
	desired.channels = channels;

	return {
		id: deviceId,
		tags,
		desired_properties: desired,
	};
}

export async function fetchDeviceStatusById(deviceId: string) {
	const resp = await get<IApiDeviceStatus>(`devices/status/${deviceId}`);

	return parseDeviceStatusFromApi(resp);
}

export async function fetchDevicesStatusById(deviceIds: string[]) {
	const resp = await post<IApiDeviceStatus[]>('devices/status/byDeviceIds', {
		deviceIds,
	});

	return resp.map((deviceStatus) => ({
		deviceId: deviceStatus.deviceId,
		deviceData: parseDeviceStatusFromApi(deviceStatus),
	}));
}

function parseDeviceStatusFromApi(deviceStatus: IApiDeviceStatus): IDeviceStatus {
	let lastTelemetryTime: Date | undefined;
	let isOnline = false;
	const { lastTelemetryTime: lastTelemetryTimeString, ...otherProperties } = deviceStatus.properties;

	if (typeof lastTelemetryTimeString === 'string') {
		lastTelemetryTime = new Date(lastTelemetryTimeString);

		if (differenceInMinutes(new Date(), lastTelemetryTime) < 180) {
			isOnline = true;
		}
	}

	return {
		isOnline,
		lastTelemetryTime,
		otherProperties,
	};
}

socket.on('device-config-updated', (device: IApiDeviceConfig) => {
	const deviceData = parseDeviceConfigFromApi(device);

	const entity = stores.entities.addAndGetEntity(device.id);
	entity.setSavedDeviceConfigData(deviceData, true);
	entity.deviceSaveRequest = Status.Done;
	stores.globals.decrementSavePendingCounter();
});

socket.on('device-config-created', (device: IApiDeviceConfig) => {
	const deviceData = parseDeviceConfigFromApi(device);

	const entity = stores.entities.addAndGetEntity(device.id);
	entity.setSavedDeviceConfigData(deviceData, true);
	entity.deviceSaveRequest = Status.Done;
	stores.globals.decrementSavePendingCounter();
});

socket.on('device-config-deleted', (deviceId: string) => {
	const entity = stores.entities.getEntity(deviceId);

	if (entity) {
		entity.savedDeviceConfigData = undefined;
		entity.deviceConfigData = undefined;
		entity.deviceDataRequest = Status.Done;
		entity.deviceSaveRequest = Status.Done;
	}

	stores.globals.decrementSavePendingCounter();
});

function parseDeviceReportedConfigFromApi(device: IApiDeviceReportedConfig): IDeviceReportedConfigData {
	const properties: {
		[key: string]: string | number | boolean;
	} = {};

	for (const propName in device.reported_properties) {
		const twinProp = device.reported_properties[propName];

		if (typeof twinProp === 'string' || typeof twinProp === 'number' || typeof twinProp === 'boolean') {
			properties[propName] = twinProp;
		}
	}

	const channels = Array.isArray(device.reported_properties.channels)
		? (device.reported_properties.channels as {
				[key: string]: string | number | boolean;
		  }[])
		: [];

	return {
		properties,
		channels,
		modifiedTime: new Date(device.modified_time),
	};
}

export async function fetchDeviceReportedConfigById(deviceId: string) {
	const device = await get<IApiDeviceReportedConfig | undefined>(`devices/reported-config/${deviceId}`);

	return device ? parseDeviceReportedConfigFromApi(device) : undefined;
}

socket.on('device-reported-config-updated', (device: IApiDeviceReportedConfig) => {
	const reportedConfigData = parseDeviceReportedConfigFromApi(device);

	const entity = stores.entities.addAndGetEntity(device.id);
	entity.deviceReportedConfigData = reportedConfigData;
});

export function subscribeToDeviceCommands(deviceId: string) {
	if (!devicesSubscribedToCommands.includes(deviceId)) {
		devicesSubscribedToCommands.push(deviceId);

		if (stores.globals.websocketConnected) {
			socket.emit('subscribe-commands', deviceId);
		}
	}
}

export function unsubscribeFromDeviceCommands(deviceId: string) {
	if (devicesSubscribedToCommands.includes(deviceId)) {
		devicesSubscribedToCommands = devicesSubscribedToCommands.filter((id) => id !== deviceId);

		if (stores.globals.websocketConnected) {
			socket.emit('unsubscribe-commands', deviceId);
		}
	}
}

socket.on('connect', () => {
	// resubscribe on connect
	for (const deviceId of devicesSubscribedToCommands) {
		socket.emit('subscribe-commands', deviceId);
	}
});
