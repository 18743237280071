import { useState } from 'react';
import { Box, Dialog, CircularProgress, DialogContent, Divider, Button, TextField, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import classNames from 'classnames';
import { Add } from '@mui/icons-material';

import { Status } from 'DataTypes';
import Templates from 'store/templates';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
		},
		box: {
			margin: `${theme.spacing(2)} 0`,
		},
		fromTemplate: {
			display: 'flex',
		},
		divider: {
			display: 'flex',
			width: '100%',
			alignItems: 'center',
			// color: theme.palette.divider,
			'&>hr': {
				flexGrow: 1,
			},
			margin: `${theme.spacing(2)} 0`,
		},
		dividerText: {
			margin: theme.spacing(2),
		},
		select: {
			marginRight: theme.spacing(2),
		},
	}),
);

interface INewTemplateDialogProps<T> {
	templates: Templates<T>;
	onClose: (createdTemplateId?: string) => void;
}

export default function NewTemplateDialog<T>({ templates, onClose }: INewTemplateDialogProps<T>) {
	const classes = useStyles();
	const [baseTemplateId, setBaseTemplateId] = useState<string | null>(
		templates.templates.length ? templates.templates[0].id : null,
	);

	return (
		<Dialog open={true} onClose={() => onClose()}>
			<DialogContent>
				<Box className={classes.container}>
					<Typography variant="overline">Extend existing template:</Typography>
					<Box className={classNames([classes.box, classes.fromTemplate])}>
						{templates.fetchStatus === Status.Loading ? (
							<CircularProgress />
						) : (
							<TextField
								value={baseTemplateId || ''}
								select={true}
								label="Base template"
								onChange={(event) => setBaseTemplateId(event.target.value || null)}
								margin="normal"
								variant="standard"
								SelectProps={{
									native: true,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								className={classes.select}
							>
								{templates.templates.map(({ id, data }) => (
									<option value={id} key={id}>
										{data?.name}
									</option>
								))}
							</TextField>
						)}
						<Button
							disabled={baseTemplateId === null}
							onClick={() => {
								if (!baseTemplateId) {
									return;
								}

								const newTemplate = templates.createTemplate(baseTemplateId);

								onClose(newTemplate.id);
							}}
							color="primary"
							variant="contained"
							startIcon={<Add />}
						>
							Create template
						</Button>
					</Box>
					<Box className={classes.divider}>
						<Divider />
						<Typography variant="overline" className={classes.dividerText}>
							or
						</Typography>
						<Divider />
					</Box>
					<Box className={classes.box}>
						<Button
							onClick={() => {
								const newTemplate = templates.createTemplate();

								onClose(newTemplate.id);
							}}
							color="primary"
							variant="contained"
							startIcon={<Add />}
						>
							Create blank template
						</Button>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
}
