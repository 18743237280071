import { useContext } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { Page, routesByName } from 'routing/routes';
import { useNavigate } from 'routing/routing';
import { UserContext } from 'store/UserProvider';

interface INavBarProps {
	open: boolean;
	onClose: () => void;
}

export default function NavBar({ open, onClose }: INavBarProps) {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	if (!user) {
		return null;
	}

	const pages = user.roles.includes('Administrator')
		? [Page.Entities, Page.Templates, Page.Replay, Page.Imports, Page.Exports, Page.Users]
		: user.roles.includes('Contributor')
		? [Page.Entities, Page.Templates, Page.Replay, Page.Exports]
		: [Page.Entities, Page.Templates, Page.Exports];

	return (
		<Drawer anchor="left" open={open} onClose={onClose}>
			<List>
				{pages.map((routeName) => {
					const route = routesByName[routeName];
					return (
						<ListItem
							button={true}
							key={route.path}
							onClick={() => {
								navigate(route.path);
								onClose();
							}}
						>
							<ListItemIcon>{route.icon}</ListItemIcon>
							<ListItemText primary={route.label} />
						</ListItem>
					);
				})}
			</List>
		</Drawer>
	);
}
