import { useState } from 'react';
import { Chip, Select, IconButton, InputAdornment, FormControl, InputLabel } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Add } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		tagsContainer: {
			flex: 1,
			height: 'auto',
			maxHeight: 'none',
			flexWrap: 'wrap',
			margin: '0.5rem 0',
		},
		input: {
			flexGrow: 1,
		},
		container: {
			marginRight: '1rem',
			minWidth: '30rem',
			'& select': {
				width: '10rem',
			},
			'& .MuiSelect-icon': {
				right: '71px',
			},
		},
		chip: {
			margin: '0.25rem',
		},
	}),
);

interface ITagsInputDropdownProps {
	value: string[];
	label?: string;
	select: { value: string; label: string }[];
	disabled?: boolean;
	onChange: (tags: string[]) => void;
}

export default function TagsInputDropdown({ value, label, select, disabled, onChange }: ITagsInputDropdownProps) {
	const classes = useStyles();
	const [input, setInput] = useState('');
	const variant = label ? 'outlined' : 'standard';

	const addTag = (tag: string) => {
		if (!value.includes(tag)) {
			onChange([...value, tag]);
		}
	};

	const removeTag = (tag: string) => {
		if (value.includes(tag)) {
			onChange(value.filter((t) => t !== tag));
		}
	};

	return (
		<FormControl variant={variant as any} className={classes.container}>
			<InputLabel shrink={true}>{label}</InputLabel>
			<Select
				value={input}
				label={label}
				native={true}
				onChange={(event) => setInput(event.target.value as string)}
				variant={variant as any}
				disabled={disabled}
				startAdornment={
					<InputAdornment position="start" className={classes.tagsContainer}>
						{value.map((v) => (
							<Chip
								key={v}
								label={select.find((o) => o.value === v)?.label || ''}
								onDelete={disabled ? undefined : () => removeTag(v)}
								className={classes.chip}
							/>
						))}
					</InputAdornment>
				}
				endAdornment={
					<InputAdornment position="end">
						<IconButton disabled={!input} onClick={() => addTag(input)}>
							<Add />
						</IconButton>
					</InputAdornment>
				}
			>
				<option value="">Select from list</option>
				{select.map(({ value: v, label: l }) => (
					<option key={v} value={v}>
						{l}
					</option>
				))}
			</Select>
		</FormControl>
	);
}
