import { observer } from 'mobx-react-lite';
import { Box, Divider, LinearProgress } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { Status } from 'DataTypes';
import EntityProperty from './EntityProperty';
import { DevicePointTemplate } from 'store/devicePointTemplate';
import DevicePointTemplatePropertiesDefinition from './DevicePointTemplatePropertiesDefinition';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			overflow: 'auto',
			flexGrow: 1,
			display: 'flex',
			flexDirection: 'column',
			minHeight: 0,
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
		properties: {
			margin: theme.spacing(2),
		},
	}),
);

interface IDevicePointTemplateDetailsProps {
	template: DevicePointTemplate;
	canEdit: boolean;
}

export default observer(function DevicePointTemplateDetails({ template, canEdit }: IDevicePointTemplateDetailsProps) {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			{template.dataRequest === Status.Loading && <LinearProgress />}
			{template.data && (
				<>
					<Box className={classes.properties}>
						<EntityProperty
							label="Template name"
							type="string"
							value={template.data?.name}
							onChange={(value) => template.data && (template.data.name = (value as string) || '')}
							className={classes.field}
							disabled={!canEdit}
						/>
					</Box>
					<Divider />
					<DevicePointTemplatePropertiesDefinition template={template} canEdit={canEdit} />
				</>
			)}
		</Box>
	);
});
