import { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import { Box, Divider, List, ListItem, ListItemText, Button } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';

import EntityView from './EntityView';
import { useStores } from 'store';
import { Add as AddIcon } from '@mui/icons-material';
import { IEntityData } from 'store/entity';
import { useParams, useNavigate } from 'routing/routing';
import { UserContext } from 'store/UserProvider';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			flex: 1,
			display: 'flex',
			height: '100%',
		},
		sideBarContainer: {
			display: 'flex',
			flexDirection: 'column',
			borderRight: '1px solid rgba(0, 0, 0, 0.12)',
			minWidth: '20vw',
			maxWidth: '25vw',
			height: '100%',
		},
		topBar: {
			display: 'flex',
		},
		button: {
			margin: theme.spacing(1),
		},
		unsaved: {
			color: theme.palette.secondary.dark,
		},
		deleted: {
			textDecoration: 'line-through',
		},
	}),
);

export default observer(function Clients() {
	const { entities } = useStores();
	const { entityId } = useParams();
	const navigate = useNavigate();
	const classes = useStyles();
	const { user } = useContext(UserContext);

	useEffect(() => {
		entities.fetchClients();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(
		() =>
			autorun(() => {
				if (!entityId) {
					return;
				}

				const entity = entities.addAndGetEntity(entityId);

				// Deselect entity if it is not a client
				if (entity && entity.isClient === false) {
					navigate(null, { entityId: null });
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[entityId],
	);

	const createClientEntity = () => {
		const entityData: IEntityData = {
			name: 'New client',
			properties: {},
			tags: ['client'],
			relations: {},
			externalMappings: {},
			templates: {},
			lifecycleStatus: 'Configuration',
		};
		const id = uuid();

		entities.addEntitiesFromUi([{ id, entityData }]);
		navigate(null, { entityId: id });
	};

	const canEdit = user?.roles.includes('Contributor') || false;

	return (
		<Box className={classes.container}>
			<Box className={classes.sideBarContainer}>
				{canEdit && (
					<>
						<Box className={classes.topBar}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<AddIcon />}
								onClick={() => createClientEntity()}
								className={classes.button}
							>
								Add client
							</Button>
						</Box>
						<Divider />
					</>
				)}
				<Box>
					<List>
						{entities.clients.map((client, index) => {
							return (
								<ListItem
									key={index}
									button
									selected={client.id === entityId}
									onClick={() => navigate(null, { entityId: client.id })}
								>
									<ListItemText
										primary={client.displayName}
										className={classNames({
											[classes.unsaved]: client.unsaved,
											[classes.deleted]: client.deleted,
										})}
									/>
								</ListItem>
							);
						})}
					</List>
				</Box>
			</Box>
			<Box sx={{ overflow: 'auto', flex: 1 }}>
				<EntityView />
			</Box>
		</Box>
	);
});
