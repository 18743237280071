import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { IApiDeviceCommand, IApiDeviceCommandStatus } from '@mitie/metadata-api-types';
import { Dialpad } from '@mui/icons-material';

import Entity from 'store/entity';
import { socket } from 'api/api';
import { subscribeToDeviceCommands, triggerDeviceCommand, unsubscribeFromDeviceCommands } from 'api/devices';
import EntityProperty from './EntityProperty';
import { AlertsContext } from 'store/AlertsProvider';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(2),
		},
		field: {},
	}),
);

interface IChannelDeviceCommandsProps {
	entity: Entity;
}

export default observer(function ChannelDeviceCommands({ entity }: IChannelDeviceCommandsProps) {
	const classes = useStyles();
	const { addAlert } = useContext(AlertsContext);
	const [showWriteDialog, setShowWriteDialog] = useState(false);
	const [writeValue, setWriteValue] = useState<number | boolean>(0);
	const [commandStatus, setCommandStatus] = useState<IApiDeviceCommandStatus>();

	useEffect(() => {
		const device = entity.parentDevice;
		const metricName = String(entity.getProperty('metric_name'));

		if (!device || !metricName) {
			return;
		}

		// Entity has changed, subscribe to new data
		function handleCommandStatus(commandStatus: IApiDeviceCommandStatus) {
			if (commandStatus.metric_name === metricName) {
				setCommandStatus(commandStatus);
			}
		}

		subscribeToDeviceCommands(device.id);
		socket.on('command-status', handleCommandStatus);

		return function cleanup() {
			if (device) {
				unsubscribeFromDeviceCommands(device.id);
			}

			socket.off('command-status', handleCommandStatus);
		};
	}, [entity]);

	const device = entity.parentDevice;
	const metricName = String(entity.getProperty('metric_name'));

	if (!device || !metricName) {
		return null;
	}

	const triggerWriteCommand = async (value: boolean | number) => {
		try {
			let commandStatus: IApiDeviceCommandStatus = {
				deviceId: device.id,
				metric_name: metricName,
				command: 'write',
				status: 'pending',
				status_text: 'Sending command to server',
			};
			setCommandStatus(commandStatus);

			const commandData: IApiDeviceCommand = {
				deviceId: device.id,
				metric_name: metricName,
				command: 'write',
				data: {
					value,
				},
			};
			commandStatus = await triggerDeviceCommand(commandData);
			setCommandStatus(commandStatus);
		} catch (error: any) {
			addAlert('error', 'Failed to trigger command');
			const commandStatus: IApiDeviceCommandStatus = {
				deviceId: device.id,
				metric_name: metricName,
				command: 'write',
				status: 'failed',
				status_text: 'Failed to send command to server',
			};
			setCommandStatus(commandStatus);
		}
	};

	const datatype = entity.getProperty('datatype');

	if (datatype !== 'number' && datatype !== 'boolean') {
		return <Typography>This channel doesn't support commands</Typography>;
	}

	const channelReportedProperties = device.deviceReportedConfigData?.channels
		? device.deviceReportedConfigData.channels.find((c) => c.metric_name === metricName)
		: undefined;
	const isLoading =
		commandStatus !== undefined && commandStatus.status !== 'success' && commandStatus.status !== 'failed';

	return (
		<Box className={classes.root}>
			{channelReportedProperties?.['writable'] ? (
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<LoadingButton
						loading={isLoading}
						loadingPosition="start"
						startIcon={<Dialpad />}
						onClick={() => setShowWriteDialog(true)}
						variant="outlined"
						sx={{ marginRight: '16px' }}
					>
						Write value...
					</LoadingButton>
					<Typography variant="body2">{commandStatus?.status_text || ''}</Typography>
				</Box>
			) : (
				<Typography>This channel doesn't support commands</Typography>
			)}
			<Dialog open={showWriteDialog} onClose={() => setShowWriteDialog(false)}>
				<DialogTitle>Change value</DialogTitle>
				<DialogContent>
					<EntityProperty
						label="Value"
						type={datatype}
						value={writeValue}
						onChange={(value) => setWriteValue(value as number | boolean)}
						className={classes.field}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowWriteDialog(false)} color="primary">
						Cancel
					</Button>
					<Button
						onClick={() => {
							triggerWriteCommand(writeValue);
							setShowWriteDialog(false);
						}}
						color="primary"
						autoFocus={true}
					>
						Send command
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
});
