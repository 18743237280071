import { useContext, useState } from 'react';
import {
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Select,
	FormControlLabel,
	Checkbox,
	TextField,
	FormControl,
	InputLabel,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { ITaskDefinition, ITaskParameters } from '@mitie/telemetry-api-types';
import { format } from 'date-fns';

import { Status } from 'DataTypes';
import * as TelemetryApi from '../api/telemetry';
import { AlertsContext } from 'store/AlertsProvider';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {},
		input: {
			margin: theme.spacing(1),
			display: 'block',
		},
	}),
);

interface ICreateTaskDialogProps {
	tasksDefinition: ITaskDefinition[];
	onClose: () => void;
}

function initialParameters(taskDefinition: ITaskDefinition) {
	return taskDefinition.parameters.reduce((parameters, current) => {
		if (current.default) {
			// Use default value if defined
			parameters[current.name] = current.default;
		} else if (current.type === 'Date') {
			// Use current date and time
			parameters[current.name] = format(new Date(), "yyyy-MM-dd'T'HH:mm");
		}

		return parameters;
	}, {} as ITaskParameters);
}

export default function CreateTaskDialog({ tasksDefinition, onClose }: ICreateTaskDialogProps) {
	const classes = useStyles();
	const [selectedTaskDefinition, setSelectedTaskDefinition] = useState(tasksDefinition[0]);
	const [requestStatus, setRequestStatus] = useState(Status.None);
	const [parameters, setParameters] = useState(initialParameters(selectedTaskDefinition) as ITaskParameters);
	const { addAlert } = useContext(AlertsContext);

	return (
		<Dialog open={true} onClose={() => onClose()}>
			<DialogTitle>New data replay task</DialogTitle>
			<DialogContent>
				<Box className={classes.container}>
					<FormControl className={classes.input}>
						<InputLabel htmlFor="task-type-input">Task type</InputLabel>
						<Select
							native={true}
							value={selectedTaskDefinition.orchestratorName}
							inputProps={{
								id: 'task-type-input',
							}}
							onChange={(e) => {
								const task = tasksDefinition.find((t) => t.orchestratorName === e.target.value);

								if (task) {
									setParameters(initialParameters(selectedTaskDefinition));
									setSelectedTaskDefinition(task);
								}
							}}
						>
							{tasksDefinition.map((def) => (
								<option key={def.orchestratorName} value={def.orchestratorName}>
									{def.displayName}
								</option>
							))}
						</Select>
					</FormControl>
					{selectedTaskDefinition.parameters.map((param) => {
						const value = parameters[param.name] !== undefined ? parameters[param.name] : param.default;

						switch (param.type) {
							case 'boolean':
								return (
									<FormControlLabel
										control={
											<Checkbox
												checked={Boolean(value)}
												onChange={(e) => {
													setParameters({ ...parameters, [param.name]: e.target.checked });
												}}
											/>
										}
										label={param.label}
										key={param.name}
										className={classes.input}
									/>
								);
							case 'Date':
								return (
									<TextField
										label={param.label}
										type="datetime-local"
										value={value ? format(new Date(String(value)), "yyyy-MM-dd'T'HH:mm") : undefined}
										InputLabelProps={{
											shrink: true,
										}}
										onChange={(e) => {
											const date = new Date(e.target.value);
											setParameters({ ...parameters, [param.name]: date.toISOString() });
										}}
										required={!param.optional}
										key={param.name}
										className={classes.input}
									/>
								);
							case 'number':
								return (
									<TextField
										label={param.label}
										type="number"
										value={value !== undefined ? Number(value) : undefined}
										InputLabelProps={{
											shrink: true,
										}}
										onChange={(e) => {
											setParameters({ ...parameters, [param.name]: Number(e.target.value) });
										}}
										required={!param.optional}
										key={param.name}
										className={classes.input}
									/>
								);
							default:
								return (
									<TextField
										label={param.label}
										value={value}
										onChange={(e) => {
											setParameters({ ...parameters, [param.name]: e.target.value });
										}}
										required={!param.optional}
										key={param.name}
										className={classes.input}
									/>
								);
						}
					})}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} color="primary">
					Cancel
				</Button>
				<Button
					onClick={async () => {
						setRequestStatus(Status.Loading);

						try {
							await TelemetryApi.createTask(selectedTaskDefinition.orchestratorName, parameters);
							setRequestStatus(Status.Done);
							onClose();
						} catch (error: any) {
							addAlert('error', 'Failed to create replay task');
							setRequestStatus(Status.Error);
						}
					}}
					color="primary"
					disabled={requestStatus === Status.Loading}
				>
					Create task
				</Button>
			</DialogActions>
		</Dialog>
	);
}
