import { IconButton, Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

interface ITooltipWithIconProps {
	tooltip: React.ReactChild;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		arrow: {
			color: theme.palette.grey[700],
		},
		tooltip: {
			backgroundColor: theme.palette.grey[700],
			whiteSpace: 'pre-line',
			fontSize: '80%',
		},
		iconButton: {
			marginLeft: theme.spacing(1),
		},
	}),
);

export default function TooltipWithIcon({ tooltip }: ITooltipWithIconProps) {
	const classes = useStyles();

	return (
		<Tooltip title={tooltip} classes={classes} arrow placement="right">
			<IconButton color="default" size="small" className={classes.iconButton}>
				<Info fontSize="inherit" />
			</IconButton>
		</Tooltip>
	);
}
