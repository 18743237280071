import { useEffect, useState } from 'react';
import { TextField, InputAdornment, Button } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';

import Entity from 'store/entity';
import { Status } from 'DataTypes';
import LocationRelationSelectDialog from './LocationRelationSelectDialog';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		input: {
			disabled: 'disabled',
		},
		container: {},
	}),
);

interface ILocationSelectProps {
	entity: Entity;
	label: string;
	className: string;
	disabled: boolean;
	onChange: (entityId: string | null) => void;
	hierarchy?: 'location' | 'gateway' | 'equip';
}

export default observer(function LocationSelect({
	entity,
	label,
	className,
	disabled,
	onChange,
	hierarchy,
}: ILocationSelectProps) {
	const classes = useStyles();
	const [popupOpen, setPopupOpen] = useState(false);

	useEffect(
		() =>
			autorun(() => {
				if (entity.locationParents) {
					for (const parent of entity.locationParents) {
						if (parent.dataRequest === Status.None) {
							parent.fetchData();
						}

						if (!parent.childrenRequest.location) {
							parent.fetchChildren('location');
						}
					}
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[entity],
	);

	const locationsList = (entity.locationParents || []).map((parent) => {
		return parent.displayName;
	});

	return (
		<>
			<TextField
				label={label}
				margin="normal"
				variant="outlined"
				className={className}
				disabled={disabled}
				value={locationsList.join(' > ')}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Button onClick={() => setPopupOpen(true)} disabled={disabled}>
								...
							</Button>
						</InputAdornment>
					),
					className: classes.input,
				}}
			/>
			{popupOpen && (
				<LocationRelationSelectDialog
					entity={entity}
					initialLocation={entity.parentLocation}
					onClose={(selected) => {
						if (selected !== undefined) {
							onChange(selected);
						}

						setPopupOpen(false);
					}}
					hierarchy={hierarchy}
				/>
			)}
		</>
	);
});
