import { useContext, useMemo } from 'react';
import { createTheme, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import { ColorModeContext } from 'store/ColorModeProvider';

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const mitiePurple = '#5b1f69';
const mitiePurpleLight = '#974892';
const mitiePurpleDark = '#421757';
const mitieRed = '#d71920';
const mitieRedLight = '#ea6c69';
const mitieRedDark = '#bc0007';

const lightTheme = createTheme({
	palette: {
		primary: {
			light: mitiePurpleLight,
			main: mitiePurple,
			dark: mitiePurpleDark,
			contrastText: '#ffffff',
		},
		secondary: {
			light: mitieRedLight,
			main: mitieRed,
			dark: mitieRedDark,
			contrastText: '#ffffff',
		},
		background: {
			default: '#f5f5f5',
		},
		mode: 'light',
	},
});

const darkTheme = createTheme({
	palette: {
		secondary: {
			light: mitieRedLight,
			main: mitieRed,
			dark: mitieRedDark,
			contrastText: '#000000',
		},
		background: {
			default: '#242424',
		},
		mode: 'dark',
	},
});

interface IThemeProps {
	children: any;
}

export default function CustomTheme(props: IThemeProps) {
	const { colorMode } = useContext(ColorModeContext);
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const theme = useMemo(() => {
		if (colorMode === 'auto') {
			return prefersDarkMode ? darkTheme : lightTheme;
		} else if (colorMode === 'dark') {
			return darkTheme;
		} else {
			return lightTheme;
		}
	}, [prefersDarkMode, colorMode]);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>{props.children}</ThemeProvider>
		</StyledEngineProvider>
	);
}
