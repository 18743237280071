import { useState } from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';

import EntitiesPickerDialog from './EntitiesPickerDialog';

interface IEntitiesPickerProps {
	label: string;
	value: string[];
	optional: boolean;
	entityType: string;
	disabled: boolean;
	onChange: (value: string[]) => void;
}

export default function EntitiesPicker({
	label,
	value,
	optional,
	entityType,
	disabled,
	onChange,
}: IEntitiesPickerProps) {
	const [isPickerOpen, setIsPickerOpen] = useState(false);

	return (
		<TextField
			variant="outlined"
			sx={{ marginRight: (theme) => theme.spacing(2), marginTop: (theme) => theme.spacing(2) }}
			label={label}
			value={value.length > 0 ? `${value.length} entities selected` : 'None selected'}
			InputProps={{
				endAdornment: (
					<InputAdornment
						position="end"
						sx={{
							height: 'auto',
							margin: '12px 8px',
						}}
					>
						<>
							<Button disabled={disabled} onClick={() => setIsPickerOpen(true)}>
								Pick...
							</Button>
							{isPickerOpen && (
								<EntitiesPickerDialog
									label={label}
									value={value}
									optional={optional}
									entityType={entityType}
									onClose={(value) => {
										if (value) {
											onChange(value);
										}

										setIsPickerOpen(false);
									}}
								/>
							)}
						</>
					</InputAdornment>
				),
				inputProps: {
					disabled: true,
				},
			}}
			InputLabelProps={{
				shrink: true,
			}}
		/>
	);
}
