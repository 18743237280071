import { IDeviceTemplate } from '@mitie/metadata-api-types';
import { action, makeObservable } from 'mobx';
import cloneDeep from 'lodash/cloneDeep';

import { Template } from './template';
import { stores } from 'store';
import { Status } from 'DataTypes';
import * as TemplatesApi from '../api/templates';

export class DeviceTemplate extends Template<IDeviceTemplate> {
	public constructor(id: string) {
		super(id, 'device');

		makeObservable(this, { save: action, discardChanges: action });
	}

	public async save() {
		if (this.unsaved) {
			this.saveRequest = Status.Loading;

			try {
				if (this.created && this.data) {
					const { templateData, updatedTime } = await TemplatesApi.createTemplate<IDeviceTemplate>(
						this.id,
						this.templateType,
						this.data,
					);
					this.setSavedData(templateData, updatedTime, true);
				} else if (this.deleted) {
					await TemplatesApi.deleteTemplate(this.id, this.templateType);

					stores.deviceTemplates.deleteTemplate(this);
					this.dataRequest = Status.Done;
				} else if (this.modified && this.data) {
					const { templateData, updatedTime } = await TemplatesApi.updateTemplate<IDeviceTemplate>(
						this.id,
						this.templateType,
						this.data,
					);
					this.setSavedData(templateData, updatedTime, true);
				}

				this.saveRequest = Status.Done;
			} catch (error: any) {
				this.saveRequest = Status.Error;
				throw error;
			}
		}
	}

	public discardChanges() {
		if (this.savedData) {
			this.data = cloneDeep(this.savedData);
		} else {
			stores.deviceTemplates.deleteTemplate(this);
		}
	}
}
