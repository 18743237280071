import { TemplateType, IApiTemplate, IApiTemplatesRequest, IApiTemplateUpdate } from '@mitie/metadata-api-types';

import { ITemplateData } from 'store/template';
import { get, post, delete_, put } from './api';

export async function createTemplate<T>(id: string, templateType: TemplateType, data: ITemplateData<T>) {
	const resp = await post<IApiTemplate<T>>('metadata/secure/templates', parseTemplateForApi(id, templateType, data));

	return { templateData: parseTemplateFromApi(resp), updatedTime: new Date(resp.modified_time) };
}

export function deleteTemplate(id: string, templateType: TemplateType) {
	return delete_<void>(`metadata/secure/templates/${templateType}/${id}`);
}

export async function fetchTemplates<T>(templateType: TemplateType, ids?: string[]) {
	const resp = await post<IApiTemplate<T>[]>('metadata/secure/templates/fetch', {
		template_type: templateType,
		ids,
	} as IApiTemplatesRequest);

	return resp.map((template) => ({
		id: template.id,
		templateData: parseTemplateFromApi<T>(template),
		updatedTime: new Date(template.modified_time),
	}));
}

export async function updateTemplate<T>(id: string, templateType: TemplateType, data: ITemplateData<T>) {
	const resp = await put<IApiTemplate<T>>(
		`metadata/secure/templates/${id}`,
		parseTemplateForApi(id, templateType, data),
	);

	return { templateData: parseTemplateFromApi<T>(resp), updatedTime: new Date(resp.modified_time) };
}

export async function fetchTemplateUsage(id: string, templateType: TemplateType) {
	const data = await get<{ count: number }>(`metadata/secure/templates/usage/${templateType}/${id}`);

	return data.count;
}

/**
 * Format the template data for REST update or create call
 * @param id Entity ID
 * @param data Entity data
 */
function parseTemplateForApi<T>(id: string, templateType: TemplateType, data: ITemplateData<T>): IApiTemplateUpdate<T> {
	return {
		id,
		name: data.name,
		template_type: templateType,
		template: data.template,
	};
}

/**
 * Parse template data received from the server
 * @param param0 Template data
 */

function parseTemplateFromApi<T>({ name, template }: IApiTemplate<T> | IApiTemplateUpdate<T>): ITemplateData<T> {
	return {
		name,
		template,
	};
}
