import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Box,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
	Typography,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Add, Delete } from '@mui/icons-material';
import { IDeviceCommandDefinition } from '@mitie/metadata-api-types';

import { DeviceTemplate } from 'store/deviceTemplate';
import DeviceCommandDefinition from './DeviceCommandDefinition';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexGrow: 1,
			minHeight: 0,
		},
		commandsList: {
			display: 'flex',
			flexDirection: 'column',
		},
		topBar: {
			display: 'flex',
		},
		list: {
			display: 'flex',
			minHeight: 0,
		},
		grow: {
			flexGrow: 1,
		},
		titleText: {
			lineHeight: '48px',
			marginRight: theme.spacing(2),
			marginLeft: theme.spacing(2),
		},
	}),
);

interface IDeviceCommandsDefinitionProps {
	template: DeviceTemplate;
	canEdit: boolean;
}

export default observer(function DeviceCommandsDefinition({ template, canEdit }: IDeviceCommandsDefinitionProps) {
	const classes = useStyles();
	const [selectedCommandIndex, setSelectedCommandIndex] = useState<number | null>(null);

	const updateCommand = (data: IDeviceCommandDefinition) => {
		if (!template.data?.template.commands || selectedCommandIndex === null) {
			return;
		}

		template.data.template.commands[selectedCommandIndex] = data;
	};

	const addCommand = () => {
		if (!template.data) {
			return;
		}

		const data: IDeviceCommandDefinition = {
			label: 'New command',
			name: 'command-name',
		};

		if (!template.data.template.commands) {
			template.data.template.commands = [data];
		} else {
			template.data.template.commands.push(data);
		}

		setSelectedCommandIndex(template.data.template.commands.length - 1);
	};

	const deleteCommand = (index: number) => {
		if (!template.data?.template.commands) {
			return;
		}

		template.data.template.commands.splice(index, 1);

		if (index === selectedCommandIndex) {
			setSelectedCommandIndex(null);
		}
	};

	return (
		<Box className={classes.root}>
			{template.data && (
				<>
					<Box className={classes.commandsList}>
						<Box className={classes.topBar}>
							<Typography variant="h6" className={classes.titleText}>
								Device commands
							</Typography>
							{canEdit && (
								<>
									<Box className={classes.grow} />
									<Tooltip title="Add command" placement="bottom">
										<IconButton color="secondary" onClick={addCommand}>
											<Add />
										</IconButton>
									</Tooltip>
								</>
							)}
						</Box>
						<Divider />
						<Box className={classes.list}>
							<Box sx={{ overflow: 'auto', minWidth: '20rem' }}>
								<List sx={{ padding: 0 }}>
									{template.data.template.commands?.map((commandDefinition, index) => (
										<ListItem
											button={true}
											key={commandDefinition.name}
											selected={index === selectedCommandIndex}
											autoFocus={index === selectedCommandIndex}
											onClick={() => setSelectedCommandIndex(index)}
										>
											<ListItemText
												sx={{ whiteSpace: 'nowrap' }}
												primary={<Typography variant="subtitle1">{commandDefinition.label}</Typography>}
											></ListItemText>
											{canEdit && (
												<ListItemSecondaryAction>
													<IconButton edge="end" onClick={() => deleteCommand(index)}>
														<Delete />
													</IconButton>
												</ListItemSecondaryAction>
											)}
										</ListItem>
									))}
								</List>
							</Box>
						</Box>
					</Box>
					<Divider orientation="vertical" />
					{selectedCommandIndex !== null && template.data.template.commands?.[selectedCommandIndex] && (
						<DeviceCommandDefinition
							data={template.data.template.commands[selectedCommandIndex]}
							setData={updateCommand}
							canEdit={canEdit}
						/>
					)}
				</>
			)}
		</Box>
	);
});
