import { useState } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { IConsistencyException } from '@mitie/metadata-api-types';

import EntityPropertyWithComparison from './EntityPropertyWithComparison';
import { useStores } from 'store';
import { EntityTemplate } from 'store/entityTemplate';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {},
	}),
);

interface IConsistencyExceptionReviewDialogProps {
	id: string;
	item: IConsistencyException;
	onClose: () => void;
}

interface IExceptionFormData {
	[masterField: string]: {
		value?: string | number | boolean | null;
		masterValue?: string | number | boolean | null;
		otherValues: { otherField: string; otherValue?: string | number | boolean | null }[];
	};
}

export default function ConsistencyExceptionReviewDialog({
	id,
	item,
	onClose,
}: IConsistencyExceptionReviewDialogProps) {
	const classes = useStyles();
	const { entities, entityTemplates } = useStores();

	const [formData, setFormData] = useState<IExceptionFormData>(
		item.differences.reduce((acc, { masterField, masterValue, otherField, otherValue }) => {
			if (!acc[masterField]) {
				acc[masterField] = {
					value: masterValue,
					masterValue: masterValue,
					otherValues: [],
				};
			}

			acc[masterField].otherValues.push({ otherField, otherValue });

			if (acc[masterField].value === undefined) {
				acc[masterField].value = otherValue;
			}

			return acc;
		}, {} as IExceptionFormData),
	);

	let template: EntityTemplate | undefined;
	const entity = entities.getEntity(id);
	const templateId = entity?.data?.templates.entity;

	if (templateId) {
		template = entityTemplates.getTemplate(templateId);
	}

	const handleChange = (field: string, value: string | number | boolean | undefined) => {
		const data = { ...formData };
		data[field].value = value;
		setFormData(data);
	};

	const handleValidate = () => {
		if (!entity || !entity.data) {
			return;
		}

		for (const field of Object.keys(formData)) {
			const value = formData[field].value;

			if (value !== undefined && value !== null) {
				if (field === 'name') {
					entity.data.name = String(value);
				} else {
					entity.data.properties[field] = value;
				}
			}
		}

		onClose();
	};

	return (
		<Dialog open={true} onClose={onClose} maxWidth="lg">
			<DialogTitle>Review data consistency exception</DialogTitle>
			<DialogContent>
				<Box className={classes.root}>
					{Object.keys(formData).map((masterField) => {
						const { value, masterValue, otherValues } = formData[masterField];
						const templateValue = template?.data?.template.properties.find((prop) => prop.name === masterField);

						return (
							<EntityPropertyWithComparison
								key={masterField}
								label={templateValue?.label || masterField}
								type={templateValue?.type || 'string'}
								value={value === null ? undefined : value}
								masterValue={masterValue === null ? undefined : masterValue}
								otherValues={otherValues}
								select={templateValue?.select}
								onChange={(v) => handleChange(masterField, v)}
							/>
						);
					})}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Cancel
				</Button>
				<Button color="primary" onClick={handleValidate}>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
}
