import { TemplateType } from '@mitie/metadata-api-types';
import { stringify } from 'qs';
import { List, Replay as ReplayIcon } from '@mui/icons-material';
import { AccountMultiple, ApplicationExport, ApplicationImport, Bookshelf } from 'mdi-material-ui';

import Entities from 'pages/Entities';
import Templates from 'pages/Templates';
import Replay from 'pages/Replay';
import Exports from 'pages/Exports';
import Imports from 'pages/Imports';
import { TabName } from 'components/EntityView';
import NotFound from 'pages/NotFound';
import Unauthenticated from 'pages/Unauthenticated';
import Users from 'pages/Users';

const entitiesTypes = ['clients', 'locations', 'devices', 'assets'] as const;
export type EntitiesType = typeof entitiesTypes[number];

export enum Page {
	Entities = '/entities',
	Templates = '/templates',
	Replay = '/replay',
	Imports = '/imports',
	Exports = '/exports',
	Unauthenticated = '/unauthorised',
	Users = '/users',
	NotFound = '*',
}

export type Params = {
	entityId?: string;
	selectedTab?: TabName;
	entitiesType?: EntitiesType;
	locationId?: string;
	deviceId?: string;
	templatesType?: TemplateType;
	templateId?: string;
	userId?: string;
};

export interface IRoute {
	path: Page;
	component: React.ReactElement;
	icon?: React.ReactNode;
	label: string;
	secured: boolean;
	getUrl?: (params: Params) => string;
}

function qs(obj: unknown): string {
	return stringify(obj, { addQueryPrefix: true });
}

export const routes: IRoute[] = [
	{
		path: Page.Entities,
		component: <Entities />,
		label: 'Master data',
		icon: <List />,
		secured: true,
		getUrl: ({ entitiesType, entityId, locationId, deviceId, selectedTab }: Params) =>
			Page.Entities +
			qs({
				entitiesType,
				entityId,
				locationId,
				deviceId,
				selectedTab,
			}),
	},
	{
		path: Page.Templates,
		component: <Templates />,
		label: 'Templates',
		icon: <Bookshelf />,
		secured: true,
		getUrl: ({ templatesType, templateId }): string =>
			Page.Templates +
			qs({
				templatesType,
				templateId,
			}),
	},
	{
		path: Page.Replay,
		component: <Replay />,
		label: 'Telemetry data replay',
		icon: <ReplayIcon />,
		secured: true,
	},
	{
		path: Page.Imports,
		component: <Imports />,
		label: 'Import data',
		icon: <ApplicationImport />,
		secured: true,
	},
	{
		path: Page.Exports,
		component: <Exports />,
		label: 'Export data',
		icon: <ApplicationExport />,
		secured: true,
	},
	{
		path: Page.Users,
		component: <Users />,
		label: 'Users management',
		icon: <AccountMultiple />,
		secured: true,
		getUrl: ({ userId }): string =>
			Page.Users +
			qs({
				userId,
			}),
	},
	{
		path: Page.NotFound,
		component: <NotFound />,
		label: 'Page not found',
		secured: true,
	},
	{
		label: 'Unauthorised',
		path: Page.Unauthenticated,
		component: <Unauthenticated />,
		secured: false,
	},
];

export const routesByName = routes.reduce((acc, cur) => {
	acc[cur.path] = cur;
	return acc;
}, {} as Record<Page, IRoute>);

export const routesPaths = Object.values(Page).map((path) => ({ path, element: null, caseSensitive: true }));

export function getParams({
	entitiesType,
	entityId,
	locationId,
	deviceId,
	selectedTab,
	templatesType,
	templateId,
	userId,
}: Record<keyof Params, string>): Params {
	return {
		entitiesType: entitiesTypes.includes(entitiesType as EntitiesType) ? (entitiesType as EntitiesType) : undefined,
		entityId,
		locationId,
		deviceId,
		selectedTab: selectedTab as TabName,
		templatesType: templatesType as TemplateType,
		templateId,
		userId,
	};
}
