import { BrowserRouter as Router } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import App from './App';
import { stores, StoreContext } from './store';
import UserProvider from 'store/UserProvider';
import ColorModeProvider from 'store/ColorModeProvider';
import AlertsProvider from 'store/AlertsProvider';

const AppContainer = () => (
	<DndProvider backend={HTML5Backend}>
		<StoreContext.Provider value={stores}>
			<ColorModeProvider>
				<UserProvider>
					<AlertsProvider>
						<Router basename={process.env.REACT_APP_DIRECTORY_NAME}>
							<App />
						</Router>
					</AlertsProvider>
				</UserProvider>
			</ColorModeProvider>
		</StoreContext.Provider>
	</DndProvider>
);

export default AppContainer;
