import { useEffect } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, LinearProgress, Tooltip, Typography } from '@mui/material';
import { Info } from '@mui/icons-material';

import Entity from 'store/entity';
import { Status } from 'DataTypes';
import DeviceTwinProperty from './DeviceTwinProperty';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
		sectionTitle: {
			marginTop: '1rem',
			display: 'flex',
			alignItems: 'center',
			'&>p': {
				fontSize: '16px',
				fontWeight: 500,
				display: 'inline',
				marginRight: '1rem',
			},
		},
	}),
);

interface IChannelDeviceTwinProps {
	entity: Entity;
	canEdit: boolean;
}

export default observer(function ChannelDeviceTwin({ entity, canEdit }: IChannelDeviceTwinProps) {
	const classes = useStyles();
	useEffect(
		() =>
			autorun(() => {
				if (!entity.parentDevice) {
					return;
				}

				entity.parentDevice.fetchDeviceConfigData();
				entity.parentDevice.fetchDeviceReportedConfigData();
				entity.parentDevice.fetchDeviceStatus();
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[entity.parentDevice],
	);

	const parent = entity.parentDevice;

	if (!parent) {
		return null;
	}

	const metricName = String(entity.getProperty('metric_name'));
	const channelDesiredProperties = parent.deviceConfigData?.channels
		? parent.deviceConfigData.channels.find((c) => c.metric_name === metricName)
		: undefined;
	const channelReportedProperties = parent.deviceReportedConfigData?.channels
		? parent.deviceReportedConfigData.channels.find((c) => c.metric_name === metricName)
		: undefined;
	const lastReported = parent.deviceReportedConfigData?.modifiedTime;

	return (
		<Box className={classes.root}>
			{parent.deviceDataRequest === Status.Loading && <LinearProgress />}
			<Box className={classes.sectionTitle}>
				<Typography>Twin properties</Typography>
				<Tooltip
					title={
						lastReported ? `Last reported by device: ${lastReported.toLocaleString()}` : 'No configuration reported yet'
					}
					placement="right"
				>
					<Info sx={{ cursor: 'pointer' }} color="action" />
				</Tooltip>
			</Box>
			{entity.deviceChannelTemplate?.data?.template.properties && parent.deviceConfigData && (
				<Box>
					{entity.deviceChannelTemplate.data.template.properties
						.filter((p) => p.include_in_device_twin)
						.map((propertyDefinition) => {
							const desiredProperty = channelDesiredProperties?.[propertyDefinition.name];
							const reportedProperty = channelReportedProperties?.[propertyDefinition.name];

							return (
								<DeviceTwinProperty
									key={propertyDefinition.name}
									label={propertyDefinition.label}
									type={propertyDefinition.type}
									select={propertyDefinition.select}
									value={desiredProperty}
									reported={reportedProperty}
									disabled={propertyDefinition.name === 'metric_name' || !canEdit}
									onChange={(value) => {
										parent.setChannelDeviceConfigProperty(metricName, propertyDefinition.name, value);
									}}
									className={classes.field}
								/>
							);
						})}
				</Box>
			)}
		</Box>
	);
});
