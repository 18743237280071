import { useContext, useState } from 'react';
import {
	AppBar,
	Box,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
	Tooltip,
	CircularProgress,
	Divider,
	Avatar,
	ToggleButton,
	ToggleButtonGroup,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import {
	Menu as MenuIcon,
	OfflineBoltOutlined,
	NotificationImportantOutlined,
	Brightness7,
	SettingsBrightness,
	Brightness4,
} from '@mui/icons-material';

import { useStores } from 'store';
import { UserContext } from 'store/UserProvider';
import { initials } from 'utils';
import { ColorModeContext } from 'store/ColorModeProvider';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		grow: {
			flexGrow: 1,
		},
		menuButton: {
			marginLeft: -12,
			marginRight: 20,
		},
		notificationButtonProgress: {
			color: theme.palette.secondary.main,
			position: 'relative',
			left: -48,
			zIndex: -1,
		},
	}),
);

interface IHeaderBarProps {
	onNavBarToggle: () => void;
	pageName: string;
}

export default observer(function HeaderBar({ onNavBarToggle, pageName }: IHeaderBarProps) {
	const { globals } = useStores();
	const { user, logout } = useContext(UserContext);
	const classes = useStyles();
	const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<HTMLElement | null>(null);
	const { colorMode, setColorMode } = useContext(ColorModeContext);

	const openMenu = (e: React.MouseEvent<HTMLElement>) => {
		setUserMenuAnchorEl(e.currentTarget);
	};

	const closeMenu = () => {
		setUserMenuAnchorEl(null);
	};

	return (
		<AppBar>
			<Toolbar>
				<IconButton color="inherit" aria-label="Open menu" onClick={onNavBarToggle}>
					<MenuIcon />
				</IconButton>
				<Typography variant="h6" color="inherit" noWrap={true} sx={{ marginLeft: (theme) => theme.spacing(1) }}>
					{pageName}
				</Typography>
				<Box className={classes.grow} />
				{globals.connected === false && (
					<IconButton>
						<OfflineBoltOutlined color="error" />
					</IconButton>
				)}
				{globals.savePendingCount > 0 && (
					<>
						<Tooltip title={`Saving in progress. ${globals.savePendingCount} entities pending`}>
							<IconButton color="inherit">
								{/* <Badge badgeContent={256} color="secondary" max={}> */}
								<NotificationImportantOutlined />
								{/* </Badge> */}
							</IconButton>
						</Tooltip>
						<CircularProgress
							size={48}
							className={classes.notificationButtonProgress}
							variant="determinate"
							value={globals.saveProgress}
						/>
					</>
				)}
				<IconButton sx={{ marginLeft: (theme) => theme.spacing(1) }} onClick={openMenu} color="inherit">
					{user?.photo ? (
						<Avatar src={user.photo} alt="avatar" />
					) : (
						<Avatar>{!user ? '?' : initials(user.profile.name)}</Avatar>
					)}
				</IconButton>
				<Menu
					anchorEl={userMenuAnchorEl}
					keepMounted={true}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={Boolean(userMenuAnchorEl)}
					onClose={closeMenu}
				>
					<Typography variant="subtitle2" sx={{ padding: '0.5rem 1rem' }}>
						{user?.profile.name}
					</Typography>
					<Divider />
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ marginLeft: (theme) => theme.spacing(2) }}>Colour mode:</Typography>
						<ToggleButtonGroup
							exclusive
							value={colorMode}
							onChange={(event, mode) => {
								if (mode) {
									setColorMode(mode);
								}
							}}
							sx={{ margin: (theme) => theme.spacing(1) }}
						>
							<ToggleButton value="light">
								<Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
									<Brightness7 sx={{ marginRight: (theme) => theme.spacing(1) }} />
									Light
								</Box>
							</ToggleButton>
							<ToggleButton value="auto">
								<Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
									<SettingsBrightness sx={{ marginRight: (theme) => theme.spacing(1) }} />
									System
								</Box>
							</ToggleButton>
							<ToggleButton value="dark">
								<Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
									<Brightness4 sx={{ marginRight: (theme) => theme.spacing(1) }} />
									Dark
								</Box>
							</ToggleButton>
						</ToggleButtonGroup>
					</Box>
					<Divider />
					<MenuItem
						onClick={() => {
							closeMenu();
							logout();
						}}
					>
						Logout
					</MenuItem>
				</Menu>
			</Toolbar>
		</AppBar>
	);
});
