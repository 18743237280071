import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, LinearProgress, TextField, InputAdornment, Button } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { autorun } from 'mobx';
import { RouterOutlined } from '@mui/icons-material';

import { Status } from 'DataTypes';
import DeviceSelectPopup from './DeviceSelectPopup';
import { useStores } from 'store';
import { useParams, useNavigate } from 'routing/routing';
import EntityTreeItem from './EntityTreeItem';
import Entity from 'store/entity';
import { ReactComponent as PointFilledIcon } from 'icons/pointFilled.svg';
import useDebounce from 'hooks/useDebounce';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			overflow: 'auto',
			padding: theme.spacing(1),
		},
		tree: {
			listStyleType: 'none',
			margin: 0,
			padding: 0,
		},
		textfield: {
			width: '100%',
			paddingBottom: theme.spacing(1),
		},
		input: {
			disabled: 'disabled',
			'&>input': {
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				direction: 'rtl',
				textAlign: 'left',
			},
		},
	}),
);

interface IDeviceTreeWithFilterProps {
	selected: string | null;
	onSelect: (entityId: string | null) => void;
	mappingModeEnabled?: boolean;
}

interface ITreeNode {
	entity: Entity;
	children: ITreeNode[];
	forceExpanded?: boolean;
}

export default observer(function DeviceTreeWithFilter({
	selected,
	onSelect,
	mappingModeEnabled,
}: IDeviceTreeWithFilterProps) {
	const classes = useStyles();
	const { entities } = useStores();
	const { deviceId } = useParams();
	const navigate = useNavigate();
	const [popupOpen, setPopupOpen] = useState(false);
	const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
	const [filter, setFilter] = useState<string>('');
	const debouncedFilter = useDebounce(filter, 100);

	useEffect(
		() =>
			autorun(() => {
				if (!deviceId) {
					return;
				}

				const device = entities.addAndGetEntity(deviceId);

				// Fetch data for selected device
				if (device.dataRequest === Status.None) {
					device.fetchData();
				}

				entities.fetchEntitiesDescendants('gateway', deviceId);
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[deviceId],
	);

	useEffect(
		() =>
			autorun(() => {
				// Makes sure that the parents of the selected entity are always expanded
				if (!selected) {
					return;
				}

				const selectedEntity = entities.addAndGetEntity(selected);

				if (selectedEntity.parentDeviceIds.length > 0) {
					setExpandedNodes((current) => [...new Set([...current, ...selectedEntity.parentDeviceIds])]);
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selected],
	);

	const onNodeExpand = (nodeId: string) => {
		setExpandedNodes((current) => [...new Set([...current, nodeId])]);
	};

	const onNodeCollapse = (nodeId: string) => {
		const newExpandedNodes = expandedNodes.filter((n) => n !== nodeId);

		setExpandedNodes(newExpandedNodes);
	};

	const rootDevice = deviceId ? entities.addAndGetEntity(deviceId) : undefined;

	let rootDevicePath = '';

	if (rootDevice) {
		if (rootDevice.deviceParents && rootDevice.deviceParents.length > 0) {
			rootDevicePath = rootDevice.deviceParents?.map((d) => d.displayName).join(' > ') + ' > ';
		}

		rootDevicePath += rootDevice.displayName;
	} else {
		rootDevicePath = 'Choose device';
	}

	const generateTreeNode = (entity: Entity, parentNodes: ITreeNode[]): ITreeNode => {
		const children: ITreeNode[] = [];
		const node: ITreeNode = { entity, children, forceExpanded: false };

		if (entity.children.gateway) {
			for (const child of entity.children.gateway) {
				children.push(generateTreeNode(child, [...parentNodes, node]));
			}
		}

		if (entity.children.device) {
			for (const child of entity.children.device) {
				let includedInFilter = false;

				if (debouncedFilter && child.displayName.toLowerCase().includes(debouncedFilter.toLowerCase())) {
					includedInFilter = true;
					node.forceExpanded = true;

					for (const parentNode of parentNodes) {
						parentNode.forceExpanded = true;
					}
				}

				if (!debouncedFilter || includedInFilter) {
					children.push({ entity: child, children: [] });
				}
			}
		}

		return node;
	};

	const treeData: ITreeNode | undefined = rootDevice ? generateTreeNode(rootDevice, []) : undefined;

	const renderTree = (treeNodes: ITreeNode[]) => {
		return treeNodes.map(({ entity, children, forceExpanded }) => {
			if (entity.isPoint) {
				// Show as disabled if point is not linked to a parent entity
				const disabled = mappingModeEnabled ? entity.data?.relations.entity !== undefined : false;

				return (
					<EntityTreeItem
						nodeId={entity.id}
						key={entity.id}
						label={entity.displayName}
						selectable={true}
						unsaved={entity.unsaved}
						deleted={entity.deleted}
						icon={<PointFilledIcon />}
						selected={selected === entity.id}
						onSelect={() => onSelect(entity.id)}
						isLeaf={true}
						disabled={disabled}
						canDrag={mappingModeEnabled && !disabled}
						datatype={entity.getProperty('datatype') as 'number' | 'boolean' | undefined}
						lifecycleStatus={entity.data?.lifecycleStatus}
					/>
				);
			} else {
				return (
					<EntityTreeItem
						nodeId={entity.id}
						key={entity.id}
						label={entity.displayName}
						selectable={true}
						selected={selected === entity.id}
						expanded={forceExpanded || expandedNodes.includes(entity.id)}
						onExpand={() => onNodeExpand(entity.id)}
						onCollapse={() => onNodeCollapse(entity.id)}
						onSelect={() => onSelect(entity.id)}
						unsaved={entity.unsaved}
						deleted={entity.deleted}
						icon={<RouterOutlined />}
					>
						{renderTree(children)}
					</EntityTreeItem>
				);
			}
		});
	};

	return (
		<Box className={classes.container}>
			<TextField
				variant="outlined"
				className={classes.textfield}
				value={rootDevicePath}
				label="Device filter"
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Button onClick={() => setPopupOpen(true)}>...</Button>
						</InputAdornment>
					),
					className: classes.input,
					disabled: true,
				}}
			/>
			<TextField
				variant="outlined"
				className={classes.textfield}
				label="Channels filter"
				value={filter}
				onChange={(e) => setFilter(e.target.value)}
			/>
			{popupOpen && (
				<DeviceSelectPopup
					title="Select device"
					allowEmpty={false}
					device={rootDevice}
					onClose={(selected) => {
						if (selected) {
							navigate(null, { deviceId: selected, entityId: null });
						}

						setPopupOpen(false);
					}}
				/>
			)}
			{treeData && rootDevice && (
				<>
					{rootDevice.descendantsRequest.gateway === Status.Loading && <LinearProgress />}
					<ul className={classes.tree}>{renderTree(treeData.children)}</ul>
				</>
			)}
		</Box>
	);
});
