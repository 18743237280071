import { TextField, InputAdornment, Tooltip, IconButton } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import classNames from 'classnames';
import { TemplatePropertyType } from '@mitie/metadata-api-types';
import { Done, ErrorOutline, HelpOutline } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		green: {
			color: '#009900',
		},
	}),
);

interface IDeviceTwinPropertyProps {
	value?: string | number | boolean | null;
	reported?: string | number | boolean | null;
	label: string;
	type: TemplatePropertyType;
	select?: Array<{ value: number | string; label: string }>;
	className: string;
	disabled?: boolean;
	onChange: (v: number | string | boolean | null) => void;
}

export default function DeviceTwinProperty({
	value,
	reported,
	label,
	type,
	select,
	className,
	disabled,
	onChange,
}: IDeviceTwinPropertyProps) {
	const classes = useStyles();

	const match = value === undefined || value === null ? undefined : value === reported;

	const reportedTooltip =
		value === undefined || value === null
			? 'Value not set'
			: reported === undefined
			? 'No reported value'
			: `Reported value: ${reported}`;

	const parseValue = (value: string) => {
		if (value === '') {
			return null;
		}

		switch (type) {
			case 'boolean':
				return value === '1' ? true : false;
			case 'number':
				const numberValue = parseFloat(value);
				return isNaN(numberValue) ? 0 : numberValue;
			default:
				return value;
		}
	};

	const parseInputValue = (value?: string | boolean | number | null) => {
		if (value === undefined) {
			value = null;
		}

		switch (type) {
			case 'boolean':
				return value ? '1' : '0';
			case 'number':
				return Number(value);
			default:
				return value || '';
		}
	};

	const desiredValue = parseInputValue(value ? value : null);

	if (type === 'boolean') {
		return (
			<TextField
				value={desiredValue}
				select={true}
				label={label}
				type={type}
				onChange={(e) => onChange(parseValue(e.target.value))}
				margin="normal"
				variant="outlined"
				className={classNames([className])}
				disabled={disabled}
				SelectProps={{
					native: true,
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Tooltip title={reportedTooltip}>
								<IconButton>
									{match === undefined ? (
										<HelpOutline />
									) : match ? (
										<Done className={classes.green} />
									) : (
										<ErrorOutline color="error" />
									)}
								</IconButton>
							</Tooltip>
						</InputAdornment>
					),
				}}
				InputLabelProps={{
					shrink: true,
				}}
			>
				<option value="">Not set</option>
				<option value="0">No</option>
				<option value="1">Yes</option>
			</TextField>
		);
	} else if (select) {
		return (
			<TextField
				value={desiredValue}
				select={true}
				label={label}
				type={type}
				onChange={(e) => onChange(parseValue(e.target.value))}
				margin="normal"
				variant="outlined"
				className={classNames([className])}
				disabled={disabled}
				SelectProps={{
					native: true,
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Tooltip title={reportedTooltip}>
								<IconButton>
									{match === undefined ? (
										<HelpOutline />
									) : match ? (
										<Done className={classes.green} />
									) : (
										<ErrorOutline color="error" />
									)}
								</IconButton>
							</Tooltip>
						</InputAdornment>
					),
				}}
				InputLabelProps={{
					shrink: true,
				}}
			>
				<option value="">Not set</option>
				{select.map(({ value, label: l }) => (
					<option key={value} value={value}>
						{l}
					</option>
				))}
			</TextField>
		);
	} else {
		return (
			<TextField
				value={desiredValue}
				label={label}
				type={type}
				onChange={(e) => onChange(parseValue(e.target.value))}
				margin="normal"
				variant="outlined"
				className={classNames([className])}
				disabled={disabled}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Tooltip title={reportedTooltip}>
								<IconButton>
									{match === undefined ? (
										<HelpOutline />
									) : match ? (
										<Done className={classes.green} />
									) : (
										<ErrorOutline color="error" />
									)}
								</IconButton>
							</Tooltip>
						</InputAdornment>
					),
				}}
			/>
		);
	}
}
