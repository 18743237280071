import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, LinearProgress, TextField, InputAdornment, Button } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { autorun, runInAction } from 'mobx';
import { Factory } from 'mdi-material-ui';

import { Status } from 'DataTypes';
import LocationSelectPopup from './LocationSelectPopup';
import { useStores } from 'store';
import { useParams, useNavigate } from 'routing/routing';
import EntityTreeItem from './EntityTreeItem';
import Entity from 'store/entity';
import { EntityPointTemplate } from 'store/entityPointTemplate';
import { ReactComponent as PointFilledIcon } from 'icons/pointFilled.svg';
import { ReactComponent as PointUnfilledIcon } from 'icons/pointUnfilled.svg';
import { bindEntityToPoint, matchSimilarPoints } from 'tools/tagging';
import TaggingOptionsDialog, { ITaggingOptions } from './TaggingOptionsDialog';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			overflow: 'auto',
			padding: theme.spacing(1),
		},
		tree: {
			listStyleType: 'none',
			margin: 0,
			padding: 0,
		},
		locationSelect: {
			width: '100%',
		},
		input: {
			disabled: 'disabled',
			'&>input': {
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				direction: 'rtl',
				textAlign: 'left',
			},
		},
		taggingOptions: {
			display: 'flex',
			flexDirection: 'column',
		},
	}),
);

interface IAssetTreeProps {
	selected: string | null;
	onSelect: (entityId: string | null) => void;
	mappingModeEnabled?: boolean;
}

export default observer(function AssetTree({ selected, onSelect, mappingModeEnabled }: IAssetTreeProps) {
	const classes = useStyles();
	const { entities, entityPointTemplates } = useStores();
	const { locationId, deviceId } = useParams();
	const navigate = useNavigate();
	const [popupOpen, setPopupOpen] = useState(false);
	const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
	const [showTaggingOptions, setShowTaggingOptions] = useState(false);
	const [taggingOptions, setTaggingOptions] = useState<ITaggingOptions>({
		predictiveTaggingEnabled: false,
		enableInterval: true,
		interval: 300,
		assetExternalMapping: {},
		pointExternalMapping: {},
	});

	useEffect(
		() =>
			autorun(() => {
				if (!locationId) {
					return;
				}

				const location = entities.addAndGetEntity(locationId);

				// Fetch data for selected location
				if (location.dataRequest === Status.None) {
					location.fetchData();
				}

				if (!location.childrenRequest.location) {
					location.fetchChildren('location');
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[locationId],
	);

	useEffect(
		() =>
			autorun(() => {
				// Makes sure that the parents of the selected entity are always expanded
				if (!selected) {
					return;
				}

				const selectedEntity = entities.addAndGetEntity(selected);

				if (selectedEntity.parentEquipIds.length > 0) {
					setExpandedNodes((current) => [...new Set([...current, ...selectedEntity.parentEquipIds])]);
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selected],
	);

	useEffect(() => {
		if (locationId) {
			entities.fetchEntitiesDescendants('location', locationId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locationId]);

	useEffect(() => {
		// Show tagging options when tagging mode is enabled
		if (mappingModeEnabled) {
			setShowTaggingOptions(true);
		}
	}, [mappingModeEnabled]);

	const location = locationId ? entities.addAndGetEntity(locationId) : undefined;

	const onNodeExpand = (nodeId: string) => {
		setExpandedNodes((current) => [...new Set([...current, nodeId])]);
	};

	const onNodeCollapse = (nodeId: string) => {
		const newExpandedNodes = expandedNodes.filter((n) => n !== nodeId);

		setExpandedNodes(newExpandedNodes);
	};

	const bindEntityWithPoint = (entity: Entity, pointId: string, entityPointTemplate: EntityPointTemplate) => {
		const point = entities.getEntity(pointId);

		if (!point?.data) {
			return;
		}

		if (point.data.relations.entity) {
			throw new Error('Unable to bind device channel to entity: This channel is already bound');
		}

		runInAction(async () => {
			if (!point.data || !entity.data) {
				return;
			}

			bindEntityToPoint(entity, point, entityPointTemplate, taggingOptions);
		});

		if (taggingOptions.predictiveTaggingEnabled) {
			if (!deviceId) {
				return;
			}

			const device = entities.getEntity(deviceId);

			if (!device?.descendants.gateway || !location?.descendants.location) {
				return;
			}

			const existingEntities = location.descendants.location.filter((e) => e.isEquip);

			matchSimilarPoints(point, entityPointTemplate, device.descendants.gateway, existingEntities, taggingOptions);
		}
	};

	let locationPath = '';

	if (location) {
		if (location.locationParents && location.locationParents.length > 0) {
			locationPath = location.locationParents?.map((l) => l.displayName).join(' > ') + ' > ';
		}

		locationPath += location.displayName;
	} else {
		locationPath = 'Choose location';
	}

	const renderTree = (entity: Entity) => {
		return (
			<EntityTreeItem
				nodeId={entity.id}
				key={entity.id}
				label={entity.displayName}
				selectable={true}
				selected={selected === entity.id}
				expanded={expandedNodes.includes(entity.id)}
				onExpand={() => onNodeExpand(entity.id)}
				onCollapse={() => onNodeCollapse(entity.id)}
				onSelect={() => onSelect(entity.id)}
				unsaved={entity.unsaved}
				deleted={entity.deleted}
				icon={<Factory />}
				lifecycleStatus={entity.data?.lifecycleStatus}
			>
				<Box key="stub" />
				{entity.children.equip && entity.children.equip.map((n) => renderTree(n))}
				{entity.children.entity &&
					entity.children.entity.map((point) => (
						<EntityTreeItem
							nodeId={point.id}
							key={point.id}
							label={point.displayName}
							selectable={true}
							unsaved={point.unsaved}
							deleted={point.deleted}
							icon={<PointFilledIcon />}
							selected={selected === point.id}
							expanded={expandedNodes.includes(point.id)}
							onExpand={() => onNodeExpand(point.id)}
							onCollapse={() => onNodeCollapse(point.id)}
							onSelect={() => onSelect(point.id)}
							isLeaf={true}
						/>
					))}
				{mappingModeEnabled &&
					entity.entityTemplate?.data?.template.points_templates &&
					entity.entityTemplate.data.template.points_templates.map((pointTemplateId) => {
						const pointTemplate = entityPointTemplates.getTemplate(pointTemplateId);

						if (!pointTemplate) {
							return null;
						}

						if (!pointTemplate.data?.template.allow_multiple) {
							// Check if this template point is already bound to a point under this entity
							// Skip this check if this point template has "allow_multiple" flag enabled
							const unbound = !entity.children.entity?.find((e) => e.data?.templates.entity_point === pointTemplateId);

							if (!unbound) {
								return null;
							}
						}

						const nodeId = `${entity.id}:${pointTemplate.id}`;
						return (
							<EntityTreeItem
								nodeId={nodeId}
								key={nodeId}
								label={pointTemplate.displayName}
								selectable={false}
								disabled={true}
								isLeaf={true}
								icon={<PointUnfilledIcon />}
								onDrop={(itemId) => bindEntityWithPoint(entity, itemId, pointTemplate)}
								datatype={pointTemplate.data?.template.datatype}
							/>
						);
					})}
			</EntityTreeItem>
		);
	};

	return (
		<Box className={classes.container}>
			<TextField
				margin="normal"
				variant="outlined"
				className={classes.locationSelect}
				value={locationPath}
				label="Location filter"
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Button onClick={() => setPopupOpen(true)}>...</Button>
						</InputAdornment>
					),
					className: classes.input,
					disabled: true,
				}}
			/>
			{popupOpen && (
				<LocationSelectPopup
					title="Select location"
					location={location}
					onClose={(selected) => {
						if (selected) {
							navigate(null, { locationId: selected, entityId: null });
						}

						setPopupOpen(false);
					}}
				/>
			)}
			{mappingModeEnabled && (
				<Button color="primary" variant="outlined" onClick={() => setShowTaggingOptions(true)}>
					Tagging options...
				</Button>
			)}
			{location && (
				<>
					{location.descendantsRequest.location === Status.Loading && <LinearProgress />}
					{location.descendants.location && (
						<ul className={classes.tree}>
							{location.descendants.location.filter((e) => e.isEquip && !e.parentEquip).map(renderTree)}
						</ul>
					)}
				</>
			)}
			{showTaggingOptions && (
				<TaggingOptionsDialog
					value={taggingOptions}
					showPredictiveTaggingOption={true}
					entityType="asset"
					onChange={(value) => setTaggingOptions(value)}
					onClose={() => setShowTaggingOptions(false)}
				/>
			)}
		</Box>
	);
});
