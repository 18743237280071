export enum HttpCode {
	Ok = 200,
	Created = 201,
	Accepted = 202,
	NoContent = 204,
	BadRequest = 400,
	Unauthorized = 401,
	Forbidden = 403,
	NotFound = 404,
	InternalServerError = 500,
}

export default class ErrorWithHttpCode extends Error {
	public code: HttpCode;

	constructor(code = HttpCode.InternalServerError, message = '') {
		super(message);
		this.code = code;
	}
}
