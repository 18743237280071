import { Transition } from 'history';
import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

import { useCurrentPage } from 'routing/routing';

function useBlocker(blocker: (tx: Transition) => void, when = true) {
	const { navigator } = useContext(NavigationContext);

	useEffect(() => {
		if (!when) return;

		const unblock = (navigator as any).block((tx: Transition) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					// Automatically unblock the transition so it can play all the way
					// through before retrying it. TODO: Figure out how to re-enable
					// this block if the transition is cancelled for some reason.
					unblock();
					tx.retry();
				},
			};

			blocker(autoUnblockingTx);
		});

		return unblock;
	}, [navigator, blocker, when]);
}

export default function usePromptWithCallback(
	message: string,
	when: boolean,
	onlyOnPageChange: boolean,
	callback: () => void,
) {
	// const [lastLocation, setLastLocation] = useState(null);
	const currentPage = useCurrentPage();

	let blocker = useCallback(
		(tx: Transition) => {
			if (
				onlyOnPageChange &&
				tx.location.pathname.replace(process.env.REACT_APP_DIRECTORY_NAME || '', '') === currentPage.path
			) {
				tx.retry();
			} else if (window.confirm(message)) {
				callback();
				tx.retry();
			}
		},
		[callback, currentPage, message, onlyOnPageChange],
	);

	useBlocker(blocker, when);
}
