import { useContext, useEffect, useState } from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import { IApiRole, IApiUser, IApiUserRoleAssignment } from '@mitie/metadata-api-types';

import ConfirmDialog from './ConfirmDialog';
import { AlertsContext } from 'store/AlertsProvider';
import * as UsersApi from '../api/users';
import * as UserRolesApi from '../api/userRoles';
import * as AssignmentsApi from '../api/userRoleAssignments';
import { useNavigate } from 'routing/routing';
import UserRoleAssignments from './UserRoleAssignments';

interface IUserDetailsProps {
	user: IApiUser;
	onChange: () => void;
}

export default function UserDetails({ user, onChange }: IUserDetailsProps) {
	const [removeConfirmDialogOpen, setRemoveConfirmDialogOpen] = useState(false);
	const [rolesById, setRolesById] = useState<Record<string, IApiRole>>();
	const [roleAssignments, setRoleAssignments] = useState<IApiUserRoleAssignment[] | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const { addAlert } = useContext(AlertsContext);
	const navigate = useNavigate();

	// Fetch list of roles on load
	useEffect(() => {
		loadRoles();
	}, []);

	// Load role assignments when selected user changes
	useEffect(() => {
		setRoleAssignments(null);
		loadRoleAssignments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.id]);

	const loadRoles = async () => {
		const data = await UserRolesApi.getAll();
		setRolesById(
			data.reduce((acc, role) => {
				acc[role.role_id] = role;

				return acc;
			}, {} as Record<string, IApiRole>),
		);
	};

	const loadRoleAssignments = async () => {
		setIsLoading(true);

		try {
			const data = await AssignmentsApi.getAssignmentsForUser(user.id);
			setRoleAssignments(data);
		} finally {
			setIsLoading(false);
		}
	};

	const removeRoleAssignment = async (data: IApiUserRoleAssignment) => {
		setIsSaving(true);

		try {
			await AssignmentsApi.remove(user.id, data);
			loadRoleAssignments();
			addAlert('info', 'Role assignment successfully removed from user');
		} finally {
			setIsSaving(false);
		}
	};

	const addRoleAssignment = async (data: IApiUserRoleAssignment) => {
		setIsSaving(true);

		try {
			await AssignmentsApi.create(user.id, data);
			loadRoleAssignments();
			addAlert('info', 'Role assignment successfully added to user');
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', margin: (theme) => theme.spacing(2) }}>
			<Box sx={{ display: 'flex', alignItems: 'start' }}>
				<Box sx={{ marginBottom: (theme) => theme.spacing(2) }}>
					<Typography variant="h5">{user.displayName}</Typography>
					<Typography variant="subtitle1">{user.type}</Typography>
				</Box>
				<Button
					variant="outlined"
					onClick={() => setRemoveConfirmDialogOpen(true)}
					sx={{ marginLeft: (theme) => theme.spacing(2) }}
				>
					Remove user
				</Button>
			</Box>
			{user.type === 'Group' && (
				<Typography variant="body2" sx={{ marginBottom: (theme) => theme.spacing(2) }}>
					If you are the owner of this security group, you can manage its members in the{' '}
					<Link
						href={`https://portal.azure.com/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/Members/groupId/${user.id}`}
						target="_blank"
					>
						Azure Active Directory portal
					</Link>
				</Typography>
			)}
			<UserRoleAssignments
				userId={user.id}
				roleAssignments={roleAssignments}
				addRoleAssignment={addRoleAssignment}
				removeRoleAssignment={removeRoleAssignment}
				isLoading={isLoading}
				isSaving={isSaving}
				rolePermissions={rolesById}
			/>
			{removeConfirmDialogOpen && (
				<ConfirmDialog
					title="Confirm removing user"
					message={`Are you sure you want to remove user ${user.displayName} from MDM?`}
					cancelLabel="Cancel"
					confirmLabel="Remove"
					onClose={() => setRemoveConfirmDialogOpen(false)}
					onConfirm={async () => {
						await UsersApi.deleteUser(user.id);
						addAlert('info', `The user ${user.displayName} has been removed`);
						navigate(null, { userId: null });
						onChange();
					}}
				/>
			)}
		</Box>
	);
}
