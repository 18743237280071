import { useContext, useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

import AlertsBanner from '../components/AlertsBanner';
import HeaderBar from '../components/HeaderBar';
import NavBar from '../components/NavBar';
import Unauthorised from 'pages/Unauthorised';
import Unauthenticated from 'pages/Unauthenticated';
import { UserContext } from 'store/UserProvider';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: 'calc(100% - 64px)',
			width: '100%',
			marginTop: '64px',
			display: 'flex',
			flexDirection: 'column',
		},
		componentContainer: {
			display: 'flex',
			height: '100%',
			width: '100%',
		},
	}),
);

interface ISecuredPageProps {
	pageName: string;
	component: React.ReactNode;
}

export default function SecuredPage({ pageName, component }: ISecuredPageProps) {
	const classes = useStyles();
	const [navBarOpen, setNavBarOpen] = useState(false);
	const { fetchUser, authenticationStatus } = useContext(UserContext);

	useEffect(() => {
		fetchUser();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	switch (authenticationStatus) {
		case 'Unauthenticated':
		case 'Authenticating':
			return (
				<div>
					<CircularProgress />
				</div>
			);
		case 'Failed':
			return <Unauthenticated />;
		case 'Forbidden':
			return <Unauthorised />;
		case 'Authenticated':
			return (
				<div className={classes.root}>
					<HeaderBar onNavBarToggle={() => setNavBarOpen((previous) => !previous)} pageName={pageName} />
					<NavBar open={navBarOpen} onClose={() => setNavBarOpen(false)} />
					<AlertsBanner />
					<div className={classes.componentContainer}>{component}</div>
				</div>
			);
	}
}
