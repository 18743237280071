import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { autorun, runInAction } from 'mobx';
import {
	Box,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
	Typography,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Add, Delete } from '@mui/icons-material';
import { IEntityPointTemplate } from '@mitie/metadata-api-types';

import { useStores } from 'store';
import { EntityTemplate } from 'store/entityTemplate';
import { Template } from 'store/template';
import EntityPointDefinition from './EntityPointDefinition';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexGrow: 1,
			minHeight: 0,
		},
		propertiesList: {
			display: 'flex',
			flexDirection: 'column',
		},
		topBar: {
			display: 'flex',
		},
		list: {
			display: 'flex',
			minHeight: 0,
		},
		grow: {
			flexGrow: 1,
		},
		titleText: {
			lineHeight: '48px',
			marginRight: theme.spacing(2),
			marginLeft: theme.spacing(2),
		},
	}),
);

interface IEntityPointsDefinitionProps {
	template: EntityTemplate;
	canEdit: boolean;
}

export default observer(function EntityPointsDefinition({ template, canEdit }: IEntityPointsDefinitionProps) {
	const classes = useStyles();
	const [selectedPointIndex, setSelectedPointIndex] = useState<number | null>(null);

	const { entityPointTemplates } = useStores();
	const [epTemplates, setEpTemplates] = useState([] as Array<Template<IEntityPointTemplate>>);

	// Fetch entity points data
	useEffect(
		() =>
			autorun(() => {
				if (!template.data?.template.points_templates) {
					setEpTemplates([]);
				} else {
					const data = template.data.template.points_templates.map((entityPointTemplateId) => {
						const epTemplate = entityPointTemplates.addAndGetTemplate(entityPointTemplateId);

						if (!epTemplate.data) {
							entityPointTemplates.templatesToFetch.push(entityPointTemplateId);
						}

						return epTemplate;
					});

					setEpTemplates(data);
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[template],
	);

	const addPointTemplate = () => {
		runInAction(() => {
			if (!template.data) {
				return;
			}

			const epTemplate = entityPointTemplates.createTemplate();

			if (!template.data!.template.points_templates) {
				template.data!.template.points_templates = [];
			}

			template.data!.template.points_templates.push(epTemplate.id);

			setSelectedPointIndex(template.data.template.points_templates.length - 1);
		});
	};

	const deletePointTemplate = (index: number) => {
		if (!template.data?.template.points_templates) {
			return;
		}

		template.data.template.points_templates.splice(index, 1);

		if (index === selectedPointIndex) {
			setSelectedPointIndex(null);
		}
	};

	return (
		<Box className={classes.root}>
			{template.data && (
				<>
					<Box className={classes.propertiesList}>
						<Box className={classes.topBar}>
							<Typography variant="h6" className={classes.titleText}>
								Data points
							</Typography>
							{canEdit && (
								<>
									<Box className={classes.grow} />
									<Tooltip title="New point" placement="bottom">
										<IconButton color="secondary" onClick={addPointTemplate}>
											<Add />
										</IconButton>
									</Tooltip>
								</>
							)}
						</Box>
						<Divider />
						<Box className={classes.list}>
							<Box sx={{ overflow: 'auto', minWidth: '20rem' }}>
								<List sx={{ padding: 0 }}>
									{epTemplates.map((template, index) => (
										<ListItem
											button={true}
											key={index}
											selected={index === selectedPointIndex}
											autoFocus={index === selectedPointIndex}
											onClick={() => setSelectedPointIndex(index)}
										>
											<ListItemText
												sx={{ whiteSpace: 'nowrap' }}
												primary={<Typography variant="subtitle1">{template.displayName}</Typography>}
											></ListItemText>
											{canEdit && (
												<ListItemSecondaryAction>
													<IconButton edge="end" onClick={() => deletePointTemplate(index)}>
														<Delete />
													</IconButton>
												</ListItemSecondaryAction>
											)}
										</ListItem>
									))}
								</List>
							</Box>
						</Box>
					</Box>
					<Divider orientation="vertical" />
					{selectedPointIndex !== null && epTemplates[selectedPointIndex] && (
						<EntityPointDefinition template={epTemplates[selectedPointIndex]} canEdit={canEdit} />
					)}
				</>
			)}
		</Box>
	);
});
