import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Divider, Typography, Tabs, Tab, LinearProgress } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { Status } from 'DataTypes';
import EntityProperty from './EntityProperty';
import TagsInput from './TagsInput';
import CustomPropertiesDefinition from './CustomPropertiesDefinition';
import DeviceTwinPropertiesDefinition from './DeviceTwinPropertiesDefinition';
import DeviceCommandsDefinition from './DeviceCommandsDefinition';
import DeviceTwinTagsList from './DeviceTwinTagsList';
import DeviceTemplateChannels from './DeviceTemplateChannels';
import { DeviceTemplate } from 'store/deviceTemplate';
import TagsInputDropdown from './TagsInputDropdown';
import { useStores } from 'store';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			overflow: 'auto',
			flexDirection: 'column',
			flexGrow: 1,
		},
		properties: {
			display: 'flex',
			margin: theme.spacing(2),
		},
		tags: {
			marginBottom: theme.spacing(2),
			marginTop: theme.spacing(2),
			marginLeft: theme.spacing(2),
		},
		selectChip: {
			marginRight: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		propertyValue: {
			display: 'inline',
		},
		tabs: {
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
		tab: {
			'&>span:first-child': {
				flexDirection: 'row',
			},
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
		childrenTemplates: {
			margin: theme.spacing(2),
		},
	}),
);

interface IDeviceTemplateDetailsProps {
	template: DeviceTemplate;
	canEdit: boolean;
}

enum TabName {
	Properties = 0,
	DeviceTwin = 1,
	DeviceCommands = 2,
	ChildrenTemplates = 3,
	DefaultDevicePoints = 4,
}

export default observer(function DeviceTemplateDetails({ template, canEdit }: IDeviceTemplateDetailsProps) {
	const classes = useStyles();
	const { deviceTemplates } = useStores();

	const [selectedTab, setSelectedTab] = useState(TabName.Properties as TabName);

	return (
		<Box className={classes.root}>
			{template.dataRequest === Status.Loading && <LinearProgress />}
			{template.data && (
				<>
					<Tabs
						value={selectedTab}
						onChange={(event, value: TabName) => setSelectedTab(value)}
						variant="scrollable"
						scrollButtons="auto"
						className={classes.tabs}
						indicatorColor="primary"
						textColor="primary"
					>
						<Tab value={TabName.Properties} label="Properties" className={classes.tab} />
						<Tab value={TabName.DeviceTwin} label="Device Twin" className={classes.tab} />
						<Tab value={TabName.DeviceCommands} label="Device commands" className={classes.tab} />
						<Tab value={TabName.ChildrenTemplates} label="Children devices" className={classes.tab} />
						<Tab value={TabName.DefaultDevicePoints} label="Device channels" className={classes.tab} />
					</Tabs>
					{selectedTab === TabName.Properties && (
						<>
							<Box className={classes.properties}>
								<EntityProperty
									label="Template name"
									type="string"
									value={template.data?.name}
									onChange={(value) => template.data && (template.data.name = (value as string) || '')}
									className={classes.field}
									disabled={!canEdit}
								/>
								<TagsInput
									label="Tags"
									value={template.data.template.tags || []}
									onChange={(newTags) => template.data && (template.data.template.tags = newTags)}
									className={classes.field}
									disabled={!canEdit}
								/>
							</Box>
							<Divider />
							<CustomPropertiesDefinition properties={template.data.template.properties} canEdit={canEdit} />
						</>
					)}
					{selectedTab === TabName.DeviceTwin && (
						<>
							<Box className={classes.tags}>
								<Typography variant="h6">Device twin tags</Typography>
								<DeviceTwinTagsList
									tags={template.data?.template.device_twin?.tags || {}}
									setTags={
										canEdit
											? (tags) => {
													if (!template.data) {
														return;
													}

													if (!template.data.template.device_twin) {
														template.data.template.device_twin = { tags, properties: [] };
													} else {
														template.data.template.device_twin.tags = tags;
													}
											  }
											: undefined
									}
								/>
							</Box>
							<Divider />
							<DeviceTwinPropertiesDefinition template={template} canEdit={canEdit} />
						</>
					)}
					{selectedTab === TabName.DeviceCommands && <DeviceCommandsDefinition template={template} canEdit={canEdit} />}

					{selectedTab === TabName.ChildrenTemplates && (
						<Box className={classes.childrenTemplates}>
							<TagsInputDropdown
								value={template.data.template.children_templates || []}
								label="Children templates"
								select={deviceTemplates.templates.map((template) => ({
									label: template.data?.name || 'Unknown template',
									value: template.id,
								}))}
								onChange={(value) => (template.data!.template.children_templates = value)}
								disabled={!canEdit}
							/>
						</Box>
					)}
					{selectedTab === TabName.DefaultDevicePoints && (
						<DeviceTemplateChannels template={template} canEdit={canEdit} />
					)}
				</>
			)}
		</Box>
	);
});
