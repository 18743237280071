import { runInAction } from 'mobx';

import { ITaggingOptions } from 'components/TaggingOptionsDialog';
import Entity from 'store/entity';
import { EntityPointTemplate } from 'store/entityPointTemplate';

export async function bindEntityToPoint(
	entity: Entity,
	point: Entity,
	entityPointTemplate: EntityPointTemplate,
	taggingOptions: ITaggingOptions,
) {
	if (!point.data || !entity.data) {
		return;
	}

	point.setRelation('entity', entity.id);
	point.setEntityPointTemplate(entityPointTemplate.id);

	const device = point.parentDevice;

	if (device && taggingOptions.enableInterval) {
		if (!device.deviceConfigData) {
			await device.fetchDeviceConfigData();
		}

		const metricName = String(point.getProperty('metric_name'));
		device.setChannelDeviceConfigProperty(metricName, 'interval', taggingOptions.interval);
	}

	// Set external mappings on point if set in tagging options
	for (const [externalSystemTemplate, mappingData] of Object.entries(taggingOptions.pointExternalMapping)) {
		if (!point.data.externalMappings[externalSystemTemplate]) {
			point.data.externalMappings[externalSystemTemplate] = mappingData;
		}
	}

	// Set external mappings on parent entity if set in tagging options
	for (const [externalSystemTemplate, mappingData] of Object.entries(taggingOptions.assetExternalMapping)) {
		if (!entity.data.externalMappings[externalSystemTemplate]) {
			entity.data.externalMappings[externalSystemTemplate] = mappingData;
		}
	}
}

export function matchSimilarPoints(
	manuallyMatchedPoint: Entity,
	pointTemplate: EntityPointTemplate,
	devicesAndPoints: Entity[],
	existingEntities: Entity[],
	taggingOptions: ITaggingOptions,
) {
	const matchedDevice = manuallyMatchedPoint.parentDevice;
	const matchedEntity = manuallyMatchedPoint.parentEquip;
	const matchedEntityTemplate = matchedEntity?.data?.templates.entity;
	const pointName = manuallyMatchedPoint.data?.name;

	if (!matchedDevice || !matchedEntity || !pointName || !matchedEntityTemplate) {
		return;
	}

	if (!matchedDevice.data?.name || !matchedEntity.data?.name || matchedDevice.data.name !== matchedEntity.data.name) {
		// Only try to match other points if the entity and device have the same name
		// This is necessary so that the entity can be determined from the device name
		return;
	}

	const match = matchedDevice.data?.name.match(/^[a-zA-Z0-9]+/);

	if (!match?.[0]) {
		return;
	}

	const devicePrefix = match[0];

	const pointNameForMatching = pointName.toLowerCase().replace(/[^a-z]/g, '');
	const similarChannels = devicesAndPoints.filter(
		(e) => e.isPoint && e.data?.name.toLowerCase().replace(/[^a-z]/g, '') === pointNameForMatching && !e.parentEquip,
	);

	runInAction(() => {
		for (const otherChannel of similarChannels) {
			const otherDeviceName = otherChannel.parentDevice?.data?.name;

			if (!otherDeviceName) {
				continue;
			}

			// Check that the device name starts the same as the one of the manually matched device
			if (!otherDeviceName.startsWith(devicePrefix)) {
				continue;
			}

			// Find entity with same name as the device and same entity template as the one matched manually
			const existingOtherEntity = existingEntities.find(
				(e) => e.data?.name === otherDeviceName && e.data.templates.entity === matchedEntityTemplate,
			);

			if (existingOtherEntity) {
				const existingOtherPoints = existingOtherEntity.children.entity;

				if (
					existingOtherPoints &&
					existingOtherPoints.find((p) => p.data?.templates.entity_point === pointTemplate.id)
				) {
					// This point is already bound to an asset, don't do anything
					continue;
				}

				bindEntityToPoint(existingOtherEntity, otherChannel, pointTemplate, taggingOptions);
			} else {
				const otherEntity = matchedEntity.clone(otherDeviceName);

				if (!otherEntity) {
					// Cloning failed
					continue;
				}

				bindEntityToPoint(otherEntity, otherChannel, pointTemplate, taggingOptions);
			}
		}
	});
}
