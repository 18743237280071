import { ITaskStatus, ITaskDefinition, ITaskParameters } from '@mitie/telemetry-api-types';

import { IDataPoint } from 'DataTypes';
import { get, post, delete_, socket } from './api';
import { stores } from 'store';

let pointsSubscribedToTelemetry: string[] = [];

export async function fetchTelemetry(id: string, startDate: Date, endDate: Date) {
	const data: any[] = await post('telemetry/api/telemetry/byIds', {
		ids: [id],
		startDate: startDate.toISOString(),
		endDate: endDate.toISOString(),
	});

	const parsedData: IDataPoint[] = data[0].data;

	return parsedData;
}

export async function deleteTelemetry(id: string, startDate: Date, endDate: Date) {
	await delete_(`telemetry/api/telemetry/${id}`, {
		startDate: startDate.toISOString(),
		endDate: endDate.toISOString(),
	});
}

export function fetchTasksDefinition() {
	return get<ITaskDefinition[]>('telemetry/api/tasks/definitions');
}

export function fetchTasks() {
	return get<ITaskStatus[]>('telemetry/api/tasks');
}

export function cancelTask(instanceId: string) {
	return post<ITaskStatus[]>(`telemetry/api/tasks/instances/${instanceId}/terminate`, {});
}

export function createTask(orchestrator: string, parameters: ITaskParameters) {
	return post<void>(`telemetry/api/tasks/orchestrator/${orchestrator}`, {
		parameters,
	});
}

export function subscribeToPointData(pointId: string) {
	if (!pointsSubscribedToTelemetry.includes(pointId)) {
		pointsSubscribedToTelemetry.push(pointId);

		if (stores.globals.websocketConnected) {
			socket.emit('subscribe-telemetry', pointId);
		}
	}
}

export function unsubscribeFromPointData(pointId: string) {
	if (pointsSubscribedToTelemetry.includes(pointId)) {
		pointsSubscribedToTelemetry = pointsSubscribedToTelemetry.filter((id) => id !== pointId);

		if (stores.globals.websocketConnected) {
			socket.emit('unsubscribe-telemetry', pointId);
		}
	}
}

socket.on('connect', () => {
	// resubscribe on connect
	for (const existingPointId of pointsSubscribedToTelemetry) {
		socket.emit('subscribe-telemetry', existingPointId);
	}
});
