import {
	useLocation,
	useParams as useOriginalParams,
	useNavigate as useOriginalNavigate,
	matchRoutes,
} from 'react-router-dom';
import Router, { RouteMatch } from 'react-router';

import { Page, Params, routesByName, getParams, routesPaths } from './routes';

function getMatchedRouteAndParams(pathname: string): RouteMatch | null {
	// Temporary hack due to bug with basepath in React Router v6
	// https://github.com/ReactTraining/react-router/issues/7216
	return matchRoutes(routesPaths, pathname.replace(process.env.REACT_APP_DIRECTORY_NAME || '', ''))?.[0] || null;
}

export function getUrl(page: Page, params: Params): string {
	const route = routesByName[page];

	if (!route) {
		return page;
	}

	// Temporary hack due to bug with basepath in React Router v6
	// https://github.com/ReactTraining/react-router/issues/7216
	return (process.env.REACT_APP_DIRECTORY_NAME || '') + (route.getUrl ? route.getUrl(params) : page);
}

/** Hook to navigate to a different page, with the provided parameters
 * @param newPage Page to navigate to. If `null`, stay on the existing page
 * @param params Parameters to set. Set members' value to `null` to unset a parameter
 */
export function useNavigate(): (newPage: Page | null, params?: { [K in keyof Params]?: Params[K] | null }) => void {
	const navigate = useOriginalNavigate();
	const currentPage = useCurrentPage();
	const originalParams = useParams();

	return (newPage, params = {}): void => {
		const newParams = { ...originalParams };

		for (const param of Object.keys(params)) {
			const paramValue = params[param];

			if (paramValue === null) {
				delete newParams[param];
			} else {
				newParams[param] = paramValue;
			}
		}

		const newUrl = getUrl(newPage || currentPage.path, newParams);

		return navigate(newUrl);
	};
}

/** Hook the returns the URL for a page with specified parameters
 * @param newPage Page to generate the URl for
 * @param params Parameters to include in the URL
 */
export function useLink(): (newPage: Page, params?: Params) => string {
	return (newPage, params = {}): string => getUrl(newPage, params);
}

function getParsedParams(currentParams: Readonly<Router.Params<string>>, location: ReturnType<typeof useLocation>) {
	const params = { ...currentParams };
	const search = new URLSearchParams(location.search);
	for (const [key, value] of search.entries()) {
		params[key] = value;
	}

	return getParams(params as Record<keyof Params, string>);
}

/** Hook that parses and returns the parameters set in URL */
export function useParams(): Params {
	const location = useLocation();
	const originalParams = useOriginalParams();
	return getParsedParams(originalParams, location);
}

/** Hook that returns the route definition for the current page */
export function useCurrentPage() {
	const location = useLocation();
	const pagePath = getMatchedRouteAndParams(location.pathname)?.route.path as Page;

	return routesByName[pagePath];
}
