import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { RelationType } from '@mitie/metadata-api-types';

import Entity from 'store/entity';
import LocationSelect from './LocationSelect';
import RelationSelect from './RelationSelect';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
	}),
);

interface IRelationsProps {
	entity: Entity;
	loading: boolean;
	canEdit: boolean;
	hierarchy?: 'location' | 'gateway' | 'equip';
}

export default observer(function Relations({ entity, loading, canEdit, hierarchy }: IRelationsProps) {
	const classes = useStyles();

	const displayLocation = !entity.isClient && !entity.isPoint;
	const displayClient = !entity.isClient && !entity.isPoint;
	const displayGateway = entity.isDevice;
	const displayEquip = entity.isEquip && !entity.isPoint;
	const displayEntity = entity.isPoint;
	const displayDevice = entity.isPoint;

	const updateRelation = (relation: RelationType, entityId: string | null) => {
		if (!entity) {
			return;
		}

		if (entityId) {
			entity.setRelation(relation, entityId);
		} else {
			entity.removeRelation(relation);
		}
	};

	return (
		<>
			{displayLocation && (
				<LocationSelect
					label="Location"
					entity={entity}
					className={classes.field}
					disabled={loading || !canEdit}
					onChange={(id) => updateRelation('location', id)}
					hierarchy={hierarchy}
				/>
			)}
			{displayClient && (
				<RelationSelect
					label="Client"
					relationType="client"
					entity={entity}
					className={classes.field}
					disabled={loading || !canEdit}
					optional={false}
					onChange={(id) => updateRelation('client', id)}
				/>
			)}
			{displayGateway && (
				<RelationSelect
					label="Parent device"
					relationType="gateway"
					entity={entity}
					className={classes.field}
					disabled={loading || !canEdit}
					optional={true}
					onChange={(id) => updateRelation('gateway', id)}
				/>
			)}
			{displayEquip && (
				<RelationSelect
					label="Parent equip"
					relationType="equip"
					entity={entity}
					className={classes.field}
					disabled={loading || !canEdit}
					optional={true}
					onChange={(id) => updateRelation('equip', id)}
				/>
			)}
			{displayEntity && (
				<RelationSelect
					label="Equip"
					relationType="entity"
					entity={entity}
					className={classes.field}
					disabled={loading || !canEdit}
					optional={true}
					onChange={(id) => updateRelation('entity', id)}
				/>
			)}
			{displayDevice && (
				<RelationSelect
					label="Device"
					relationType="device"
					entity={entity}
					className={classes.field}
					disabled={loading || !canEdit}
					optional={true}
					onChange={(id) => updateRelation('device', id)}
				/>
			)}
		</>
	);
});
