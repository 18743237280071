import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Box,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
	Typography,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Add, Delete } from '@mui/icons-material';
import { IPropertyDefinition } from '@mitie/metadata-api-types';

import CustomPropertyDefinition from './CustomPropertyDefinition';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexGrow: 1,
			minHeight: 0,
		},
		propertiesList: {
			display: 'flex',
			flexDirection: 'column',
		},
		topBar: {
			display: 'flex',
		},
		list: {
			display: 'flex',
			minHeight: 0,
		},
		grow: {
			flexGrow: 1,
		},
		titleText: {
			lineHeight: '48px',
			marginRight: theme.spacing(2),
			marginLeft: theme.spacing(2),
		},
	}),
);

interface ICustomPropertiesDefinitionProps {
	properties: IPropertyDefinition[];
	canEdit: boolean;
}

export default observer(function CustomPropertiesDefinition({ properties, canEdit }: ICustomPropertiesDefinitionProps) {
	const classes = useStyles();
	const [selectedPropertyIndex, setSelectedPropertyIndex] = useState<number | null>(null);

	const updateProperty = (data: IPropertyDefinition) => {
		if (selectedPropertyIndex === null) {
			return;
		}

		properties[selectedPropertyIndex] = data;
	};

	const addProperty = () => {
		const data: IPropertyDefinition = {
			label: 'Property name',
			name: '',
			type: 'string',
		};

		properties.push(data);

		setSelectedPropertyIndex(properties.length - 1);
	};

	const deleteProperty = (index: number) => {
		properties.splice(index, 1);

		if (index === selectedPropertyIndex) {
			setSelectedPropertyIndex(null);
		}
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.propertiesList}>
				<Box className={classes.topBar}>
					<Typography variant="h6" className={classes.titleText}>
						Custom properties
					</Typography>
					{canEdit && (
						<>
							<Box className={classes.grow} />
							<Tooltip title="New property" placement="bottom">
								<IconButton color="secondary" onClick={addProperty}>
									<Add />
								</IconButton>
							</Tooltip>
						</>
					)}
				</Box>
				<Divider />
				<Box className={classes.list}>
					<Box sx={{ overflow: 'auto', minWidth: '20rem' }}>
						<List sx={{ padding: 0 }}>
							{properties.map((propertyDefinition, index) => (
								<ListItem
									button={true}
									key={propertyDefinition.name}
									selected={index === selectedPropertyIndex}
									autoFocus={index === selectedPropertyIndex}
									onClick={() => setSelectedPropertyIndex(index)}
								>
									<ListItemText
										sx={{ whiteSpace: 'nowrap' }}
										primary={<Typography variant="subtitle1">{propertyDefinition.label}</Typography>}
									></ListItemText>
									{canEdit && (
										<ListItemSecondaryAction>
											<IconButton edge="end" onClick={() => deleteProperty(index)}>
												<Delete />
											</IconButton>
										</ListItemSecondaryAction>
									)}
								</ListItem>
							))}
						</List>
					</Box>
				</Box>
			</Box>
			<Divider orientation="vertical" />
			{selectedPropertyIndex !== null && properties[selectedPropertyIndex] && (
				<CustomPropertyDefinition data={properties[selectedPropertyIndex]} setData={updateProperty} canEdit={canEdit} />
			)}
		</Box>
	);
});
