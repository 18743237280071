import { useState } from 'react';
import { Box, Dialog, LinearProgress, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

import { useStores } from 'store';
import Entity from 'store/entity';
import { Status } from 'DataTypes';
import LocationTree from './LocationTree';

interface ILocationSelectPopupProps {
	title: string;
	location?: Entity;
	onClose: (entityId?: string | null) => void;
}

export default function LocationSelectPopup({ title, location, onClose }: ILocationSelectPopupProps) {
	const { entities } = useStores();
	const [selectedEntity, setSelectedEntity] = useState<Entity | null>(location || null);

	return (
		<Dialog open={true} onClose={() => onClose()} maxWidth="md" fullWidth>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<Box sx={{ height: '500px' }}>
					{entities.rootLocationsFetchStatus === Status.Loading ? (
						<LinearProgress />
					) : (
						<LocationTree selected={selectedEntity} onSelect={(selected) => setSelectedEntity(selected)} hidePoints />
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} color="primary" variant="outlined">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					onClick={() => onClose(selectedEntity?.id)}
					color="primary"
					variant="contained"
					disabled={!selectedEntity}
				>
					Choose selected
				</Button>
			</DialogActions>
		</Dialog>
	);
}
