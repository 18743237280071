import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Divider, Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { autorun } from 'mobx';

import EntityView from './EntityView';
import DeviceTree from 'components/DeviceTree';
import ResizablePanel from './ResizablePanel';
import NewDeviceDialog from './NewDeviceDialog';
import { useNavigate, useParams } from 'routing/routing';
import { useStores } from 'store';
import { UserContext } from 'store/UserProvider';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flex: 1,
			display: 'flex',
			height: '100%',
		},
		sidebar: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
		},
		grow: {
			flexGrow: 1,
		},
		treeContainer: {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
			borderRight: '1px solid rgba(0, 0, 0, 0.12)',
			minHeight: 0,
		},
		topBar: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		actionButton: {
			margin: theme.spacing(1),
		},
	}),
);

export default observer(function Devices() {
	const classes = useStyles();
	const { entities } = useStores();
	const [newDeviceDialogOpen, setNewDeviceDialogOpen] = useState(false);
	const { entityId } = useParams();
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	useEffect(
		() =>
			autorun(() => {
				if (!entityId) {
					return;
				}

				const entity = entities.addAndGetEntity(entityId);

				if (entity && entity.isDevice === false && entity.isPoint === false) {
					navigate(null, { entityId: null });
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[entityId],
	);

	const canEdit = user?.roles.includes('Contributor') || false;

	return (
		<Box className={classes.root}>
			<ResizablePanel defaultWidth={500} className={classes.sidebar}>
				<Box className={classes.treeContainer}>
					{canEdit && (
						<>
							<Box className={classes.topBar}>
								<Button
									startIcon={<AddIcon />}
									variant="contained"
									onClick={() => setNewDeviceDialogOpen(true)}
									className={classes.actionButton}
								>
									Add device or channel...
								</Button>
							</Box>
							<Divider />
						</>
					)}
					<DeviceTree selected={entityId || null} onSelect={(entityId) => navigate(null, { entityId })} />
				</Box>
			</ResizablePanel>
			<EntityView hierarchy="gateway" />
			{newDeviceDialogOpen && <NewDeviceDialog onClose={() => setNewDeviceDialogOpen(false)} />}
		</Box>
	);
});
