import { useEffect, useState } from 'react';
import { TextField, InputAdornment, Button } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import Entity from 'store/entity';
import { autorun } from 'mobx';

import { useStores } from 'store';
import { RelationType } from '@mitie/metadata-api-types';
import { Status } from 'DataTypes';
import EntitySelectDialog from './EntitySelectDialog';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		input: {
			disabled: 'disabled',
		},
	}),
);

interface IRelationSelectProps {
	entity: Entity;
	relationType: RelationType;
	label: string;
	className: string;
	optional: boolean;
	disabled: boolean;
	onChange: (entityId: string | null) => void;
}

export default observer(function RelationSelect({
	entity,
	relationType,
	label,
	className,
	optional,
	disabled,
	onChange,
}: IRelationSelectProps) {
	const classes = useStyles();
	const { entities } = useStores();
	const [popupOpen, setPopupOpen] = useState(false);
	const [relatedEntity, setRelatedEntity] = useState<Entity>();

	useEffect(
		() =>
			autorun(() => {
				if (!entity.data) {
					return;
				}

				const relatedEntityId = entity.data.relations[relationType];

				if (!relatedEntityId) {
					setRelatedEntity(undefined);
				} else {
					setRelatedEntity(entities.addAndGetEntity(relatedEntityId));
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[entity],
	);

	useEffect(
		() =>
			autorun(() => {
				if (!entity.data) {
					return;
				}

				const relatedEntityId = entity.data.relations[relationType];

				if (!relatedEntityId) {
					return;
				}

				const relatedEntity = entities.addAndGetEntity(relatedEntityId);

				if (relatedEntity.dataRequest === Status.None) {
					relatedEntity.fetchData();
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[entity],
	);

	return (
		<>
			<TextField
				label={label}
				margin="normal"
				variant="outlined"
				className={className}
				disabled={disabled}
				value={relatedEntity ? relatedEntity.displayName : 'None'}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Button onClick={() => setPopupOpen(true)} disabled={disabled}>
								...
							</Button>
						</InputAdornment>
					),
					className: classes.input,
				}}
			/>
			{popupOpen && (
				<EntitySelectDialog
					entityType={relationType}
					value={relatedEntity}
					optional={optional}
					onChange={(v) => {
						setPopupOpen(false);
						onChange(v);
					}}
					onClose={() => setPopupOpen(false)}
				/>
			)}
		</>
	);
});
