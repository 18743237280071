import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, TextField } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { IUnitsDictionary } from '@mitie/metadata-api-types';

import { Status } from 'DataTypes';
import EntityProperty from './EntityProperty';
import Relations from './Relations';
import Entity from 'store/entity';
import { useStores } from 'store';
import LifecycleStatusPicker from './LifecycleStatusPicker';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
	}),
);

interface IEntityPropertiesProps {
	entity: Entity;
	hierarchy?: 'location' | 'gateway' | 'equip';
	canEdit: boolean;
}

export default observer(function EntityProperties({ entity, hierarchy, canEdit }: IEntityPropertiesProps) {
	const classes = useStyles();
	const entityLoading = entity.dataRequest === Status.Loading || entity.saveRequest === Status.Loading;
	const { unitsDictionary } = useStores();
	const [fullUnitsList, setFullUnitsList] = useState<IUnitsDictionary>();

	useEffect(() => {
		unitsDictionary.getList().then(setFullUnitsList);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!entity.data) {
		return null;
	}

	const unitsTypes = [
		{ label: 'None', value: '' },
		...(fullUnitsList ? Object.keys(fullUnitsList).map((type) => ({ label: type, value: type })) : []),
	];

	const unitsType = entity.getProperty('units_type');
	const unitsList = [
		{ label: 'None', value: '' },
		...(fullUnitsList && typeof unitsType === 'string'
			? fullUnitsList[unitsType]
				? fullUnitsList[unitsType].map((units) => ({ label: units, value: units }))
				: []
			: []),
	];

	return (
		<Box className={classes.root}>
			<TextField
				label="Name"
				value={entity.data.name}
				onChange={(e) => (entity.data!.name = e.target.value)}
				margin="normal"
				variant="outlined"
				className={classes.field}
				disabled={entityLoading || !canEdit}
				error={!entity.data.name.length}
			/>
			{!entity.isPoint && (
				<LifecycleStatusPicker
					entity={entity}
					hierarchy={hierarchy}
					value={entity.data.lifecycleStatus}
					disabled={!canEdit}
				/>
			)}
			<Relations entity={entity} loading={entityLoading} canEdit={canEdit} hierarchy={hierarchy} />
			{entity.entityTemplate?.data?.template.properties && (
				<Box>
					{entity.entityTemplate.data.template.properties.map((prop) => {
						return (
							<EntityProperty
								key={prop.name}
								label={prop.label}
								type={prop.type}
								value={entity.getProperty(prop.name)}
								select={prop.select}
								onChange={(value) => {
									if (value === undefined) {
										delete entity.data!.properties[prop.name];
									} else {
										entity.data!.properties[prop.name] = value;
									}
								}}
								className={classes.field}
							/>
						);
					})}
				</Box>
			)}
			{entity.deviceTemplate?.data?.template.properties && (
				<Box>
					{entity.deviceTemplate.data.template.properties.map((prop) => {
						return (
							<EntityProperty
								key={prop.name}
								label={prop.label}
								type={prop.type}
								value={entity.getProperty(prop.name)}
								select={prop.select}
								onChange={(value) => {
									if (value === undefined) {
										delete entity.data!.properties[prop.name];
									} else {
										entity.data!.properties[prop.name] = value;
									}
								}}
								className={classes.field}
							/>
						);
					})}
				</Box>
			)}
			{entity.deviceChannelTemplate?.data?.template.properties && (
				<Box>
					{entity.deviceChannelTemplate.data.template.properties
						.filter((p) => !p.include_in_device_twin)
						.map((propertyDefinition) => (
							<EntityProperty
								key={propertyDefinition.name}
								label={propertyDefinition.label}
								type={propertyDefinition.type}
								value={entity.getProperty(propertyDefinition.name)}
								select={propertyDefinition.select}
								onChange={(value) => {
									if (value === undefined) {
										delete entity.data!.properties[propertyDefinition.name];
									} else {
										entity.data!.properties[propertyDefinition.name] = value;
									}
								}}
								className={classes.field}
							/>
						))}
				</Box>
			)}
			{entity.isPoint && (
				<>
					<EntityProperty
						label="Data type"
						type="string"
						value={entity.getProperty('datatype')}
						select={[
							{ value: '', label: 'Not set' },
							{ value: 'number', label: 'Number' },
							{ value: 'boolean', label: 'Boolean' },
						]}
						onChange={(value) => {
							if (value === undefined || value === '') {
								delete entity.data!.properties.datatype;
							} else {
								entity.data!.properties.datatype = value;
							}
						}}
						className={classes.field}
					/>
					<EntityProperty
						label="Unit type"
						type="string"
						value={unitsType}
						select={unitsTypes}
						onChange={(value) => {
							if (value === undefined || value === '') {
								delete entity.data!.properties.units_type;
							} else {
								entity.data!.properties.units_type = value;
							}
						}}
						className={classes.field}
					/>
					<EntityProperty
						label="Units"
						type="string"
						value={entity.getProperty('units')}
						select={unitsList}
						disabled={!unitsTypes}
						onChange={(value) => {
							if (value === undefined || value === '') {
								delete entity.data!.properties.units;
							} else {
								entity.data!.properties.units = value;
							}
						}}
						className={classes.field}
					/>
				</>
			)}
		</Box>
	);
});
