import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, IconButton, Tab, Typography, Menu, MenuItem, ListItemIcon, Button } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { TabPanel, TabList, TabContext } from '@mui/lab';
import { MoreVert as MoreVertIcon, Undo as UndoIcon } from '@mui/icons-material';
import { ContentSaveAll, Wrench as WrenchIcon } from 'mdi-material-ui';

import { useStores } from 'store';
import Clients from '../components/Clients';
import Locations from '../components/Locations';
import Assets from '../components/Assets';
import Devices from '../components/Devices';
import ConfirmDialog from 'components/ConfirmDialog';
import ConsistencyReports from 'components/ConsistencyReports';
import { EntitiesType } from 'routing/routes';
import { useParams, useNavigate } from 'routing/routing';
import usePromptWithCallback from 'hooks/usePromptWithCallback';
import PointsMappingDrawer from 'components/PointsMappingDrawer';
import { UserContext } from 'store/UserProvider';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			display: 'flex',
		},
		drawer: {
			transition: theme.transitions.create('margin-left', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: '35%',
			height: '100%',
			boxSizing: 'border-box',
			borderRight: `1px solid ${theme.palette.divider}`,
		},
		content: {
			width: '100%',
			flexGrow: 1,
			display: 'flex',
			flexDirection: 'column',
		},
		header: {
			display: 'flex',
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
		tabs: {
			flexGrow: 1,
		},
		tabPanel: {
			padding: 0,
			flexGrow: 1,
			minHeight: 0,
		},
		actions: {
			flexShrink: 0,
			display: 'flex',
			alignItems: 'center',
			marginRight: theme.spacing(2),
		},
		button: {
			marginLeft: theme.spacing(2),
		},
	}),
);

export default observer(function EntitiesPage() {
	const classes = useStyles();
	const { entities, globals } = useStores();
	const { entitiesType } = useParams();
	const [showSaveConfirmDialog, setShowSaveConfirmDialog] = useState<boolean>(false);
	const [showDiscardConfirmDialog, setShowDiscardConfirmDialog] = useState<boolean>(false);
	const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<null | HTMLElement>(null);
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
	const [drawerContent, setDrawerContent] = useState<'consistencyReports' | 'mapping' | null>(null);
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	useEffect(() => {
		// Set default entity type if not set
		if (!entitiesType) {
			navigate(null, { entitiesType: 'locations' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entitiesType]);

	usePromptWithCallback(
		'Are you sure you want to navigate away? Unsaved changes to entities will be discarded.',
		entities.entitiesUnsavedCount > 0,
		true,
		() => entities.discardAll(),
	);

	if (!entitiesType) {
		return null;
	}

	const canEdit = user?.roles.includes('Contributor');

	return (
		<Box className={classes.root}>
			<Box className={classes.drawer} sx={{ marginLeft: drawerOpen ? 0 : '-35%' }}>
				{drawerContent === 'consistencyReports' && <ConsistencyReports onClose={() => setDrawerOpen(false)} />}
				{drawerContent === 'mapping' && <PointsMappingDrawer onClose={() => setDrawerOpen(false)} />}
			</Box>
			<Box className={classes.content}>
				<TabContext value={entitiesType}>
					<Box className={classes.header}>
						<IconButton
							onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
								setMoreMenuAnchorEl(event.currentTarget);
							}}
						>
							<MoreVertIcon />
						</IconButton>
						<Menu
							anchorEl={moreMenuAnchorEl}
							keepMounted={true}
							open={Boolean(moreMenuAnchorEl)}
							onClose={() => setMoreMenuAnchorEl(null)}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							<MenuItem
								onClick={() => {
									setMoreMenuAnchorEl(null);
									setDrawerOpen(true);
									setDrawerContent('consistencyReports');
								}}
							>
								<ListItemIcon>
									<WrenchIcon />
								</ListItemIcon>
								<Typography variant="inherit">Data consistency reports...</Typography>
							</MenuItem>
							{canEdit && (
								<>
									<MenuItem
										onClick={() => {
											setMoreMenuAnchorEl(null);
											setDrawerOpen(true);
											setDrawerContent('mapping');
										}}
									>
										<ListItemIcon>
											<WrenchIcon />
										</ListItemIcon>
										<Typography variant="inherit">Points mapping...</Typography>
									</MenuItem>
								</>
							)}
						</Menu>
						<TabList
							onChange={(e, tabName) => {
								navigate(null, { entitiesType: tabName as EntitiesType });
							}}
							variant="scrollable"
							scrollButtons="auto"
							className={classes.tabs}
							indicatorColor="primary"
							textColor="primary"
						>
							<Tab value="clients" label="Clients" />
							<Tab value="locations" label="Locations" />
							<Tab value="assets" label="Assets" />
							<Tab value="devices" label="Devices" />
						</TabList>
						{canEdit && (
							<Box className={classes.actions}>
								{entities.entitiesUnsavedCount > 0 && globals.savePendingCount === 0 && (
									<>
										{entities.entitiesUnsavedCount > 0 && (
											<Typography
												variant="overline"
												color="secondary"
											>{`${entities.entitiesUnsavedCount} unsaved`}</Typography>
										)}
										<Button
											startIcon={<ContentSaveAll />}
											color="secondary"
											variant="contained"
											onClick={() => setShowSaveConfirmDialog(true)}
											className={classes.button}
										>
											Save all
										</Button>
										<Button
											startIcon={<UndoIcon />}
											color="secondary"
											variant="outlined"
											onClick={() => setShowDiscardConfirmDialog(true)}
											className={classes.button}
										>
											Discard all
										</Button>
									</>
								)}
							</Box>
						)}
					</Box>
					<TabPanel value="clients" className={classes.tabPanel}>
						<Clients />
					</TabPanel>
					<TabPanel value="locations" className={classes.tabPanel}>
						<Locations mappingModeEnabled={drawerOpen && drawerContent === 'mapping'} />
					</TabPanel>
					<TabPanel value="assets" className={classes.tabPanel}>
						<Assets mappingModeEnabled={drawerOpen && drawerContent === 'mapping'} />
					</TabPanel>
					<TabPanel value="devices" className={classes.tabPanel}>
						<Devices />
					</TabPanel>
				</TabContext>
			</Box>
			{showSaveConfirmDialog && (
				<ConfirmDialog
					title="Save all changes?"
					message={`${entities.entitiesUnsavedCount} ${
						entities.entitiesUnsavedCount > 1 ? 'entities' : 'entity'
					} will be created, modified or deleted.`}
					cancelLabel="Cancel"
					confirmLabel="Save all"
					onClose={() => setShowSaveConfirmDialog(false)}
					onConfirm={() => entities.saveAll()}
				/>
			)}
			{showDiscardConfirmDialog && (
				<ConfirmDialog
					title="Discard all changes?"
					message={`${entities.entitiesUnsavedCount} ${
						entities.entitiesUnsavedCount > 1 ? 'changes' : 'change'
					} will be discarded.`}
					cancelLabel="Cancel"
					confirmLabel="Discard all"
					onClose={() => setShowDiscardConfirmDialog(false)}
					onConfirm={() => entities.discardAll()}
				/>
			)}
		</Box>
	);
});
