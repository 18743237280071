import {
	IParsedCsvFile,
	IApiEntity,
	IApiEntityUpdate,
	RelationType,
	IApiEntitiesSaveRequest,
	IApiCsvResponse,
	IApiCsvRequest,
} from '@mitie/metadata-api-types';

import { post, get, delete_, put, socket } from './api';
import { stores } from '../store';
import { IEntityData } from 'store/entity';

export type FileImportStatus =
	| 'No file'
	| 'Loading in progress'
	| 'File loaded'
	| 'Loading error'
	| 'Parsing in progress'
	| 'Parsing error'
	| 'Parsing completed';

export interface IFileImportResult {
	fileName?: string;
	rawContent?: string;
	content?: IParsedCsvFile;
	status?: FileImportStatus;
	statusText?: string;
	error?: string;
}

const importers: {
	[importId: string]: {
		callback: (content: IFileImportResult) => void;
		index: number;
	};
} = {};

export async function createEntity(id: string, data: IEntityData) {
	const resp = await post<IApiEntity>('metadata/secure/entities', parseEntityForApi(id, data));

	return { entityData: parseEntityFromApi(resp), updatedTime: new Date(resp.modified_time) };
}

export function deleteEntity(id: string) {
	return delete_<void>(`metadata/secure/entities/${id}`);
}

export async function updateEntity(id: string, data: IEntityData) {
	const resp = await put<IApiEntity>(`metadata/secure/entities/${id}`, parseEntityForApi(id, data));

	return { entityData: parseEntityFromApi(resp), updatedTime: new Date(resp.modified_time) };
}

export async function fetchClients() {
	const resp = await get<IApiEntity[]>('metadata/secure/entities/clients');

	return resp.map((entity) => ({
		id: entity.id,
		entityData: parseEntityFromApi(entity),
		updatedTime: new Date(entity.modified_time),
	}));
}

export async function fetchRootEntities(relationType: string) {
	const resp = await get<IApiEntity[]>(`metadata/secure/entities/${relationType}/root`);

	return resp;
}

export async function fetchRootLocations() {
	const resp = await get<IApiEntity[]>('metadata/secure/entities/locations/root');

	return resp.map((entity) => ({
		id: entity.id,
		entityData: parseEntityFromApi(entity),
		updatedTime: new Date(entity.modified_time),
	}));
}

export async function fetchRootDevices() {
	const resp = await get<IApiEntity[]>('metadata/secure/entities/devices/root');

	return resp.map((entity) => ({
		id: entity.id,
		entityData: parseEntityFromApi(entity),
		updatedTime: new Date(entity.modified_time),
	}));
}

export async function fetchEntitiesByIds(ids: string[]) {
	const resp = await post<IApiEntity[]>('metadata/secure/entities/byIds', {
		ids,
	});

	return resp.map((entity) => ({
		id: entity.id,
		entityData: parseEntityFromApi(entity),
		updatedTime: new Date(entity.modified_time),
	}));
}

export async function fetchEntitiesChildren(id: string, relationType: RelationType, include_points: boolean = true) {
	const resp = await post<IApiEntity[]>('metadata/secure/entities/children/byIds', {
		relation_type: relationType,
		ids: [id],
		include_points,
	});

	return resp;
}

export async function fetchEntitiesChildrenByIds(
	relationType: RelationType,
	ids: string[],
	include_points: boolean = true,
) {
	const resp = await post<IApiEntity[]>('metadata/secure/entities/children/byIds', {
		relation_type: relationType,
		ids,
		include_points,
	});

	return resp.map((entity) => ({
		id: entity.id,
		entityData: parseEntityFromApi(entity),
		updatedTime: new Date(entity.modified_time),
	}));
}

export async function fetchEntitiesDescendants(relationType: RelationType, id: string) {
	const resp = await get<IApiEntity[]>(`metadata/secure/entities/descendants/${id}/${relationType}`);

	return resp.map((entity) => ({
		id: entity.id,
		entityData: parseEntityFromApi(entity),
		updatedTime: new Date(entity.modified_time),
	}));
}

export function searchEntities(entityType: string, params?: { search: string }, signal?: AbortSignal) {
	return get<{ label: string; value: string }[]>(`metadata/secure/entities/search/${entityType}`, params, signal);
}

export function batchSaveEntities(
	toCreate: { id: string; data: IEntityData }[],
	toUpdate: { id: string; data: IEntityData }[],
	toDelete: string[],
) {
	const request: IApiEntitiesSaveRequest = {
		create: toCreate.map(({ id, data }) => parseEntityForApi(id, data)),
		update: toUpdate.map(({ id, data }) => parseEntityForApi(id, data)),
		delete: toDelete,
	};

	return post<void>('metadata/secure/entities/batchSave', request);
}

export async function registerImporter(
	content: string,
	callback: (content: Partial<IFileImportResult>) => void,
	index: number,
) {
	const { importId } = await post<IApiCsvResponse>('file-parser/csv', {
		content,
		socketId: socket.id,
	} as IApiCsvRequest);

	importers[importId] = {
		callback,
		index,
	};

	callback({ status: 'Parsing in progress', statusText: 'File accepted by server' });
}

/**
 * Format the entity data for REST update or create call
 * @param id Entity ID
 * @param data Entity data
 */
function parseEntityForApi(id: string, data: IEntityData): IApiEntityUpdate {
	return {
		id,
		name: data.name,
		properties: data.properties,
		tags: data.tags,
		relations: data.relations,
		external_mappings: data.externalMappings,
		templates: data.templates,
		lifecycle_status: data.lifecycleStatus,
	};
}

/**
 * Parse entity data received from the server
 * @param param0 Entity data
 */
export function parseEntityFromApi({
	name,
	tags,
	properties,
	relations,
	external_mappings,
	templates,
	lifecycle_status,
}: IApiEntity | IApiEntityUpdate) {
	const data: IEntityData = {
		name,
		properties,
		relations,
		tags,
		externalMappings: external_mappings,
		templates: {},
		lifecycleStatus: lifecycle_status,
	};

	if (templates) {
		data.templates = templates;
	}

	return data;
}

socket.on('entity-updated', (entity: IApiEntity) => {
	const entityData = parseEntityFromApi(entity);
	const updatedTime = new Date(entity.modified_time);

	stores.entities.updateEntity(entity.id, entityData, updatedTime);
	stores.globals.decrementSavePendingCounter();
});

socket.on('entity-deleted', (entityId: string) => {
	stores.entities.deleteEntity(entityId);
	stores.globals.decrementSavePendingCounter();
});

socket.on('import-status', (importId: string, statusText: string) => {
	const importer = importers[importId];

	if (!importer) {
		return;
	}

	importer.callback({ status: 'Parsing in progress', statusText });
});

socket.on('import-error', (importId: string, error: string) => {
	const importer = importers[importId];

	if (!importer) {
		return;
	}

	importer.callback({ status: 'Parsing error', error });
});

socket.on('import-done', (importId: string, content: IParsedCsvFile) => {
	const importer = importers[importId];

	if (!importer) {
		return;
	}

	importer.callback({ status: 'Parsing completed', content, statusText: undefined });
});
