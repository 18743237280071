import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	FormControlLabel,
	TextField,
	Typography,
	CircularProgress,
	Chip,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { useStores } from 'store';
import { Status } from 'DataTypes';
import { Add } from '@mui/icons-material';
import TooltipWithIcon from './TooltipWithIcon';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		taggingOptions: {
			display: 'flex',
			flexDirection: 'column',
		},
		title: {
			marginTop: theme.spacing(2),
			display: 'flex',
		},
		options: {
			margin: theme.spacing(1),
		},
		selectChip: {
			marginRight: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		addButton: {
			marginLeft: theme.spacing(1),
		},
	}),
);

export interface ITaggingOptions {
	predictiveTaggingEnabled: boolean;
	enableInterval: boolean;
	interval: number;
	assetExternalMapping: { [templateId: string]: {} };
	pointExternalMapping: { [templateId: string]: {} };
}

interface ITaggingOptionsDialogProps {
	value: ITaggingOptions;
	showPredictiveTaggingOption: boolean;
	entityType: 'asset' | 'location';
	onChange: (options: ITaggingOptions) => void;
	onClose: () => void;
}

export default observer(function TaggingOptionsDialog({
	value,
	showPredictiveTaggingOption,
	entityType,
	onChange,
	onClose,
}: ITaggingOptionsDialogProps) {
	const classes = useStyles();
	const { externalSystemTemplates } = useStores();
	const [selectedTemplateForAsset, setSelectedTemplateForAsset] = useState('');
	const [selectedTemplateForPoint, setSelectedTemplateForPoint] = useState('');

	useEffect(() => {
		if (externalSystemTemplates.fetchStatus === Status.None) {
			externalSystemTemplates.fetchAll();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addAssetExternalMapping = (templateId: string) => {
		if (!value.assetExternalMapping[templateId]) {
			const assetExternalMapping = { ...value.assetExternalMapping, [templateId]: {} };
			onChange({ ...value, assetExternalMapping });
		}
	};

	const removeAssetExternalMapping = (templateId: string) => {
		if (value.assetExternalMapping[templateId]) {
			const { [templateId]: existing, ...rest } = value.assetExternalMapping;
			onChange({ ...value, assetExternalMapping: rest });
		}
	};

	const addPointExternalMapping = (templateId: string) => {
		if (!value.pointExternalMapping[templateId]) {
			const pointExternalMapping = { ...value.pointExternalMapping, [templateId]: {} };
			onChange({ ...value, pointExternalMapping });
		}
	};

	const removePointExternalMapping = (templateId: string) => {
		if (value.pointExternalMapping[templateId]) {
			const { [templateId]: existing, ...rest } = value.pointExternalMapping;
			onChange({ ...value, pointExternalMapping: rest });
		}
	};

	return (
		<Dialog open={true} onClose={() => onClose()}>
			<DialogTitle>Tagging options</DialogTitle>
			<DialogContent>
				<Box className={classes.taggingOptions}>
					{showPredictiveTaggingOption && (
						<Box>
							<FormControlLabel
								control={
									<Checkbox
										checked={value.predictiveTaggingEnabled}
										onChange={(e) => onChange({ ...value, predictiveTaggingEnabled: e.target.checked })}
										color="primary"
									/>
								}
								label="Enable predictive tagging"
							/>
							<TooltipWithIcon
								tooltip={`If this option is enabled, predictive tagging will try to map similar channels from other devices when a channel is mapped to an entity. 

							Note that this only works when the device has exactly the same name as the asset.`}
							/>
						</Box>
					)}
					<Box>
						<FormControlLabel
							control={
								<Checkbox
									checked={value.enableInterval}
									onChange={(e) => onChange({ ...value, enableInterval: e.target.checked })}
									color="primary"
								/>
							}
							label={
								<Box>
									<Typography component="span" sx={{ lineHeight: '32px', marginRight: '8px' }}>
										Set interval when binding to
									</Typography>
									<TextField
										value={value.interval}
										select={true}
										variant="standard"
										onChange={(e) => onChange({ ...value, interval: Number(e.target.value) })}
										SelectProps={{
											native: true,
										}}
										disabled={!value.enableInterval}
									>
										<option value={60}>1 min</option>
										<option value={300}>5 min</option>
										<option value={900}>15 min</option>
										<option value={1800}>30 min</option>
										<option value={3600}>1 hr</option>
										<option value={7200}>2 hr</option>
										<option value={86400}>24 hr</option>
									</TextField>
								</Box>
							}
						/>
						<TooltipWithIcon
							tooltip={`If this option is enabled, the point's data collection interval will be overridden to the set value when it is bound to a device channel. 

							This does not affect points chich have previously been bound.`}
						/>
					</Box>
					<Box className={classes.title}>
						<Typography variant="h6" component="span">
							{`Default integration mappings for ${entityType}s`}
						</Typography>
						<TooltipWithIcon
							tooltip={`When a point is bound to a channel, integration with the systems listed below will be enabled on the ${entityType}.`}
						/>
					</Box>
					{externalSystemTemplates.fetchStatus === Status.Loading ? (
						<CircularProgress />
					) : (
						<Box>
							<Box className={classes.options}>
								{Object.keys(value.assetExternalMapping).length > 0 ? (
									Object.keys(value.assetExternalMapping).map((templateId) => (
										<Chip
											key={templateId}
											label={externalSystemTemplates.getTemplate(templateId)?.displayName}
											onDelete={() => removeAssetExternalMapping(templateId)}
											className={classes.selectChip}
										/>
									))
								) : (
									<Typography variant="body2">None set</Typography>
								)}
							</Box>
							<TextField
								value={selectedTemplateForAsset}
								select={true}
								variant="standard"
								onChange={(e) => setSelectedTemplateForAsset(e.target.value)}
								SelectProps={{
									native: true,
								}}
							>
								<option value="">Choose from list</option>
								{externalSystemTemplates.templates.map((template) => (
									<option key={template.id} value={template.id}>
										{template.displayName}
									</option>
								))}
							</TextField>
							<Button
								size="small"
								variant="outlined"
								startIcon={<Add fontSize="small" />}
								disabled={!selectedTemplateForAsset}
								onClick={() => addAssetExternalMapping(selectedTemplateForAsset)}
								className={classes.addButton}
							>
								Add
							</Button>
						</Box>
					)}
					<Box className={classes.title}>
						<Typography variant="h6" component="span">
							Default integration mappings for points
						</Typography>
						<TooltipWithIcon
							tooltip={`When a point is bound to a channel, integration with the systems listed below will be enabled on the point.`}
						/>
					</Box>
					{externalSystemTemplates.fetchStatus === Status.Loading ? (
						<CircularProgress />
					) : (
						<Box>
							<Box className={classes.options}>
								{Object.keys(value.pointExternalMapping).length > 0 ? (
									Object.keys(value.pointExternalMapping).map((templateId) => (
										<Chip
											key={templateId}
											label={externalSystemTemplates.getTemplate(templateId)?.displayName}
											onDelete={() => removePointExternalMapping(templateId)}
										/>
									))
								) : (
									<Typography variant="body2">None set</Typography>
								)}
							</Box>
							<TextField
								value={selectedTemplateForPoint}
								select={true}
								variant="standard"
								onChange={(e) => setSelectedTemplateForPoint(e.target.value)}
								SelectProps={{
									native: true,
								}}
							>
								<option value="">Choose from list</option>
								{externalSystemTemplates.templates.map((template) => (
									<option key={template.id} value={template.id}>
										{template.displayName}
									</option>
								))}
							</TextField>
							<Button
								size="small"
								variant="outlined"
								startIcon={<Add fontSize="small" />}
								disabled={!selectedTemplateForPoint}
								onClick={() => addPointExternalMapping(selectedTemplateForPoint)}
								className={classes.addButton}
							>
								Add
							</Button>
						</Box>
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
});
