// Inspired from: https://www.aaron-powell.com/posts/2019-09-23-recursive-settimeout-with-react-hooks/
import { useEffect, useRef } from 'react';

function useRecursiveTimeout<T>(callback: () => Promise<T> | void, delay: number) {
	const savedCallback = useRef(callback);

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the timeout loop.
	useEffect(() => {
		let id: NodeJS.Timeout;

		function tick() {
			const ret = savedCallback.current();

			if (ret instanceof Promise) {
				ret.then(() => {
					id = setTimeout(tick, delay);
				});
			} else {
				id = setTimeout(tick, delay);
			}
		}

		tick();
		return () => id && clearTimeout(id);
	}, [delay]);
}

export default useRecursiveTimeout;
