import { createContext, useContext } from 'react';
import { configure } from 'mobx';

import Entities from './entities';
import Globals from './globals';
import EntityTemplates from './entityTemplates';
import DeviceTemplates from './deviceTemplates';
import ExternalSystemTemplates from './externalSystemTemplates';
import DevicePointTemplates from './devicePointTemplates';
import EntityPointTemplates from './entityPointTemplates';
import UnitsDictionary from './unitsDictionary';

configure({
	enforceActions: 'never',
});

const globals = new Globals();
const entities = new Entities();
const entityTemplates = new EntityTemplates();
const deviceTemplates = new DeviceTemplates();
const externalSystemTemplates = new ExternalSystemTemplates();
const devicePointTemplates = new DevicePointTemplates();
const entityPointTemplates = new EntityPointTemplates();
const unitsDictionary = new UnitsDictionary();

export const stores = {
	globals,
	entities,
	entityTemplates,
	deviceTemplates,
	externalSystemTemplates,
	devicePointTemplates,
	entityPointTemplates,
	unitsDictionary,
};

export type TStores = typeof stores;

export const StoreContext = createContext(stores);
export const useStores = () => {
	return useContext(StoreContext);
};
