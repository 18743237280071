import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { IUnitsDictionary } from '@mitie/metadata-api-types';

import EntityProperty from './EntityProperty';
import { EntityPointTemplate } from 'store/entityPointTemplate';
import TagsInput from './TagsInput';
import { useStores } from 'store';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginLeft: theme.spacing(2),
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
	}),
);

interface IEntityPointDefinitionProps {
	template: EntityPointTemplate;
	canEdit: boolean;
}

export default observer(function EntityPointDefinition({ template, canEdit }: IEntityPointDefinitionProps) {
	const classes = useStyles();
	const { unitsDictionary } = useStores();
	const [fullUnitsList, setFullUnitsList] = useState<IUnitsDictionary>();

	useEffect(() => {
		unitsDictionary.getList().then(setFullUnitsList);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!template.data) {
		return null;
	}

	const unitsTypes = [
		{ label: 'None', value: '' },
		...(fullUnitsList ? Object.keys(fullUnitsList).map((type) => ({ label: type, value: type })) : []),
	];

	const unitsList = [
		{ label: 'None', value: '' },
		...(fullUnitsList && template.data.template.units_type
			? fullUnitsList[template.data.template.units_type]
				? fullUnitsList[template.data.template.units_type!].map((units) => ({ label: units, value: units }))
				: []
			: []),
	];

	return (
		<Box className={classes.root}>
			<EntityProperty
				label="Name"
				type="string"
				value={template.data.name}
				className={classes.field}
				onChange={(value) => (template.data!.name = value as string)}
				disabled={!canEdit}
			/>
			<EntityProperty
				label="Type"
				type="string"
				value={template.data.template.datatype}
				select={[
					{ value: '', label: 'Not set' },
					{ value: 'number', label: 'Number' },
					{ value: 'boolean', label: 'Boolean' },
				]}
				onChange={(value) => {
					if (value === 'number' || value === 'boolean') {
						template.data!.template.datatype = value;
					} else {
						delete template.data!.template.datatype;
					}
				}}
				className={classes.field}
				disabled={!canEdit}
			/>
			<EntityProperty
				label="Unit type"
				type="string"
				value={template.data.template.units_type}
				select={unitsTypes}
				className={classes.field}
				onChange={(value) => {
					if (!value) {
						delete template.data!.template.units_type;
					} else {
						template.data!.template.units_type = value as string;
					}
				}}
				disabled={!fullUnitsList || !canEdit}
			/>
			<EntityProperty
				label="Default units"
				type="string"
				value={template.data.template.default_units}
				select={unitsList}
				className={classes.field}
				onChange={(value) => {
					if (!value) {
						delete template.data!.template.default_units;
					} else {
						template.data!.template.default_units = value as string;
					}
				}}
				disabled={!template.data!.template.units_type || !canEdit}
			/>
			<EntityProperty
				label="Allow multiple instances"
				type="boolean"
				value={template.data.template.allow_multiple}
				className={classes.field}
				onChange={(value) => {
					template.data!.template.allow_multiple = value as boolean;
				}}
				disabled={!canEdit}
			/>
			<TagsInput
				label="Tags"
				value={template.data.template.tags}
				onChange={(value) => (template.data!.template.tags = value)}
				className={classes.field}
				disabled={!canEdit}
			/>
		</Box>
	);
});
