import { observer } from 'mobx-react-lite';
import { Box, Divider, Typography, LinearProgress, FormGroup, FormControlLabel, Checkbox, Radio } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { Status } from 'DataTypes';
import EntityProperty from './EntityProperty';
import CustomPropertiesDefinition from './CustomPropertiesDefinition';
import { ExternalSystemTemplate } from 'store/externalSystemTemplate';
import DeviceTwinTagsList from './DeviceTwinTagsList';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			minHeight: 0,
		},
		propertyValue: {
			display: 'inline',
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
		properties: {
			margin: theme.spacing(2),
			display: 'flex',
		},
		integrationFeatures: {
			marginLeft: theme.spacing(2),
			marginBottom: theme.spacing(2),
		},
		tags: {
			marginLeft: theme.spacing(2),
			marginBottom: theme.spacing(2),
		},
	}),
);

interface IIntegrationTemplateDetailsProps {
	template: ExternalSystemTemplate;
	canEdit: boolean;
}

export default observer(function IntegrationTemplateDetails({ template, canEdit }: IIntegrationTemplateDetailsProps) {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			{template.dataRequest === Status.Loading && <LinearProgress />}
			{template.data && (
				<>
					<Box className={classes.properties}>
						<EntityProperty
							label="Template name"
							type="string"
							value={template.data?.name}
							onChange={(value) => template.data && (template.data.name = (value as string) || '')}
							className={classes.field}
							disabled={!canEdit}
						/>
					</Box>
					<Box className={classes.integrationFeatures}>
						<Typography variant="h6">Integration features</Typography>
						<FormGroup>
							<FormControlLabel
								control={
									<Radio
										checked={template.data.template.features.includes('manual')}
										onChange={(e) => {
											if (!template.data) {
												return;
											}

											if (e.target.checked) {
												template.data.template.features.push('manual');
												template.data.template.features = template.data.template.features.filter((f) => f !== 'slave');
											} else {
												template.data.template.features.push('slave');
												template.data.template.features = template.data.template.features.filter((f) => f !== 'manual');
											}
										}}
										disabled={!canEdit}
									/>
								}
								label="No automated synchronisation. The corresponding entity in the external system needs to be created
								manually and the corresponding IDs to be added manually below"
							/>
							<FormControlLabel
								control={
									<Radio
										checked={template.data.template.features.includes('slave')}
										onChange={(e) => {
											if (!template.data) {
												return;
											}

											if (e.target.checked) {
												template.data.template.features.push('slave');
												template.data.template.features = template.data.template.features.filter((f) => f !== 'manual');
											} else {
												template.data.template.features.push('manual');
												template.data.template.features = template.data.template.features.filter((f) => f !== 'slave');
											}
										}}
										disabled={!canEdit}
									/>
								}
								label="One-way automated synchronisation. Entity in the external system is created and updated based on
								the entity data"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={template.data.template.features.includes('telemetry')}
										onChange={(e) => {
											if (!template.data) {
												return;
											}

											if (e.target.checked) {
												template.data.template.features.push('telemetry');
											} else {
												template.data.template.features = template.data.template.features.filter(
													(f) => f !== 'telemetry',
												);
											}
										}}
										disabled={!canEdit}
									/>
								}
								label="The telemetry will be forwarded automatically to the external system if the entity is a device and
								the corresponding entity exists in the external system"
							/>
						</FormGroup>
					</Box>
					<Box className={classes.tags}>
						<Typography variant="h6">Device twin tags</Typography>
						<DeviceTwinTagsList
							tags={template.data?.template.device_twin_tags || {}}
							setTags={
								canEdit
									? (tags) => {
											if (!template.data) {
												return;
											}

											template.data.template.device_twin_tags = tags;
									  }
									: undefined
							}
						/>
					</Box>
					<Divider />
					<CustomPropertiesDefinition properties={template.data.template.properties} canEdit={canEdit} />
				</>
			)}
		</Box>
	);
});
