import { action, computed, makeObservable, observable } from 'mobx';
import { IDeviceTemplate } from '@mitie/metadata-api-types';

import { DeviceTemplate } from './deviceTemplate';
import Templates from './templates';
import { v4 } from 'uuid';

export default class DeviceTemplates extends Templates<IDeviceTemplate> {
	private list: { [id: string]: DeviceTemplate } = {};

	constructor() {
		super('device');

		makeObservable<DeviceTemplates, 'list'>(this, {
			addAndGetTemplate: action,
			templates: computed,
			deleteTemplate: action,
			list: observable,
		});
	}

	public getTemplate(id: string): DeviceTemplate | undefined {
		return this.list[id];
	}

	public getTemplateByName(name: string): DeviceTemplate | undefined {
		for (const templateId in this.list) {
			const template = this.list[templateId];

			if (template.data?.name === name) {
				return template;
			}
		}

		return;
	}

	public addAndGetTemplate(templateId: string): DeviceTemplate {
		const template = this.getTemplate(templateId);

		if (template) {
			return template;
		}

		const newTemplate = new DeviceTemplate(templateId);
		this.list[templateId] = newTemplate;

		return newTemplate;
	}

	public get templates() {
		return Object.keys(this.list)
			.map((id) => this.list[id])
			.sort((a, b) => a.displayName.localeCompare(b.displayName));
	}

	public createTemplate(baseTemplateId?: string) {
		const entityId = v4();
		const newTemplate = this.addAndGetTemplate(entityId);

		if (baseTemplateId) {
			const baseTemplate = this.getTemplate(baseTemplateId);

			if (!baseTemplate || !baseTemplate.data) {
				throw new Error('Unable to create template: Base template not found');
			}

			newTemplate.setData(baseTemplate.data);
			newTemplate.data!.name = 'New device template';
		} else {
			newTemplate.setData({
				name: 'New device template',
				template: {
					properties: [],
					tags: ['device'],
					children_templates: [],
				},
			});
		}

		return newTemplate;
	}

	public deleteTemplate(template: DeviceTemplate) {
		template.savedData = undefined;
		template.data = undefined;
		template.updatedTime = undefined;

		delete this.list[template.id];
	}
}
