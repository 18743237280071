import enGB from 'date-fns/locale/en-GB';
import formatRelative from 'date-fns/formatRelative';
import words from 'lodash/words';
import { Status } from 'DataTypes';

/** Custom version of date-fns' `formatRelative`, that only include the date and not the time */
export function formatRelativeDate(date: string) {
	const formatRelativeLocale = {
		lastWeek: "'last' eeee",
		yesterday: "'yesterday'",
		today: "'today'",
		tomorrow: "'tomorrow'",
		nextWeek: "'next' eeee",
		other: 'dd/MM/yyyy',
	};

	const locale = {
		...enGB,
		formatRelative: (token: keyof typeof formatRelativeLocale) => formatRelativeLocale[token],
	};

	return formatRelative(new Date(date), new Date(), { locale });
}

export function initials(name: string) {
	return words(name)
		.map((w) => w[0])
		.join('');
}

/**
 * Get the value of a cookie
 * Source: https://vanillajstoolkit.com/helpers/getcookie/
 * @param  {String} name  The name of the cookie
 * @return {String}       The cookie value
 */
export function getCookie(name: string) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop()!.split(';').shift();

	return undefined;
}

export function setCookie(name: string, value: string) {
	document.cookie = `${name}=${value};path=/;max-age=31536000`;
}

export function clearCookie(name: string) {
	document.cookie = `${name}=; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
}

export interface IFlatTreeNode<T extends { [key: string]: any }> {
	id: string;
	parentId?: string;
	name: string;
	data: T;
	childrenLoadStatus: Status;
}

export interface ITreeNode<T extends { [key: string]: any }> extends IFlatTreeNode<T> {
	children: ITreeNode<T>[];
}

export function createTreeFromArray<T extends { [key: string]: any }>(
	data: { [id: string]: IFlatTreeNode<T> },
	filter: string,
) {
	const nodes: { [id: string]: ITreeNode<T> } = Object.fromEntries(
		Object.entries(data)
			.filter(([k, v]) => filter.length === 0 || v.name.toLowerCase().includes(filter.toLowerCase()))
			.map(([k, v]) => [k, { ...v, children: [] }]),
	);
	const tree: ITreeNode<T>[] = [];

	for (const node of Object.values(nodes)) {
		let siblings: ITreeNode<T>[];

		if (node.parentId && nodes[node.parentId]) {
			siblings = nodes[node.parentId].children;
		} else {
			siblings = tree;
		}

		// Insert node at the correct place within siblings array
		let inserted = false;

		for (let index = 0; index < siblings.length; index++) {
			const existingItem = siblings[index];

			if (existingItem.name.toLowerCase() > node.name.toLowerCase()) {
				siblings.splice(index, 0, node);
				inserted = true;
				break;
			}
		}

		if (!inserted) {
			siblings.push(node);
		}
	}

	return tree;
}
