import React, { useContext, useState } from 'react';
import {
	Box,
	Button,
	Typography,
	FormControl,
	InputLabel,
	Select,
	Divider,
	LinearProgress,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Close as CloseIcon, Refresh as RefreshIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { IConsistencyException, ConsistencyExceptionType } from '@mitie/metadata-api-types';
import { v4 as uuid } from 'uuid';

import { fetchConsistencyExceptions } from 'api/consistencyExceptions';
import { useStores } from 'store';
import ConsistencyExceptionReviewDialog from './ConsistencyExceptionReviewDialog';
import { IEntityData } from 'store/entity';
import { useNavigate } from 'routing/routing';
import { AlertsContext } from 'store/AlertsProvider';

interface IConsistencyReportsProps {
	onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			display: 'flex',
			flexDirection: 'column',
			boxSizing: 'border-box',
			height: '100%',
		},
		header: {
			display: 'flex',
			marginBottom: theme.spacing(1),
		},
		form: {
			marginTop: theme.spacing(2),
			display: 'flex',
		},
		button: {
			marginLeft: theme.spacing(2),
		},
		grow: {
			flexGrow: 1,
		},
		tableContainer: {
			minHeight: 0,
			flexGrow: 1,
			marginTop: theme.spacing(2),
			overflow: 'auto',
		},
		table: {
			flexGrow: 1,
			maxHeight: '100%',
		},
		actionButton: {
			width: '100%',
			boxSizing: 'border-box',
		},
		summary: {
			marginTop: theme.spacing(2),
		},
	}),
);

const exceptionTypeLabel: { [type in ConsistencyExceptionType]: string } = {
	missing_in_other_system: 'Missing in other system',
	missing_in_master: 'Missing in master database',
	mismatch: 'Mismatch',
};

export default function ConsistencyReports({ onClose }: IConsistencyReportsProps) {
	const { entities } = useStores();
	const { addAlert } = useContext(AlertsContext);
	const navigate = useNavigate();
	const classes = useStyles();

	const [consistencyExceptions, setConsistencyExceptions] = useState<IConsistencyException[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [selectedItem, setSelectedItem] = useState<IConsistencyException>();
	const [isReviewPopupOpen, setIsReviewPopupOpen] = useState(false);

	const fetchData = async () => {
		setIsLoading(true);

		try {
			const data = await fetchConsistencyExceptions('maximo_locations');
			setConsistencyExceptions(data);
			setIsLoaded(true);
		} catch (e) {
			addAlert('error', 'Failed to load exceptions list');
		}

		setIsLoading(false);
	};

	const createEntity = (exception: IConsistencyException) => {
		let name = '';
		const properties: {
			[property: string]: string | number | boolean;
		} = {};

		for (const field of exception.differences) {
			if (field.otherValue !== undefined && field.otherValue !== null) {
				if (field.masterField === 'name') {
					name = String(field.otherValue);
				} else {
					properties[field.masterField] = field.otherValue;
				}
			}
		}

		const entityData: IEntityData = {
			name,
			properties,
			tags: [],
			relations: {},
			externalMappings: exception.externalMappings,
			templates: { entity: exception.entityTemplate },
			lifecycleStatus: 'Configuration',
		};
		const id = uuid();

		entities.addEntitiesFromUi([{ id, entityData }]);
		navigate(null, { entityId: id });
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.header}>
				<Typography variant="h6">Data consistency reports</Typography>
				<Box className={classes.grow} />
				<Button className={classes.button} startIcon={<CloseIcon />} onClick={() => onClose()}>
					Close
				</Button>
			</Box>
			<Divider />
			<Box className={classes.form}>
				<FormControl>
					<InputLabel htmlFor="age-native-helper">Data source</InputLabel>
					<Select native>
						<option value="maximo_locations">Maximo locations</option>
					</Select>
				</FormControl>
				<Button
					variant="outlined"
					className={classes.button}
					onClick={fetchData}
					disabled={isLoading}
					startIcon={<RefreshIcon />}
				>
					{isLoaded ? 'Refresh' : 'Load'}
				</Button>
			</Box>
			{isLoaded && (
				<Typography
					variant="subtitle2"
					className={classes.summary}
				>{`Found ${consistencyExceptions.length} exceptions`}</Typography>
			)}
			<Box className={classes.tableContainer}>
				{isLoading && <LinearProgress />}
				{isLoaded &&
					consistencyExceptions.map((exception) => {
						return (
							<Accordion
								key={exception.id}
								expanded={selectedItem !== undefined && selectedItem.id === exception.id}
								onChange={(event: React.ChangeEvent<{}>, isExpanded: boolean) => {
									setSelectedItem(isExpanded ? exception : undefined);

									if (exception.masterId) {
										navigate(null, { entityId: exception.masterId });
									}
								}}
							>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>{`${exceptionTypeLabel[exception.type]}: ${
									exception.name
								}`}</AccordionSummary>
								<AccordionDetails>
									{exception.masterId && (
										<Button
											color="primary"
											onClick={() => navigate(null, { entityId: exception.masterId })}
											className={classes.actionButton}
										>
											Go to location
										</Button>
									)}
									{exception.type === 'mismatch' && (
										<Button
											color="primary"
											onClick={() => {
												setIsReviewPopupOpen(true);
											}}
											className={classes.actionButton}
										>
											Review...
										</Button>
									)}
									{exception.type === 'missing_in_master' && (
										<Button
											color="primary"
											onClick={() => {
												createEntity(exception);
											}}
											className={classes.actionButton}
										>
											Import...
										</Button>
									)}
								</AccordionDetails>
							</Accordion>
						);
					})}
			</Box>
			{isReviewPopupOpen && selectedItem?.masterId && (
				<ConsistencyExceptionReviewDialog
					item={selectedItem}
					id={selectedItem.masterId}
					onClose={() => setIsReviewPopupOpen(false)}
				/>
			)}
		</Box>
	);
}
