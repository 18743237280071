import { makeAutoObservable } from 'mobx';

class Globals {
	public websocketConnected: boolean = false;
	public savePendingCount = 0;
	public saveTotalCount = 0;

	public get connected() {
		return this.websocketConnected;
	}

	public constructor() {
		makeAutoObservable(this);
	}

	public get saveProgress() {
		if (this.saveTotalCount === 0 || this.savePendingCount === 0) {
			return 100;
		}

		return Math.round((1 - this.savePendingCount / this.saveTotalCount) * 100);
	}

	public decrementSavePendingCounter() {
		if (this.savePendingCount > 0) {
			this.savePendingCount--;
		}
	}
}

export default Globals;
