import { useState, useEffect, useContext } from 'react';
import { Box, ToggleButtonGroup, ToggleButton, Typography, Button } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { ShowChart, TableRows } from '@mui/icons-material';
import { endOfDay, startOfDay } from 'date-fns';
import { Range } from 'react-date-range';

import Entity from 'store/entity';
import { Status } from 'DataTypes';
import { socket } from 'api/api';
import { subscribeToPointData, unsubscribeFromPointData } from 'api/telemetry';
import ChannelDataChart from './ChannelDataChart';
import ChannelDataTable from './ChannelDataTable';
import DeleteTelemetryDialog from './DeleteTelemetryDialog';
import { UserContext } from 'store/UserProvider';
import DateRangePickerField from './DateRangePickerField';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			margin: '1rem',
			height: 'calc(100% - 81px)',
			minWidth: 0,
		},
		formControl: {
			marginRight: theme.spacing(4),
		},
		buttonLabel: {
			marginLeft: theme.spacing(1),
		},
	}),
);

interface IChannelTelemetryProps {
	entity: Entity;
}

export default function ChannelTelemetry({ entity }: IChannelTelemetryProps) {
	const classes = useStyles();
	const [fetchStatus, setFetchStatus] = useState(Status.None);
	const [data, setData] = useState<{ x: Date; y: number }[]>([]);
	const [dateRange, setDateRange] = useState<Range>({
		startDate: startOfDay(new Date()),
		endDate: endOfDay(new Date()),
		key: 'selection',
	});
	const [visualisationType, setVisualisationType] = useState('chart');
	const [deleteDataDialogOpen, setDeleteDataDialogOpen] = useState(false);
	const { user } = useContext(UserContext);

	useEffect(() => {
		// Entity has changed, subscribe to new data
		subscribeToPointData(entity.id);

		return function cleanup() {
			unsubscribeFromPointData(entity.id);
		};
	}, [entity]);

	useEffect(() => {
		if (!dateRange.startDate || !dateRange.endDate) {
			return;
		}

		// Entity or date range has changed, load initial data and set callback for processing new data
		function handleTelemetryEvent({ time, value, pointId }: { time: string; value: number; pointId: string }) {
			if (pointId === entity.id) {
				const newPoint = { x: new Date(time), y: value };
				displayData([newPoint], dateRange.startDate!, dateRange.endDate!);
			}
		}

		socket.on('telemetry', handleTelemetryEvent);

		setFetchStatus(Status.Loading);

		loadData();

		return function cleanup() {
			socket.off('telemetry', handleTelemetryEvent);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entity, dateRange]);

	const loadData = () => {
		if (!dateRange.startDate || !dateRange.endDate) {
			return;
		}

		fetchData(dateRange.startDate, dateRange.endDate)
			.then((data) => {
				displayData(data, dateRange.startDate!, dateRange.endDate!, true);
				setFetchStatus(Status.Done);
			})
			.catch((error) => {
				setFetchStatus(Status.Error);
				throw error;
			});
	};

	const fetchData = async (startDate: Date, endDate: Date) => {
		const apiData = await entity.fetchTelemetry(startDate, endDate);

		return apiData.map((p) => ({ x: new Date(p.time), y: p.value }));
	};

	const displayData = (
		data: Array<{ x: Date; y: number }>,
		startDate: Date,
		endDate: Date,
		overrideExisting?: boolean,
	) => {
		setData((current) => {
			const newData = overrideExisting ? [] : [...current];

			for (const newPoint of data) {
				if (!newData.find((p) => p.x.getTime() === newPoint.x.getTime())) {
					newData.push(newPoint);
				}
			}

			return newData.filter((p) => p.x >= startDate && p.x <= endDate);
		});
	};

	const canEdit = user?.roles.includes('Contributor') || false;

	return (
		<Box className={classes.container}>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: (theme) => theme.spacing(1),
					marginBottom: (theme) => theme.spacing(2),
				}}
			>
				<Typography variant="body2">Date range:</Typography>
				<DateRangePickerField onChange={(value) => setDateRange(value)} value={dateRange} />
				<ToggleButtonGroup
					value={visualisationType}
					exclusive
					onChange={(e, value) => {
						if (value !== null) {
							setVisualisationType(value);
						}
					}}
					size="small"
				>
					<ToggleButton value="chart">
						<ShowChart />
						<Typography variant="button" className={classes.buttonLabel}>
							Chart
						</Typography>
					</ToggleButton>
					<ToggleButton value="table">
						<TableRows />
						<Typography variant="button" className={classes.buttonLabel}>
							Table
						</Typography>
					</ToggleButton>
				</ToggleButtonGroup>
				{canEdit && (
					<Button variant="outlined" onClick={() => setDeleteDataDialogOpen(true)}>
						Delete data...
					</Button>
				)}
			</Box>
			{visualisationType === 'chart' ? (
				<ChannelDataChart entity={entity} fetchStatus={fetchStatus} data={data}></ChannelDataChart>
			) : (
				<ChannelDataTable entity={entity} fetchStatus={fetchStatus} data={data}></ChannelDataTable>
			)}
			{deleteDataDialogOpen && (
				<DeleteTelemetryDialog
					pointId={entity.id}
					onClose={(result: boolean) => {
						if (result) {
							loadData();
						}

						setDeleteDataDialogOpen(false);
					}}
				/>
			)}
		</Box>
	);
}
