import { IApiUser, IApiUserWithProfile } from '@mitie/metadata-api-types';

import * as Api from './api';

export async function fetchUsersList() {
	const resp = await Api.get<IApiUser[]>('users');

	return resp;
}

export function create(id: string) {
	return Api.post<IApiUser>('users', { id });
}

export function deleteUser(id: string) {
	return Api.delete_<void>(`users/${id}`);
}

export function search(search: string) {
	return Api.post<(IApiUser & IApiUserWithProfile)[]>('users/search', { search });
}
