import { useMemo } from 'react';
import { TextField } from '@mui/material';
import { TemplatePropertyType } from '@mitie/metadata-api-types';

interface IEntityPropertyProps {
	value?: string | number | boolean;
	label: string;
	type: TemplatePropertyType;
	select?: Array<{ value: number | string; label: string }>;
	className: string;
	disabled?: boolean;
	onChange?: (value?: string | number | boolean) => void;
}

export default function EntityProperty({
	label,
	type,
	className,
	onChange,
	select,
	value,
	disabled,
}: IEntityPropertyProps) {
	const parsedValue = useMemo(() => {
		if (value === undefined) {
			return '';
		}

		switch (type) {
			case 'boolean':
				return value ? '1' : '0';
			case 'number':
				return Number(value);
			default:
				return value || '';
		}
	}, [value, type]);

	const parseValue = (value: string) => {
		if (value === '') {
			return undefined;
		}

		switch (type) {
			case 'boolean':
				return value === '1' ? true : false;
			case 'number':
				const numberValue = parseFloat(value);
				return isNaN(numberValue) ? 0 : numberValue;
			default:
				return value;
		}
	};

	if (type === 'boolean') {
		return (
			<TextField
				value={parsedValue}
				select={true}
				label={label}
				type={type}
				onChange={(e) => onChange && onChange(parseValue(e.target.value))}
				margin="normal"
				variant="outlined"
				className={className}
				disabled={disabled}
				SelectProps={{
					native: true,
				}}
				InputLabelProps={{
					shrink: true,
				}}
			>
				<option value="">Not set</option>
				<option value="0">No</option>
				<option value="1">Yes</option>
			</TextField>
		);
	} else if (select) {
		return (
			<TextField
				value={parsedValue}
				select={true}
				label={label}
				type={type}
				onChange={(e) => onChange && onChange(parseValue(e.target.value))}
				margin="normal"
				variant="outlined"
				className={className}
				disabled={disabled}
				SelectProps={{
					native: true,
				}}
				InputLabelProps={{
					shrink: true,
				}}
			>
				{select.map(({ value: v, label: l }) => (
					<option key={v} value={v}>
						{l}
					</option>
				))}
			</TextField>
		);
	} else {
		return (
			<TextField
				value={parsedValue}
				label={label}
				type={type}
				onChange={(e) => onChange && onChange(parseValue(e.target.value))}
				margin="normal"
				variant="outlined"
				className={className}
				disabled={disabled}
				multiline={type === 'string-multiline'}
				rows={3}
			/>
		);
	}
}
