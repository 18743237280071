import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Divider, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { autorun } from 'mobx';

import EntityView from './EntityView';
import AssetTree from 'components/AssetTree';
import ResizablePanel from './ResizablePanel';
import NewAssetDialog from './NewAssetDialog';
import { useNavigate, useParams } from 'routing/routing';
import { useStores } from 'store';
import { UserContext } from 'store/UserProvider';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flex: 1,
			display: 'flex',
			height: '100%',
		},
		sidebar: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
		},
		grow: {
			flexGrow: 1,
		},
		treeContainer: {
			display: 'flex',
			flexDirection: 'column',
			borderRight: '1px solid rgba(0, 0, 0, 0.12)',
			minHeight: 0,
			flexGrow: 1,
		},
		topBar: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		actionButton: {
			margin: theme.spacing(1),
		},
	}),
);

interface IAssetsProps {
	mappingModeEnabled?: boolean;
}

export default observer(function Assets({ mappingModeEnabled }: IAssetsProps) {
	const classes = useStyles();
	const { entities } = useStores();
	const [newAssetDialogOpen, setNewAssetDialogOpen] = useState(false);
	const { locationId, entityId } = useParams();
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	useEffect(
		() =>
			autorun(() => {
				if (!entityId) {
					return;
				}

				const entity = entities.addAndGetEntity(entityId);

				if (entity && entity.isEquip === false && entity.isPoint === false) {
					navigate(null, { entityId: null });
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[entityId],
	);

	const canEdit = user?.roles.includes('Contributor') || false;

	return (
		<Box className={classes.root}>
			<ResizablePanel defaultWidth={350} className={classes.sidebar}>
				<Box className={classes.treeContainer}>
					{canEdit && (
						<>
							<Box className={classes.topBar}>
								<Button
									startIcon={<Add />}
									variant="contained"
									onClick={() => setNewAssetDialogOpen(true)}
									className={classes.actionButton}
									disabled={locationId === undefined}
								>
									Add asset...
								</Button>
							</Box>
							<Divider />
						</>
					)}
					<AssetTree
						selected={entityId || null}
						onSelect={(entityId) => navigate(null, { entityId })}
						mappingModeEnabled={mappingModeEnabled}
					/>
				</Box>
			</ResizablePanel>
			<EntityView hierarchy="equip" />
			{newAssetDialogOpen && <NewAssetDialog onClose={() => setNewAssetDialogOpen(false)} />}
		</Box>
	);
});
