import { IUser } from 'store/UserProvider';

export type AuthenticationResult = 'Unauthenticated' | 'Forbidden' | 'Authenticated';

export async function getProfile(): Promise<{ status: AuthenticationResult; profile?: IUser }> {
	const r = await fetch(`${process.env.REACT_APP_API_URL}/auth/me`, {
		method: 'GET',
		credentials: 'include',
	});

	if (r.status === 401) {
		return { status: 'Unauthenticated' };
	} else if (r.status === 403) {
		try {
			const data: IUser = await r.json();
			return { status: 'Forbidden', profile: data };
		} catch {
			return { status: 'Forbidden' };
		}
	} else {
		const data: IUser = await r.json();
		return { status: 'Authenticated', profile: data };
	}
}

export function login() {
	window.location.replace(
		`${process.env.REACT_APP_API_URL}/auth/login?ref=${encodeURIComponent(window.location.href)}`,
	);
}

export function logout() {
	window.location.replace(
		`${process.env.REACT_APP_API_URL}/auth/logout?ref=${encodeURIComponent(window.location.origin)}`,
	);
}
