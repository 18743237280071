import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Box,
	Typography,
	TextField,
	IconButton,
	Tooltip,
	Divider,
	LinearProgress,
	Tab,
	Tabs,
	Badge,
	CircularProgress,
	Chip,
	InputAdornment,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Delete as DeleteIcon, Undo as UndoIcon, Info as InfoIcon, Autorenew } from '@mui/icons-material';
import { autorun } from 'mobx';

import { Status } from 'DataTypes';
import TagsInput from './TagsInput';
import EntityIntegration from './EntityIntegration';
import ChannelTelemetry from './ChannelTelemetry';
import { useStores } from 'store';
import DeviceTwin from './DeviceTwin';
import ChannelDeviceTwin from './ChannelDeviceTwin';
import DeviceCommands from './DeviceCommands';
import ChannelDeviceCommands from './ChannelDeviceCommands';
import { useParams, useNavigate } from 'routing/routing';
import Entity from 'store/entity';
import EntityProperties from './EntityProperties';
import { UserContext } from 'store/UserProvider';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
		statusChip: {
			marginRight: theme.spacing(1),
		},
		grow: {
			flexGrow: 1,
		},
		topBar: {
			display: 'flex',
			alignItems: 'center',
		},
		titleText: {
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		iconsDivider: {
			width: '1px',
			backgroundColor: theme.palette.divider,
			height: '48px',
			margin: '0 12px',
		},
		button: {
			cursor: 'pointer',
		},
		tabs: {
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
		tab: {
			'&>span:first-child': {
				flexDirection: 'row',
			},
		},
		tabIcon: {
			marginLeft: '0.5rem',
			color: '#009900',
		},
		entityDetails: {
			overflow: 'auto',
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
		},
		templatesContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		selectWithAdornment: {
			'& .MuiNativeSelect-icon': {
				marginRight: theme.spacing(5),
			},
		},
	}),
);

export enum TabName {
	Templates = 'type',
	Entity = 'properties',
	DeviceTwin = 'deviceTwin',
	DeviceCommands = 'deviceCommands',
	Telemetry = 'telemetry',
	Integration = 'integration',
}

interface IEntityViewProps {
	hierarchy?: 'location' | 'gateway' | 'equip';
}

function isTabValidForEntity(entity: Entity, tab: TabName, canEdit: boolean) {
	if (!entity.isDevice && !entity.isPoint && (tab === TabName.DeviceTwin || tab === TabName.DeviceCommands)) {
		return false;
	} else if ((!entity.isPoint || entity.created) && tab === TabName.Telemetry) {
		return false;
	} else if (tab === TabName.DeviceCommands && !canEdit) {
		return false;
	} else {
		return true;
	}
}

export default observer(function EntityView({ hierarchy }: IEntityViewProps) {
	const classes = useStyles();
	const {
		entities,
		entityTemplates,
		deviceTemplates,
		externalSystemTemplates,
		devicePointTemplates,
		entityPointTemplates,
	} = useStores();
	const { entityId, selectedTab } = useParams();
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	useEffect(() => {
		// Fetch templates
		entityTemplates.fetchAll();
		deviceTemplates.fetchAll();
		externalSystemTemplates.fetchAll();
		devicePointTemplates.fetchAll();
		entityPointTemplates.fetchAll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(
		() =>
			autorun(() => {
				if (!entityId) {
					return;
				}

				const entity = entities.addAndGetEntity(entityId);

				// De-select entity if it doesn't exists
				if (entity.dataRequest === Status.Error || entity.dataRequest === Status.Empty) {
					navigate(null, { entityId: null });
				}

				// Fetch data for selected entity
				if (!entity.created && entity.dataRequest === Status.None) {
					entity.fetchData();
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[entityId],
	);

	useEffect(
		() =>
			autorun(() => {
				if (!entityId) {
					return;
				}

				const entity = entities.addAndGetEntity(entityId);

				// Switch tab if selected tab does not exist for selected entity
				if (selectedTab === undefined) {
					navigate(null, { selectedTab: TabName.Entity });
				} else if (
					(entity.created || entity.dataRequest === Status.Done) &&
					!isTabValidForEntity(entity, selectedTab, canEdit)
				) {
					navigate(null, { selectedTab: TabName.Entity });
				}

				// Load device twin if necessary
				if (selectedTab === TabName.DeviceTwin && entity.isDevice) {
					entity.fetchDeviceConfigData();
					entity.fetchDeviceReportedConfigData();
					entity.fetchDeviceStatus();
				}
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[entityId, selectedTab],
	);

	if (!entityId || !selectedTab) {
		return null;
	}

	const canEdit = user?.roles.includes('Contributor') || false;
	const entity = entities.addAndGetEntity(entityId);

	const title = entity.isClient
		? 'Client details'
		: entity.isLocation
		? 'Location details'
		: entity.isPoint
		? 'Point details'
		: entity.isDevice
		? 'Device details'
		: 'Asset details';
	const entityLoading = entity.dataRequest === Status.Loading || entity.saveRequest === Status.Loading;

	const discardChanges = () => {
		if (!entity) {
			return;
		}

		entity.discardChanges();
	};

	const deleteEntity = () => {
		if (!entity) {
			return;
		}

		if (entity.isPoint && entity.parentDevice) {
			const metricName = entity.getProperty('metric_name');

			if (metricName) {
				entity.parentDevice.removeChannelDeviceTwin(metricName as string);
			}
		}

		entity.delete(true);
	};

	const updateTags = (tags: string[]) => {
		if (!entity.data) {
			return;
		}

		entity.data.tags = tags;
	};

	const updateEntityTemplate = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!entity) {
			return;
		}

		entity.setEntityTemplate(event.target.value);
	};

	const updateDeviceTemplate = (event: React.ChangeEvent<HTMLInputElement>) => {
		entity.setDeviceTemplate(event.target.value);
	};

	return (
		<Box className={classes.container}>
			<Box className={classes.topBar}>
				<Typography variant="h6" className={classes.titleText}>
					{title}
				</Typography>
				{entity.saving ? (
					<>
						<Chip label="Saving..." color="secondary" size="small" className={classes.statusChip} />
						<CircularProgress color="secondary" size={20} />
					</>
				) : entity.unsaved ? (
					<Chip
						label={entity.created ? 'New' : entity.deleted ? 'Deleted' : 'Modified'}
						color="secondary"
						size="small"
						className={classes.statusChip}
					/>
				) : (
					<Tooltip
						title={entity.updatedTime ? `Last saved: ${entity.updatedTime.toLocaleString()}` : 'Not saved yet'}
						placement="right"
					>
						<InfoIcon className={classes.button} color="action" />
					</Tooltip>
				)}
				{canEdit && (
					<>
						<Box className={classes.grow} />
						{!entity.created && !entity.deleted && (
							<Tooltip title="Delete entity" placement="bottom">
								<IconButton color="default" onClick={deleteEntity}>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
						)}
						{entity.unsaved && (
							<>
								<Box className={classes.iconsDivider} />
								<Tooltip title="Discard changes" placement="bottom">
									<IconButton color="default" onClick={discardChanges}>
										<UndoIcon />
									</IconButton>
								</Tooltip>
							</>
						)}
					</>
				)}
			</Box>
			<Divider />
			<Box className={classes.entityDetails}>
				{entityLoading && <LinearProgress />}
				{entity.data && (
					<>
						<Tabs
							value={isTabValidForEntity(entity, selectedTab, canEdit) ? selectedTab : false}
							onChange={(event, value: TabName) => navigate(null, { selectedTab: value })}
							variant="scrollable"
							scrollButtons="auto"
							className={classes.tabs}
							indicatorColor="primary"
							textColor="primary"
						>
							<Tab value={TabName.Templates} label="Type" className={classes.tab} />
							<Tab value={TabName.Entity} label="Properties" className={classes.tab} />
							{isTabValidForEntity(entity, TabName.DeviceTwin, canEdit) && (
								<Tab
									value={TabName.DeviceTwin}
									label={
										<Badge color="secondary" variant="dot" invisible={!entity.deviceUnsaved}>
											Device twin
										</Badge>
									}
									className={classes.tab}
								/>
							)}
							{isTabValidForEntity(entity, TabName.DeviceCommands, canEdit) && (
								<Tab value={TabName.DeviceCommands} label="Device commands" className={classes.tab} />
							)}
							{isTabValidForEntity(entity, TabName.Telemetry, canEdit) && (
								<Tab value={TabName.Telemetry} label="Live telemetry" className={classes.tab} />
							)}
							<Tab value={TabName.Integration} label="Integration" className={classes.tab} />
						</Tabs>
						{selectedTab === TabName.Templates && (
							<Box className={classes.templatesContainer}>
								{!entity.isPoint && (
									<TextField
										value={entity.data.templates.entity || ''}
										select={true}
										label="Entity type"
										onChange={updateEntityTemplate}
										margin="normal"
										variant="outlined"
										className={classes.field}
										classes={{ root: classes.selectWithAdornment }}
										SelectProps={{
											native: true,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={() => {
															if (entity.entityTemplate) {
																entity.setEntityTemplate(entity.entityTemplate.id);
															}
														}}
														edge="end"
														disabled={!entity.entityTemplate || !canEdit}
													>
														<Tooltip title="Re-apply template">
															<Autorenew />
														</Tooltip>
													</IconButton>
												</InputAdornment>
											),
										}}
										disabled={!canEdit}
									>
										<option value="">No template</option>
										{entityTemplates.templates.map(({ id, data }) => (
											<option value={id} key={id}>
												{data?.name}
											</option>
										))}
									</TextField>
								)}
								{entity.isDevice && (
									<TextField
										value={entity.data.templates.device || ''}
										select={true}
										label="Device type"
										onChange={updateDeviceTemplate}
										margin="normal"
										variant="outlined"
										className={classes.field}
										classes={{ root: classes.selectWithAdornment }}
										SelectProps={{
											native: true,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={() => {
															if (entity.deviceTemplate) {
																entity.setDeviceTemplate(entity.deviceTemplate.id);
															}
														}}
														edge="end"
														disabled={!entity.deviceTemplate}
													>
														<Tooltip title="Re-apply template">
															<Autorenew />
														</Tooltip>
													</IconButton>
												</InputAdornment>
											),
										}}
										disabled={!canEdit}
									>
										<option value="">No device template</option>
										{deviceTemplates.templates.map(({ id, data }) => (
											<option value={id} key={id}>
												{data?.name}
											</option>
										))}
									</TextField>
								)}
								{entity.isPoint && (
									<TextField
										value={entity.data.templates.device_point || ''}
										select={true}
										label="Channel template"
										onChange={(event) => (entity.data!.templates.device_point = event.target.value)}
										margin="normal"
										variant="outlined"
										className={classes.field}
										SelectProps={{
											native: true,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										disabled={!canEdit}
									>
										<option value="">No channel template</option>
										{devicePointTemplates.templates.map((template) => (
											<option key={template.id} value={template.id}>
												{template.data?.name}
											</option>
										))}
									</TextField>
								)}
								{entity.isPoint && (
									<TextField
										value={entity.data.templates.entity_point || ''}
										select={true}
										label="Point template"
										onChange={(event) => entity.setEntityPointTemplate(event.target.value)}
										margin="normal"
										variant="outlined"
										className={classes.field}
										SelectProps={{
											native: true,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										disabled={!canEdit}
									>
										<option value="">No template</option>
										{entityPointTemplates.templates.map((template) => (
											<option key={template.id} value={template.id}>
												{template.data?.name}
											</option>
										))}
									</TextField>
								)}
								<TagsInput
									label="Tags"
									value={entity.data.tags}
									onChange={updateTags}
									className={classes.field}
									disabled={true}
								/>
							</Box>
						)}
						{selectedTab === TabName.Entity && (
							<EntityProperties entity={entity} hierarchy={hierarchy} canEdit={canEdit} />
						)}
						{selectedTab === TabName.DeviceTwin && entity.isDevice && <DeviceTwin entity={entity} canEdit={canEdit} />}
						{selectedTab === TabName.DeviceTwin && entity.isPoint && (
							<ChannelDeviceTwin entity={entity} canEdit={canEdit} />
						)}
						{selectedTab === TabName.DeviceCommands && entity.isDevice && canEdit && <DeviceCommands entity={entity} />}
						{selectedTab === TabName.DeviceCommands && entity.isPoint && canEdit && (
							<ChannelDeviceCommands entity={entity} />
						)}
						{selectedTab === TabName.Telemetry && <ChannelTelemetry entity={entity} />}
						{selectedTab === TabName.Integration && <EntityIntegration entity={entity} canEdit={canEdit} />}
					</>
				)}
			</Box>
		</Box>
	);
});
