import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: `${process.env.REACT_APP_APPINSIGHT_KEY}`,
		disableFetchTracking: false,
		appId: `${process.env.REACT_APP_AZURE_AD_CLIENT_ID}`,
	},
});
appInsights.loadAppInsights();
