import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Divider, List, ListItem, ListItemText } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import classNames from 'classnames';

import Entity from 'store/entity';
import { useStores } from 'store';
import EntityExternalSystemMapping from './EntityExternalInterfaceMapping';
import { ExternalSystemTemplate } from 'store/externalSystemTemplate';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			minHeight: 0,
			flexGrow: 1,
		},
		sideBar: {
			display: 'flex',
			flexDirection: 'column',
			minWidth: '16rem',
		},
		listContainer: {
			overflow: 'auto',
			flexShrink: 0,
		},
		details: {
			flexGrow: 1,
			overflow: 'auto',
		},
		inactive: {
			color: theme.palette.grey[600],
		},
	}),
);

interface IEntityIntegrationProps {
	entity: Entity;
	canEdit: boolean;
}

export default observer(function DevicePointsList({ entity, canEdit }: IEntityIntegrationProps) {
	const classes = useStyles();
	const { externalSystemTemplates } = useStores();
	const [selectedItem, setSelectedItem] = useState<ExternalSystemTemplate>();

	return (
		<Box className={classes.root}>
			<Box className={classes.sideBar}>
				<Box className={classes.listContainer}>
					<List>
						{externalSystemTemplates.templates.map(
							(template, index) =>
								template.data && (
									<ListItem
										button={true}
										selected={selectedItem && template.id === selectedItem.id}
										onClick={() => setSelectedItem(template)}
										key={index}
										className={classNames({
											[classes.inactive]: !entity.data?.externalMappings[template.id],
										})}
									>
										<ListItemText primary={template.displayName} />
									</ListItem>
								),
						)}
					</List>
				</Box>
			</Box>
			<Divider orientation="vertical" />
			<Box className={classes.details}>
				{selectedItem && (
					<EntityExternalSystemMapping
						entity={entity!}
						templateId={selectedItem.id}
						systemName={selectedItem.data?.name!}
						template={selectedItem.data?.template!}
						canEdit={canEdit}
					/>
				)}
			</Box>
		</Box>
	);
});
