import { IconButton, TextField, InputAdornment, Tooltip } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import classNames from 'classnames';
import { TemplatePropertyType } from '@mitie/metadata-api-types';
import { Done, HelpOutline } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		green: {
			color: '#009900',
		},
	}),
);

interface IDevicePropertyProps {
	reported?: string | number | boolean | null;
	lastReported?: string;
	label: string;
	type: TemplatePropertyType;
	className: string;
}

export default function DeviceReadOnlyProperty({
	reported,
	lastReported,
	label,
	type,
	className,
}: IDevicePropertyProps) {
	const classes = useStyles();

	const reportedTooltip =
		reported === undefined || reported === null ? 'No reported value' : `Reported value: ${reported}`;

	const parseInputValue = (value?: string | boolean | number | null) => {
		if (value === undefined) {
			value = null;
		}

		switch (type) {
			case 'boolean':
				return value ? '1' : '';
			case 'number':
				return Number(value);
			default:
				return value || '';
		}
	};

	const reportedValue = parseInputValue(reported ? reported : null);

	return (
		<TextField
			value={reportedValue}
			label={label}
			type={type}
			margin="normal"
			variant="outlined"
			className={classNames([className])}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Tooltip title={reportedTooltip}>
							<IconButton>{reported !== undefined ? <Done className={classes.green} /> : <HelpOutline />}</IconButton>
						</Tooltip>
					</InputAdornment>
				),
				disabled: true,
			}}
		/>
	);
}
