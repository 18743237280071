import { useCallback, useRef, useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawer: {
			position: 'relative',
		},
		dragger: {
			width: 10,
			cursor: 'ew-resize',
			position: 'absolute',
			height: '100%',
			right: -5,
			top: 0,
		},
	}),
);

interface IResizablePanelProps {
	defaultWidth: number;
	className?: string;
	sx?: SxProps;
	children?: React.ReactNode;
}

export default function ResizablePanel({ className, sx, children, defaultWidth }: IResizablePanelProps) {
	const classes = useStyles();
	const [drawerWidth, setDrawerWidth] = useState(defaultWidth);
	const drawerElement = useRef<HTMLDivElement>(null);

	const handleMouseDown = () => {
		document.addEventListener('mouseup', handleMouseUp, true);
		document.addEventListener('mousemove', handleMouseMove, true);
	};

	const handleMouseUp = () => {
		document.removeEventListener('mouseup', handleMouseUp, true);
		document.removeEventListener('mousemove', handleMouseMove, true);
	};

	const handleMouseMove = useCallback(
		(e: MouseEvent) => {
			if (!drawerElement.current) {
				return;
			}

			const mousePos = e.clientX - document.body.offsetLeft;
			const offset = drawerElement.current.offsetLeft;
			const newWidth = mousePos - offset;
			const minWidth = defaultWidth * 0.5;

			if (newWidth >= minWidth) {
				setDrawerWidth(newWidth);
			}

			e.preventDefault();
		},
		[defaultWidth],
	);

	return (
		<Box className={classes.drawer} ref={drawerElement}>
			<Box className={className} sx={{ ...sx, width: drawerWidth }}>
				{children}
			</Box>
			<Box onMouseDown={handleMouseDown} className={classes.dragger} />
		</Box>
	);
}
