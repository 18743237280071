import { useEffect, useState } from 'react';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Error } from '@mui/icons-material';
import { FileDocument } from 'mdi-material-ui';

import { registerImporter, parseEntityFromApi, IFileImportResult } from 'api/entities';
import Uploader from '../components/Uploader';
import { IEntityData } from 'store/entity';
import { useStores } from 'store';

const parseEntitiesToImport = (file: IFileImportResult) => {
	const entities: { id: string; entityData: IEntityData }[] = [];

	if (file.status === 'Parsing completed' && file.content && file.content.data) {
		if (file.content.fileType !== null) {
			if (file.content.data.new) {
				entities.push(
					...file.content.data.new.map((entity) => ({ id: entity.id, entityData: parseEntityFromApi(entity) })),
				);
			}

			if (file.content.data.changed) {
				entities.push(
					...file.content.data.changed.map((entity) => ({ id: entity.id, entityData: parseEntityFromApi(entity) })),
				);
			}
		}
	} else {
		return [];
	}

	return entities;
};

export default observer(function ImportDialog() {
	const { entities } = useStores();
	const [importResult, setImportResult] = useState<IFileImportResult>({ status: 'No file' });
	const [entitiesToImport, setEntitiesToImport] = useState<{ id: string; entityData: IEntityData }[]>([]);
	const tableColumns: GridColDef[] = [
		{ field: 'id', headerName: 'Entity ID', flex: 1 },
		{
			field: 'name',
			headerName: 'Name',
			valueGetter: (params: GridValueGetterParams<any, { id: string; entityData: IEntityData }>) =>
				params.row.entityData.name,
			flex: 1,
		},
		{
			field: 'type',
			headerName: 'Type',
			valueGetter: (params: GridValueGetterParams<any, { id: string; entityData: IEntityData }>) =>
				params.row.entityData.tags,
			flex: 1,
		},
	];

	useEffect(() => {
		setEntitiesToImport(parseEntitiesToImport(importResult));
	}, [importResult]);

	useEffect(() => {
		if (importResult.status === 'File loaded' && importResult.rawContent) {
			registerImporter(importResult.rawContent, setFileContent, 0);
		}
	}, [importResult]);

	const setFileContent = (content: Partial<IFileImportResult>) => {
		setImportResult((current) => {
			return { ...current, ...content };
		});
	};

	const importEntities = () => {
		entities.addEntitiesFromUi(entitiesToImport);
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', margin: (theme) => theme.spacing(1), width: '100%' }}>
			<Box sx={{ display: 'flex' }}>
				<Uploader onStatusChange={setFileContent} />
				{importResult.status !== 'No file' && (
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						{importResult.status === 'Loading error' || importResult.status === 'Parsing error' ? (
							<Error color="error" />
						) : (
							<FileDocument />
						)}
						<Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: (theme) => theme.spacing(1) }}>
							{(importResult.status === 'Loading in progress' || importResult.status === 'Parsing in progress') && (
								<LinearProgress />
							)}
							<Typography variant="subtitle2">{importResult.fileName}</Typography>
							{importResult.status === 'Loading error' || importResult.status === 'Parsing error' ? (
								<Typography variant="caption" color="error">
									{importResult.error}
								</Typography>
							) : (
								<Typography variant="caption">{importResult.statusText}</Typography>
							)}
						</Box>
					</Box>
				)}
			</Box>
			{importResult.status === 'Parsing completed' && (
				<>
					<Box style={{ display: 'flex', height: '100%' }}>
						<Box sx={{ flexGrow: 1 }}>
							<DataGrid rows={entitiesToImport} columns={tableColumns} autoPageSize={true} disableSelectionOnClick />
						</Box>
					</Box>
					<Box>
						<Button onClick={importEntities} color="primary" autoFocus={true} disabled={entitiesToImport.length === 0}>
							{entitiesToImport.length === 0
								? 'Import'
								: `Import ${entitiesToImport.length} ${entitiesToImport.length > 1 ? 'entities' : 'entity'}`}
						</Button>
					</Box>
				</>
			)}
		</Box>
	);
});
