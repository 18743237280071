import { useState } from 'react';
import { Box, Chip, Input, FormControl, Typography, Button } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Add } from '@mui/icons-material';
import { TemplatePropertyType } from '@mitie/metadata-api-types';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginTop: theme.spacing(2),
		},
		selectChip: {
			marginRight: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		options: {
			margin: theme.spacing(1),
		},
		button: {
			marginLeft: theme.spacing(1),
		},
	}),
);

interface IDropdownOptionsInputProps {
	value:
		| Array<{
				value: number | string;
				label: string;
		  }>
		| undefined;
	type: TemplatePropertyType;
	onChange: (
		newOptions:
			| Array<{
					value: number | string;
					label: string;
			  }>
			| undefined,
	) => void;
	canEdit: boolean;
}

export default function DropdownOptionsInput({ value, type, onChange, canEdit }: IDropdownOptionsInputProps) {
	const classes = useStyles();

	const [labelInput, setLabelInput] = useState('');
	const [valueInput, setValueInput] = useState('');

	return (
		<Box className={classes.root}>
			<Typography variant="h6">Limit values to:</Typography>
			<Box className={classes.options}>
				{value && value.length ? (
					value.map(({ label, value: v }, index) => (
						<Chip
							className={classes.selectChip}
							key={v}
							label={`${label} (${v})`}
							onDelete={
								canEdit
									? () => {
											if (value) {
												const newItems = [...value];
												newItems.splice(index, 1);

												onChange(newItems);
											}
									  }
									: undefined
							}
						/>
					))
				) : (
					<Typography variant="body2">Any value allowed</Typography>
				)}
			</Box>
			{canEdit && (
				<>
					<Box sx={{ flexDirection: 'row', display: 'inline' }}>
						<FormControl>
							<Input
								placeholder="Label"
								sx={{ fontSize: '13px', marginLeft: '8px', marginRight: '8px' }}
								value={labelInput}
								onChange={(e) => setLabelInput(e.currentTarget.value)}
							/>
						</FormControl>
						<FormControl>
							<Input
								placeholder="Value"
								sx={{ fontSize: '13px' }}
								value={valueInput}
								onChange={(e) => setValueInput(e.currentTarget.value)}
							/>
						</FormControl>
					</Box>
					<Button
						size="small"
						disabled={!labelInput || !valueInput || (value && value.find((s) => s.value === valueInput) !== undefined)}
						variant="outlined"
						onClick={() => {
							const existingItems = value || [];

							const newItem = {
								label: labelInput,
								value: type === 'number' ? parseFloat(valueInput) : valueInput,
							};

							onChange([...existingItems, newItem]);

							setLabelInput('');
							setValueInput('');
						}}
						startIcon={<Add fontSize="small" />}
						className={classes.button}
					>
						Add option
					</Button>
				</>
			)}
		</Box>
	);
}
