import { action, computed, makeObservable, observable } from 'mobx';
import { IExternalSystemTemplate } from '@mitie/metadata-api-types';
import { v4 } from 'uuid';

import Templates from './templates';
import { ExternalSystemTemplate } from './externalSystemTemplate';

export default class ExternalSystemTemplates extends Templates<IExternalSystemTemplate> {
	private list: { [id: string]: ExternalSystemTemplate } = {};

	public constructor() {
		super('external_system');

		makeObservable<ExternalSystemTemplates, 'list'>(this, {
			addAndGetTemplate: action,
			templates: computed,
			deleteTemplate: action,
			list: observable,
		});
	}

	public getTemplate(id: string): ExternalSystemTemplate | undefined {
		return this.list[id];
	}

	public addAndGetTemplate(templateId: string): ExternalSystemTemplate {
		const template = this.getTemplate(templateId);

		if (template) {
			return template;
		}

		const newTemplate = new ExternalSystemTemplate(templateId);
		this.list[templateId] = newTemplate;

		return newTemplate;
	}

	public get templates() {
		return Object.keys(this.list)
			.map((id) => this.list[id])
			.sort((a, b) => a.displayName.localeCompare(b.displayName));
	}

	public createTemplate(baseTemplateId?: string) {
		const entityId = v4();
		const newTemplate = this.addAndGetTemplate(entityId);

		if (baseTemplateId) {
			const baseTemplate = this.getTemplate(baseTemplateId);

			if (!baseTemplate || !baseTemplate.data) {
				throw new Error('Unable to create template: Base template not found');
			}

			newTemplate.setData(baseTemplate.data);
			newTemplate.data!.name = 'New integration template';
		} else {
			newTemplate.setData({
				name: 'New integration template',
				template: {
					features: [],
					properties: [],
					device_twin_tags: {},
				},
			});
		}

		return newTemplate;
	}

	public deleteTemplate(template: ExternalSystemTemplate) {
		template.savedData = undefined;
		template.data = undefined;
		template.updatedTime = undefined;

		delete this.list[template.id];
	}
}
