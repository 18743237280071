import { useState } from 'react';
import {
	Box,
	CircularProgress,
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TablePagination,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import Entity from 'store/entity';
import { Status } from 'DataTypes';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			position: 'relative',
			height: 'calc(100% - 64px)',
			overflow: 'hidden',
		},
		container: {
			height: 'calc(100% - 52px)',
		},
		loadingIndicator: {
			width: '100%',
			height: '100%',
			position: 'absolute',
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	}),
);

interface IChannelDataChartProps {
	entity: Entity;
	fetchStatus: Status;
	data: { x: Date; y: number }[];
}

export default function ChannelDataChart({ entity, fetchStatus, data }: IChannelDataChartProps) {
	const classes = useStyles();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const units = entity.data?.properties.units || null;

	return (
		<Paper className={classes.root}>
			{fetchStatus === Status.Loading && (
				<Box className={classes.loadingIndicator}>
					<CircularProgress />
				</Box>
			)}
			<TableContainer className={classes.container}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell>Time</TableCell>
							<TableCell align="right">Value</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data
							.sort((a, b) => b.x.getTime() - a.x.getTime())
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map(({ x, y }, index) => (
								<TableRow hover key={index}>
									<TableCell>{x.toLocaleString()}</TableCell>
									<TableCell align="right">{`${y.toLocaleString()}${units ? ' ' + units : ''}`}</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[50, 100, 500, 1000]}
				component="div"
				count={data.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={(event, page) => setPage(page)}
				onRowsPerPageChange={(event) => {
					setRowsPerPage(Number(event.target.value));
					setPage(0);
				}}
			/>
		</Paper>
	);
}
