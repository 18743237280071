import { Chip, Tooltip } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Done, ErrorOutline } from '@mui/icons-material';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(2),
			overflow: 'auto',
		},
		selectChip: {
			marginTop: theme.spacing(2),
			marginRight: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		fab: {
			marginLeft: theme.spacing(1),
		},
		mismatch: {
			backgroundColor: theme.palette.error.main,
			color: theme.palette.error.contrastText,
		},
		mismatchIcon: {
			color: theme.palette.error.contrastText,
		},
		deleted: {
			textDecoration: 'line-through',
		},
	}),
);

interface IDeviceTwinChannelsProps {
	desired: {
		[property: string]: string | number | boolean;
	}[];
	reported:
		| {
				[property: string]: string | number | boolean;
		  }[]
		| undefined;
}

export default observer(function DeviceTwinChannels({ desired, reported }: IDeviceTwinChannelsProps) {
	const classes = useStyles();

	const mappedChannels: { match: boolean; name: string; desiredMissing: boolean; reportedMissing: boolean }[] = [];

	for (const desiredChannel of desired) {
		const name = desiredChannel.metric_name;
		if (typeof name === 'string') {
			const desiredMissing = false;
			let match = false;

			const reportedChannel = (reported || []).find((c) => c.metric_name === name);
			const reportedMissing = reportedChannel === undefined;

			if (!reportedMissing) {
				match = isEqual(desiredChannel, reportedChannel);
			}

			mappedChannels.push({ match, name, desiredMissing, reportedMissing });
		}
	}

	if (reported) {
		for (const reportedChannel of reported) {
			const name = reportedChannel.metric_name;

			if (typeof name === 'string') {
				const desiredChannel = desired.find((c) => c.metric_name === name);
				const desiredMissing = desiredChannel === undefined;

				if (desiredMissing) {
					const match = false;
					const reportedMissing = false;

					mappedChannels.push({ match, name, desiredMissing, reportedMissing });
				}
			}
		}
	}

	return (
		<>
			{mappedChannels.map(({ match, name, desiredMissing, reportedMissing }) => (
				<Tooltip
					title={
						match
							? 'Desired and reported properties are matching'
							: desiredMissing
							? 'Channel deleted'
							: reportedMissing
							? 'No reported value'
							: 'Reported property mismatch'
					}
					key={name}
				>
					<Chip
						className={classNames({
							[classes.selectChip]: true,
							[classes.mismatch]: !match,
							[classes.deleted]: desiredMissing,
						})}
						label={`${name}`}
						icon={match ? <Done /> : <ErrorOutline className={classes.mismatchIcon} />}
					/>
				</Tooltip>
			))}
		</>
	);
});
