import { IExternalSystemTemplate } from '@mitie/metadata-api-types';

import Entity from 'store/entity';
import { observer } from 'mobx-react-lite';
import { Box, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
		enableSwitch: {
			marginTop: '16px',
			marginRight: '1rem',
			marginBottom: '8px',
			marginLeft: 0,
			height: '56px',
		},
		featuresContainer: {
			marginTop: '0.5rem',
		},
	}),
);

interface IEntityExternalSystemMappingProps {
	entity: Entity;
	templateId: string;
	systemName: string;
	template: IExternalSystemTemplate;
	canEdit: boolean;
}

export default observer(function EntityExternalSystemMapping({
	entity,
	templateId,
	systemName,
	template,
	canEdit,
}: IEntityExternalSystemMappingProps) {
	const classes = useStyles();

	const enabled = entity.data?.externalMappings[templateId] !== undefined;

	const setEnabled = (checked: boolean) => {
		if (!entity.data) {
			return;
		}

		if (checked) {
			entity.data.externalMappings[templateId] = {};
		} else {
			delete entity.data.externalMappings[templateId];
		}
	};

	if (!entity.data) {
		return null;
	}

	return (
		<Box className={classes.root}>
			<Box className={classes.featuresContainer}>
				<Typography variant="subtitle1">{`Integration levels for ${systemName}:`}</Typography>
				<ul>
					{template.features.map((feature) => {
						switch (feature) {
							case 'manual':
								return (
									<li key={feature}>
										<Typography>
											No automated synchronisation. The corresponding entity in the external system needs to be created
											manually and the corresponding IDs to be added manually below
										</Typography>
									</li>
								);
							case 'slave':
								return (
									<li key={feature}>
										<Typography>
											One-way automated synchronisation. Entity in the external system is created and updated based on
											the entity data.
										</Typography>
									</li>
								);
							case 'telemetry':
								return (
									<li key={feature}>
										<Typography>
											The telemetry will be forwarded automatically to the external system if the entity is a device and
											the corresponding entity exists in the external system.
										</Typography>
									</li>
								);
							default:
								return null;
						}
					})}
				</ul>
			</Box>

			<FormControlLabel
				control={
					<Switch
						checked={enabled}
						disabled={!canEdit}
						color="primary"
						onChange={(event) => setEnabled(event.target.checked)}
					/>
				}
				label="Enabled"
				className={classes.enableSwitch}
			/>

			{template.properties.map(({ label, name, type }) => {
				return (
					<TextField
						key={name}
						label={label}
						value={entity.getExternalMapping(templateId, name) || ''}
						onChange={(e) => {
							entity.setExternalMapping(templateId, name, e.target.value);

							// Add device twin tags when relevant
							// Todo: remove tags when swich is set to disabled
							if (entity.isDevice) {
								if (entity.deviceConfigData) {
									for (const tagName in template.device_twin_tags) {
										const tagValue = template.device_twin_tags[tagName];
										entity.deviceConfigData.tags[tagName] = tagValue;
									}
								}
							} else if (entity.isPoint) {
								if (entity.parentDevice && entity.parentDevice.deviceConfigData) {
									for (const tagName in template.device_twin_tags) {
										const tagValue = template.device_twin_tags[tagName];
										entity.parentDevice.deviceConfigData.tags[tagName] = tagValue;
									}
								}
							}
						}}
						margin="normal"
						variant="outlined"
						type={type}
						className={classes.field}
						disabled={!enabled || !canEdit}
					/>
				);
			})}
		</Box>
	);
});
